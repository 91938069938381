import { Component, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { UiModalService } from '@app/core/services';
import { ToastrService } from 'ngx-toastr';

@Component({
  templateUrl: './modal-input.component.html',
})
export class ModalInputComponent implements OnInit {
  @Output() completeHandler: (url: string) => void;

  @Input() text: string;
  @Input() title: string;
  @Input() label = $localize`URL`;
  @Input() errorMessage = $localize`Please enter a valid Url`;
  @Input() validators = [];

  inputForm: UntypedFormGroup;

  constructor(
    private modalService: UiModalService,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.inputForm = this.formBuilder.group({
      text: [this.text, this.validators],
    });
  }

  closeAction() {
    this.modalService.destroy();
  }

  submitForm(form) {
    if (form.invalid) {
      this.toastr.error($localize`There was a problem`);
      return;
    }

    this.completeHandler(this.inputForm.get('text').value);
    this.modalService.destroy();
  }
}
