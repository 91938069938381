import { ApplicationRef, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, UserService, PollsService } from '@app/core/services';

import { Action, Actions } from '@app/shared/model';
import { videoroomConfig } from '@env/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-live-stage',
  templateUrl: './live-stage.component.html',
  styleUrls: ['./live-stage.component.scss'],
})
export class LiveStageComponent implements OnInit, OnDestroy {
  primaryAction: Action | null;
  stage: any;
  stageNumber: number;

  roomVisible = false;
  roomStarted = false;
  roomJoined = false;
  displayName: string;
  userId: string | null = null;

  chatVisible = false;
  pollsVisible = false;

  canJoin = false;

  janusServerUrl = videoroomConfig.url;
  iceServers = videoroomConfig.iceServers;

  viewersCount: number;
  viewersSubscription: Subscription;

  isConnected = false;

  actions: string[];

  polls: any[] = [];

  constructor(
    private route: ActivatedRoute,
    public authService: AuthService,
    private userService: UserService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private afDatabase: AngularFireDatabase,
    private pollsService: PollsService,
  ) {
    this.displayName = this.authService.userInfo?.username || 'Guest';
    this.userId = this.authService.activeUser?.uid || null;
    this.primaryAction = this.authService.canPresentOnLiveStage ? Actions.join : null;

    this.actions = this.authService.companySettings.disablestagechat
      ? ['leave', 'audioMute', 'videoMute', 'shareScreen', 'participants', 'sharePost', 'polls']
      : ['leave', 'audioMute', 'videoMute', 'shareScreen', 'participants', 'chat', 'sharePost', 'polls'];
  }

  ngOnInit(): void {
    this.watchParams();
    this.loadPolls();
  }

  ngOnDestroy() {}

  loadPolls() {
    this.pollsService.getPolls().subscribe((polls) => {
      this.polls = polls;
    });
  }

  watchParams() {
    this.route.params.subscribe((params) => {
      this.stageNumber = params.id;
    });

    this.route.data.subscribe((data) => {
      this.roomVisible = false;
      this.leftRoom();
      this.cd.detectChanges();
      this.stage = data.stage;
      this.roomVisible = true;
      this.viewersSubscription?.unsubscribe();

      const path = `livevideo/room2/${this.authService.currentBusinessId}/${this.stage.id}`;
      this.viewersSubscription = this.afDatabase
        .object<any>(path)
        .valueChanges()
        .subscribe((room: any) => {
          this.viewersCount = room?.usersliveuniq || 0;
        });
    });
  }

  connected() {
    this.isConnected = true;
    this.roomStarted = true;
    this.canJoin = true;
  }

  join() {
    this.roomJoined = true;
    this.canJoin = null;
  }

  leftRoom() {
    this.roomJoined = false;
    this.canJoin = true;
  }

  toggleChat() {
    this.chatVisible = !this.chatVisible;
  }

  togglePolls() {
    this.pollsVisible = !this.pollsVisible;
  }
}
