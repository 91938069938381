<div class="row" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="col-7">
    <div *ngIf="title || true" [class]="options.titleClass" [attr.aria-label]="title">
      {{ title }}
    </div>
    <div
      *ngIf="message && options.enableHtml"
      role="alert"
      aria-live="polite"
      [class]="options.messageClass"
      [innerHTML]="message"
    ></div>
    <div
      *ngIf="message && !options.enableHtml"
      role="alert"
      aria-live="polite"
      [class]="options.messageClass"
      [attr.aria-label]="message"
    >
      {{ message }}
    </div>
  </div>
  <div class="col-5 text-right">
    <a class="action action-accept" (click)="action(LiveVideoIncomingCallResult.Accept)" i18n>Accept Call</a>
    <a class="action action-reject" (click)="action(LiveVideoIncomingCallResult.Reject)" i18n>Reject Call</a>
  </div>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
