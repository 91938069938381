import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { configUrl } from '@env/environment';
import { Appointment } from '@app/shared/model';
import { AuthService } from '.';
import { map } from 'rxjs/operators';

@Injectable()
export class AppointmentsService {
  api = configUrl.URL;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  public getMyAppointments(): Observable<Appointment[]> {
    const uid = this.authService.activeUser?.uid;
    const url = `${this.api}appointment/user/${uid}`;
    return this.http.get<any>(url).pipe(
      map((appointments) => {
        return appointments.map((appointment) => {
          const memberIds = appointment.members ? appointment.members.split(',') : [];
          const memberNames = appointment.membersnames ? appointment.membersnames.split(',') : [];

          const members = memberIds.map((id, index) => ({ id, name: memberNames[index] }));

          return {
            id: appointment.id,
            title: appointment.title,
            details: appointment.description,
            manyAttendees: appointment.maxparticipants > 2,
            start: appointment.startdate,
            end: appointment.enddate,
            roomId: appointment.roomid,
            members,
          };
        });
      }),
    );
  }

  getMyBooked() {
    const url = `${this.api}appointment/booked/me`;
    return this.http.get<any>(url).pipe(
      map((appointments) => {
        return appointments.map((appointment) => {
          const memberIds = appointment.members ? appointment.members.split(',') : [];
          const memberNames = appointment.membersnames ? appointment.membersnames.split(',') : [];

          const members = memberIds.map((id, index) => ({ id, name: memberNames[index] }));

          return {
            id: appointment.id,
            title: appointment.title,
            details: appointment.description,
            manyAttendees: appointment.maxparticipants > 2,
            start: appointment.startdate,
            end: appointment.enddate,
            roomId: appointment.roomid,
            members,
            ownerId: appointment.ownerid,
          };
        });
      }),
    );
  }

  getExternalEvents(): Observable<any[]> {
    const calendarUrl = encodeURIComponent('https://app.development.eventanywhere.co.uk/en/invite.ics');
    const url = `${this.api}appointment/calendar?calendarurl=${calendarUrl}`;
    return this.http.get<any[]>(url);
  }

  public getUsersList(): Observable<any[]> {
    const url = `${this.api}appointment`;
    return this.http.get<any[]>(url);
  }

  public getUserAppointments(userId: string): Observable<Appointment[]> {
    const url = `${this.api}appointment/user/${userId}`;
    return this.http.get<any>(url);
  }

  public getUserPublicAppointments(userId: string): Observable<Appointment[]> {
    const url = `${this.api}appointment/user/${userId}/public`;
    return this.http.get<any>(url);
  }

  public createAppointments(appointments: Appointment[]): Observable<Appointment> {
    const url = `${this.api}appointment`;
    return this.http.post<any>(url, appointments);
  }

  public bookAppointment(appointment: any) {
    const url = `${this.api}appointment/book`;
    const body = {
      id: appointment.id,
    };
    return this.http.post<any>(url, body);
  }

  public bookPublicAppointment(appointment: any, username: string, email: string) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = `${this.api}appointment/book/public`;
    const body = {
      id: appointment.id,
      username,
      email,
      timezone,
    };
    return this.http.post<any>(url, body);
  }

  public updateAppointment(appointment: Appointment): Observable<Appointment> {
    const body = {
      id: appointment.id,
      title: appointment.title,
      descrription: appointment.details,
      startdate: appointment.start,
      enddate: appointment.end,
      maxparticipants: appointment.manyAttendees ? 99 : 4,
    };
    const url = `${this.api}appointment/${appointment.id}`;
    return this.http.put<any>(url, body);
  }

  public deleteAppointment(appointment: Appointment): Observable<any> {
    const url = `${this.api}appointment/${appointment.id}`;
    return this.http.delete<any>(url);
  }

  public getAppoinment(appointmentId: string) {
    const url = `${this.api}appointment/${appointmentId}`;
    return this.http.get<any>(url);
  }

  public getPublicAppointment(appointmentId: string, code: string, email: string) {
    const url = `${this.api}appointment/${appointmentId}/code?code=${encodeURIComponent(
      code,
    )}&email=${encodeURIComponent(email)}`;
    return this.http.get<any>(url);
  }

  public cancelAppointment(appointmentId: string, code: string, email: string) {
    const url = `${this.api}appointment/book/${appointmentId}/code?code=${encodeURIComponent(
      code,
    )}&email=${encodeURIComponent(email)}`;
    return this.http.delete<any>(url);
  }
}
