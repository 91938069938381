import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(array: any[], fn: (item: any, param: any, p2: any) => boolean, param: any, p2: any): any[] {
    if (!array) {
      return array;
    }
    return array.filter((item) => fn(item, param, p2));
  }
}
