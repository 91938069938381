import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SharedModule } from '@app/shared/shared.module';

import { TwitterCardComponent } from './twitter-card/twitter-card.component';

const twitterCardRoutes: Routes = [
  {
    path: 'twittercard/:id',
    component: TwitterCardComponent,
  },
];

@NgModule({
  declarations: [TwitterCardComponent],
  imports: [SharedModule, RouterModule.forChild(twitterCardRoutes)],
  exports: [RouterModule],
})
export class TwitterCardModule {}
