import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Subject, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { LiveVideoCall } from '.';
import {
  LiveVideoIncomingCallToastComponent,
  LiveVideoIncomingCallResult,
} from '../live-video-incoming-call-toast/live-video-incoming-call-toast.component';

interface IncomingCallToast {
  call: LiveVideoCall;
  toast: ActiveToast<any>;
}

@Injectable()
export class LiveVideoCallToastsService {
  private callToasts: IncomingCallToast[] = [];

  private callAudioElement: HTMLAudioElement;

  private callAcceptedSubject = new Subject<LiveVideoCall>();
  public callAccepted$ = this.callAcceptedSubject.asObservable();

  private callRejectedSubject = new Subject<LiveVideoCall>();
  public callRejected$ = this.callRejectedSubject.asObservable();

  private hasToastsSubject = new BehaviorSubject<boolean>(false);
  private hasToasts$ = this.hasToastsSubject.asObservable().pipe(distinctUntilChanged());

  constructor(private toastr: ToastrService) {
    this.hasToasts$.subscribe((hasToasts) => (hasToasts ? this.startCallAudio() : this.stopCallAudio()));
  }

  add(call: LiveVideoCall) {
    const toast = this.toastr.info(call.callername, 'Incoming Call', {
      toastComponent: LiveVideoIncomingCallToastComponent,
      disableTimeOut: true,
      tapToDismiss: false,
    });

    toast.onAction.subscribe((action) => {
      this.remove(call);

      if (action === LiveVideoIncomingCallResult.Accept) {
        this.callAcceptedSubject.next(call);
      } else {
        this.callRejectedSubject.next(call);
      }
    });

    this.callToasts
      .filter((callToast) => callToast.call.roomid === call.roomid)
      .forEach((callToast) => {
        callToast.toast.toastRef.manualClose();
      });

    this.callToasts = this.callToasts
      .filter((callToast) => callToast.call.roomid !== call.roomid)
      .concat({ call, toast });
    this.hasToastsSubject.next(this.callToasts.length > 0);
  }

  remove(call: LiveVideoCall) {
    this.callToasts
      .filter((callToast) => callToast.call.roomid === call.roomid)
      .forEach((callToast) => {
        callToast.toast.toastRef.manualClose();
      });

    this.callToasts = this.callToasts.filter((callToast) => callToast.call.roomid !== call.roomid);

    this.hasToastsSubject.next(this.callToasts.length > 0);
  }

  private startCallAudio() {
    this.stopCallAudio();
    this.callAudioElement = new Audio();
    this.callAudioElement.loop = true;
    this.callAudioElement.src = '/assets/ring.mp3';
    this.callAudioElement.load();
    this.callAudioElement.play();
  }

  private stopCallAudio() {
    this.callAudioElement?.pause();
  }
}
