import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { UserService, QueryService, UiModalService, AuthService, NavService, LangService } from '@app/core/services';

import { ModalAlertComponent } from '@app/shared/modal/modal-alert/modal-alert.component';
import { configUrl, config, environment } from '@env/environment';
import { request } from 'http';
import { of, Subscription } from 'rxjs';
import { ModalLanguageListComponent } from '../modal/modal-language-list/modal-language-list.component';

@Component({
  selector: 'app-app-bar-user',
  templateUrl: './app-bar-user.component.html',
  styleUrls: ['./app-bar-user.component.scss'],
})
export class AppBarUserComponent implements OnInit, OnDestroy {
  @Input() dropUp = true;

  configUrl: any = configUrl;
  config = config;
  environment = environment;
  loginName: string;
  password: string;
  regLoginName: string;
  regLoginEmail: string;
  regPassword: string;
  isLogin = true;
  showSocialLogin = false;
  createAccount = true;
  togglePhotoChange = false;

  userInfo: any;

  imageSrc =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
  imageSrcOnError =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

  authSub: Subscription;

  lang = (id: string) => this.langService.lang(id);

  constructor(
    public us: UserService,
    public qs: QueryService,
    private modal: UiModalService,
    private sanitizer: DomSanitizer,
    public authService: AuthService,
    public navService: NavService,
    private langService: LangService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.authSub = this.authService.user$.subscribe((auth) => {
      this.userInfo = auth.user;
      this.imageSrc = this.userInfo
        ? `${this.configUrl.bucketImage}${this.authService.activeUser?.uid}/${this.userInfo.imageid}`
        : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    });
  }

  ngOnDestroy() {
    this.authSub?.unsubscribe();
  }

  about() {
    const title = this.config.title;
    const inputs = {
      title: $localize`About ${title}`,
      message: this.sanitizer.bypassSecurityTrustHtml(
        `<p>${this.config.title} &copy; 2020. ` +
          $localize`All Rights Reserved.` +
          `<br>` +
          $localize`Version ${this.environment.version}:versionNumber:` +
          `<br><a href="mailto:${this.config.enquiryEmail}">${this.config.enquiryEmail}</a>` +
          `</p>`,
      ),
    };
    this.modal.init(ModalAlertComponent, inputs);
  }

  changeLanguage() {
    const outputs = {
      actionCallback: (language) => {
        const userId = this.authService.activeUser?.uid;

        let request$ = of(null);

        if (userId) {
          const body = {
            id: userId,
            lang: language,
            username: this.authService.userInfo.username,
            privacy: this.authService.userInfo.privacy,
          };

          request$ = this.us.updateUser(body);
        }

        request$.subscribe(() => {
          window.location.href = `${this.document.location.origin}/${language}${this.router.url}`;
        });
      },
    };
    this.modal.init(ModalLanguageListComponent, {}, outputs);
  }

  updateUrl(event: any) {
    this.imageSrc = this.imageSrcOnError;
  }
}
