import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Action } from '@app/shared/model';

@Component({
  selector: 'app-page-actions-navigation',
  templateUrl: './page-actions-navigation.component.html',
  styleUrls: ['./page-actions-navigation.component.scss'],
})
export class PageActionsNavigationComponent {
  @Input() actions: Action[];
  @Output() actionHandler = new EventEmitter<Action>();

  onAction(action: Action) {
    this.actionHandler.emit(action);
  }
}
