import { Component, OnInit, Output, Input, ChangeDetectorRef, ApplicationRef } from '@angular/core';
import { timer, forkJoin, of, Observable, from } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AuthService, UiModalService, UserService } from '@app/core/services';

const { listTimeZones } = require('timezone-support');
import * as CryptoJS from 'crypto-js';

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

import { config } from '@env/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

class ImageSnippet {
  constructor(
    public src: string,
    public file: File,
    public imageCheckSum: string,
  ) {}
}

@Component({
  templateUrl: './modal-user-onboarding.component.html',
  styleUrls: ['./modal-user-onboarding.component.scss'],
})
export class ModalUserOnboardingComponent implements OnInit {
  config = config;

  @Input() user: any;
  @Output() completionHandler: (response: any) => void;
  categories: any;

  title: string;
  companyname: string;
  jobrole: string;

  // tslint:disable-next-line: variable-name
  idc_province: string;

  // tslint:disable-next-line: variable-name
  idc_cipr_number: string;

  // tslint:disable-next-line: variable-name
  idc_amf_number: string;

  saving = false;

  thumbnail: ImageSnippet;
  originalUrl: string;

  timeZones = listTimeZones();
  localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  uncroppedUrl: SafeUrl;

  get companySettings(): any {
    return this.authService.companySettings;
  }

  provinces = {
    AB: `Alberta`,
    BC: `British Columbia`,
    MB: `Manitoba`,
    NB: `New Brunswick`,
    NL: `Newfoundland and Labrador`,
    NS: `Nova Scotia`,
    ON: `Ontario`,
    PE: `Prince Edward Island`,
    QC: `Quebec`,
    SK: `Saskatchewan`,
  };

  constructor(
    private modalService: UiModalService,
    private toastr: ToastrService,
    public us: UserService,
    private sanitizer: DomSanitizer,
    private app: ApplicationRef,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.getUserImage().subscribe((url) => {
      this.originalUrl = url;
    });

    this.title = this.user.username;
    this.companyname = this.user.companyname;
    this.jobrole = this.user.jobrole;
    this.idc_province = this.user.idc_province;
    this.idc_cipr_number = this.user.idc_cipr_number;
    this.idc_amf_number = this.user.idc_amf_number;
  }

  saveAction() {
    if (!this.title) {
      return;
    }

    if (!this.saving) {
      this.saving = true;

      const body = {
        id: this.user.id,
        username: this.title,
        companyname: this.companyname,
        jobrole: this.jobrole,
        onboarded: 1,
        privacy: 'Public',
        idc_province: this.idc_province,
        idc_cipr_number: this.idc_cipr_number,
        idc_amf_number: this.idc_amf_number,
      };

      const request$ = this.us.updateUser(body);

      forkJoin({
        user: request$,
        thumbnail: this.thumbnail ? this.sendImage(this.thumbnail) : of(null),
        timer: timer(500),
      }).subscribe(
        ({ user }) => {
          this.saving = false;
          this.authService.refreshAuth.next('refresh');
          this.completionHandler(user);
          this.modalService.destroy();
        },
        (error) => {
          this.toastr.error($localize`There was a problem updating the user`);
          this.saving = false;
        },
      );
    }
  }

  dismissAction() {
    this.modalService.destroy();
  }

  processFile(podcastThumbnailInput: any) {
    const file: File = podcastThumbnailInput.files[0];
    podcastThumbnailInput.value = '';

    this.uncroppedUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }

  cropped(blob) {
    this.uncroppedUrl = null;

    const file = this.blobToFile(blob, 'image.jpeg');

    const reader = new FileReader();
    reader.onloadend = () => {
      const wordArray = CryptoJS.lib.WordArray.create(reader.result);
      const imageCheckSum = CryptoJS.MD5(wordArray).toString();

      this.thumbnail = new ImageSnippet(URL.createObjectURL(file), file, imageCheckSum);
      this.app.tick();
    };
    reader.readAsArrayBuffer(file);
  }

  cancelled() {
    this.uncroppedUrl = null;
  }

  public blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;

    return <File>theBlob;
  };

  removeThumbnail() {
    this.thumbnail = null;
    this.app.tick();
  }

  sendImage(image: ImageSnippet): Observable<any> {
    const body = {
      filechecksum: image.imageCheckSum,
      file: image.file,
      authorid: this.user.id,
    };
    return this.us.updateUserImage(body);
  }

  getUserImage(): Observable<any> {
    const imageId = this.user.imageid;
    if (!imageId) {
      return of(null);
    }
    const path = `image/${this.user.id}/${imageId}`;
    const storageRef = firebase.storage().ref();
    return from(storageRef.child(path).getDownloadURL());
  }
}
