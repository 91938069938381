import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { configUrl } from '@env/environment';
import { AuthService } from './auth.service';

import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';

class ImageSnippet {
  constructor(
    public src: string,
    public file: File,
  ) {}
}

@Injectable()
export class GroupService {
  groupListChanged$ = new Subject<null>();
  configUrl = configUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toastr: ToastrService,
  ) {}

  getMyGroups(params?: { [param: string]: string }, fullResponse: boolean = false): Observable<any> {
    const options: any = { params };
    if (fullResponse) {
      options.observe = 'response';
    }
    return this.http.get(configUrl.groupMy, options);
  }

  createGroup(name: string): Observable<any> {
    const url = configUrl.group;

    const body = {
      name,
      privacy: 'Private',
    };

    return this.http.post(url, body);
  }

  updateGroup(id: string, name: string, image?: ImageSnippet): Observable<any> {
    const url = configUrl.group;

    const formData = new FormData();
    formData.append('id', id);
    formData.append('name', name);

    return this.getImageChecksum(image).pipe(
      switchMap((checksum: string) => {
        if (image) {
          formData.append('image', image.file, 'image.jpeg');
          formData.append('imagechecksum', checksum);
        }

        return this.http.put(url, formData);
      }),
    );
  }

  deleteGroup(group: any): Observable<any> {
    const url = configUrl.groupDelete;

    const body = {
      id: group.id,
    };

    const options = { body };

    return this.http.request('delete', url, options);
  }

  inviteUser(group: any, user: any): Observable<any> {
    const url = configUrl.groupUserInvite;

    const body = {
      groupid: group.id,
      userid: user.id,
      typeid: parseInt(user.userrole, 10) || 2,
      statusid: 1,
    };

    return this.http.post(url, body);
  }

  groupUserInviteByEmail(email: string, groupid: string, callback: any = () => {}): any {
    const body = {
      email,
      groupid,
    };

    this.http.post(this.configUrl.groupUserInviteEmail, body).subscribe(
      (response) => {
        this.toastr.success($localize`Invitation sent to ${email}`);
        callback(null, response);
      },
      (error) => {
        this.toastr.error($localize`Unable to send invitation to ${email}`);
        callback(error, null);
      },
    );
  }

  updateGroupUserInviteByEmail(email: string, groupid: string): Observable<any> {
    const body = {
      email,
      groupid,
    };

    return this.http.put(this.configUrl.groupUserInviteEmail, body);
  }

  removeUserFromGroup(groupid: string, member: any): Observable<any> {
    if (!confirm('remove User from the group ?')) {
      return;
    }

    const body = {
      groupid,
      userid: member.id || member.userid,
      statusid: 3,
    };

    const observable = this.http.put(this.configUrl.groupUser, body);
    observable.subscribe();

    return observable;
  }

  changeAdminUserFromGroup(groupid: string, member: any): Observable<any> {
    const body = {
      groupid,
      userid: member.id || member.userid,
      typeid: parseInt(member.typeid, 10),
    };

    const observable = this.http.put(this.configUrl.groupUser, body);

    observable.subscribe();

    return observable;
  }

  acceptGroupRequest(groupid: string, statusid: any): Observable<any> {
    const body = {
      groupid,
      userid: this.authService.activeUser.uid,
      statusid,
    };

    const updateGroupUserInvite$ = this.http.put(this.configUrl.groupUserInvite, body).pipe(
      tap(() => {
        const notificationStatus = statusid === 3 ? 'rejected' : 'accepted';
        this.toastr.success($localize`Invitation ${notificationStatus}`);
        this.groupListChanged();
      }),
    );

    return updateGroupUserInvite$;
  }

  leaveGroup(groupid: string) {
    const body = {
      groupid,
      userid: this.authService.activeUser.uid,
      statusid: 3,
    };

    return this.http.put(this.configUrl.groupUserInvite, body);
  }

  observeGroupListChanges(): Observable<null> {
    return this.groupListChanged$.asObservable();
  }

  groupListChanged() {
    this.groupListChanged$.next();
  }

  private getImageChecksum(image?: ImageSnippet): Observable<any> {
    const subject = new Subject();
    if (image) {
      const fileReader = new FileReader();

      fileReader.onload = function () {
        const arrayBuffer = this.result;
        const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
        const imagechecksum = CryptoJS.MD5(wordArray).toString();
        subject.next(imagechecksum);
        subject.complete();
      };

      fileReader.readAsArrayBuffer(image.file);
    } else {
      setTimeout(function () {
        subject.next(null);
        subject.complete();
      });
    }

    return subject.asObservable();
  }
}
