import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, LiveVideoService } from '@app/core/services';
import { configUrl } from '@env/environment';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-embed-contact',
  templateUrl: './embed-contact.component.html',
  styleUrls: ['./embed-contact.component.scss'],
})
export class EmbedContactComponent {
  user;

  presenceSubscription: Subscription;

  presence: any;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private authService: AuthService,
    private afDatabase: AngularFireDatabase,
    private liveVideoService: LiveVideoService,
    private router: Router,
  ) {
    this.route.params.subscribe((params: any) => {
      this.id = params.id;
      this.loadUser();
    });
  }

  id: string;

  loadUser() {
    const url = `${configUrl.getUserUrl}${this.id}`;

    this.http.get(url).subscribe(
      (user) => {
        this.user = user;
        this.observeUserPresence();
      },
      (err) => {},
    );
  }

  observeUserPresence() {
    this.presenceSubscription?.unsubscribe();
    const subdomain = this.authService.getSubdomain() || '_global';

    this.presenceSubscription = this.afDatabase
      .object<any>(`presence/${subdomain}/${this.user.id}`)
      .valueChanges()
      .subscribe((presence) => {
        this.presence = presence;
      });
  }

  call() {
    const userId = this.user.id;

    if (this.authService.activeUser) {
      this.liveVideoService
        .createRoom()
        .pipe(
          switchMap((room) => {
            return this.liveVideoService.addUserToRoom(userId, room.id).pipe(map((_) => room));
          }),
        )
        .subscribe((room) => {
          this.navigateToRoom(room.id, userId);
        });
    } else {
      this.liveVideoService
        .createPublicRoom()
        .pipe(
          switchMap((room) => {
            return this.liveVideoService.addUserToPublicRoom(userId, room.id).pipe(map((_) => room));
          }),
        )
        .subscribe((room) => {
          this.navigateToRoom(room.id, userId);
        });
    }
  }

  private navigateToRoom(roomId: string, call?: string) {
    const queryParams = { call, returnUrl: this.router.url, public: !this.authService.activeUser };
    this.router.navigate([`/live/${roomId}`], { queryParams });
  }
}
