<div class="ui-modal-header">
  <h2 i18n>Video Room</h2>
</div>

<div class="ui-modal-body">
  <div class="mb-3">
    <label for="displayName" i18n>Display Name</label>
    <input type="test" class="form-control" id="displayName" name="displayName" [(ngModel)]="displayName" required />
  </div>

  <div class="terms-policy" i18n>
    <a href="{{ configUrl?.terms }}" target="_blank">Terms of Use</a> and
    <a href="{{ configUrl?.policy }}" target="_blank">Privacy Policy</a>
  </div>
</div>

<div class="ui-modal-footer">
  <button class="btn btn-md btn-primary" (click)="submitAction()" [disabled]="!displayName" i18n>
    Enter Video Room
  </button>
</div>
