import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { config } from '@env/environment';
import { LangService } from '../services';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router,
    public toastr: ToastrService,
    private langService: LangService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.user$.pipe(
      map((auth) => {
        if (!auth.auth) {
          this.router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } });
          return false;
        }

        if (auth.companySettings.companyid && !auth.selectedCompany && auth.companySettings.ispublic === 0) {
          this.authService.signOut();
          this.toastr.error($localize`You are not a member of this ${this.langService.lang('community')}`);
          this.router.navigate(['/sign-in']);
          return false;
        }

        return true;
      }),
    );
  }
}
