<div class="shadow-box">
  <div class="box-container">
    <div class="table-container">
      <table class="table">
        <thead>
          <tr>
            <th *ngFor="let column of columns" [class.pinch]="column.pinch">{{ column.name }}</th>
            <th *ngIf="hasActions" class="pinch">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of rows | slice: 0 : (pagination ? paginationLimit - 1 : undefined)">
            <td *ngFor="let column of columns">{{ row.data[column.id] }}</td>
            <td *ngIf="hasActions">
              <ul *ngIf="row.actions" class="nav">
                <li *ngFor="let action of row.actions" class="nav-item">
                  <a (click)="action.callback(row)" class="nav-link" [innerHtml]="action.name"></a>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="freeze" *ngIf="freezeUI"></div>
    </div>
  </div>
</div>
<div>
  <ul
    *ngIf="pagination && !(rows.length < paginationLimit && paginationOffset === 0)"
    class="pagination justify-content-end"
  >
    <li class="page-item" [class.disabled]="paginationOffset === 0">
      <a class="page-link" (click)="prev()" i18n="@@previous">Previous</a>
    </li>
    <li class="page-item" [class.disabled]="rows?.length < paginationLimit">
      <a class="page-link" (click)="next()" i18n="@@next">Next</a>
    </li>
  </ul>
</div>
