<div class="page-header">
  <div class="page-header-title">
    <h2><ng-content></ng-content></h2>
    <nav *ngIf="breadcrumbs" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li *ngFor="let breadcrumb of breadcrumbs" class="breadcrumb-item" [class.active]="!breadcrumb.link">
          <a *ngIf="breadcrumb.link" [routerLink]="breadcrumb.link">{{ breadcrumb.name }}</a>
          <span *ngIf="!breadcrumb.link">{{ breadcrumb.name }}</span>
        </li>
      </ol>
    </nav>
  </div>
  <div class="page-header-actions">
    <button
      *ngIf="primaryAction"
      class="btn btn-primary"
      (click)="clickPrimaryAction(primaryAction)"
      [disabled]="disabledAction"
    >
      {{ primaryAction.name }}
    </button>
    <div *ngIf="actions" class="page-header-actions-menu" ngbDropdown placement="bottom-right">
      <a class="btn btn-link no-caret" ngbDropdownToggle aria-haspopup="true" aria-expanded="false"
        ><mdi name="Actions"></mdi
      ></a>
      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        <div
          *ngFor="let action of actions"
          ngbDropdownItem
          [class.dropdown-item]="action"
          [class.dropdown-divider]="!action"
          (click)="onAction(action)"
        >
          {{ action?.name }}
        </div>
      </div>
    </div>
  </div>
</div>
