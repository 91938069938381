import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up-business',
  template: '',
})
export class SignUpBusinessComponent {
  constructor(
    public location: Location,
    private router: Router,
  ) {
    const queryParams = { returnUrl: '/sign-up-create-url' };
    this.router.navigate(['/auth'], { queryParams });
  }
}
