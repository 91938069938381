import { Injectable, Injector, ComponentFactoryResolver, EmbeddedViewRef, ApplicationRef } from '@angular/core';

interface ChildConfig {
  inputs: object;
  outputs: object;
}

@Injectable()
export class UiModalDomService {
  private childComponentRef;

  get isOpen(): boolean {
    return !!this.childComponentRef;
  }

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}

  appendComponentTo(parentId: string, child: any, childConfig?: ChildConfig) {
    const childComponentRef = this.componentFactoryResolver.resolveComponentFactory(child).create(this.injector);

    this.attachConfig(childConfig, childComponentRef);

    this.childComponentRef = childComponentRef;
    this.appRef.attachView(childComponentRef.hostView);

    const childDomElem = (childComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    document.getElementById(parentId).appendChild(childDomElem);
  }

  removeComponent() {
    if (!this.childComponentRef) {
      return;
    }
    this.appRef.detachView(this.childComponentRef.hostView);
    this.childComponentRef.destroy();
    this.childComponentRef = null;
  }

  private attachConfig(config, componentRef) {
    const inputs = config.inputs;
    const outputs = config.outputs;
    for (const key in inputs) {
      if (inputs.hasOwnProperty(key)) {
        componentRef.instance[key] = inputs[key];
      }
    }
    for (const key in outputs) {
      if (outputs.hasOwnProperty(key)) {
        componentRef.instance[key] = outputs[key];
      }
    }
  }
}
