import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ClipService } from '@app/core/services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-responsive-video',
  templateUrl: './responsive-video.component.html',
  styleUrls: ['./responsive-video.component.scss'],
})
export class ResponsiveVideoComponent implements OnInit, OnChanges {
  @Input() videoUrl;
  @Input() thumbnailUrl;
  @Input() preload = false;
  @Input() autoplay = false;
  @Input() clipid;

  started = false;
  error = false;

  @ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLVideoElement>;

  options: any;

  constructor(private clipService: ClipService) {}

  ngOnInit() {
    this.started = false;

    this.loadVideo();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.videoUrl?.firstChange === false) {
      this.loadVideo();
    }
  }

  loadVideo() {
    this.options = {
      sources: {
        src: this.videoUrl,
        type: 'video/mp4',
      },
      fluid: true,
      poster: this.thumbnailUrl,
      autoplay: this.autoplay,
      controlBar: {
        pictureInPictureToggle: true,
        volumePanel: false,
      },
      playbackRates: [0.5, 1, 1.5, 2],
      aspectRatio: '16:9',
    };
  }

  firstPlayed() {
    this.clipService.markClipAsPlayed({ id: this.clipid });
  }
}
