import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { QueryService, UserService, BusinessService, SignupBusinessService, LangService } from '@app/core/services';

import { configUrl, config } from '@env/environment';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { hide } from '@app/nav.actions';

@Component({
  selector: 'app-create-url',
  templateUrl: './create-url.component.html',
  styleUrls: ['./create-url.component.scss'],
})
export class CreateUrlComponent implements OnInit, OnDestroy {
  nav$: Observable<any>;

  @ViewChild('companuUrl') elCompanuUrl: ElementRef;

  authSub: any;

  configUrl = configUrl;
  config = config;

  companyName: string;
  companyUrl: string;

  companyUrlAvailable: any[] = [];
  isCompanyUrlAvailable = false;

  domain = configUrl.domain;
  title = config.title;

  userIsAHuman = false;

  lang = (id: string) => this.langService.lang(id);

  constructor(
    private router: Router,
    public qs: QueryService,
    public us: UserService,
    public suBusiness: SignupBusinessService,
    private toastr: ToastrService,
    private business: BusinessService,
    private route: ActivatedRoute,
    private store: Store<any>,
    private langService: LangService,
  ) {
    this.nav$ = store.select('nav');
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.companyName = params.companyName;
    });

    setTimeout(() => {
      this.store.dispatch(hide());
    }, 0);
  }

  ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  closePage() {
    this.router.navigate(['/']);
  }

  createCompany(): any {
    if (this.companyUrl === 'app' || this.companyUrl === 'www') {
      this.toastr.error($localize`You cannot use ${this.companyUrl} as a subdomain`);
      return;
    }

    if (!this.isCompanyUrlAvailable) {
      this.toastr.error($localize`Please choose an available url`);
      return;
    }

    if ((this.companyName?.length || 0) <= 2 || (this.companyUrl?.length || 0) <= 1) {
      this.toastr.error(
        $localize`${this.lang(
          'Community_Name',
        )} must have at least 3 characters, and subdomain name at least 2 characters long`,
      );
      return;
    }

    if (!this.userIsAHuman) {
      this.toastr.error($localize`Please confirm you are not a robot`);
      return;
    }

    this.suBusiness.postCompanyUrl(this.companyName, this.companyUrl).subscribe((response) => {
      try {
        const res = response;
        if (res['message']) {
          this.toastr.error($localize`There was a problem creating a ${this.lang('community')}`);
          return;
        } else {
          this.navigateToCompany(response);
        }
      } catch (error) {
        this.toastr.error($localize`There was a problem creating a ${this.lang('community')}`);
        return;
      }
    });
  }

  navigateToCompany(company: any) {
    this.business.navigateToCompany(company);
  }

  checkIfCompanyUrlAllowed(event: any, name: string): any {
    if ((name?.length || 0) < 3) {
      if (event) {
        event.target.classList.add('ng-invalid');
      }
      this.isCompanyUrlAvailable = false;
      return;
    }

    this.fixUrl();

    name = this.companyUrl;

    if (event) {
      event.target.classList.remove('ng-invalid');
    }
    this.suBusiness.checkCompanyUrl(name).subscribe(
      (response) => {
        this.companyUrlAvailable = [];

        try {
          const res = response;
          if (res['ok']) {
            this.isCompanyUrlAvailable = true;
            if (event) {
              event.target.classList.remove('ng-invalid');
            }
          } else {
            if (event) {
              event.target.classList.add('ng-invalid');
            }
            this.isCompanyUrlAvailable = false;

            if (res['available']) {
              this.companyUrlAvailable = res['available'];
            }
          }
        } catch (e) {
          this.isCompanyUrlAvailable = false;
          if (event) {
            event.target.classList.add('ng-invalid');
          }
        }
      },
      (error) => {
        this.toastr.error(error.message);
      },
    );
  }

  changeCompanyUrl(availableUrl: string) {
    this.companyUrl = availableUrl;
    this.companyUrlAvailable = [];
    this.elCompanuUrl.nativeElement.classList.remove('ng-invalid');
  }

  fixUrl() {
    this.companyUrl = this.companyUrl.replace(/\W+/g, '');
    this.companyUrl = this.companyUrl.toLowerCase();
  }

  reCaptureResolved(response: string) {
    this.userIsAHuman = true;
  }
}
