import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NavService {
  private menuVisibleSubject = new BehaviorSubject<boolean>(false);
  menuVisible$ = this.menuVisibleSubject.asObservable();

  showMenu() {
    this.menuVisibleSubject.next(true);
  }

  hideMenu() {
    this.menuVisibleSubject.next(false);
  }
}
