<div class="video-container" *ngIf="room?.statusid === 1">
  <app-jan-room
    *ngIf="roomVisible"
    [url]="janusServerUrl"
    [iceServers]="iceServers"
    [displayName]="displayName"
    [userId]="userId"
    [pin]="pin"
    [secret]="secret"
    [token]="token"
    [room]="roomId"
    (connected)="onJanusConnectionSuccess()"
    [actions]="actions"
    (addUser)="addPeople()"
    (roomLink)="viewRoomLink()"
    (closeRoom)="exit()"
  ></app-jan-room>

  <div class="outgoing-call" *ngIf="activeOutgoingCall">
    <div class="outgoing-call-alert" i18n>Calling {{ activeOutgoingCall.usertocallname }}</div>
    <audio autoplay="true" loop="true">
      <source src="/assets/ring.mp3" type="audio/mpeg" />
    </audio>
  </div>
</div>

<div class="video-container" *ngIf="room?.statusid === 2">
  <div class="video-message" i18n>This meeting has finished</div>
  <a (click)="close()" class="btn btn-outline-light btn-lg" i18n>Close Meeting</a>
</div>

<div class="video-container" *ngIf="room?.statusid === 0">
  <div class="video-message" i18n>Meeting starts at {{ formatDate(room.startdate) }}</div>
  <a (click)="close()" class="btn btn-outline-light btn-lg" i18n>Close Meeting</a>
</div>

<div class="video-container" *ngIf="roomError">
  <div class="video-message">{{ roomError }}</div>
  <a *ngIf="authService.activeUser" (click)="close()" class="btn btn-outline-light btn-lg" i18n>Close Meeting</a>
</div>
