import { Component, Input } from '@angular/core';

import { UiModalService } from '@app/core/services';

@Component({
  templateUrl: './modal-alert.component.html',
})
export class ModalAlertComponent {
  @Input() title = $localize`Alert`;
  @Input() message = '';

  constructor(private modalService: UiModalService) {}

  okAction() {
    this.modalService.destroy();
  }

  dismissAction() {
    this.modalService.destroy();
  }
}
