import { Component, ViewChild, ElementRef, Input, Output, NgZone, OnDestroy } from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { Subscription } from 'rxjs';

import { UiModalService } from '@app/core/services';
import { configUrl } from '@env/environment';
import { User } from '@app/shared/model';

@Component({
  selector: 'app-modal-user-list',
  templateUrl: './modal-user-list.component.html',
})
export class ModalUserListComponent implements OnDestroy {
  @ViewChild('usersList') usersList: ElementRef;

  @Input() actionTitle = $localize`Action`;
  @Input() modalTitle = $localize`:@@users:Users`;
  @Input() users: User[] = [];
  @Output() actionCallback: (user: User) => void;

  listTopShadow = false;
  listBottomShadow = false;

  configUrl = configUrl;

  scrollDispatcherSubscription: Subscription;

  constructor(
    private modal: UiModalService,
    private scrollDispatcher: ScrollDispatcher,
    private zone: NgZone,
  ) {
    this.scrollDispatcherSubscription = this.scrollDispatcher.scrolled().subscribe((x) => {
      this.updateListShadows();
    });
  }

  ngOnDestroy() {
    this.scrollDispatcherSubscription?.unsubscribe();
  }

  updateListShadows() {
    const element = this.usersList?.nativeElement;

    const listTopShadow = this.usersList && element.scrollTop > 0;
    const listBottomShadow = this.usersList && element.scrollTop < element.scrollHeight - element.clientHeight;

    if (this.listTopShadow !== listTopShadow || this.listBottomShadow !== listBottomShadow) {
      this.zone.run((_) => {
        this.listTopShadow = listTopShadow;
        this.listBottomShadow = listBottomShadow;
      });
    }
  }

  dismiss() {
    this.modal.destroy();
  }

  performAction(user: User) {
    this.actionCallback(user);
    this.modal.destroy();
  }
}
