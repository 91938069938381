import { Component, ViewChild, ElementRef, Input, Output, NgZone, OnDestroy, ApplicationRef } from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { Subscription } from 'rxjs';

import { QueryService, UiModalService } from '@app/core/services';
import { configUrl } from '@env/environment';
import { User } from '@app/shared/model';

@Component({
  selector: 'app-modal-language-list',
  templateUrl: './modal-language-list.component.html',
})
export class ModalLanguageListComponent implements OnDestroy {
  @ViewChild('postsList') postsList: ElementRef;

  @Input() actionTitle = $localize`Choose`;
  @Input() modalTitle = $localize`Choose Language`;
  @Output() actionCallback: (post: any) => void;

  @Input() languages = {
    en: 'English',
    fr: 'French',
  };

  listTopShadow = false;
  listBottomShadow = false;

  configUrl = configUrl;

  scrollDispatcherSubscription: Subscription;

  constructor(
    private modal: UiModalService,
    private scrollDispatcher: ScrollDispatcher,
    private zone: NgZone,
    private appRef: ApplicationRef,
    public qs: QueryService,
  ) {
    this.scrollDispatcherSubscription = this.scrollDispatcher.scrolled().subscribe((x) => {
      this.updateListShadows();
    });
  }

  ngOnDestroy() {
    this.scrollDispatcherSubscription?.unsubscribe();
  }

  updateListShadows() {
    const element = this.postsList?.nativeElement;

    const listTopShadow = this.postsList && element.scrollTop > 0;
    const listBottomShadow = this.postsList && element.scrollTop < element.scrollHeight - element.clientHeight;

    if (this.listTopShadow !== listTopShadow || this.listBottomShadow !== listBottomShadow) {
      this.zone.run((_) => {
        this.listTopShadow = listTopShadow;
        this.listBottomShadow = listBottomShadow;
      });
    }
  }

  dismiss() {
    this.modal.destroy();
  }

  performAction(post: any) {
    this.actionCallback(post);
    this.modal.destroy();
  }
}
