import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AngularFireDatabase, ChildEvent } from '@angular/fire/compat/database';
import { Observable, of, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';

import { configUrl } from '@env/environment';
import { AuthService } from './auth.service';

export interface LiveVideoRoom {
  id: string;
  name: string;
  ispermanent: number;
  ownerid: string;
  companyid: string;
  pin: string;
  secret: string;
  token: string;
  createdat: Date;
  updatedat: Date;
  privacy: number;
  statusid?: number;
}

export interface LiveVideoCall {
  callerid: string;
  callerimageid?: string;
  callername: string;
  date: Date;
  roomid: string;
  roomname?: string;
  usertocallid: string;
  usertocallimageid?: string;
  usertocallname: string;
}

export interface LiveVideoActiveRoom {
  date: Date;
  roomid: string;
  roomname: string;
}

export interface LiveVideoMessage {
  message: string;
}

@Injectable()
export class LiveVideoService {
  constructor(
    private http: HttpClient,
    private afDatabase: AngularFireDatabase,
    private authService: AuthService,
  ) {}

  public getRoom(roomId: string, code: string = null, email: string = null): Observable<LiveVideoRoom> {
    const url = code ? `${configUrl.livevideoRoom}${roomId}/code` : `${configUrl.livevideoRoom}${roomId}`;

    const params = { code, email };
    return this.http.get<LiveVideoRoom>(url, { params });
  }

  public createRoom(): Observable<LiveVideoRoom> {
    const url = configUrl.livevideoRoom;
    const body = {
      name: 'videochatroom',
      allowinvites: 1,
    };
    return this.http.post<LiveVideoRoom>(url, body);
  }

  public createPublicRoom(): Observable<LiveVideoRoom> {
    const url = configUrl.livevideoPublicRoom;
    const body = {
      name: 'publicvideochatroom',
      allowinvites: 1,
      privacy: 2,
    };
    return this.http.post<LiveVideoRoom>(url, body);
  }

  public addUserToRoom(userId: string, roomId: string): Observable<LiveVideoMessage> {
    const url = `${configUrl.livevideoRoom}${roomId}/user`;
    const body = {
      userid: userId,
    };
    return this.http.post<LiveVideoMessage>(url, body);
  }

  public addUserToPublicRoom(userId: string, roomId: string): Observable<LiveVideoMessage> {
    const url = `${configUrl.livevideoRoom}${roomId}/user/public`;
    const body = {
      userid: userId,
    };
    return this.http.post<LiveVideoMessage>(url, body);
  }

  public removeUserFromRoom(userId: string, roomId: string): Observable<LiveVideoMessage> {
    const url = `${configUrl.livevideoRoom}${roomId}/user/${userId}`;
    return this.http.delete<LiveVideoMessage>(url);
  }

  public call(userId: string, roomId: string, isPublic = false, displayName: string = null): Observable<LiveVideoRoom> {
    const url = isPublic
      ? `${configUrl.livevideoRoom}${roomId}/call/public`
      : `${configUrl.livevideoRoom}${roomId}/call`;
    const body: any = {
      userid: userId,
    };
    if (displayName && isPublic) {
      body.callerdisplayname = displayName;
    }
    return this.http.post<LiveVideoRoom>(url, body);
  }

  public cancelCall(userId: string, roomId: string, isPublic = false): Observable<LiveVideoRoom> {
    const url = isPublic
      ? `${configUrl.livevideoRoom}${roomId}/call/cancel/public`
      : `${configUrl.livevideoRoom}${roomId}/call/cancel`;
    const body = {
      userid: userId,
    };
    return this.http.post<LiveVideoRoom>(url, body);
  }

  public rejectCall(userId: string, roomId: string): Observable<LiveVideoRoom> {
    const url = `${configUrl.livevideoRoom}${roomId}/call/reject`;
    const body = {
      userid: userId,
    };
    return this.http.post<LiveVideoRoom>(url, body);
  }

  public connect(roomId: string, isPublic = false): Observable<LiveVideoRoom> {
    const url = isPublic
      ? `${configUrl.livevideoRoom}${roomId}/connect/public`
      : `${configUrl.livevideoRoom}${roomId}/connect`;
    const body = {};
    return this.http.post<LiveVideoRoom>(url, body);
  }

  public disconnect(roomId: string, isPublic = false): Observable<LiveVideoRoom> {
    const url = isPublic
      ? `${configUrl.livevideoRoom}${roomId}/disconnect/public`
      : `${configUrl.livevideoRoom}${roomId}/disconnect`;
    const body = {};
    return this.http.post<LiveVideoRoom>(url, body);
  }

  public observeIncomingCalls(events: ChildEvent[] = []): Observable<LiveVideoCall[]> {
    const uid = this.authService.activeUser?.uid;
    if (!uid) {
      return of([]);
    }
    return this.afDatabase
      .list<LiveVideoCall>(`livevideo/incoming/${uid}`)
      .valueChanges(events)
      .pipe(map((calls) => calls.reduce((acc, current) => acc.concat(Object.values(current)), [])));
  }

  public observeOutgoingCall(userId: string, uid: string | undefined): Observable<LiveVideoCall> {
    if (!uid) {
      return of(null);
    }
    return this.afDatabase
      .list<LiveVideoCall>(`livevideo/incoming/${userId}`)
      .valueChanges()
      .pipe(map((calls) => calls.reduce((acc, current) => ({ ...current, ...acc }), {})[uid] || null));
  }

  public observeNewCalls(): Observable<LiveVideoCall> {
    const uid = this.authService.activeUser?.uid;
    if (!uid) {
      return EMPTY;
    }
    return this.afDatabase
      .list<LiveVideoCall>(`livevideo/incoming/${uid}`)
      .stateChanges(['child_added'])
      .pipe(map((calls) => Object.values(calls.payload.val())[0]));
  }

  public observeRemovedCalls(): Observable<LiveVideoCall> {
    const uid = this.authService.activeUser?.uid;
    if (!uid) {
      return EMPTY;
    }
    return this.afDatabase
      .list<LiveVideoCall>(`livevideo/incoming/${uid}`)
      .stateChanges(['child_removed'])
      .pipe(map((calls) => Object.values(calls.payload.val())[0]));
  }

  public observeIncomingCallsFromUser(userId: string): Observable<LiveVideoCall[]> {
    return this.observeIncomingCalls().pipe(map((calls) => calls.filter((call) => call.callerid === userId)));
  }

  public observeActiveRooms(): Observable<LiveVideoActiveRoom[]> {
    const uid = this.authService.activeUser?.uid;
    if (!uid) {
      return of([]);
    }
    return this.afDatabase
      .list<LiveVideoCall>(`livevideo/active/${uid}`)
      .valueChanges()
      .pipe(map((calls) => calls.reduce((acc, current) => acc.concat(Object.values(current)), [])));
  }
}
