import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, combineLatest, from, EMPTY, Subject } from 'rxjs';
import { shareReplay, switchMap, tap } from 'rxjs/operators';

import { AwsService } from './aws.service';
import { Clip } from '@app/shared/model';
import { configUrl } from '@env/environment';
import { UploadingToastComponent } from '../uploading-toast/uploading-toast.component';

import { v4 as uuid } from 'uuid';

declare var window: any;
import * as CryptoJS from 'crypto-js';
import { LoggerService } from './logger.service';

@Injectable()
export class ClipService {
  configUrl = configUrl;

  speechRecognizer;

  private newClipSubscription = new Subject<Clip>();
  newClip$ = this.newClipSubscription.asObservable();

  private transcriptionSubject = new Subject<string>();
  transcription$ = this.transcriptionSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private aws: AwsService,
    private logger: LoggerService,
  ) {}

  post(clip: Clip, target: string = null, image?: any, file?: Blob, thumbnail?: Blob): Observable<any> {
    let url = this.configUrl.clipUrl;

    if (clip.cliptype === 'Text') {
      url = this.configUrl.textUrl;
    }

    if (clip.cliptype === 'Video') {
      url = this.configUrl.videoUrl;
    }

    const formData = new FormData();
    formData.append('cliptype', clip.cliptype);
    formData.append('title', clip.title);
    formData.append('privacy', clip.privacy);

    if (clip.link) {
      formData.append('link', clip.link);
    }

    if (clip.userid) {
      formData.append('userid', clip.userid);
    }

    if (clip.categoryid) {
      formData.append('categoryid', clip.categoryid);
    }

    if (clip.cliptext) {
      formData.append('cliptext', clip.cliptext);
    }

    if (image) {
      formData.append('imagechecksum', image.imagechecksum);
      formData.append('image0', image.file, 'attachment.jpeg');
    }

    if (clip.groupid) {
      formData.append('groupid', clip.groupid);
    } else if (clip.chatid) {
      formData.append('chatid', clip.chatid);
    }

    if (clip.parentid) {
      formData.append('parentid', clip.parentid);
    }

    // Video
    if (thumbnail) {
      const progressionToast = file
        ? this.toastr.info(`Uploading ${clip.title}`, 'Uploading', {
            toastComponent: UploadingToastComponent,
            extendedTimeOut: 100000000,
            timeOut: 100000000,
            progressBar: true,
            progressAnimation: 'increasing',
          })
        : null;

      const uploadAndPost$ = from(this.blobToArrayBuffer(thumbnail)).pipe(
        switchMap((arrayBuffer) => {
          const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
          const checksum = CryptoJS.MD5(wordArray).toString();

          formData.append('thumbnailchecksum', checksum);
          formData.append('thumbnail', thumbnail, 'clip.opus');

          return this.aws.getVideoUrl().pipe(
            switchMap(({ filename, uploadurl }) => {
              return combineLatest([of(filename), this.aws.putVideo(file, uploadurl, filename, 'video/mp4', true)]);
            }),
          );
        }),
        switchMap(([filename, event]) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              (progressionToast.portal.instance as UploadingToastComponent).progress = Math.round(
                (100 * event.loaded) / event.total,
              );
              break;
            case HttpEventType.Response:
              formData.append('videoid', `${filename}.mp4`);
              formData.append('isconverted', '0');
              progressionToast.toastRef.close();
              return this.httpClient
                .post(url, formData)
                .pipe(tap((repsonse: Clip) => this.newClipSubscription.next(repsonse)));
          }

          return EMPTY;
        }),
        shareReplay(1),
      );

      const postRecording$ = from(this.blobToArrayBuffer(thumbnail)).pipe(
        switchMap((arrayBuffer) => {
          console.log('c');
          const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
          const checksum = CryptoJS.MD5(wordArray).toString();

          formData.append('thumbnailchecksum', checksum);
          formData.append('thumbnail', thumbnail, 'clip.opus');
          formData.append('videoid', `${uuid()}.mp4`);
          formData.append('recorderid', clip.recordingid);

          return of(true);
        }),
        switchMap(() => {
          console.log('cc');
          return this.httpClient
            .post(url, formData)
            .pipe(tap((response: Clip) => this.newClipSubscription.next(response)));
        }),
      );

      console.log('*');

      if (file) {
        uploadAndPost$.subscribe();
        return uploadAndPost$;
      } else if (clip.recordingid) {
        console.log('**');
        return postRecording$;
      }

      return of(null);
    }

    // Audio
    if (file) {
      return from(this.blobToArrayBuffer(file)).pipe(
        switchMap((arrayBuffer) => {
          const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
          const checksum = CryptoJS.MD5(wordArray).toString();

          formData.append('filechecksum', checksum);
          formData.append('file', file, 'clip.opus');

          return this.httpClient
            .post(url, formData)
            .pipe(tap((repsonse: Clip) => this.newClipSubscription.next(repsonse)));
        }),
      );
    }

    return this.httpClient.post(url, formData).pipe(tap((repsonse: Clip) => this.newClipSubscription.next(repsonse)));
  }

  private blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        resolve(event.target.result as ArrayBuffer);
      };
      fileReader.readAsArrayBuffer(blob);
    });
  }

  makeMyVideoPublic(clip: Clip) {
    const formData = new FormData();
    formData.append('id', clip.id);
    formData.append('privacy', 'Public');
    formData.append('notimeline', '1');

    return this.httpClient.put(this.configUrl.clipUpdate, formData);
  }

  updateClip(body: any): Observable<any> {
    const formData = new FormData();
    formData.append('id', body.id);
    formData.append('title', body.title);
    formData.append('link', body.link);
    formData.append('privacy', 'Public');

    if (body.categoryid) {
      formData.append('categoryid', body.categoryid);
    }

    if (body.imageUrlArray) {
      for (let i = 0; i < body.imageUrlArray.length; i++) {
        formData.append('imagechecksum', body.imageUrlArray[i].imagechecksum);
        formData.append('image' + i, body.imageUrlArray[i].file, body.imageUrlArray[i].name);
      }
    }

    if (!body.imageUrlArray?.length && body.deleteimage) {
      formData.append('deleteimage', body.deleteimage);
    }

    return this.httpClient.put(this.configUrl.clipUpdate, formData);
  }

  likeSeed(seedid: string, clip: any): any {
    const body = {
      id: seedid,
    };

    this.httpClient.post(this.configUrl.clipLike, body).subscribe((response: any) => {
      clip.likedat = response.liketime;

      if (response.liketime === null) {
        clip.likes--;
      } else {
        clip.likes++;
      }

      return clip;
    });
  }

  markClipAsPlayed(body: any): any {
    this.httpClient.post(this.configUrl.clipMarkAsPlayed, body).subscribe();
  }

  reportClip(body: any): Observable<any> {
    return this.httpClient.post(this.configUrl.clipReport, body);
  }

  deleteClip(body: any): Observable<any> {
    return this.httpClient.request('delete', this.configUrl.clipDelete, { body });
  }

  startRecognizingSpeech() {
    if (!('webkitSpeechRecognition' in window)) {
      return;
    }

    if (this.speechRecognizer) {
      return;
    }

    try {
      this.speechRecognizer = new window.webkitSpeechRecognition();
      this.speechRecognizer.continuous = true;
      this.speechRecognizer.interimResults = true;

      this.speechRecognizer.lang = 'en-GB';
      this.speechRecognizer.start();

      this.speechRecognizer.onresult = (event) => {
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            this.transcriptionSubject.next(transcript);
          }
        }
      };
    } catch (err) {
      this.logger.error(err);
    }
  }

  stopRecognizingSpeech() {
    this.speechRecognizer?.stop();
    this.speechRecognizer = null;
  }
}
