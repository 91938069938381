import {
  Component,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Input,
  ElementRef,
  OnInit,
} from '@angular/core';
import { ClipService } from '@app/core/services';
import { ToastrService } from 'ngx-toastr';

import * as RecordRTC from 'recordrtc';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-post-record-video',
  templateUrl: './post-record-video.component.html',
  styleUrls: ['./post-record-video.component.scss'],
})
export class PostRecordVideoComponent implements OnInit {
  @Input() public appearance: string = null;
  @Output() fileRecorded = new EventEmitter<any>();
  @Output() thumbnailCreated = new EventEmitter<any>();
  @Output() transcription = new EventEmitter<string>();

  private transcriptionSubscription: Subscription;

  @ViewChild('hardwareVideo', { static: true }) hardwareVideo: any;
  @ViewChild('savedVideo', { static: true }) savedVideo: any;
  @ViewChild('canvas', { static: true }) canvas: any;
  @ViewChild('placeholder', { static: true }) placeholder: ElementRef<HTMLDivElement>;

  isRecording = false;
  videoUrlSaved;
  timeLimit = 240000;
  recordingTimer: any;
  loading = false;

  private stream: MediaStream;
  private recordRTC: any;
  public thumbnail;

  constructor(
    private cd: ChangeDetectorRef,
    private clipService: ClipService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.placeholder.nativeElement.addEventListener(
      'dragenter',
      (event) => {
        event.stopPropagation();
        event.preventDefault();
      },
      false,
    );

    this.placeholder.nativeElement.addEventListener(
      'dragover',
      (event) => {
        event.stopPropagation();
        event.preventDefault();
      },
      false,
    );

    this.placeholder.nativeElement.addEventListener(
      'dragleave',
      (event) => {
        event.stopPropagation();
        event.preventDefault();
      },
      false,
    );

    this.placeholder.nativeElement.addEventListener(
      'drop',
      (event) => {
        event.stopPropagation();
        event.preventDefault();

        const dt = event.dataTransfer;
        const files = dt.files;
        const video: HTMLVideoElement = this.savedVideo.nativeElement;
        const file = files.item(0);

        if (video.canPlayType(file.type) === '') {
          this.toastr.error($localize`This file is not supported`);
          return;
        }

        this.fileRecorded.emit(file as Blob);

        const reader = new FileReader();
        reader.addEventListener('load', (event: any) => {
          this.videoUrlSaved = event.target.result;
          video.src = event.target.result;
          video.addEventListener(
            'loadeddata',
            () => {
              if (video.duration === Infinity) {
                this.toastr.error($localize`Video duration cannot be determined`);
                this.videoUrlSaved = null;
                this.thumbnail = null;
                return;
              }
              if (video.duration > 240) {
                this.toastr.error(
                  $localize`Video duration is too long`,
                  $localize`Videos must be a maximum of 4 minutes.`,
                );
                this.videoUrlSaved = null;
                this.thumbnail = null;
                return;
              }

              this.createThumbnail();
              this.cd.detectChanges();
            },
            false,
          );
          this.cd.detectChanges();
        });
        reader.readAsDataURL(file);
      },
      false,
    );
  }

  startRecording() {
    this.loading = true;

    this.transcriptionSubscription = this.clipService.transcription$.subscribe((transcription) =>
      this.transcription.emit(transcription),
    );
    this.clipService.startRecognizingSpeech();

    const constraints = {
      video: {
        minWidth: 1280,
        width: 1280,
        maxWidth: 1280,
        minHeight: 720,
        height: 720,
        maxHeight: 720,
        frameRate: 30,
      },
      audio: true,
    };

    navigator.mediaDevices.getUserMedia(constraints).then(this.recordingStarted.bind(this));
  }

  async recordingStarted(stream: MediaStream) {
    const options = {
      mimeType: 'video/webm',
      bitsPerSecond: 8000000,
    };
    this.stream = stream;
    this.recordRTC = RecordRTC(stream, options);
    const video: HTMLVideoElement = this.hardwareVideo.nativeElement;

    video.srcObject = stream;
    await video.play();
    this.isRecording = true;
    this.loading = false;
    video.muted = true;
    this.recordRTC.startRecording();

    this.recordingTimer = setTimeout(() => {
      this.stopRecording();
    }, this.timeLimit);
  }

  stopRecording() {
    this.clipService.stopRecognizingSpeech();

    this.createThumbnail();
    clearTimeout(this.recordingTimer);
    this.recordingTimer = null;

    this.recordRTC.stopRecording((src) => {
      const video: HTMLVideoElement = this.savedVideo.nativeElement;
      video.src = src;
      const blob = this.recordRTC.getBlob();
      this.fileRecorded.emit(blob);
      this.recordRTC.getDataURL((dataURL) => {
        this.isRecording = false;
        this.videoUrlSaved = dataURL;
        this.cd.detectChanges();
      });
    });

    this.stream.getTracks().forEach((track) => track.stop());
  }

  public updateThumbail() {
    this.createThumbnail();
  }

  reset() {
    this.transcriptionSubscription?.unsubscribe();
    this.transcriptionSubscription = null;

    if (this.isRecording) {
      this.stopRecording();
    }
    this.videoUrlSaved = null;
    this.thumbnail = null;
  }

  private createThumbnail() {
    const canvas: HTMLCanvasElement = this.canvas.nativeElement;
    const context = canvas.getContext('2d');
    const video: HTMLVideoElement = this.isRecording ? this.hardwareVideo.nativeElement : this.savedVideo.nativeElement;

    const width = video.offsetWidth;
    const height = video.offsetHeight;
    canvas.width = width * 4;
    canvas.height = height * 4;

    context.drawImage(video, 0, 0, width * 4, height * 4);

    this.thumbnail = canvas.toDataURL('image/jpeg');

    canvas.toBlob((blob) => {
      this.thumbnailCreated.emit(blob);
    }, 'image/jpeg');
  }
}
