import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { UserService } from '@app/core/services';
import { configUrl } from '@env/environment';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { hide } from '@app/nav.actions';

@Component({
  selector: 'app-twitter-card',
  templateUrl: './twitter-card.component.html',
})
export class TwitterCardComponent implements OnInit {
  nav$: Observable<any>;

  private seedId: string;
  public clip: any;

  constructor(
    private us: UserService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private store: Store<any>,
  ) {
    this.nav$ = store.select('nav');

    this.route.params.subscribe((params: any) => {
      this.seedId = params.id;
      this.loadClip();
    });
  }

  ngOnInit() {
    this.us.getBusinessStyles();
    setTimeout(() => {
      this.store.dispatch(hide());
    }, 0);
  }

  loadClip() {
    const url = `${configUrl.getClipByIdUrl}${this.seedId}`;

    this.http.get(url).subscribe((clip) => {
      this.clip = clip;
    });
  }
}
