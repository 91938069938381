import { Injectable } from '@angular/core';
import { UiModalDomService } from './ui-modal-dom.service';

@Injectable()
export class UiModalService {
  constructor(private domService: UiModalDomService) {}

  private modalElementId = 'modal-container';
  private overlayElementId = 'overlay';
  private preventDestroy = false;
  private onDestroy?: () => void;

  init(
    component: any,
    inputs: object = {},
    outputs: object = {},
    modalClass: string = null,
    overlayClass: string = null,
    preventDestory = false,
    onDestroy?: () => void,
  ) {
    this.preventDestroy = preventDestory;
    this.onDestroy = onDestroy;
    const componentConfig = { inputs, outputs };
    this.domService.appendComponentTo(this.modalElementId, component, componentConfig);
    document.getElementById(this.modalElementId).className = 'ui-modal-show';
    document.getElementById(this.overlayElementId).className = 'ui-modal-show';

    if (modalClass) {
      document.getElementById(this.modalElementId).classList.add(modalClass);
    }

    if (overlayClass) {
      document.getElementById(this.overlayElementId).classList.add(overlayClass);
    }
  }

  isOpen() {
    return document.getElementById(this.modalElementId).className === 'ui-modal-show';
  }

  destroy(force = false) {
    if (this.preventDestroy && !force) {
      return;
    }
    this.onDestroy?.();
    this.domService.removeComponent();
    document.getElementById(this.modalElementId).className = 'ui-modal-hidden';
    document.getElementById(this.overlayElementId).className = 'ui-modal-hidden';
  }
}
