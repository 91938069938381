import { Component, Input } from '@angular/core';

import { UiModalService } from '@app/core/services';
import { configUrl } from '@env/environment';

@Component({
  selector: 'app-modal-video',
  templateUrl: './modal-video.component.html',
  styleUrls: ['./modal-video.component.scss'],
})
export class ModalVideoComponent {
  @Input() clip: any;

  configUrl = configUrl;

  constructor(private modalService: UiModalService) {}

  dismissAction() {
    this.modalService.destroy();
  }
}
