import { Component, ViewChild, ElementRef, Input, Output, NgZone, OnDestroy, ApplicationRef } from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { Subscription } from 'rxjs';

import { QueryService, UiModalService } from '@app/core/services';
import { configUrl } from '@env/environment';
import { User } from '@app/shared/model';

@Component({
  selector: 'app-modal-polls-list',
  templateUrl: './modal-polls-list.component.html',
})
export class ModalPollsListComponent implements OnDestroy {
  @ViewChild('postsList') postsList: ElementRef;

  @Input() actionTitle = $localize`Action`;
  @Input() modalTitle = $localize`:@@posts:Posts`;
  @Input() polls: any[] = [];
  @Output() actionCallback: (post: any) => void;

  listTopShadow = false;
  listBottomShadow = false;

  configUrl = configUrl;

  scrollDispatcherSubscription: Subscription;

  page = 1;

  constructor(
    private modal: UiModalService,
    private scrollDispatcher: ScrollDispatcher,
    private zone: NgZone,
    private appRef: ApplicationRef,
    public qs: QueryService,
  ) {
    this.scrollDispatcherSubscription = this.scrollDispatcher.scrolled().subscribe((x) => {
      this.updateListShadows();
    });
  }

  ngOnDestroy() {
    this.scrollDispatcherSubscription?.unsubscribe();
  }

  updateListShadows() {
    const element = this.postsList?.nativeElement;

    const listTopShadow = this.postsList && element.scrollTop > 0;
    const listBottomShadow = this.postsList && element.scrollTop < element.scrollHeight - element.clientHeight;

    if (this.listTopShadow !== listTopShadow || this.listBottomShadow !== listBottomShadow) {
      this.zone.run((_) => {
        this.listTopShadow = listTopShadow;
        this.listBottomShadow = listBottomShadow;
      });
    }
  }

  changePage(page: number) {
    this.page = page;
    this.appRef.tick();
    this.updateListShadows();
  }

  dismiss() {
    this.modal.destroy();
  }

  performAction(post: any) {
    this.actionCallback(post);
    this.modal.destroy();
  }
}
