import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService, BusinessService, LangService } from '@app/core/services';
import { configUrl, config } from '@env/environment';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss'],
})
export class CompanySelectorComponent {
  @Input() companySelector = true;

  configUrl = configUrl;
  config = config;

  subdomain: string;

  logo = 'assets/images/logo.png';

  lang = (id: string) => this.langService.lang(id);

  constructor(
    public authService: AuthService,
    public business: BusinessService,
    private router: Router,
    private langService: LangService,
  ) {
    this.subdomain = this.authService.getSubdomain();

    this.authService.user$.subscribe((auth) => {
      const logo = auth.companySettings?.logo;
      this.logo = logo ? configUrl.bucketURL + 'logo/' + logo : 'assets/images/logo.png';
    });
  }

  checkIfCompanyActiveBoolean(company: any): boolean {
    return this.subdomain === company.subdomain;
  }

  toggleLocation(company: any) {
    this.business.navigateToCompany(company);
  }

  addCompany() {
    this.router.navigate(['/sign-up-create-url']);
  }

  filterByAccepted(item) {
    // return true;
    return item.statusid === 2;
  }
}
