import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, of, forkJoin, BehaviorSubject, combineLatest, throwError, from } from 'rxjs';
import { switchMap, take, map, tap, shareReplay, catchError } from 'rxjs/operators';

import { configUrl, config, environment } from '@env/environment';
import { v4 as uuid } from 'uuid';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import * as psl from 'psl';
import { CompanyMemberType } from '@app/shared/model';
import LogRocket from 'logrocket';

@Injectable()
export class AuthService {
  activeUser: firebase.User | null;
  companies: any[];
  selectedCompany: any;
  companySettings: any;
  userInfo: any;

  token: string;

  refreshAuth = new BehaviorSubject<string>(uuid());

  token$ = this.afAuth.idToken;
  user$ = combineLatest([this.refreshAuth, this.afAuth.authState]).pipe(
    switchMap(([refreshId, user]) => {
      return forkJoin({
        auth: of(user),
        user: user ? this.http.get(configUrl.getUserUrl + user.uid).pipe(catchError((err) => of(null))) : of(null),
        companies: user ? this.getCompanies() : of(null),
        selectedCompany: user ? this.selectedCompany$ : of(null),
        companySettings: this.getCompanySettings().pipe(
          catchError((err) => {
            if (err.status === 404 && this.router.url !== '/notfound') {
              this.router.navigate(['/notfound']);
            }
            return of({});
          }),
        ),
        refreshId: of(refreshId),
      });
    }),
    tap((auth) => {
      return (
        auth.user &&
        LogRocket.identify(auth.user.id, {
          name: auth.user.username,
          email: auth.user.email,
        })
      );
    }),
    shareReplay(1),
  );

  loggedIn$: Observable<boolean> = this.user$.pipe(map((userData) => !!userData.auth));

  loggedOut$: Observable<boolean> = this.user$.pipe(map((userData) => !userData.auth));

  private selectedCompany$ = this.getCompanies().pipe(
    map((companies) => {
      const matchedCompanies = companies.filter((company) => {
        return company.subdomain === this.getSubdomain();
      });

      return matchedCompanies.length ? matchedCompanies[0] : null;
    }),
  );

  get isMemberOfCompany(): boolean {
    return !this.selectedCompany || this.selectedCompany?.statusid === 2;
  }

  get isCompanyAdmin(): boolean {
    return (
      this.selectedCompany?.statusid === 2 &&
      (this.selectedCompany?.memberType === CompanyMemberType.Owner ||
        this.selectedCompany?.memberType === CompanyMemberType.Admin)
    );
  }

  get canPresentOnLiveStage(): boolean {
    return (
      this.selectedCompany?.statusid === 2 &&
      (this.selectedCompany?.memberType === CompanyMemberType.Owner ||
        this.selectedCompany?.memberType === CompanyMemberType.Admin ||
        this.selectedCompany?.memberType === CompanyMemberType.Presenter)
    );
  }

  get canUploadPrivateVideos(): boolean {
    return (
      this.selectedCompany?.statusid === 2 &&
      (this.selectedCompany?.memberType === CompanyMemberType.Owner ||
        this.selectedCompany?.memberType === CompanyMemberType.Admin ||
        this.selectedCompany?.memberType === CompanyMemberType.Presenter)
    );
  }

  get currentBusinessId(): string | null {
    return this.companySettings.companyid;
  }

  constructor(
    private afAuth: AngularFireAuth,
    private http: HttpClient,
    private router: Router,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    this.token$.subscribe((token) => {
      this.token = token;
    });

    this.user$.subscribe((userData) => {
      this.activeUser = userData.auth;
      this.companies = userData.companies;
      this.selectedCompany = userData.selectedCompany;
      this.companySettings = userData.companySettings;
      this.userInfo = userData.user;
    });
  }

  sendVerificationEmail() {
    const subdomain = this.getSubdomain();
    const subdomainPart = subdomain ? `?subdomain=${subdomain}` : '';
    const globalSubdomain = config.globalSubdomain ? `${config.globalSubdomain}.` : '';
    this.activeUser?.sendEmailVerification({
      url: `${location.protocol}//${globalSubdomain}${configUrl.domain}/verified${subdomainPart}`,
    });
  }

  login(email: string, password: string): Observable<firebase.auth.UserCredential> {
    return from(this.signInWithEmailAndPassword(email, password));
  }

  loginFacebook(): Observable<any> {
    return from(this.signInWithFacebook()).pipe(
      map((auth: firebase.auth.UserCredential) => {
        // if (!auth.additionalUserInfo.isNewUser) {
        //   return null;
        // }

        return {
          email: auth.user.email || 'webuser@example.com',
          username: auth.user.displayName || '????',
          privacy: 'Public',
          lang: this.locale,
        };
      }),
    );
  }

  loginGoogle(): Observable<any> {
    return from(this.signInWithGoogle()).pipe(
      map((auth: firebase.auth.UserCredential) => {
        // if (!auth.additionalUserInfo.isNewUser) {
        //   return null;
        // }

        return {
          email: auth.user.email || 'webuser@example.com',
          username: auth.user.displayName || '????',
          privacy: 'Public',
          lang: this.locale,
        };
      }),
    );
  }

  signup(userName: string, login: string, password: string, isCompanyAdmin: boolean = false): Observable<any> {
    if ((userName || '').length < 3) {
      return throwError(new Error('Name needs to be at least 3 characters long.'));
    }

    return from(this.afAuth.createUserWithEmailAndPassword(login, password)).pipe(
      map((auth: firebase.auth.UserCredential) => {
        const body: any = { username: userName };
        if (isCompanyAdmin) {
          body.iscompanyadmin = 1;
        }
        return {
          email: auth.user.email || 'webuser@example.com',
          username: auth.user.displayName || '????',
          privacy: 'Public',
          lang: this.locale,
          ...body,
        };
      }),
    );
  }

  createUserWithEmailAndPassword(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  signInWithEmailAndPassword(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  signInWithGoogle(): Promise<firebase.auth.UserCredential> {
    return this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  signInWithFacebook(): Promise<firebase.auth.UserCredential> {
    return this.afAuth.signInWithPopup(new firebase.auth.FacebookAuthProvider());
  }

  signOut(redirect: boolean = true) {
    this.afAuth.signOut();
    if (redirect) {
      this.loggedOut$.pipe(take(1)).subscribe((_) => {
        this.router.navigate(['/sign-in']);
      });
    }
  }

  ////

  getHeaders(): Observable<HttpHeaders> {
    return this.token$.pipe(
      take(1),
      map((token) => this.getHttpHeaders(token)),
    );
  }

  private getHttpHeaders(token: string = this.token): HttpHeaders {
    let headers = new HttpHeaders();

    if (this.token) {
      headers = headers.append('Authorization', `Bearer ${this.token}`);
    }

    if (this.companySettings?.companyid) {
      headers = headers.append('companyid', this.companySettings?.companyid);
    }

    if (this.getSubdomain()) {
      headers = headers.append('subdomain', this.getSubdomain());
    }

    return headers;
  }

  updateCompanies() {
    return this.getCompanies().pipe(
      tap((companies) => {
        this.companies = companies;
      }),
    );
  }

  private getCompanies(): Observable<any[]> {
    return this.http.get<any[]>(configUrl.companyMy + '?status=all');
  }

  getCompanySettings(): Observable<any> {
    const url = Boolean(this.getSubdomain()) ? configUrl.companySetting : configUrl.setting;

    return this.http.get<any>(url);
  }

  updateCompaniesSetting(body: any): Observable<any> {
    const url = Boolean(this.getSubdomain()) ? configUrl.companySetting : configUrl.setting;

    return this.http.post<any>(url, body).pipe(tap(() => this.refreshAuth.next(uuid())));
  }

  getSubdomain(): string | null {
    if (configUrl.isLocalDomain) {
      return localStorage.getItem('subdomain');
    }

    const url = psl.parse(window.location.host);
    if (url.subdomain === 'app') {
      return null;
    }
    return url.subdomain || null;
  }
}
