import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

import { configUrl } from '@env/environment';
import { AuthService } from '../services';

@Injectable()
export class StageListResolver implements Resolve<any> {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): Observable<any> {
    return this.getData();
  }

  getData(): Observable<any> {
    const n = this.auth.companySettings.stages;
    const stagesIds = [...Array(n).keys()].map((n) => n + 1);
    const requests = stagesIds.map((id) => this.http.get(`${configUrl.livevideoStage}${id}`));

    return forkJoin(requests);
  }
}
