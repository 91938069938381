import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { GlobalvariablesService } from './globalvariables.service';
import { AuthService } from './auth.service';
import { configUrl } from '@env/environment';
import { LoggerService } from './logger.service';

@Injectable()
export class UserService {
  private configUrl = configUrl;

  private countersSub: any;

  constructor(
    private gv: GlobalvariablesService,
    private afAuth: AngularFireAuth,
    private http: HttpClient,
    private afDatabase: AngularFireDatabase,
    private authService: AuthService,
    private logger: LoggerService,
  ) {}

  getUserInfo(uid: string): Observable<any> {
    return this.http.get(configUrl.getUserUrl + uid);
  }

  saveUserInfo(body: object = {}): Observable<any> {
    return this.http.post(configUrl.user2, body).pipe(tap((_) => this.authService.refreshAuth.next('updateuser')));
  }

  resetPassword(email: string, callback: (result: any) => void = () => {}): Observable<any> {
    const body = { email: email };

    const request$ = this.http.post(this.configUrl.userPasswordReset, body);
    request$.subscribe(
      (response) => callback(null),
      (error) => callback(error),
    );
    return request$;
  }

  getUserSettings(): Observable<any> {
    const userSettings$ = this.http.get(this.configUrl.userSettings);

    userSettings$.subscribe((response) => {
      this.gv.userSettings = response;
    });

    return userSettings$;
  }

  updateUserSettings(data: any = null): Observable<any> {
    if (this.gv.userSettings.emailnoti) {
      this.gv.userSettings.emailnoti = 1;
    } else {
      this.gv.userSettings.emailnoti = 0;
    }

    const request$ = this.http.post(this.configUrl.userSettings, data ? data : this.gv.userSettings);

    request$.subscribe((response) => {
      this.gv.userSettings = response;
    });

    return request$;
  }

  updateUserSettingsDefaultCompany(data: any = null): Observable<any> {
    const companyid = this.gv.userSettings.defaultcompanyid === 'null' ? null : this.gv.userSettings.defaultcompanyid;
    const body = {
      defaultcompanyid: companyid,
    };

    const request$ = this.http.post(this.configUrl.userSettingsDefaultcompany, data ? data : body);

    request$.subscribe((response) => {
      this.gv.userSettings = response;
    });

    return request$;
  }

  updateUserImage(body, callback: (update$: Observable<any>) => void = () => {}): Observable<any> {
    const formData = new FormData();
    formData.append('filechecksum', body.filechecksum);
    formData.append('file', body.file);
    formData.append('authorid', body.authorid);

    const userUpdate = this.http.post(this.configUrl.userImage, formData);
    callback(userUpdate);

    return userUpdate;
  }

  updateUser(body, callback: (response: any) => void = () => {}): Observable<any> {
    const updateUser = this.http
      .put(this.configUrl.user, body)
      .pipe(tap((_) => this.authService.refreshAuth.next('updateuser')));
    callback(updateUser);
    return updateUser;
  }

  userCounters(): any {
    if (this.countersSub) {
      this.countersSub.unsubscribe();
    }

    let subscriptionArray = this.afDatabase.object(`/userData/${this.authService.activeUser.uid}/counters`);
    const companyId = this.authService.currentBusinessId;
    if (companyId) {
      subscriptionArray = this.afDatabase.object(
        `/company/${companyId}/userData/${this.authService.activeUser.uid}/counters`,
      );
    }

    this.countersSub = subscriptionArray.valueChanges().subscribe((response: any) => {
      this.gv.userCounters = response;
      if (response) {
        this.gv.userCounters.sum = parseInt(response.chatsCount, 10) + parseInt(response.groupsCount, 10);
      }
    });
  }

  observeChatCounter(chatId: string): Observable<number> {
    const companyId = this.authService.currentBusinessId;
    const path = companyId
      ? `/company/${companyId}/userData/${this.authService.activeUser.uid}/counters/chats/${chatId}`
      : `/userData/${this.authService.activeUser.uid}/counters/chats/${chatId}`;

    return this.afDatabase
      .object(path)
      .valueChanges()
      .pipe(map((count: string) => parseInt(count, 10)));
  }

  cleanCounters(type: string, id: string) {
    const companyId = this.authService.currentBusinessId;

    if (type === 'group') {
      let subscriptionArray = this.afDatabase
        .object(`/userData/${this.authService.activeUser.uid}/counters/groups/${id}`)
        .remove();

      if (companyId) {
        const path = `/company/${companyId}/userData/${this.authService.activeUser.uid}/counters/groups/${id}`;
        subscriptionArray = this.afDatabase.object(path).remove();
      }

      subscriptionArray.then(() => {}).catch((err) => this.logger.error(err));
    }
    if (type === 'chat') {
      let subscriptionArray = this.afDatabase
        .object(`/userData/${this.authService.activeUser.uid}/counters/chats/${id}`)
        .remove();

      if (companyId) {
        const path = `/company/${companyId}/userData/${this.authService.activeUser.uid}/counters/chats/${id}`;
        subscriptionArray = this.afDatabase.object(path).remove();
      }

      subscriptionArray.then(() => {}).catch((err) => this.logger.error(err));
    }
    if (type === 'inbox') {
      let subscriptionArray = this.afDatabase.object(`/userData/${this.authService.activeUser.uid}/counters`).remove();

      if (companyId) {
        const path = `/company/${companyId}/userData/${this.authService.activeUser.uid}/counters`;
        subscriptionArray = this.afDatabase.object(path).remove();
      }

      subscriptionArray.then(() => {}).catch((err) => this.logger.error(err));
    }
  }

  resetCountCounter(type: string) {
    const companyId = this.authService.currentBusinessId;

    if (type === 'group') {
      const path = companyId
        ? `/company/${companyId}/userData/${this.authService.activeUser.uid}/counters/groupsCount`
        : `/userData/${this.authService.activeUser.uid}/counters/groupsCount`;

      this.afDatabase.object(path).remove();
    }

    if (type === 'chat') {
      const path = companyId
        ? `/company/${companyId}/userData/${this.authService.activeUser.uid}/counters/chatsCount`
        : `/userData/${this.authService.activeUser.uid}/counters/chatsCount`;

      this.afDatabase.object(path).remove();
    }
  }

  getBusinessStylesRawRequest() {
    const options = {
      responseType: 'text' as 'text',
    };
    return this.http.get(this.configUrl.getCss + this.authService.selectedCompany.id, options);
  }

  getBusinessStyles() {
    if (this.authService.selectedCompany) {
      const options = {
        responseType: 'text' as 'text',
      };
      return this.http
        .get(this.configUrl.getCss + this.authService.selectedCompany.id, options)
        .subscribe((response) => {
          this.gv.businessStyles = response;
          if (this.gv.businessStyles) {
            if (document.getElementById('business-style')) {
              document.getElementById('business-style').remove();
            }
            const printContainer = document.createElement('style');
            printContainer.id = 'business-style';

            printContainer.innerHTML = this.gv.businessStyles;

            document.body.appendChild(printContainer);
          }
        });
    }
  }

  watchGroups() {
    const companyId = this.authService.currentBusinessId;
    let groupNodeRef = this.afDatabase.database.ref('userData/' + this.authService.activeUser.uid + '/invites/groups');

    if (companyId) {
      const path = `/company/${companyId}/userData/${this.authService.activeUser.uid}/invites/groups`;
      groupNodeRef = this.afDatabase.database.ref(path);
    }

    groupNodeRef.on('value', (snapshot) => {
      const newGroupInvite = snapshot.val();

      if (newGroupInvite) {
        this.gv.invites.groups.push(newGroupInvite);
      }
    });
  }
}
