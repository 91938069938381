import { Component, OnInit, Input, Inject } from '@angular/core';

import { configUrl } from '@env/environment';
import { AuthService, AudioPlayerService, QueryService } from '@app/core/services';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-play-audio-button',
  templateUrl: './play-audio-button.component.html',
  styleUrls: ['./play-audio-button.component.scss'],
})
export class PlayAudioButtonComponent implements OnInit {
  configUrl = configUrl;

  @Input() clip: any;
  @Input() type = 'large';
  @Input() audioServer = configUrl.audioFS;

  constructor(
    public qs: QueryService,
    public authService: AuthService,
    private audioPlayer: AudioPlayerService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.clip.playAudio = (playNext, completed) => this.play(playNext, completed);
    this.clip.stopAudio = () => this.stop();
  }

  stop() {
    if (this.clip.playing) {
      this.audioPlayer.stop();
    }
  }

  play(playNext = false, completed: (clip) => void = null) {
    if (this.clip.playing) {
      this.audioPlayer.stop();
    } else {
      this.audioPlayer.play(
        `${this.audioServer}${this.clip.audio}`,
        playNext,
        (player) => {
          this.clip.playing = true;
          this.document.getElementById('play-recorder-style')?.remove();
          const printContainer = this.document.createElement('style');
          printContainer.id = 'play-recorder-style';
          printContainer.innerHTML = ` .play-icon.active svg circle {animation: countdownclip ${player.duration}s linear forwards;} `;
          this.document.body.appendChild(printContainer);
        },
        (shouldPlayNext) => {
          this.clip.playing = false;
          this.document.getElementById('play-recorder-style')?.remove();
          completed?.(shouldPlayNext);
        },
      );
    }
  }
}
