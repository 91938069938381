<div class="manage-clip" *ngIf="authService.activeUser && clip">
  <div class="dropdown" ngbDropdown placement="bottom-right">
    <mdi name="More" class="no-caret" ngbDropdownToggle aria-haspopup="true" aria-expanded="false"></mdi>
    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
      <a ngbDropdownItem class="dropdown-item" *ngIf="userHasEditRights" (click)="editClipAction()" i18n>Edit</a>
      <a ngbDropdownItem class="dropdown-item" *ngIf="clip?.cliptype !== 'Text'" (click)="playedByAction()" i18n
        >Played by&hellip;</a
      >
      <a ngbDropdownItem class="dropdown-item" (click)="reportClipAction()" i18n>Report</a>
      <a ngbDropdownItem class="dropdown-item" *ngIf="userHasEditRights" (click)="deleteClipAction()" i18n="@@delete"
        >Delete</a
      >
    </div>
  </div>
</div>
