<div class="postnew" [class.postnew-compact]="appearance === 'compact'">
  <div class="postnew-media-container" *ngIf="clipType !== ClipType.Text">
    <div class="postnew-media postnew-media-audio" *ngIf="clipType === ClipType.Audio">
      <app-post-record-audio
        (fileRecorded)="addAudio($event)"
        (transcription)="transcription($event)"
      ></app-post-record-audio>
    </div>
    <div class="invalid-feedback postnew-missing-error" *ngIf="postForm.errors?.invalidAudio && ngForm.submitted" i18n>
      Please record an audio clip.
    </div>

    <div *ngIf="clipType === ClipType.Video">
      <div
        *ngIf="target !== 'AudioProfile' && target !== 'UserTestimonial' && compactReveal()"
        style="margin-bottom: 12px"
      >
        <div *ngIf="thumbnailSrc" style="max-width: 300px; margin: 0 auto 20px; position: relative">
          <img [src]="thumbnailSrc" style="max-width: 300px" />
          <div class="video-close" (click)="clearThumbnailFile()"><mdi name="Close"></mdi></div>
        </div>

        <div
          *ngIf="!thumbnailSrc"
          class="img-upload img-upload-placeholder"
          style="display: block; margin-bottom: 12px"
        >
          <a href="javascript:void(0)"><mdi name="Plus" size="16"></mdi> <span i18n>Thumbnail</span></a>
          <input
            #thumbnailFileInput
            type="file"
            class="img-preview-upload"
            id="image"
            name="image"
            accept="image/jpeg,image/png"
            (change)="thumbnailFile(thumbnailFileInput)"
            required
          />
        </div>

        <div
          class="invalid-feedback postnew-missing-error"
          *ngIf="postForm.get('thumbnail').invalid && ngForm.submitted"
          i18n
        >
          Please choose a thumbail.
        </div>

        <!-- Video upload -->

        <div class="mb-3" *ngIf="!recordingId">
          <label class="form-label" for="video" #videoLabel i18n>Video</label>
          <input
            #videoInput
            type="file"
            class="form-control"
            id="video"
            accept="video/mp4"
            (change)="videoFile(videoInput)"
          />
        </div>

        <div class="invalid-feedback postnew-missing-error" *ngIf="postForm.errors?.invalidVideo && ngForm.submitted">
          Please choose a video.
        </div>
      </div>
    </div>
  </div>

  <div class="postnew-form-container">
    <form [formGroup]="postForm" #ngForm="ngForm" (ngSubmit)="submitForm(ngForm)" novalidate>
      <div class="mb-3">
        <textarea
          autosize
          [minRows]="2"
          [maxRows]="5"
          [class.is-invalid]="postForm.get('title').invalid && (postForm.get('title').dirty || ngForm.submitted)"
          [class.form-control-lg]="appearance === 'compact'"
          class="form-control"
          formControlName="title"
          id="title"
          name="title"
          style="resize: none; padding-bottom: 0"
          [placeholder]="titlePlaceholderText"
          autocomplete="off"
        ></textarea>
        <div class="invalid-feedback" i18n>Please enter a title.</div>
      </div>

      <div
        class="mb-3"
        *ngIf="
          !postForm.get('parentId').value && target !== 'AudioProfile' && target !== 'UserTestimonial' && !this.where
        "
      >
        <select
          class="form-select"
          [class.is-invalid]="postForm.errors?.invalidWhere && (postForm.get('where').dirty || ngForm.submitted)"
          formControlName="where"
          id="where"
          name="where"
          (change)="changeWhere()"
        >
          <option [ngValue]="null" i18n>Post to...</option>
          <option value="timeline" i18n>Public Timeline</option>
          <option *ngIf="hasFeature(Feature.Groups)" value="group" i18n>Group</option>
          <option *ngIf="hasFeature(Feature.Chat)" value="chat" i18n="@@chat">Chat</option>
          <option value="myvideos" i18n>My Videos</option>
        </select>
        <div class="invalid-feedback" i18n>Please choose where to post.</div>
      </div>

      <div class="mb-3" *ngIf="postForm.get('where').value === 'group' && !this.where">
        <select
          class="form-select"
          [class.is-invalid]="postForm.errors?.invalidGroup && (postForm.get('group').dirty || ngForm.submitted)"
          formControlName="group"
          id="group"
          name="group"
          [compareWith]="compareId"
        >
          <option [ngValue]="null" i18n>Choose group...</option>
          <option *ngFor="let groupOption of groups" [ngValue]="groupOption">{{ groupOption.name }}</option>
        </select>
        <div class="invalid-feedback" i18n>Please select a group.</div>
      </div>

      <div class="mb-3" *ngIf="postForm.get('where').value === 'chat' && !this.where">
        <select
          class="form-select"
          [class.is-invalid]="postForm.errors?.invalidChat && (postForm.get('chat').dirty || ngForm.submitted)"
          formControlName="chat"
          id="chat"
          name="chat"
          [compareWith]="compareId"
        >
          <option [ngValue]="null" i18n>Choose chat...</option>
          <option *ngFor="let chatOption of chats" [ngValue]="chatOption">{{ chatOption.username }}</option>
        </select>
        <div class="invalid-feedback" i18n>Please select a chat.</div>
      </div>

      <div
        class="mb-3"
        [hidden]="
          postForm.get('where').value !== 'timeline' || !compactReveal() || !categoriesLoaded || !categories.length
        "
      >
        <select class="form-select" formControlName="category" id="category" name="category">
          <option [ngValue]="null" i18n>No Category</option>
          <option *ngFor="let category of categories" [ngValue]="category.id">{{ category.fullName }}</option>
        </select>
      </div>

      <div class="postnew-attach" *ngIf="target !== 'AudioProfile' && target !== 'UserTestimonial' && compactReveal()">
        <div class="img-upload">
          <a href="javascript:void(0)" class="btn btn-outline-info"
            ><mdi name="Plus" size="16"></mdi> <span i18n>Image</span></a
          >
          <input
            #profileImageInput
            type="file"
            class="img-preview-upload"
            id="image"
            name="image"
            accept="image/jpeg"
            (change)="processFile(profileImageInput)"
            required
          />
        </div>
        &nbsp;
        <a (click)="addLink()" class="btn btn-outline-info"><mdi name="Plus" size="16"></mdi> <span i18n>Link</span></a>
      </div>

      <div class="mb-3" *ngIf="postForm.get('link').value">
        <div class="postnew-link">
          {{ postForm.get('link').value }}
          <div class="postnew-link-close" (click)="removeLink()">
            <mdi name="Close"></mdi>
          </div>
        </div>
      </div>

      <div class="mb-3" *ngIf="thumbnail || originalUrl">
        <div class="img-preview" [class.img-preview-image]="thumbnail || originalUrl">
          <img [src]="thumbnail || originalUrl ? (thumbnail ? thumbnail?.src : originalUrl) : ''" />

          <div>
            <div (click)="removeThumbnail()" class="img-preview-remove" *ngIf="thumbnail">
              <mdi name="Close"></mdi>
            </div>
          </div>
        </div>
        <div style="clear: both"></div>
      </div>

      <div class="postnew-actions" *ngIf="compactReveal()">
        <a class="btn btn-light" (click)="cancel()">{{ cancelButtonText }}</a
        >&nbsp;
        <button type="submit" class="btn btn-primary" i18n>Post</button>
      </div>
    </form>
  </div>
</div>
