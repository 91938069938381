import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { configUrl } from '@env/environment';
import { AuthService } from './auth.service';

@Injectable()
export class TimelineService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  getTimeline(categoryId: string = null, offset: number = 0, limit: number = 25): Observable<any> {
    let url = categoryId !== null ? `${configUrl.getByCategoryId}${categoryId}/subcat` : configUrl.getTimeline;

    if (!this.authService.activeUser) {
      url = configUrl.timelinePublic;
    }

    const params = {
      cliptype: 'All',
      offset: offset.toString(),
      limit: limit.toString(),
    };

    return this.http.get(url, { params });
  }
}
