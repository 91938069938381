import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import {
  QueryService,
  ChatService,
  AuthService,
  ClipService,
  UiPopupService,
  UiModalService,
  BusinessService,
} from '@app/core/services';

import { configUrl, config } from '@env/environment';
import { Clip, Feature } from '@app/shared/model';
import { Subscription } from 'rxjs';
import { PostCreatePopupComponent } from '../post-create-popup/post-create-popup.component';
import { ModalConfirmComponent } from '@app/shared/modal/modal-confirm/modal-confirm.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})
export class PostComponent implements OnInit, OnDestroy {
  configUrl: any = configUrl;
  config = config;
  Feature = Feature;

  subscription: Subscription;

  @Input() clip: any;
  @Input() extraClass: any = 'regular';
  @Input() noReply = false;
  @Input() updateSource: string;
  @Input() updateOrder: number;

  get canInteract(): boolean {
    return !!this.authService.activeUser;
  }

  constructor(
    public authService: AuthService,
    private afDatabase: AngularFireDatabase,
    private chatService: ChatService,
    public qs: QueryService,
    public clipService: ClipService,
    private popupService: UiPopupService,
    private modalService: UiModalService,
    private router: Router,
    private businessService: BusinessService,
  ) {}

  ngOnInit() {
    this.clipsReplies();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  hasFeature(feature: Feature) {
    return this.businessService.hasFeature(feature);
  }

  showReply(clip: any) {
    if (!this.canInteract) {
      this.showAccessRequiredMessage();
      return;
    }

    let inputs;

    if (clip.chatid) {
      inputs = {
        chat: {
          id: clip.chatid,
          username: clip.authorname,
        },
      };
    } else {
      inputs = {
        heading: $localize`:@@reply:Reply`,
        parentId: clip.id,
        title: `RE: ${clip.title}`,
      };
    }

    this.popupService.init(PostCreatePopupComponent, inputs);
  }

  navigateToChat(userId1: string, userId2: string, username: string) {
    this.chatService.navigateToChat(userId1, userId2, username);
  }

  clipsReplies() {
    if (!this.clip) {
      return;
    }
    this.clip.replies = 0;
    let completeSeed = this.afDatabase.object(`/clipsReplies/${this.clip.id}`);

    const companyId = this.authService.currentBusinessId;
    if (companyId) {
      completeSeed = this.afDatabase.object(`/company/${companyId}/clipsReplies/${this.clip.id}`);
    }

    this.subscription = completeSeed.valueChanges().subscribe((clip: any) => {
      if (clip?.childrenNumber && clip?.children) {
        this.clip.replies = clip.childrenNumber;
        this.clip.repliesid = clip.children;
      }
    });
  }

  setToPublic(event: Event, clip: Clip, index: number) {
    this.clipService.makeMyVideoPublic(clip).subscribe((res) => {
      this.clip = res;
    });

    event.stopPropagation();
    event.preventDefault();
  }

  likeSeed() {
    if (!this.canInteract) {
      this.showAccessRequiredMessage();
      return;
    }

    this.clipService.likeSeed(this.clip?.id, this.clip);
  }

  showAccessRequiredMessage() {
    const inputs = {
      title: $localize`Login Required`,
      message: $localize`You need to be logged in to do this`,
      yesButtonText: $localize`:@@createAccount:Create Account`,
      noButtonText: $localize`:@@signIn:Sign In`,
    };
    const outputs = {
      completeHandler: (yes: boolean) => {
        if (yes) {
          this.router.navigate(['/sign-up'], { queryParams: { returnUrl: this.router.url } });
        } else {
          this.router.navigate(['/sign-in'], { queryParams: { returnUrl: this.router.url } });
        }
      },
    };
    this.modalService.init(ModalConfirmComponent, inputs, outputs);
  }
}
