import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { Store } from '@ngrx/store';

import {
  AuthService,
  QueryService,
  BusinessService,
  UserService,
  UiModalService,
  LoggerService,
  LangService,
} from '@app/core/services';

import { config, configUrl } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { SocialLogin } from '@app/shared/model';
import { ModalConfirmComponent } from '@app/shared/modal/modal-confirm/modal-confirm.component';
import { hide, show } from '@app/nav.actions';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, OnDestroy {
  nav$: Observable<any>;

  configUrl = configUrl;
  config = config;
  SocialLogin = SocialLogin;

  terms = false;

  subdomain: string;
  returnUrl: string;

  tab = 0;

  loading = false;

  name: string;
  email: string;
  password: string;

  invite: any;
  inviteError: any;

  logo = 'assets/images/logo.png';

  error = $localize`Unknown invitation error`;

  get canSignUp(): boolean {
    return this.authService.companySettings?.isselfregister || this.invite;
  }

  lang = (id: string) => this.langService.lang(id);

  constructor(
    public qs: QueryService,
    public business: BusinessService,
    public authService: AuthService,
    public userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private http: HttpClient,
    private modalService: UiModalService,
    private logger: LoggerService,
    private store: Store<any>,
    private langService: LangService,
  ) {
    this.nav$ = store.select('nav');

    if (this.route.snapshot.data.invite?.id) {
      this.invite = this.route.snapshot.data.invite;
    } else {
      this.inviteError = this.route.snapshot.data.invite;
    }

    if (this.invite) {
      this.tab = 1;
    }

    const startTab = this.route.snapshot.params.tab;
    if (startTab === 'signup') {
      this.tab = 1;
    }

    this.authService.user$.subscribe((auth) => {
      const logo = auth.companySettings.logo;
      this.logo = logo ? configUrl.bucketURL + 'logo/' + logo : 'assets/images/logo.png';
    });
  }

  ngOnInit() {
    this.subdomain = this.authService.getSubdomain();
    setTimeout(() => {
      this.store.dispatch(hide());
    }, 0);

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.store.dispatch(show());
    }, 0);
  }

  selfRegister(auth) {
    if (!auth.companySettings.companyid) {
      return of(auth);
    } else if (auth.selectedCompany) {
      return of(auth);
    } else if (auth.companySettings?.isselfregister === 1) {
      const url = configUrl.companyUserJoin;
      return forkJoin({
        register: this.http.post(url, {
          statusid: 2,
          companyid: auth.companySettings.companyid,
        }),
        auth: of(auth),
      }).pipe(map((response) => response.auth));
    } else {
      return of(auth);
    }
  }

  finalAction(auth): Observable<any> {
    const refreshId = uuid();
    if (this.invite) {
      const url = `${configUrl.companyInvite}/${this.invite.id}/accept`;
      return this.http
        .post(url, {
          userid: auth.auth.uid,
        })
        .pipe(
          catchError((error) => {
            if (error.error.code === 'ER_DUP_ENTRY') {
              this.logger.log('already a member');
            } else if (error.status === 410) {
              this.toastr.error($localize`This invite has already been used`);
            } else {
              this.toastr.error($localize`There was a problem with you invite`);
            }

            return of(null);
          }),
          tap(() => this.authService.refreshAuth.next(refreshId)),
          switchMap((_) => this.authService.user$),
          filter((a) => a.refreshId === refreshId),
          take(1),
          tap((a) => {
            if (!this.ready(a)) {
              this.authService.signOut();
              throw new Error('You do not have permissions.');
            }
          }),
        );
    }

    return of(null).pipe(
      tap(() => this.authService.refreshAuth.next(refreshId)),
      switchMap((_) => this.authService.user$),
      filter((a) => a.refreshId === refreshId),
      take(1),
      tap((a) => {
        if (!this.ready(a)) {
          this.authService.signOut();
          throw new Error('You do not have permissions.');
        }
      }),
    );
  }

  ready(auth): boolean {
    return (
      auth.auth && (!auth.companySettings.companyid || auth.selectedCompany || auth.companySettings?.ispublic === 1)
    );
  }

  continueAsLoggedInUser() {
    this.loading = true;
    this.authService.user$
      .pipe(
        take(1),
        switchMap((auth) => this.selfRegister(auth)),
        switchMap((auth) => this.finalAction(auth)),
      )
      .subscribe(
        (auth) => {
          this.redirect();
        },
        (error) => {
          this.loading = false;
          this.toastr.error(error.message);
        },
      );
  }

  redirect() {
    this.router.navigateByUrl(this.returnUrl);
  }

  logout() {
    this.authService.signOut(false);
  }

  login() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.authService
      .login(this.email, this.password)
      .pipe(
        switchMap((_) => this.authService.user$),
        filter((auth) => !!auth.auth),
        take(1),
        switchMap((auth) => this.selfRegister(auth)),
        switchMap((auth) => this.finalAction(auth)),
      )
      .subscribe(
        (auth) => {
          this.loading = false;
          this.redirect();
        },
        (error) => {
          this.loading = false;
          this.toastr.error(error.message);
        },
      );
  }

  loginAsGuest() {
    this.redirect();
  }

  loginSocial(social: string) {
    const inputs = {
      title: $localize`Terms of Service`,
      message: $localize`To sign in to ${config.title} you must agree to the <a href="${configUrl.terms}" target="_blank">Terms of Service</a> and <a href="${configUrl.policy}" target="_blank">Privacy Policy</a>`,
      yesButtonText: $localize`Agree`,
      noButtonText: $localize`:@@cancel:Cancel`,
    };
    const outputs = {
      completeHandler: (yes: boolean) => {
        if (yes) {
          if (social === 'google') {
            this.loginGoogle();
          } else if (social === 'facebook') {
            this.loginFacebook();
          }
        }
      },
    };
    this.modalService.init(ModalConfirmComponent, inputs, outputs);
  }

  loginGoogle() {
    this.authService
      .loginGoogle()
      .pipe(
        switchMap((info) => this.updateUserInfo(info)),
        switchMap((_) => this.authService.user$),
        filter((auth) => !!auth.auth),
        take(1),
        switchMap((auth) => this.selfRegister(auth)),
        switchMap((auth) => this.finalAction(auth)),
      )
      .subscribe(
        (info) => {
          this.redirect();
        },
        (error) => {
          this.toastr.error(error.message);
        },
      );
  }

  loginFacebook() {
    this.authService
      .loginFacebook()
      .pipe(
        switchMap((info) => this.updateUserInfo(info)),
        switchMap((_) => this.authService.user$),
        filter((auth) => !!auth.auth),
        take(1),
        switchMap((auth) => this.selfRegister(auth)),
        switchMap((auth) => this.finalAction(auth)),
      )
      .subscribe(
        (info) => {
          this.redirect();
        },
        (error) => {
          this.toastr.error(error.message);
        },
      );
  }

  signUp(name, email, password) {
    if (!this.terms) {
      this.toastr.error($localize`Please agree to the Terms of Service and Privacy Policy`);
      return;
    }

    if (this.loading) {
      return;
    }
    this.loading = true;
    this.authService
      .signup(name, email, password)
      .pipe(
        catchError((error) => {
          this.loading = false;
          this.toastr.error(error.message);
          return throwError(error);
        }),
        switchMap((info) => this.updateUserInfo(info)),
        switchMap((_) => this.authService.user$),
        filter((auth) => !!auth.auth),
        take(1),
        switchMap((auth) => this.selfRegister(auth)),
        switchMap((auth) => this.finalAction(auth)),
      )
      .subscribe(
        (auth) => {
          this.loading = false;
          this.redirect();
        },
        (error) => {
          this.loading = false;
          this.toastr.error(error.message);
        },
      );
  }

  updateUserInfo(userInfo: any) {
    return this.userService.saveUserInfo(userInfo);
  }

  changeTab(index: number) {
    this.tab = index;
    this.email = null;
    this.password = null;
    this.name = null;
  }

  isChrome() {
    return (window as any).chrome;
  }
}
