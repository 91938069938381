import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { switchMap, map, take } from 'rxjs/operators';

import {
  GlobalvariablesService,
  UiModalService,
  AuthService,
  NavService,
  LiveVideoService,
  QueryService,
  ChatService,
  UserService,
  GroupService,
  BusinessService,
  NotificationsService,
} from '@app/core/services';

import { ModalGroupAddComponent } from '../modal-group-add/modal-group-add.component';
import { ModalUserSearchComponent } from '@app/shared/modal/modal-user-search/modal-user-search.component';
import { ModalContactInviteComponent } from '@app/shared/modal/modal-contact-invite/modal-contact-invite.component';

import { config } from '@env/environment';
import { Feature, CompanyMemberType } from '@app/shared/model';
import { forkJoin, Subscription } from 'rxjs';

@Component({
  selector: 'app-combined',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {
  Feature = Feature;
  CompanyMemberType = CompanyMemberType;
  config = config;

  loading = false;

  compact = this.config.navInlineChatsGroups;

  chats: any;
  groups: any;

  menuVisible$ = this.navService.menuVisible$;

  @ViewChild('mainNavigation') mainNav: ElementRef;

  notificationsBadgeNumber = 0;

  groupListSub: Subscription;

  constructor(
    public gv: GlobalvariablesService,
    private modal: UiModalService,
    private router: Router,
    public authService: AuthService,
    private navService: NavService,
    private liveVideoService: LiveVideoService,
    public qs: QueryService,
    private chatService: ChatService,
    private groupService: GroupService,
    private businessService: BusinessService,
    public us: UserService,
    private notificationService: NotificationsService,
  ) {
    router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });

    this.groupListSub = groupService.observeGroupListChanges().subscribe(() => {
      this.loadGroups();
    });

    this.notificationService.getBadgeNumber().subscribe((badgeNumber) => {
      this.notificationsBadgeNumber = badgeNumber;
    });

    this.authService.loggedIn$.pipe(take(1)).subscribe((loggedIn) => {
      if (loggedIn) {
        this.loadGroups();
        this.loadChats();
      } else {
        this.groups = [];
        this.chats = [];
      }
    });
  }

  ngOnInit() {
    this.menuVisible$.subscribe((isVisible) => {
      if (isVisible) {
        this.mainNav.nativeElement.scrollTop = 0;
      }
    });
  }

  ngOnDestroy() {
    this.groupListSub?.unsubscribe();
  }

  loadGroups() {
    forkJoin({
      pending: this.groupService.getMyGroups({
        memberstatusid: '1',
      }),
      accepted: this.groupService.getMyGroups({
        limit: '5',
        sort: 'lastclipat',
        sortorder: 'desc',
        memberstatusid: '2',
      }),
    }).subscribe((groups) => (this.groups = [...groups.pending, ...groups.accepted]));
  }

  loadChats() {
    this.chatService
      .getMyChats(this.authService.activeUser.uid, {
        limit: '5',
        sort: 'lastclipat',
        sortorder: 'desc',
      })
      .subscribe((chats) => (this.chats = chats));
  }

  hideMenu() {
    this.navService.hideMenu();
  }

  hasFeature(feature: Feature) {
    return this.businessService.hasFeature(feature);
  }

  checkIfCountersIsSet(type: string, dataObject: any): boolean {
    if (type === 'chat') {
      return this.gv.userCounters?.chats?.[dataObject.id];
    } else if (type === 'group') {
      return this.gv.userCounters?.groups?.[dataObject.id];
    }
  }

  openAddChat() {
    this.hideMenu();
    const inputs = {
      modalTitle: $localize`Start Chat`,
      actionTitle: $localize`:@@chat:Chat`,
      blacklist: this.authService.userInfo ? [this.authService.userInfo] : [],
    };
    const outputs = {
      actionCallback: (user, modal: ModalUserSearchComponent) => {
        const userId1 = this.authService.activeUser.uid;
        const userId2 = user.id;
        const username = user.name;

        modal.dismiss();
        this.navigateToChat(userId1, userId2, username);
      },
    };

    this.modal.init(ModalUserSearchComponent, inputs, outputs);
  }

  navigateToChat(userId1: string, userId2: string, username: string) {
    this.chatService.navigateToChat(userId1, userId2, username).subscribe(() => {
      this.loadChats();
    });
  }

  openAddGroup() {
    this.hideMenu();
    const inputs = {};
    const outputs = {
      actionCallback: (group) => {
        this.loadGroups();
        this.router.navigate(['/combined/group', group.id]);
      },
    };
    this.modal.init(ModalGroupAddComponent, inputs, outputs);
  }

  checkRouterEvent(routerEvent: Event) {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    } else if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.loading = false;
    }
  }

  getUserChatId(chat: any) {
    if (chat.userid1 === this.authService.activeUser.uid) {
      return chat.userid2;
    } else {
      return chat.userid1;
    }
  }

  makeCall(userId: string, event: MouseEvent) {
    this.liveVideoService
      .createRoom()
      .pipe(
        switchMap((room) => {
          return this.liveVideoService.addUserToRoom(userId, room.id).pipe(map((_) => room));
        }),
      )
      .subscribe((room) => {
        this.hideMenu();
        this.navigateToRoom(room.id, userId);
      });

    event.stopPropagation();
    event.preventDefault();
  }

  inviteContact() {
    this.hideMenu();
    this.modal.init(ModalContactInviteComponent);
  }

  private navigateToRoom(roomId: string, call?: string) {
    const queryParams = { call };
    this.router.navigate([`/live/${roomId}`], { queryParams });
  }
}
