import { Injectable } from '@angular/core';
import { UiPopupDomService } from './ui-popup-dom.service';

@Injectable()
export class UiPopupService {
  constructor(private domService: UiPopupDomService) {}

  private modalElementId = 'popup-container';

  init(component: any, inputs: object = {}, outputs: object = {}) {
    if (this.domService.isOpen) {
      return;
    }

    const componentConfig = { inputs, outputs };
    this.domService.appendComponentTo(this.modalElementId, component, componentConfig);
    document.getElementById(this.modalElementId).className = 'ui-popup-show';
  }

  destroy() {
    this.domService.removeComponent();
    document.getElementById(this.modalElementId).className = 'ui-popup-hidden';
  }
}
