<div class="timeline-image">
  <span style="cursor: pointer" (click)="originalSize()">
    <img [src]="this.imageSrc" class="adjustedSize" />
  </span>

  <div class="image-container" *ngIf="fullScreen" #fullwidthimage>
    <div class="image-background" (click)="originalSize()"></div>
    <div class="image-data">
      <span style="cursor: pointer" (click)="originalSize()">
        <mdi name="close"></mdi>
      </span>
      <img [src]="this.imageSrc" />
    </div>
  </div>
</div>
