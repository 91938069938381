import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ScrollDispatcher } from '@angular/cdk/scrolling';

import {
  QueryService,
  UserService,
  BusinessService,
  CategoryService,
  GroupService,
  NotificationsService,
  AuthService,
  NavService,
} from '@app/core/services';

import { configUrl, config } from '@env/environment';
import { Feature } from '@app/shared/model';

@Component({
  selector: 'app-app-bar',
  templateUrl: './app-bar.component.html',
  styleUrls: ['./app-bar.component.scss', '../../nav/nav/nav.component.scss'],
})
export class AppBarComponent implements OnInit {
  @Output() composeStatusChange = new EventEmitter<boolean>();

  @ViewChild('inputSearch') inputSearch: ElementRef<HTMLInputElement>;

  configUrl: any = configUrl;
  config = config;
  Feature = Feature;
  audioUrl: any;
  sideNav = false;
  searchToggleOn = false;

  showAllGroups = false;
  showAllChats = false;
  showToTop = false;

  condensedNavigationSize = 5;

  constructor(
    public business: BusinessService,
    public cs: CategoryService,
    public groupManager: GroupService,
    public qs: QueryService,
    public us: UserService,
    public authService: AuthService,
    private navService: NavService,
    private scrollDispacher: ScrollDispatcher,
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.topNav();
  }

  showMenu() {
    this.navService.showMenu();
  }

  topNav() {
    this.scrollDispacher.scrolled().subscribe((scrollable) => {
      if (scrollable === undefined) {
        const scrollY = this.document.documentElement.scrollTop;
        const screenHeight = this.document.documentElement.clientHeight;

        const shouldShowToTop = scrollY > screenHeight * 2;

        if (shouldShowToTop !== this.showToTop) {
          this.zone.run((_) => {
            this.showToTop = shouldShowToTop;
          });
        }
      }
    });
  }

  scrollToTop() {
    this.document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
