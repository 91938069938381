import { Component, Input, EventEmitter, Output } from '@angular/core';
import { UiModalService, UiPopupService } from '@app/core/services';

@Component({
  selector: 'app-create-post-popup',
  templateUrl: './post-create-popup.component.html',
  styleUrls: ['./post-create-popup.component.scss'],
})
export class PostCreatePopupComponent {
  tab = 0;

  @Input() tabs: string[] = ['Video', 'Audio', 'Text'];

  @Input() heading = 'New Post';
  @Input() chat: any = null;
  @Input() group: any = null;
  @Input() title: string = null;
  @Input() parentId: string = null;
  @Input() categoryId: number = null;
  @Input() target: string = null;
  @Input() userId: string = null;
  @Input() inModal = false;

  @Output() public uploadComplete = new EventEmitter();

  get cliptype() {
    return this.tabs[this.tab];
  }

  constructor(
    private popupService: UiPopupService,
    private modalService: UiModalService,
  ) {}

  dismissAction() {
    this.popupService.destroy();
    this.modalService.destroy();
  }

  switchTab(tab) {
    this.tab = tab;
  }

  uploadCompleteAction() {
    this.uploadComplete.emit();
  }
}
