import { Component, Output } from '@angular/core';
import { timer, forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { UiModalService, GroupService } from '@app/core/services';

@Component({
  templateUrl: './modal-group-add.component.html',
})
export class ModalGroupAddComponent {
  @Output() actionCallback: (response: any) => void;

  name: string;
  saving = false;

  constructor(
    private modal: UiModalService,
    private groupService: GroupService,
    private toastr: ToastrService,
  ) {}

  createAction() {
    if (!this.name) {
      return;
    }

    if (!this.saving) {
      this.saving = true;

      forkJoin({
        response: this.groupService.createGroup(this.name),
        timer: timer(500),
      }).subscribe(
        ({ response }) => {
          this.toastr.success($localize`Group successfully created.`);

          this.saving = false;
          this.actionCallback?.(response);
          this.modal.destroy();
        },
        (_) => {
          this.toastr.error($localize`There was a problem creating a group.`);
          this.saving = false;
        },
      );
    }
  }

  dismissAction() {
    this.modal.destroy();
  }
}
