import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable()
export class LoggerService {
  log(message: any) {
    if (!environment.production) {
      console.log(message);
    }
  }

  error(error: any) {
    console.error(error);
  }
}
