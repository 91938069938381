import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';

enum AudioPlayerRequestAction {
  Play,
  Stop,
}

interface AudioPlayerRequest {
  action: AudioPlayerRequestAction;
  playNext?: boolean;
  audio?: string;
  startedHandler?: (audio: HTMLAudioElement) => void;
  completionHandler?: (playNext: boolean) => void;
}

export class AudioPlayerPlayAllManager {
  private autoPlayingClip: any = null;
  private clips?: any = [];

  public get autoPlaying() {
    return Boolean(this.autoPlayingClip);
  }

  constructor(private audioPlayer: AudioPlayerService) {}

  public playAll(clips: any[]) {
    this.clips = clips;

    if (this.autoPlaying) {
      this.autoPlayingClip.stopAudio?.();
      return;
    }

    this.audioPlayer.stop();
    this._play(0);
  }

  private _play(index: number) {
    if (!this.clips?.[index]) {
      this.autoPlayingClip = null;
      return;
    }

    this.autoPlayingClip = this.clips?.[index];

    this.clips[index].playAudio?.(true, (playNext) => {
      if (!playNext) {
        this.autoPlayingClip = null;
        return;
      }

      if (this.autoPlaying) {
        this._play(index + 1);
      }
    });
  }
}

@Injectable()
export class AudioPlayerService {
  private player = new Audio();

  private loading = false;

  private currentRequest: AudioPlayerRequest;
  private pendingRequest: AudioPlayerRequest;

  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
  ) {
    this.player.addEventListener('ended', (event) => {
      this._end();
    });
  }

  public play(
    audio: string,
    playNext = false,
    startedHandler: (audio: HTMLAudioElement) => void,
    completionHandler: (playNext: boolean) => void,
  ) {
    const request = {
      action: AudioPlayerRequestAction.Play,
      playNext,
      audio,
      startedHandler,
      completionHandler,
    };

    if (this.loading) {
      this.pendingRequest = request;
    } else {
      this._stop();
      this.currentRequest = request;
      this._play();
    }
  }

  public stop() {
    const request = {
      action: AudioPlayerRequestAction.Stop,
    };

    if (this.loading) {
      this.pendingRequest = request;
    } else {
      this._stop();
    }
  }

  private _play() {
    this.loading = true;

    this.loadAudio(this.currentRequest.audio).then((data) => {
      this.player.src = data as string;

      this.player.load();
      this.player.play().then((_) => {
        this.loading = false;

        if (this.pendingRequest) {
          this._stop();
          this.currentRequest = this.pendingRequest;
          this.pendingRequest = null;
          if (this.currentRequest.action === AudioPlayerRequestAction.Play) {
            this._play();
          }
        } else {
          this.currentRequest.startedHandler(this.player);
        }
      });
    });
  }

  private _stop() {
    if (!this.currentRequest) {
      return;
    }

    this.player.pause();
    this._end(true);
  }

  private _end(cancelPlayNext = false) {
    const request = this.currentRequest;
    this.currentRequest = null;

    const shouldPlayNext = !cancelPlayNext && (request?.playNext ?? false);
    request?.completionHandler?.(shouldPlayNext);
  }

  private loadAudio(url: string): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const http = url.includes('amazon') ? new HttpClient(this.httpBackend) : this.http;
      http.get(url, { responseType: 'arraybuffer' }).subscribe(
        (response) => {
          const data = new Uint8Array(response);
          const blob = new Blob([data], { type: 'audio/mpeg' });
          const blobURL = URL.createObjectURL(blob);
          resolve(blobURL);
        },
        (error) => reject(error),
      );
    });
  }
}
