import { ApplicationRef, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, UserService } from '@app/core/services';

import { Action, Actions } from '@app/shared/model';
import { videoroomConfig } from '@env/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-greenroom',
  templateUrl: './greenroom.component.html',
  styleUrls: ['./greenroom.component.scss'],
})
export class GreenroomComponent implements OnInit, OnDestroy {
  stage: any;

  roomVisible = false;
  roomStarted = false;
  roomJoined = false;
  displayName: string;
  userId: string | null = null;

  janusServerUrl = videoroomConfig.url;
  iceServers = videoroomConfig.iceServers;

  viewersCount: number;
  viewersSubscription: Subscription;

  isConnected = false;

  constructor(
    private route: ActivatedRoute,
    public authService: AuthService,
    private userService: UserService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private afDatabase: AngularFireDatabase,
  ) {
    this.displayName = this.authService.userInfo?.username || $localize`Guest`;
    this.userId = this.authService.activeUser?.uid || null;
  }

  ngOnInit(): void {
    this.watchParams();
  }

  ngOnDestroy() {}

  watchParams() {
    if (!this.authService.canPresentOnLiveStage) {
      return;
    }

    this.route.data.subscribe((data) => {
      this.roomVisible = false;
      this.leftRoom();
      this.cd.detectChanges();
      this.stage = data.stage;
      this.roomVisible = true;
      this.viewersSubscription?.unsubscribe();

      const path = `livevideo/room2/${this.authService.currentBusinessId}/${this.stage.id}`;
      this.viewersSubscription = this.afDatabase
        .object<any>(path)
        .valueChanges()
        .subscribe((room: any) => {
          this.viewersCount = room?.usersliveuniq || 0;
        });
    });
  }

  connected() {
    this.isConnected = true;
    this.roomStarted = true;
  }

  join() {
    this.roomJoined = true;
  }

  leftRoom() {
    this.roomJoined = false;
  }
}
