<form #inviteForm="ngForm" autocomplete="off">
  <div class="ui-modal-header">
    <h2>{{ modalTitle }}</h2>
    <div class="ui-modal-close" (click)="dismiss()">
      <mdi name="Close"></mdi>
    </div>
  </div>

  <div class="ui-modal-body">
    <!-- {{sessions|json}} -->
    <div
      class="members-list posts-list"
      [ngClass]="{ 'scroll-at-top': listTopShadow, 'scroll-at-bottom': listBottomShadow }"
    >
      <div cdkScrollable #sessionsList>
        <ul *ngIf="sessions.length">
          <li *ngFor="let session of sessions" class="has-subtitle" style="padding: 0 70px 0 0">
            <div class="members-list-name">
              {{ session.title }}
              <div class="members-list-subtitle">{{ formatDate(session.startdate) }}</div>
            </div>
            <a (click)="performAction(session)" class="action">{{ actionTitle }}</a>
          </li>
        </ul>
        <div *ngIf="!sessions.length" class="suggestions-list-no-results" i18n>No sessions</div>
      </div>
    </div>
  </div>

  <div class="ui-modal-footer"></div>
</form>
