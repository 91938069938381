import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { format } from 'date-fns';

import { AuthService } from './auth.service';

import { configUrl, config } from '@env/environment';
import { LoggerService } from './logger.service';

@Injectable()
export class QueryService {
  query = '';
  configUrl = configUrl;
  config = config;

  constructor(
    private httpBackend: HttpBackend,
    private http: HttpClient,
    private authService: AuthService,
    private logger: LoggerService,
  ) {}

  getAudioImage(imageId: string, callback: any) {
    const options = {
      responseType: 'arraybuffer' as 'json',
    };

    const getAudioImageObserv = this.http.get<any>(this.configUrl.audioImageFS + imageId, options);
    callback(getAudioImageObserv);
  }

  getClipPlays(clip: any): Observable<any> {
    if (!clip.id) {
      return of(null);
    }
    return this.http.get(`${this.configUrl.clipPlays}${clip.id}`);
  }

  mySeeds(seedsMyLoadMore: number): Observable<any> {
    return this.http.get(this.configUrl.clipMy + '?cliptype=All&limit=100&offset=' + seedsMyLoadMore);
  }

  formatDate(value: number): string {
    if (!value) {
      return '';
    }

    const created = new Date(value);

    return format(created, 'd MMM yyyy, k:mm:ss');
  }

  formatDateToDay(value: number): any {
    if (!value) {
      return '';
    }

    const created = new Date(value);

    return format(created, 'd MMM yyyy');
  }

  formatDateInbox(value: number): any {
    const now = new Date();
    const nowTime = now.getTime();
    const nowHours = now.setHours(0, 0, 0, 0);
    const created = new Date(value);
    const createdTime = created.getTime();
    const createdHours = created.setHours(0, 0, 0, 0);

    if (nowTime - createdTime <= 3600000) {
      return format(new Date(value), 'h:mma').toLowerCase();
    } else if (nowHours === createdHours) {
      return format(new Date(value), 'h:mma').toLowerCase();
    } else if (now.getFullYear() === created.getFullYear()) {
      return format(created, 'd MMM');
    } else {
      return format(created, 'd MMM yyyy');
    }
  }

  updatePlayedat(myseed) {
    setTimeout(() => {
      myseed.playedat = new Date();
      return myseed;
    }, 10000);
  }

  updatePlayedNumber(myseed) {
    this.http.get(this.configUrl.audioURL + 'video/' + myseed.id).subscribe(
      (response) => {},
      (error) => {
        this.logger.error(error);
      },
    );
  }

  searchUserGlobalSBSub(searchstring: string): Observable<any> {
    // Use different http client so http intercepters are bypassed
    const httpClient = new HttpClient(this.httpBackend);

    const headers = new HttpHeaders({ Authorization: `Bearer ${this.authService.token}` });
    const options = { headers };
    const find = '/';
    const re = new RegExp(find, 'g');
    const cleanSearchQuery = searchstring.replace(re, '%2F');
    const limit = '?limit=100&offset=0';

    return httpClient.get(`${this.configUrl.searchUser}${cleanSearchQuery}${limit}&type=User`, options);
  }
}
