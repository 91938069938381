import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

export enum LiveVideoIncomingCallResult {
  Accept,
  Reject,
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[app-live-video-incoming-call-toast]',
  templateUrl: './live-video-incoming-call-toast.component.html',
  styleUrls: ['./live-video-incoming-call-toast.component.scss'],
})
export class LiveVideoIncomingCallToastComponent extends Toast {
  LiveVideoIncomingCallResult = LiveVideoIncomingCallResult;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  action(result: LiveVideoIncomingCallResult) {
    this.toastPackage.triggerAction(result);
    this.remove();
  }
}
