import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { UiModalService } from '@app/core/services';
import { config, configUrl } from '@env/environment';
import { NgForm } from '@angular/forms';

class Invite {
  constructor(
    public email: string,
    public fullname?: string,
  ) {}
}

@Component({
  templateUrl: './modal-contact-invite.component.html',
})
export class ModalContactInviteComponent {
  model = new Invite('');
  saving = false;

  inviteTo: string = config.title;

  constructor(
    private modal: UiModalService,
    private http: HttpClient,
    private toastr: ToastrService,
  ) {}

  inviteAction(form: NgForm) {
    if (form.invalid) {
      this.toastr.error($localize`Please fix form errors.`);
      return;
    }

    if (!this.saving) {
      this.saving = true;

      const body = [
        {
          email: this.model.email,
          fullname: this.model.fullname,
        },
      ];

      this.http.post(configUrl.multipleInvites, body).subscribe(
        (response) => {
          this.toastr.success($localize`Invitation sent.`);
          form.resetForm();
          this.saving = false;
        },
        (error) => {
          this.toastr.error($localize`There was a problem sending invitations`);
        },
      );
    }
  }

  dismissAction() {
    this.modal.destroy();
  }
}
