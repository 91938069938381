<pre>{{ user | json }}</pre>

<pre>{{ presence | json }}</pre>

<app-user-image [userId]="user.id" [imageId]="user.imageid" classType="nav-photo"></app-user-image>
<div>{{ user.username }}</div>
<div>{{ user.jobrole }}</div>
<div>
  <button *ngIf="presence?.status === 'online'" (click)="call()" class="btn btn-lg btn-primary" i18n>Video Call</button>
  <button *ngIf="presence?.status !== 'online'" (click)="call()" class="btn btn-lg btn-secondary" disabled i18n>
    Video Call
  </button>
</div>
