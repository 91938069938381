import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
})
export class CropperComponent implements OnInit {
  @Input() imageUrl: SafeUrl | undefined;
  @Input() containerWidth = 600;
  @Output() cropped = new EventEmitter<Blob>();
  @Output() cancelled = new EventEmitter();

  @ViewChild('img') img: ElementRef | undefined;

  zoom = 1;
  width: number | undefined;
  height: number | undefined;

  startX: number | undefined;
  startY: number | undefined;

  x: number = 0;
  y: number = 0;

  offsetX: number = 0;
  offsetY: number = 0;

  centerX = this.containerWidth / 2;
  centerY = 150;
  circle = 240;

  zoomStart: number | undefined;

  loaded = false;

  constructor(private cd: ChangeDetectorRef) {
    this.createToBlobPolyfill();
  }

  ngOnInit(): void {
    this.centerX = this.containerWidth / 2;
  }

  onLoad(img: HTMLImageElement) {
    const w = img.width;
    const h = img.height;

    if (w > h) {
      this.width = w * (this.circle / h);
      this.height = this.circle;
      this.zoomStart = this.circle / h;
    } else {
      this.width = this.circle;
      this.height = h * (this.circle / w);
      this.zoomStart = this.circle / w;
    }

    this.loaded = true;
    this.cd.detectChanges();
  }

  onChangeZoom(zoom: number) {
    this.zoom = zoom;
    this.adjustPosition();
  }

  onMouseDown(event: MouseEvent) {
    if (!this.loaded) {
      return;
    }
    this.startX = event.x;
    this.startY = event.y;
  }

  onMouseMove(event: MouseEvent) {
    if (this.startX !== undefined && this.startY !== undefined) {
      this.offsetX = event.x - this.startX;
      this.offsetY = event.y - this.startY;
    }
  }

  onMouseUp(event: MouseEvent) {
    if (this.startX !== undefined && this.startY !== undefined) {
      this.x = this.x + this.offsetX;
      this.y = this.y + this.offsetY;

      this.startX = undefined;
      this.startY = undefined;
      this.offsetX = 0;
      this.offsetY = 0;

      this.adjustPosition();
    }
  }

  adjustPosition() {
    const width = (this.width || 0) * this.zoom;
    const height = (this.height || 0) * this.zoom;

    const leftBoundary = -((width - this.circle) / 2);
    const rightBoundary = (width - this.circle) / 2;
    const topBoundary = -((height - this.circle) / 2);
    const bottomBoundary = (height - this.circle) / 2;

    if (this.x < leftBoundary) {
      this.x = leftBoundary;
    }
    if (this.x > rightBoundary) {
      this.x = rightBoundary;
    }
    if (this.y < topBoundary) {
      this.y = topBoundary;
    }
    if (this.y > bottomBoundary) {
      this.y = bottomBoundary;
    }
  }

  finish() {
    const x = (0.5 * this.width! * this.zoom - this.circle / 2 - this.x) / this.zoomStart! / this.zoom;
    const y = (0.5 * this.height! * this.zoom - this.circle / 2 - this.y) / this.zoomStart! / this.zoom;
    const size = this.circle / this.zoom / this.zoomStart!;

    const canvas = document.createElement('canvas');
    canvas.width = 512;
    canvas.height = 512;
    const context = canvas.getContext('2d')!;
    context.drawImage(this.img!.nativeElement, x, y, size, size, 0, 0, 512, 512);

    canvas.toBlob((blob) => {
      this.cropped.emit(blob!);
    }, 'image/jpeg');
  }

  cancel() {
    this.cancelled.emit();
  }

  createToBlobPolyfill() {
    if (!HTMLCanvasElement.prototype.toBlob) {
      Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value: function (callback, type, quality) {
          const dataURL = this.toDataURL(type, quality).split(',')[1];
          setTimeout(function () {
            const binStr = atob(dataURL);
            const len = binStr.length;
            const arr = new Uint8Array(len);

            for (let i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }

            callback(new Blob([arr], { type: type || 'image/png' }));
          });
        },
      });
    }
  }
}
