import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { AppointmentsService, AuthService } from '../services';
import { Appointment } from '@app/shared/model';

@Injectable()
export class AppointmentsResolver implements Resolve<any> {
  constructor(
    private appointmentsService: AppointmentsService,
    private authService: AuthService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): Observable<any> {
    const id = route.paramMap.get('id');
    return this.getData(id);
  }

  getData(id: string): Observable<Appointment[]> {
    return this.authService.activeUser
      ? this.appointmentsService.getUserAppointments(id)
      : this.appointmentsService.getUserPublicAppointments(id);
  }
}
