<div class="ui-modal-header">
  <h2>{{ title }}</h2>
  <div class="ui-modal-close" (click)="dismissAction()">
    <mdi name="Close"></mdi>
  </div>
</div>

<div class="ui-modal-body">
  <p style="overflow: hidden" [innerHTML]="message"></p>
</div>

<div class="ui-modal-footer">
  <button class="btn btn-md btn-primary" (click)="okAction()" i18n>OK</button>
</div>
