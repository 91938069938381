import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { configUrl } from '@env/environment';

@Injectable()
export class PollsService {
  api = configUrl.URL;

  constructor(private http: HttpClient) {}

  public getPolls(): Observable<any> {
    const url = `${this.api}poll`;
    return this.http.get<any>(url);
  }

  public getPoll(id: string): Observable<any> {
    const url = `${this.api}poll/${id}`;
    return this.http.get<any>(url);
  }

  public getPollOptions(id: string): Observable<any> {
    const url = `${this.api}poll/${id}/option`;
    return this.http.get<any>(url);
  }

  public getPollResults(id: string): Observable<any> {
    const url = `${this.api}poll/${id}/response`;
    return this.http.get<any>(url);
  }

  public vote(pollId: string, optionId: string): Observable<any> {
    const body = [
      {
        polloptionid: optionId,
        answerbool: 1,
      },
    ];
    const url = `${this.api}poll/${pollId}/response`;
    return this.http.post<any>(url, body);
  }

  public getRoomPolls(roomId: string): Observable<any> {
    const url = `${this.api}poll/room/${roomId}`;
    return this.http.get<any>(url);
  }

  public addPollToRoom(pollId: string, roomid: string): Observable<any> {
    const body: any = { roomid };
    const url = `${this.api}poll/${pollId}/room`;
    return this.http.post<any>(url, body);
  }

  public removePollFromRoom(pollId: string, roomId: string): Observable<any> {
    const url = `${this.api}poll/${pollId}/room/${roomId}`;
    return this.http.delete<any>(url);
  }

  public createPoll(title, description): Observable<any> {
    const body: any = { title };
    if (description) {
      body.description = description;
    }
    const url = `${this.api}poll`;
    return this.http.post<any>(url, body);
  }

  public createPollOptions(id: string, body: any[]): Observable<any> {
    const url = `${this.api}poll/${id}/option`;
    return this.http.put<any>(url, body);
  }

  public updatePoll(id, title, description): Observable<any> {
    const body: any = { id, title };
    if (description) {
      body.description = description;
    }
    const url = `${this.api}poll/${id}`;
    return this.http.put<any>(url, body);
  }

  public deletePoll(id) {
    const url = `${this.api}poll/${id}`;
    return this.http.delete<any>(url);
  }
}
