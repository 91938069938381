import { Component, Input, Output, EventEmitter } from '@angular/core';

import { TileAction } from '../../model';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
})
export class TileComponent {
  @Input() maintitle: string;
  @Input() subtitle: string;
  @Input() imageId: string;
  @Input() userId: string;
  @Input() icon: string;
  @Input() presence: string | boolean | null;
  @Input() actions: TileAction[] = [];
  @Input() badgeNumber: string;

  @Output() handleAction = new EventEmitter<TileAction>();

  actionClick(event: MouseEvent, action: TileAction) {
    event.stopImmediatePropagation();
    this.handleAction.emit(action);
  }
}
