import { Injectable } from '@angular/core';
import { config } from '@env/environment';

@Injectable()
export class LangService {
  strings = {
    wal: {
      community: $localize`community`,
      Community: $localize`Community`,
      communities: $localize`communities`,
      Communities: $localize`Communities`,
      Community_Admin: $localize`Community Admin`,
      My_Communities: $localize`My Communities`,
      Community_Details: $localize`Community Details`,
      Community_Name: $localize`Community Name`,
      Community_URL: $localize`Community URL`,
      Create_Community: $localize`Create Community`,
      Change_community_theme: $localize`Change community theme`,
      Community_settings: $localize`Community settings`,
      Add_Community: $localize`Add Community`,
      Auth_Title: $localize`The All-In-One Video Platform`,
      Auth_Description: $localize`Video calls and video messages for work`,
    },
    ea: {
      community: $localize`event`,
      Community: $localize`Event`,
      communities: $localize`events`,
      Communities: $localize`Events`,
      Community_Admin: $localize`Event Admin`,
      My_Communities: $localize`My Events`,
      Community_Details: $localize`Event Details`,
      Community_Name: $localize`Event Name`,
      Community_URL: $localize`Event URL`,
      Create_Community: $localize`Create Event`,
      Change_community_theme: $localize`Change event theme`,
      Community_settings: $localize`Event settings`,
      Add_Community: $localize`Add Event`,
      Auth_Title: $localize`Virtual events. Virtually flawless`,
      Auth_Description: $localize`The all-in-one virtual events platform for more personable online events`,
    },
  };

  lang(id: string) {
    return this.strings[config.prefix][id];
  }
}
