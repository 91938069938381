<div class="ui-modal-header">
  <h2 i18n>Edit Post</h2>
  <div class="ui-modal-close" (click)="closeAction()">
    <mdi name="Close"></mdi>
  </div>
</div>

<form [formGroup]="editForm" #ngForm="ngForm" (ngSubmit)="submitForm(ngForm)" novalidate>
  <div class="ui-modal-body">
    <div class="mb-3">
      <input
        type="text"
        [class.is-invalid]="editForm.get('title').invalid && (editForm.get('title').dirty || ngForm.submitted)"
        class="form-control"
        formControlName="title"
        id="title"
        name="title"
        autocomplete="off"
      />
      <div class="invalid-feedback" i18n>Please enter a title.</div>
    </div>

    <div class="mb-3" [hidden]="clip.chatid || clip.groupid || !categoriesLoaded || !categories.length">
      <select class="form-select" formControlName="category" id="category" name="category">
        <option [ngValue]="null" i18n>No Category</option>
        <option
          *ngFor="let category of categories"
          [ngValue]="category.id"
          [selected]="editForm.get('category').value === category.id"
        >
          {{ category.fullName }}
        </option>
      </select>
    </div>

    <div class="mb-3">
      <input
        type="text"
        [class.is-invalid]="editForm.get('link').invalid && (editForm.get('link').dirty || ngForm.submitted)"
        class="form-control"
        formControlName="link"
        id="link"
        name="link"
        autocomplete="off"
        i18n-placeholder
        placeholder="Link"
      />
      <div class="invalid-feedback" i18n>Please enter a valid web address.</div>
    </div>

    <div class="postnew-attach" *ngIf="!originalUrl">
      <div class="img-upload">
        <a href="javascript:void(0)" class="btn btn-outline-info"
          ><mdi name="Plus" size="16"></mdi> <span i18n>Image</span></a
        >
        <input
          #profileImageInput
          type="file"
          class="img-preview-upload"
          id="image"
          name="image"
          accept="image/jpeg"
          (change)="processFile(profileImageInput)"
          required
        />
      </div>
    </div>

    <div class="mb-3" *ngIf="thumbnail || originalUrl">
      <div class="img-preview" [class.img-preview-image]="thumbnail || originalUrl">
        <img [src]="thumbnail || originalUrl ? (thumbnail ? thumbnail?.src : originalUrl) : ''" />

        <div>
          <div (click)="removeThumbnail()" class="img-preview-remove" *ngIf="thumbnail">
            <mdi name="Close"></mdi>
          </div>
          <div (click)="removeOriginalImage()" class="img-preview-remove" *ngIf="originalUrl">
            <mdi name="Close"></mdi>
          </div>
        </div>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>

  <div class="ui-modal-footer">
    <button class="btn btn-md btn-light" (click)="closeAction()" i18n="@@cancel">Cancel</button>
    <button type="submit" [ngClass]="{ 'ui-modal-saving': saving }" class="btn btn-md btn-primary" i18n>Update</button>
  </div>
</form>
