<div class="tile">
  <div class="tile-profile-image" *ngIf="userId">
    <app-user-image [userId]="userId" [imageId]="imageId"></app-user-image>
    <div *ngIf="presence === true" class="presence"></div>
    <div *ngIf="presence && presence !== true" class="presence presence-text">{{ presence }}</div>
  </div>
  <div class="tile-icon" *ngIf="icon">
    <mdi [name]="icon" size="40"></mdi>
  </div>
  <div class="tile-body">
    <div class="tile-title">{{ maintitle }}</div>
    <div class="tile-subtitle">{{ subtitle }}</div>
  </div>
  <div class="tile-actions" *ngIf="badgeNumber">
    <span class="badge rounded-pill bg-primary">{{ badgeNumber }}</span>
  </div>
  <div class="tile-actions" *ngIf="actions.length">
    <a *ngFor="let action of actions" class="tile-action" (click)="actionClick($event, action)">
      <mdi [name]="action.icon"></mdi>
    </a>
  </div>
</div>
