<div class="layout-middle-col">
  <app-access-denied *ngIf="!canViewPage"></app-access-denied>

  <div *ngIf="canViewPage">
    <app-page-header [breadcrumbs]="breadcrumbs" i18n>Reported Clips</app-page-header>

    <app-no-records *ngIf="reportedClips?.length === 0"></app-no-records>

    <app-loading *ngIf="!reportedClips"></app-loading>

    <div class="shadow-box">
      <div class="shadow-box-header top" *ngIf="reportedClips?.length > 0">
        <div class="search-box">
          <div class="search-input">
            <span><mdi name="Search"></mdi></span>
            <input
              class="form-control"
              type="text"
              placeholder="search clip by title"
              i18n-placeholder
              [(ngModel)]="filterQuery"
            />
          </div>
        </div>
      </div>

      <div class="shadow-box-body" *ngIf="reportedClips?.length > 0">
        <table class="table">
          <thead>
            <tr>
              <th i18n>Post</th>
              <th i18n>Author</th>
              <th i18n>Date</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let clip of reportedClips | filter: filterByTitle : filterQuery">
              <td class="reported-clips-post">
                <span class="icon" *ngIf="!clip.groupid && !clip.chatid">
                  <mdi name="Timeline"></mdi>
                </span>
                <span class="icon" *ngIf="clip.chatid">
                  <mdi name="Chat"></mdi>
                </span>
                <span class="icon" *ngIf="clip.groupid">
                  <mdi name="Group"></mdi>
                </span>
                <a [routerLink]="['/combined/clip/', clip.id]">{{ clip.title }}</a>
              </td>
              <td>
                <span *ngIf="clip.authorid">
                  <app-user-image
                    [userId]="clip.authorid"
                    [imageId]="clip.imageid"
                    [classType]="'nav-photo'"
                  ></app-user-image>
                </span>
                <span *ngIf="!clip.username?.length">
                  {{ clip.authorid }}
                </span>
                <span *ngIf="clip.username?.length">
                  {{ clip.username }}
                </span>
              </td>
              <td>
                <span>{{ qs.formatDateInbox(clip.createdat) }}</span>
              </td>
              <td>
                <span *ngIf="clip.isdeleted" i18n="@@deleted">Deleted</span>
                <div class="dropdown" ngbDropdown placement="bottom-right">
                  <mdi ngbDropdownToggle class="no-caret" name="More" aria-haspopup="true" aria-expanded="false"></mdi>
                  <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                    <a
                      ngbDropdownItem
                      class="dropdown-item"
                      *ngIf="!clip.isdeleted"
                      (click)="modalActionDelete(clip)"
                      i18n="@@deleteClip"
                      >Delete Clip</a
                    >
                    <a ngbDropdownItem class="dropdown-item" (click)="modalActionRemoveFromReported(clip)" i18n
                      >Cancel Report</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
