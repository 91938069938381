<form #inviteForm="ngForm" autocomplete="off">
  <div class="ui-modal-header">
    <h2>{{ modalTitle }}</h2>
    <div class="ui-modal-close" (click)="dismiss()">
      <mdi name="Close"></mdi>
    </div>
  </div>

  <div class="ui-modal-body">
    <div
      class="members-list posts-list"
      [ngClass]="{ 'scroll-at-top': listTopShadow, 'scroll-at-bottom': listBottomShadow }"
    >
      <ul cdkScrollable #usersList *ngIf="polls.length">
        <li *ngFor="let poll of polls; let i = index" class="has-subtitle" style="padding: 0 70px 0 0">
          <div class="members-list-name">
            {{ poll.title }}
          </div>
          <a (click)="performAction(poll)" class="action">{{ actionTitle }}</a>
        </li>
      </ul>
      <div *ngIf="!polls.length" class="suggestions-list-no-results" i18n>No polls</div>
    </div>
  </div>

  <div class="ui-modal-footer"></div>
</form>
