<div class="heading">
  <h2 i18n>Participants</h2>
  <div class="close-action" (click)="closePanel.emit()">
    <mdi name="Close" size="20"></mdi>
  </div>
  <div class="heading-actions" *ngIf="showAddUser">
    <a (click)="addUser.emit()" class="btn btn-outline-primary btn-sm" i18n="@@inviteUser">Invite User</a>
  </div>
</div>

<div class="user-body">
  <ul class="user-list">
    <li *ngIf="isPublishing">
      <div class="user-list-image-container">
        <div class="user-list-image">
          <app-user-image [userId]="authService.activeUser?.uid"></app-user-image>
        </div>
      </div>
      <div class="user-list-text">
        {{ displayName }}
      </div>
    </li>
    <li *ngFor="let participant of participants">
      <div class="user-list-image-container" (click)="showParticipant(participant)">
        <div *ngIf="!!participant.tracks && !(participant.id | janPublisher: 'isScreen')" class="user-list-image">
          <app-user-image [userId]="participant.id | janPublisher"></app-user-image>{{ participant.id | janPublisher }}
        </div>
        <div *ngIf="!participant.tracks" class="user-list-image">
          <mdi name="Loading" size="24" class="rotate-icon"></mdi>
        </div>
        <div *ngIf="!!participant.tracks && (participant.id | janPublisher: 'isScreen')" class="user-list-image">
          <mdi name="Screen" size="24"></mdi>
        </div>
      </div>
      <div class="user-list-text">
        {{ participant.display }}
      </div>
    </li>
  </ul>
  <div *ngIf="attendees">
    <div class="sub-heading">
      <h3><span i18n>All Attendees</span> <span>(</span>{{ attendees.length + 1 }}<span>)</span></h3>
    </div>
    <ul class="attendees-list">
      <li>{{ displayName }}</li>
      <li *ngFor="let attendee of attendees">{{ attendee.display }}</li>
    </ul>
  </div>
</div>
