import { endpoints } from './endpoints';
import { config, domain, isLocalDomain, urlProtocol } from './config.wal';

const URL = 'https://api-prod.watchandlearn.co.uk/v1/';
const audioURL = 'https://file-prod.watchandlearn.co.uk/';
const imageURL = 'https://file-prod.watchandlearn.co.uk/';
const bucket = 'https://storage.googleapis.com/walprod-bf5aa.appspot.com/';
const learnMore = 'https://watchandlearn.io/';
const terms = 'https://watchandlearn.io/terms/';
const policy = 'https://watchandlearn.io/privacy/';

const awsUrl = 'https://s3-eu-west-1.amazonaws.com/watchandlearn.video/';

const environment = {
  production: true,
  version: require('../../package.json').version,
};

const videoroomConfig = {
  url: 'wss://janus-wal-prod.eventanywhere.com/',
  iceServers: [{ urls: 'stun:stun.l.google.com:19302' }, { urls: 'stun:stun.services.mozilla.com' }],
};

const firebaseConfig = {
  firebase: {
    apiKey: 'AIzaSyBKK65kgLvhflzBmFh8xqCfRho4a2cM8kw',
    authDomain: 'walprod-bf5aa.firebaseapp.com',
    databaseURL: 'https://walprod-bf5aa.firebaseio.com',
    projectId: 'walprod-bf5aa',
    storageBucket: 'walprod-bf5aa.appspot.com',
    messagingSenderId: '948612720595',
    appId: '1:948612720595:web:fcfaa2183765d7b93ff126',
  },
  dynamicLink: null,
};

const configUrl = (function () {
  const obj = {
    domain,
    isLocalDomain,
    urlProtocol,
    terms,
    policy,
    learnMore,
    URL,
    audioURL,
    imageURL,
    audioFS: `${audioURL}audio/`,
    audioImageFS: `${audioURL}audioimage/`,
    imageFS: `${imageURL}image/`,
    thumbnailFS: `${imageURL}thumbnail/`,
    host: window.location.origin,
    bucketURL: bucket,
    bucketImage: `${bucket}image/`,
    bucketCompany: `${bucket}company/`,
    googleAnalytics: 'UA-89700113-1',
    videoUrlAWS: awsUrl,
    bucketCompanyAudio: 'https://s3-eu-west-1.amazonaws.com/watchandlearn.company/audio',
    bucketCompanyVideo: 'https://s3-eu-west-1.amazonaws.com/watchandlearn.company/video',
    bucketSponsor: 'https://s3-eu-west-1.amazonaws.com/watchandlearn.sponsor/',
    bucketRoomImage: 'https://s3-eu-west-1.amazonaws.com/watchandlearn.roomimage/',
    bucketRecordings: 'https://s3.eu-west-1.amazonaws.com/watchandlearn.recordings/',
    awsUrl,
    logRocketAppId: 'bscdbh/watch-and-learn',
    reCaptureSiteKey: '6LdqDbMaAAAAANnO5M85QAgvp8Z-AvXAFwwZsIBh',
    gtmId: 'GTM-NFZW2FW',
    hotjarSiteId: '3665338',
  };

  return Object.assign(obj, endpoints(URL, audioURL));
})();

export { environment, firebaseConfig, configUrl, config, videoroomConfig };
