<div class="sign-up">
  <div class="form-container">
    <div class="inside-form-container">
      <img class="sign-up-logo" src="assets/images/logo.png" height="50" />

      <div class="sign-up-tabs sign-up-tabs-only">
        <div class="sign-up-tabs-tab">
          <a (click)="$event.preventDefault()">{{ lang('Community_Details') }}</a>
        </div>
      </div>

      <div class="sign-up-form">
        <div class="mb-3">
          <input
            minlength="3"
            type="text"
            class="form-control"
            id="companyname"
            name="companyname"
            [placeholder]="lang('Community_Name')"
            [(ngModel)]="companyName"
            required
          />
        </div>
        <div class="mb-3">
          <div class="input-group">
            <input
              id="companyurl"
              minlength="3"
              type="text"
              class="form-control"
              id="companyurl"
              name="companyurl"
              [placeholder]="lang('Community_URL')"
              [(ngModel)]="companyUrl"
              (keyup)="checkIfCompanyUrlAllowed($event, $event.target.value)"
              [ngClass]="{ 'ng-invalid': !isCompanyUrlAvailable || companyUrl?.length >= 3 }"
              required
              #companuUrl
            />
            <span class="input-group-text">.{{ configUrl?.domain || domain }}</span>
          </div>
        </div>
        <div class="mb-3">
          <div *ngIf="companyUrlAvailable?.length > 0" class="alert alert-danger">
            <b>{{ companyUrl }}</b
            >.{{ configUrl?.domain || domain }} <span i18n>is already taken, how about...</span>
            <ul>
              <li *ngFor="let availableUrl of companyUrlAvailable; let i = index">
                <a (click)="changeCompanyUrl(availableUrl)">
                  <b>{{ availableUrl }}</b
                  >.{{ configUrl?.domain || domain }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="mb-3 recapture">
          <re-captcha (resolved)="reCaptureResolved($event)" [siteKey]="configUrl.reCaptureSiteKey"></re-captcha>
        </div>
      </div>

      <div class="form-submit">
        <a class="btn btn-primary sign-up-btn" (click)="createCompany()">{{ lang('Create_Community') }}</a>
      </div>
      <div class="extra-login dropdown">
        <span i18n>or</span> <a class="social-link" (click)="closePage()" i18n="@@cancel">Cancel</a>
      </div>
    </div>
    <div class="terms-policy" i18n>
      <a href="{{ configUrl?.terms }}" target="_blank">Terms of Use</a> and
      <a href="{{ configUrl?.policy }}" target="_blank">Privacy Policy</a>
    </div>
  </div>
</div>
