<div>
  <div id="top-nav-wrapper">
    <div class="nav-left">
      <div class="top-nav-menu-button" (click)="showMenu()">
        <mdi name="Menu"></mdi>
      </div>

      <div class="top-nav">
        <app-company-selector></app-company-selector>
      </div>
    </div>
  </div>

  <div class="to-top" (click)="scrollToTop()" [ngClass]="{ active: showToTop }">
    <mdi name="ChevronUp" size="24"></mdi>
  </div>
</div>
