<div class="ui-modal-header">
  <h2>{{ title }}</h2>
  <div class="ui-modal-close" (click)="dismissAction()">
    <mdi name="Close"></mdi>
  </div>
</div>

<div class="ui-modal-body">
  <p [innerHTML]="sanitizedMessage()"></p>
</div>

<div class="ui-modal-footer">
  <button class="btn btn-md btn-light" (click)="noAction()">{{ noButtonText }}</button>
  <button class="btn btn-md btn-primary" (click)="yesAction()">{{ yesButtonText }}</button>
</div>
