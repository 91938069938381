<div>
  <span
    class="play play-clip no-styles"
    id="{{ clip?.id }}"
    [ngClass]="{ 'play-small': type === 'small', 'play-large': type === 'large' }"
    [attr.data-src]="configUrl.audioFS + clip?.id"
    [attr.data-clipid]="clip?.id"
    (click)="play()"
  >
    <button
      class="play play-icon no-styles"
      [class.active]="clip.playing"
      [class.seed-played]="clip?.playedat !== null && authService.activeUser !== null"
      [class.seed-not-played]="clip?.playedat === null"
      (click)="qs.updatePlayedat(clip)"
      #playbutton
    >
      <span *ngIf="!clip.playing">
        <mdi name="Play" [size]="type === 'small' ? 22 : 30"></mdi>
      </span>

      <span *ngIf="clip.playing">
        <mdi name="Stop" [size]="type === 'small' ? 18 : 26"></mdi>
      </span>

      <svg id="cirlce">
        <circle *ngIf="type === 'large'" r="23" cx="26" cy="26"></circle>
        <circle *ngIf="type === 'small'" r="13" cx="16" cy="16"></circle>
      </svg>
    </button>
  </span>
</div>
