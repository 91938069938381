import { Component, AfterViewInit, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { v4 as uuid } from 'uuid';

import {
  UserService,
  BusinessService,
  QueryService,
  UiModalService,
  AuthService,
  LiveVideoService,
  LiveVideoCallToastsService,
  NotificationsService,
  LoggerService,
} from '@app/core/services';

import { configUrl, config } from '@env/environment';

import * as psl from 'psl';
import { DOCUMENT } from '@angular/common';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModalUserOnboardingComponent } from './shared/modal/modal-user-onboarding/modal-user-onboarding.component';

import firebase from 'firebase/compat/app';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  configUrl = configUrl;
  title = config.title + ' search';
  user: firebase.User | null;

  nav$: Observable<any>;

  constructor(
    public business: BusinessService,
    public qs: QueryService,
    private router: Router,
    private modal: UiModalService,
    public authService: AuthService,
    private userService: UserService,
    private liveVideoService: LiveVideoService,
    private callToastsService: LiveVideoCallToastsService,
    private afMessaging: AngularFireMessaging,
    private cookieService: CookieService,
    private http: HttpClient,
    private notificationService: NotificationsService,
    private tooltipService: NgbTooltipConfig,
    private gtmService: GoogleTagManagerService,
    private toastr: ToastrService,
    private logger: LoggerService,
    private store: Store<any>,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.tooltipService.openDelay = 500;

    this.nav$ = this.store.select('nav');

    this.notificationService
      .observePermissions()
      .pipe(
        filter((permission) => permission === 'granted'),
        switchMap(() => combineLatest([this.authService.user$, this.afMessaging.requestToken])),
      )
      .subscribe(([user, fcmToken]) => {
        if (user.auth && fcmToken) {
          const body = {
            sessionid: this.getDeviceId(),
            sessiontoken: fcmToken,
          };

          this.http.put(configUrl.usersession, body).subscribe();
        }
      });

    this.afMessaging.messages.subscribe((message: any) => {
      if (
        message.notification?.body &&
        message.fcmOptions._data.touserid &&
        message.fcmOptions._data.touserid === this.authService.activeUser?.uid
      ) {
        const toast = this.toastr.info(message.notification.body);
        toast.onTap.subscribe(() => {
          this.router.navigate(['/combined/notifications']);
        });
      }
    });

    this.authService.user$.subscribe((auth) => {
      this.user = auth.auth;

      if (auth.auth) {
        this.observeIncomingCalls();
        this.userService.userCounters();
        this.userService.getBusinessStyles();
        this.userService.watchGroups();
      }

      if (auth.user && !auth.user.onboarded && !this.modal.isOpen()) {
        const inputs = {
          user: auth.user,
        };
        const outputs = {
          completionHandler: (response) => {
            this.logger.log('updated user');
          },
        };

        this.modal.init(ModalUserOnboardingComponent, inputs, outputs);
      }
    });
  }

  ngAfterViewInit() {
    this.router.events
      .pipe(
        filter((item) => item instanceof NavigationEnd),
        distinctUntilChanged(),
      )
      .subscribe((item: NavigationEnd) => {
        this.document.body.scrollIntoView();

        if (configUrl.gtmId) {
          this.gtmService.pushTag({ event: 'page', pageName: item.url });
        }
      });
  }

  observeIncomingCalls() {
    this.callToastsService.callAccepted$.subscribe((call) => {
      this.acceptCall(call.roomid);
    });

    this.callToastsService.callRejected$.subscribe((call) => {
      this.rejectCall(call.callerid, call.roomid);
    });

    this.liveVideoService.observeNewCalls().subscribe((call) => {
      this.callToastsService.add(call);
    });

    this.liveVideoService.observeRemovedCalls().subscribe((call) => {
      this.callToastsService.remove(call);
    });
  }

  acceptCall(roomId: string) {
    this.navigateToRoom(roomId);
  }

  rejectCall(userId: string, roomId: string) {
    this.liveVideoService.rejectCall(userId, roomId).subscribe();
  }

  private navigateToRoom(roomId: string, call?: string) {
    const queryParams = { call };
    this.router.navigate([`/live/${roomId}`], { queryParams });
  }

  removeModal() {
    this.modal.destroy();
  }

  getDeviceId() {
    if (!this.cookieService.check('deviceId')) {
      const parsed = psl.parse(window.location.host);
      const domain = window.location.hostname === 'localhost' ? 'localhost' : `.${parsed.domain}`;
      this.cookieService.set('deviceId', uuid(), new Date('Fri, 31 Dec 9999 23:59:59 GMT'), '/', domain);
    }

    return this.cookieService.get('deviceId');
  }
}
