import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class EmailVerifiedGuardService implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // Do not check email verified status
    return of(true);

    // return this.authService.loggedIn$.pipe(
    //   tap((loggedIn) => {
    //     if (loggedIn && !this.authService.activeUser?.emailVerified) {
    //       this.router.navigate(['/verify']);
    //     }
    //   }),
    //   map(() => true),
    // );
  }
}
