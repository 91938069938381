<div [hidden]="uncroppedUrl">
  <div class="ui-modal-header">
    <h2 i18n>About Me</h2>
    <div class="ui-modal-close" (click)="dismissAction()">
      <mdi name="Close"></mdi>
    </div>
  </div>

  <div class="ui-modal-body">
    <div class="mb-3">
      <div
        class="img-preview"
        [ngStyle]="
          thumbnail || originalUrl
            ? { 'background-image': 'url(' + (thumbnail ? thumbnail?.src : originalUrl) + ')' }
            : {}
        "
      >
        <div class="img-preview-overlay" [ngClass]="{ 'img-preview-overlay-always-show': !thumbnail }">
          <mdi class="img-preview-overlay-icon" name="Camera" size="40"></mdi>
          <input
            #profileImageInput
            type="file"
            class="img-preview-upload"
            id="profile-image"
            name="profile-image"
            accept="image/jpeg, image/png"
            (change)="processFile(profileImageInput)"
            required
          />
          <div (click)="removeThumbnail()" class="img-preview-remove" *ngIf="thumbnail">
            <mdi name="Close" size="20"></mdi>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <label for="podcast-title" i18n="@@name">Name</label>
      <input type="text" class="form-control" id="podcast-title" name="podcast-title" [(ngModel)]="title" required />
    </div>

    <div *ngIf="companySettings.idc_ce_credits" class="ce-credits">
      <h5>CE Credits</h5>

      <div class="mb-3">
        <label for="idc_province" i18n>Province</label>
        <select id="idc_province" name="idc_province" class="form-select" [(ngModel)]="idc_province">
          <option value="" i18n>Choose Province...</option>
          <option *ngFor="let province of provinces | keyvalue" [value]="province.key">{{ province.value }}</option>
        </select>
      </div>

      <div class="mb-3" *ngIf="idc_province === 'AB'">
        <label for="idc_cipr_number" i18n>CIPR Number</label>
        <input
          type="text"
          class="form-control"
          id="idc_cipr_number"
          name="idc_cipr_number"
          [(ngModel)]="idc_cipr_number"
          required
        />
      </div>

      <div class="mb-3" *ngIf="idc_province === 'QC'">
        <label for="idc_amf_number" i18n>AMF Number</label>
        <input
          type="text"
          class="form-control"
          id="idc_amf_number"
          name="idc_amf_number"
          [(ngModel)]="idc_amf_number"
          required
        />
      </div>
    </div>

    <div class="mb-3">
      <label for="companyname" i18n>Company</label>
      <input type="text" class="form-control" id="companyname" name="companyname" [(ngModel)]="companyname" required />
    </div>

    <div class="mb-3">
      <label for="jobrole" i18n="@@jobRole">Job Role</label>
      <input type="text" class="form-control" id="jobrole" name="jobrole" [(ngModel)]="jobrole" required />
    </div>
  </div>

  <div class="ui-modal-footer">
    <button
      class="btn btn-md btn-primary"
      [ngClass]="{ 'ui-modal-saving': saving }"
      (click)="saveAction()"
      i18n="@@saveChanges"
    >
      Save Changes
    </button>
  </div>
</div>

<div [hidden]="!uncroppedUrl">
  <div class="ui-modal-header">
    <h2 i18n>Update Profile Image</h2>
  </div>

  <div class="ui-modal-body">
    <app-cropper
      *ngIf="uncroppedUrl"
      [containerWidth]="400"
      [imageUrl]="uncroppedUrl"
      (cropped)="cropped($event)"
      (cancelled)="cancelled()"
    ></app-cropper>
  </div>
</div>
