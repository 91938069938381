import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AngularMyDatePickerModule } from '@nodro7/angular-mydatepicker';
import { InViewportModule } from 'ng-in-viewport';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AutosizeModule } from 'ngx-autosize';
import { RecaptchaModule } from 'ng-recaptcha';

import { LoadingComponent } from './ui/loading/loading.component';
import { AccessDeniedComponent } from './ui/access-denied/access-denied.component';
import { UserImageComponent } from './user-image/user-image.component';
import { ManageClipComponent } from './manage-clip/manage-clip.component';
import { PostImageComponent } from './post/post-image/post-image.component';
import { PageHeaderComponent } from './ui/page-header/page-header.component';
import { MdiComponent } from './ui/mdi/mdi.component';
import { NoRecordsComponent } from './ui/no-records/no-records.component';
import { CompanySelectorComponent } from './company-selector/company-selector.component';
import { ShareSocialComponent } from './ui/share-social/share-social.component';
import { ReportedClipsComponent } from './reported-clips/reported-clips.component';

import { ModalAlertComponent } from './modal/modal-alert/modal-alert.component';
import { ModalConfirmComponent } from './modal/modal-confirm/modal-confirm.component';
import { ModalUrlComponent } from './modal/modal-url/modal-url.component';
import { ModalVideoComponent } from './modal/modal-video/modal-video.component';
import { ModalUserSearchComponent } from './modal/modal-user-search/modal-user-search.component';
import { ModalUserListComponent } from './modal/modal-user-list/modal-user-list.component';
import { ModalContactInviteComponent } from './modal/modal-contact-invite/modal-contact-invite.component';
import { ModalInputComponent } from './modal/modal-input/modal-input.component';
import { PostComponent } from './post/post/post.component';
import { PlayAudioButtonComponent } from './play-audio-button/play-audio-button.component';
import { TileComponent } from './ui/tile/tile.component';

import { DataFilterPipe } from './pipes/data-filter.pipe';
import { CallbackPipe } from './pipes/callback.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { ReplaceLineBreaksPipe } from './pipes/replace-line-breaks.pipe';
import { PostRecordVideoComponent } from './post/post-record-video/post-record-video.component';
import { PostRecordAudioComponent } from './post/post-record-audio/post-record-audio.component';
import { PostCreatePopupComponent } from './post/post-create-popup/post-create-popup.component';
import { PostCreateInlineComponent } from './post/post-create-inline/post-create-inline.component';
import { PostCreateFormComponent } from './post/post-create-form/post-create-form.component';
import { ModalPostEditComponent } from './modal/modal-post-edit/modal-post-edit.component';
import { PageActionsNavigationComponent } from './ui/page-actions-navigation/page-actions-navigation.component';
import { CompanyInfoComponent } from './company-info/company-info.component';
import { CardComponent } from './ui/card/card.component';
import { ModalPostListComponent } from './modal/modal-post-list/modal-post-list.component';
import { PostCreateFormVideoUploadComponent } from './post/post-create-form-video-upload/post-create-form-video-upload.component';
import { CropperComponent } from './cropper/cropper.component';
import { ModalUserOnboardingComponent } from './modal/modal-user-onboarding/modal-user-onboarding.component';
import { AppBarUserComponent } from './app-bar-user/app-bar-user.component';
import { LinkifyPipe } from './pipes/linkify.pipe';
import { TrustHtmlPipe } from './pipes/trust-html.pipe';
import { JanPublisherPipe } from './pipes/jan-publisher.pipe';
import { ResponsiveVideoComponent } from './responsive-video/responsive-video.component';
import { PollComponent } from './poll/poll.component';
import { TableComponent } from './table/table.component';
import { ModalEmbedComponent } from './modal/modal-embed/modal-embed.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { ModalDateTimeRangeComponent } from './modal/modal-datetimerange/modal-datetimerange.component';
import { ModalSessionListComponent } from './modal/modal-session-list/modal-session-list.component';
import { ModalLanguageListComponent } from './modal/modal-language-list/modal-language-list.component';
import { VideoComponent } from './video/video.component';

const components = [
  PostImageComponent,
  LoadingComponent,
  ManageClipComponent,
  MdiComponent,
  NoRecordsComponent,
  PageHeaderComponent,
  PageActionsNavigationComponent,
  UserImageComponent,
  CompanySelectorComponent,
  AccessDeniedComponent,
  ModalAlertComponent,
  ModalConfirmComponent,
  ModalUrlComponent,
  ModalEmbedComponent,
  ModalInputComponent,
  ModalVideoComponent,
  ModalUserSearchComponent,
  ModalUserListComponent,
  ModalPostListComponent,
  ModalSessionListComponent,
  ModalContactInviteComponent,
  ModalPostEditComponent,
  ModalUserOnboardingComponent,
  ModalLanguageListComponent,
  ShareSocialComponent,
  PostComponent,
  PostCreatePopupComponent,
  PostCreateInlineComponent,
  PostCreateFormComponent,
  PostRecordAudioComponent,
  PostRecordVideoComponent,
  PostCreateFormVideoUploadComponent,
  PlayAudioButtonComponent,
  ReportedClipsComponent,
  TileComponent,
  CardComponent,
  CompanyInfoComponent,
  CropperComponent,
  AppBarUserComponent,
  ResponsiveVideoComponent,
  PollComponent,
  TableComponent,
  TimepickerComponent,
  ModalDateTimeRangeComponent,
  VideoComponent,
];

const pipes = [
  DataFilterPipe,
  FilterPipe,
  CallbackPipe,
  ReplaceLineBreaksPipe,
  LinkifyPipe,
  TrustHtmlPipe,
  JanPublisherPipe,
];

@NgModule({
  declarations: [...components, ...pipes],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    ScrollingModule,
    AngularMyDatePickerModule,
    InViewportModule,
    ImageCropperModule,
    DragDropModule,
    AutosizeModule,
    RecaptchaModule,
  ],
  exports: [
    ...components,
    ...pipes,
    FormsModule,
    RouterModule,
    NgbModule,
    CommonModule,
    ScrollingModule,
    AngularMyDatePickerModule,
    InViewportModule,
    ImageCropperModule,
    DragDropModule,
    AutosizeModule,
    RecaptchaModule,
    ReactiveFormsModule,
  ],
})
export class SharedModule {}
