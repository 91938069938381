import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@app/core/services';
import { hide, show } from '@app/nav.actions';
import { configUrl } from '@env/environment';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
})
export class VerifyComponent implements OnInit, OnDestroy {
  nav$: Observable<any>;

  configUrl: any = configUrl;
  subdomain: string;
  email: string;

  logo = 'assets/images/logo.png';

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private store: Store<any>,
  ) {
    this.nav$ = store.select('nav');

    this.email = this.authService.activeUser?.email;

    if (this.authService?.activeUser.emailVerified) {
      this.router.navigate(['/']);
    }

    this.authService.user$.subscribe((auth) => {
      const logo = auth.companySettings.logo;
      this.logo = logo ? configUrl.bucketURL + 'logo/' + logo : 'assets/images/logo.png';
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.store.dispatch(hide());
    }, 0);
    this.subdomain = this.authService.getSubdomain();
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.store.dispatch(show());
    }, 0);
  }

  resendVerificationEmail() {
    this.authService.sendVerificationEmail();
    this.toastr.success($localize`Verification email successfully sent`);
  }

  signOut() {
    this.authService.signOut(true);
  }
}
