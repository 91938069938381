import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import { configUrl } from '@env/environment';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class RecordingService {
  constructor(private http: HttpClient) {}

  public startRecording(roomId: string) {
    const url = configUrl.recorder;
    const body = {
      roomid: roomId,
    };
    return this.http.post<any>(url, body);
  }

  public deleteRecording(instanceId: string) {
    const url = configUrl.recorder + '/instance/' + instanceId;
    return this.http.delete<any>(url);
  }

  public updateStatus(instanceId: string, status: string) {
    const url = configUrl.recorder + '/' + instanceId + '/status';
    const body = { status };
    return this.http.put<any>(url, body);
  }

  public getRecordingsSchedule() {
    const url = configUrl.recorderSchedule + '?all=true';
    return this.http.get<any>(url);
  }

  public postRecoringsSchedule(roomid, starttime, stoptime) {
    const url = configUrl.recorderSchedule;
    const body = { roomid, starttime, stoptime };
    return this.http.post<any>(url, body);
  }

  public deleteRecordingSchedule(instanceId: string) {
    const url = configUrl.recorderSchedule + '/' + instanceId;
    return this.http.delete<any>(url);
  }
}
