import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { PollsService } from '../services';

@Injectable()
export class PollOptionsResolver implements Resolve<any> {
  constructor(private polls: PollsService) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): Observable<any> {
    const id = route.paramMap.get('id');

    return this.getData(id);
  }

  getData(id: string): Observable<any> {
    return this.polls.getPollOptions(id);
  }
}
