import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthPublicGuardService implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router,
    public toastr: ToastrService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.user$.pipe(
      map((auth) => {
        if (auth.companySettings.companyid && !auth.selectedCompany && auth.companySettings.ispublic === 0) {
          this.router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } });
          return false;
        }

        // Publically accessible
        return true;
      }),
    );
  }
}
