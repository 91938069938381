import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-jan-action',
  templateUrl: './jan-action.component.html',
  styleUrls: ['./jan-action.component.scss'],
})
export class JanActionComponent implements OnInit {
  @Input() icon: string;
  @Input() badgeNumber = 0;

  constructor() {}

  ngOnInit(): void {}
}
