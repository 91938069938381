import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable, of, BehaviorSubject } from 'rxjs';

import { AuthService } from './auth.service';
import { configUrl } from '@env/environment';

@Injectable()
export class NotificationsService {
  permissionsSubject: BehaviorSubject<string>;

  constructor(
    private httpClient: HttpClient,
    private afDatabase: AngularFireDatabase,
    private authService: AuthService,
  ) {
    this.permissionsSubject = new BehaviorSubject<string>(
      this.webPushSupported() ? Notification.permission : 'notsupported',
    );
  }

  getNotifications(offset: number = 0, limit: number = 10): Observable<any> {
    const url = configUrl.notifications;

    const options = {
      params: {
        offset: `${offset}`,
        limit: `${limit}`,
      },
    };

    return this.httpClient.get(url, options);
  }

  markAsRead(notificationId: string): Observable<any> {
    const url = configUrl.notificationsRead;

    return this.httpClient.put(`${url}${notificationId}`, {});
  }

  resetBadgeNumber() {
    if (this.authService.activeUser) {
      const uid = this.authService.activeUser.uid;
      let pathPrefix = '';
      if (this.authService.selectedCompany) {
        pathPrefix = `company/${this.authService.selectedCompany.id}/`;
      }

      this.afDatabase.object(`${pathPrefix}badgeNumber/${uid}`).remove();
    }
  }

  getBadgeNumber(): Observable<any> {
    return this.getBadgeData();
  }

  getBadgeData(): Observable<any> {
    if (this.authService.activeUser) {
      const uid = this.authService.activeUser.uid;
      let pathPrefix = '';
      if (this.authService.selectedCompany) {
        pathPrefix = `company/${this.authService.selectedCompany.id}/`;
      }

      return this.afDatabase.object(`${pathPrefix}badgeNumber/${uid}`).valueChanges();
    } else {
      return of(0);
    }
  }

  observePermissions() {
    return this.permissionsSubject.asObservable();
  }

  updatePermissions() {
    if (this.webPushSupported()) {
      this.permissionsSubject.next(Notification.permission);
    }
  }

  webPushSupported() {
    return 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;
  }
}
