<div class="room-main">
  <div
    *ngIf="!cameraPublisherId && !screenPublisherId && !videoPublisherId && publishers.length === 0 && emptyMessage"
    class="room-message"
  >
    <p>{{ emptyMessage }}</p>
  </div>
  <div id="room-videos" #roomVideos>
    <div *ngIf="cameraStream" class="video-container" #cameraVideo id="video-camera">
      <video autoplay id="my-video" [muted]="'true'" playsinline [srcObject]="cameraStream"></video>
      <div class="display-name">{{ displayName }}</div>
      <div class="maximise" (click)="maximise('video-camera')"></div>
      <div class="minimise" (click)="minimise()"></div>
    </div>
  </div>
  <div class="room-side" [class.room-side-visible]="sidePanel">
    <div class="room-side-bar">
      <app-jan-participants
        *ngIf="sidePanel === 'participants'"
        [participants]="publishers"
        [attendees]="showAllAttendees ? viewers : null"
        [displayName]="displayName"
        [showAddUser]="actions.includes('addUser')"
        [isPublishing]="!!cameraPublisherId"
        (showUser)="showUser($event)"
        (closePanel)="closeSide()"
        (addUser)="addUser.emit()"
      ></app-jan-participants>
      <app-jan-chat
        *ngIf="sidePanel === 'chat'"
        [chats]="chats"
        [canModerate]="canModerate"
        [allowChatModeration]="allowChatModeration"
        (addChatMessage)="addChat($event)"
        (approveChatMessage)="approveChat($event)"
        (rejectChatMessage)="rejectChat($event)"
        (closePanel)="closeSide()"
      ></app-jan-chat>
      <app-jan-polls
        *ngIf="sidePanel === 'polls'"
        [polls]="polls"
        [showAddPoll]="authService.canPresentOnLiveStage"
        (addPoll)="addPoll()"
        (removePoll)="removePoll($event)"
        (closePanel)="closeSide()"
      ></app-jan-polls>
    </div>
  </div>
</div>

<div class="room-bar" *ngIf="showToolbar">
  <div class="room-bar-inner">
    <div class="room-bar-start">
      <app-jan-action
        *ngIf="actions.includes('audioMute') && cameraPublisherId"
        (click)="toggleAudioMute()"
        [class.action-danger]="audioMuted"
        [icon]="!audioMuted ? 'Record' : 'RecordDisabled'"
        placement="top"
        [ngbTooltip]="audioMutedText"
        container="body"
        tooltipClass="light-tooltip"
      ></app-jan-action>
      <app-jan-action
        *ngIf="actions.includes('videoMute') && cameraPublisherId"
        (click)="toggleVideoMute()"
        [class.action-danger]="videoMuted"
        [icon]="!videoMuted ? 'Video' : 'VideoDisabled'"
        placement="top"
        [ngbTooltip]="videoMutedText"
        container="body"
        tooltipClass="light-tooltip"
      ></app-jan-action>
    </div>
    <div class="room-bar-middle">
      <app-jan-action
        *ngIf="actions.includes('record')"
        [icon]="recordIcon"
        (click)="record()"
        placement="top"
        i18n-ngbTooltip="@@record"
        ngbTooltip="Record"
        container="body"
        tooltipClass="light-tooltip"
      ></app-jan-action>
      <app-jan-action
        *ngIf="actions.includes('addUser')"
        icon="UserAdd"
        (click)="addUser.emit()"
        placement="top"
        i18n-ngbTooltip="@@inviteUser"
        ngbTooltip="Invite User"
        container="body"
        tooltipClass="light-tooltip"
      ></app-jan-action>
      <app-jan-action
        *ngIf="actions.includes('shareScreen')"
        [class.action-active]="shareStream"
        (click)="shareScreen()"
        icon="ShareScreen"
        placement="top"
        i18n-ngbTooltip
        ngbTooltip="Share Screen"
        container="body"
        tooltipClass="light-tooltip"
      ></app-jan-action>
      <app-jan-action
        *ngIf="actions.includes('sharePost')"
        (click)="presentVideo()"
        icon="VideoAdd"
        [class.action-active]="videoStream"
        placement="top"
        i18n-ngbTooltip
        ngbTooltip="Present Video"
        container="body"
        tooltipClass="light-tooltip"
      ></app-jan-action>
      <app-jan-action
        *ngIf="actions.includes('roomLink')"
        (click)="roomLink.emit()"
        icon="Link"
        placement="top"
        i18n-ngbTooltip="@@roomLink"
        ngbTooltip="Room Link"
        container="body"
        tooltipClass="light-tooltip"
      ></app-jan-action>
      <app-jan-action
        *ngIf="actions.includes('exit')"
        (click)="shutdownRoom()"
        icon="HangUp"
        style="background: #c00"
        placement="top"
        i18n-ngbTooltip
        ngbTooltip="Exit Room"
        container="body"
        tooltipClass="light-tooltip"
      ></app-jan-action>
      <app-jan-action
        *ngIf="actions.includes('leave') && cameraPublisherId"
        (click)="leaveRoomAction()"
        icon="Exit"
        style="background: #c00"
        placement="top"
        i18n-ngbTooltip
        ngbTooltip="Leave Stage"
        container="body"
        tooltipClass="light-tooltip"
      ></app-jan-action>
    </div>
    <div class="room-bar-end">
      <app-jan-action
        *ngIf="actions.includes('polls')"
        [class.action-active]="sidePanel === 'polls'"
        (click)="toggleSide('polls')"
        icon="Poll"
        placement="top"
        i18n-ngbTooltip="@@polls"
        ngbTooltip="Polls"
        container=" "
        tooltipClass="light-tooltip"
        [badgeNumber]="polls?.length"
      ></app-jan-action>
      <app-jan-action
        *ngIf="actions.includes('chat')"
        [class.action-active]="sidePanel === 'chat'"
        (click)="toggleSide('chat')"
        icon="Chat"
        placement="top"
        i18n-ngbTooltip
        ngbTooltip="Chat"
        container="body"
        tooltipClass="light-tooltip"
        [badgeNumber]="newMessagesCount"
      ></app-jan-action>
      <app-jan-action
        *ngIf="actions.includes('participants')"
        [class.action-active]="sidePanel === 'participants'"
        (click)="toggleSide('participants')"
        icon="Group"
        placement="top"
        i18n-ngbTooltip
        ngbTooltip="Participants"
        container="body"
        tooltipClass="light-tooltip"
        [badgeNumber]="publishers.length + 1 > maxVisibleVideos ? publishers.length + 1 : 0"
      ></app-jan-action>
    </div>
  </div>
</div>

<div class="room-unpause" *ngIf="audioPaused">
  <a (click)="startAudio()" class="btn btn-primary" i18n>View Stage</a>
</div>

<!-- <iframe src="https://live.syncwords.com/i-TL3Pw9" style="width:100%; height:220px; border:0;"></iframe> -->

<iframe
  *ngIf="syncwords"
  src="https://live.syncwords.com/i-Vj2sQR"
  style="width: 100%; height: 220px; border: 0"
></iframe>

<div *ngIf="isRecording" class="is-recording"><mdi name="RecordRoom" size="17"></mdi> Recording</div>
