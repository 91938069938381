import { Component, Input } from '@angular/core';

import { UiModalService } from '@app/core/services';
import { ModalEmbedComponent } from '@app/shared/modal/modal-embed/modal-embed.component';
import { ModalUrlComponent } from '../../modal/modal-url/modal-url.component';

enum SocialPlatform {
  Facebook = 'facebook',
  Twitter = 'twitter',
  WhatsApp = 'whatsapp',
  LinkedIn = 'linkedin',
}

@Component({
  selector: 'app-share-social',
  templateUrl: './share-social.component.html',
  styleUrls: ['./share-social.component.scss'],
})
export class ShareSocialComponent {
  public SocialPlatform = SocialPlatform;

  @Input() url: string;
  @Input() salesUrl: string;
  @Input() twitterCardUrl: string;
  @Input() title: string;
  @Input() embedOption = false;

  constructor(private modalService: UiModalService) {}

  public openShareWindow(platform: SocialPlatform) {
    const width = 550;
    const height = 450;
    const left = screen.width / 2 - width / 2;
    const top = screen.height / 2 - height / 2;
    window.open(
      this.socialLink(platform),
      `_blank`,
      `toolbar=0,personalbar=0,resizable,scrollbars,status,width=${width},height=${height},top=${top},left=${left}`,
    );
  }

  public openEmbedModal() {
    this.modalService.init(ModalEmbedComponent, { baseUrl: this.salesUrl });
  }

  public openSalesModal() {
    const baseUrl = this.salesUrl;
    const inputs = {
      baseUrl,
      embed: false,
    };
    this.modalService.init(ModalEmbedComponent, inputs);
  }

  private socialLink(platform: SocialPlatform): string {
    const encodedUrl = encodeURIComponent(this.url);
    const encodedTitle = encodeURIComponent(this.title);

    switch (platform) {
      case SocialPlatform.Facebook:
        return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&t=${encodedTitle}&quote=`;
      case SocialPlatform.Twitter:
        return `https://twitter.com/intent/tweet?text=${encodedTitle}%20${encodedUrl}`;
      case SocialPlatform.WhatsApp:
        return `whatsapp://send?text=${encodedTitle}%20${encodedUrl}`;
      case SocialPlatform.LinkedIn:
        return `https://www.addtoany.com/add_to/linkedin?linkurl=${encodedUrl}&linkname=${encodedTitle}&linknote=`;
    }
  }
}
