import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SharedModule } from '@app/shared/shared.module';

import { SignUpBusinessComponent } from './sign-up-business/sign-up-business.component';
import { CreateUrlComponent } from './create-url/create-url.component';
import { RestorePasswordComponent } from './restore-password/restore-password.component';
import { InviteComponent } from './invite/invite.component';
import { VerifyComponent } from './verify/verify.component';
import { VerifiedComponent } from './verified/verified.component';
import { AuthComponent } from './auth/auth.component';
import { AuthGuardService } from '@app/core/guards/auth-guard.service';
import { InviteResolver } from '@app/core/resolvers/invite-resolver.service';

const authRoutes: Routes = [
  {
    path: 'sign-up-business',
    component: SignUpBusinessComponent,
  },
  {
    path: 'sign-up-create-url',
    component: CreateUrlComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'sign-up',
    redirectTo: 'auth/signup',
  },
  {
    path: 'sign-in',
    redirectTo: 'auth/signin',
  },
  {
    path: 'verify',
    component: VerifyComponent,
  },
  {
    path: 'verified',
    component: VerifiedComponent,
  },
  {
    path: 'reset-password',
    component: RestorePasswordComponent,
  },
  {
    path: 'invite',
    component: InviteComponent,
  },
  {
    path: 'invite/company',
    redirectTo: 'invite',
  },
  {
    path: 'invite/group',
    redirectTo: 'invite',
  },
  {
    path: 'auth',
    component: AuthComponent,
    resolve: {
      invite: InviteResolver,
    },
  },
  {
    path: 'auth/:tab',
    component: AuthComponent,
  },
];

@NgModule({
  declarations: [
    CreateUrlComponent,
    RestorePasswordComponent,
    SignUpBusinessComponent,
    InviteComponent,
    VerifyComponent,
    VerifiedComponent,
    AuthComponent,
  ],
  imports: [SharedModule, RouterModule.forChild(authRoutes)],
  exports: [RouterModule],
})
export class AuthModule {}
