<div class="sign-up">
  <div class="form-container">
    <div class="inside-form-container">
      <img class="sign-up-logo" [src]="logo" height="50" />

      <div class="sign-up-tabs">
        <div class="sign-up-tabs-tab sign-up-tabs-tab-active">
          <a (click)="$event.preventDefault()" i18n="@@createAccount">Create Account</a>
        </div>
      </div>

      <div *ngIf="isGroupInvite" class="auth-info" i18n>
        You have been invited to join group <i>{{ queryParams['groupname'] }}</i> by
        <i>{{ queryParams['invitedby'] }}</i>
      </div>

      <div *ngIf="isCompanyInvite" class="auth-info" i18n>
        You have been invited to join {{ lang('community') }} <i>{{ queryParams['companyname'] }}</i> by
        <i>{{ queryParams['invitedby'] }}</i>
      </div>

      <div *ngIf="isPlatformInvite" class="auth-info" i18n>
        You have been invited to join by <i>{{ queryParams['invitedbyuser'] }}</i>
      </div>

      <div class="sign-up-form">
        <div class="mb-3">
          <input
            minlength="3"
            type="text"
            class="form-control"
            id="name"
            name="name"
            placeholder="Your Name"
            i18n-placeholder
            [(ngModel)]="name"
            required
          />
        </div>

        <div class="mb-3">
          <input
            type="email"
            [email]="true"
            [readonly]="true"
            class="form-control"
            id="email"
            name="email"
            placeholder="Email"
            i18n-placeholder
            [(ngModel)]="email"
            required
          />
          <small id="emailHelp" class="form-text text-muted" i18n
            >You are required to create an account with the email address you were invited with.</small
          >
        </div>
        <div class="mb-3">
          <input
            minlength="6"
            type="password"
            class="form-control"
            id="password"
            name="password"
            placeholder="Password"
            i18n-placeholder
            [(ngModel)]="password"
            required
          />
        </div>
        <div class="form-submit">
          <a class="btn btn-primary sign-up-btn" (click)="signUp(name, email, password)" i18n>Create&nbsp;Account</a>
        </div>
      </div>
    </div>
    <div class="terms-policy" i18n>
      <a href="{{ configUrl?.terms }}" target="_blank">Terms of Use</a> and
      <a href="{{ configUrl?.policy }}" target="_blank">Privacy Policy</a>
    </div>
  </div>
</div>
