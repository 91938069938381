import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subject, Observable, forkJoin } from 'rxjs';
import { switchMap, take, catchError, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { format, parseISO } from 'date-fns';

import { ModalUserSearchComponent } from '@app/shared/modal/modal-user-search/modal-user-search.component';
import {
  LiveVideoService,
  AuthService,
  UiModalService,
  LiveVideoRoom,
  LiveVideoCall,
  UserService,
} from '@app/core/services';
import { videoroomConfig } from '@env/environment';
import { ModalUrlComponent } from '@app/shared/modal/modal-url/modal-url.component';
import { ModalLiveRoomUserInfoComponent } from '../modal-live-room-user-info/modal-live-room-user-info.component';
import { Store } from '@ngrx/store';
import { hide, show } from '@app/nav.actions';

@Component({
  selector: 'app-live-room',
  templateUrl: './live-room.component.html',
  styleUrls: ['./live-room.component.scss'],
})
export class LiveRoomComponent implements OnInit, OnDestroy {
  nav$: Observable<any>;

  room: any;
  roomId: string;
  pin: string;
  secret: string;
  token: string;
  call: string;
  activeOutgoingCall: LiveVideoCall;

  janusServerUrl = videoroomConfig.url;
  iceServers = videoroomConfig.iceServers;

  exiting = false;

  public displayName: string;
  userId: string | null = null;

  roomVisible = false;
  reloadingRoom = false;

  roomClosedSubscription$ = new Subject<boolean>();

  roomError: string;

  returnUrl: string;
  queued: string[];
  exitOnComplete: boolean;

  actions: any[] = ['exit', 'audioMute', 'videoMute', 'shareScreen', 'sharePost', 'participants', 'chat'];

  isPublicCall: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private userService: UserService,
    private liveVideoService: LiveVideoService,
    private modalService: UiModalService,
    private toastr: ToastrService,
    private store: Store<any>,
  ) {
    this.nav$ = store.select('nav');

    this.userId = this.authService.activeUser?.uid || null;
    if (this.authService.activeUser) {
      this.actions.push('addUser');
    }
  }

  ngOnInit(): void {
    this.call = this.route.snapshot.queryParamMap.get('call');
    this.isPublicCall = this.route.snapshot.queryParamMap.get('public') === 'true';
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';
    this.displayName = this.authService.userInfo?.username || 'Guest';
    this.exitOnComplete = this.route.snapshot.queryParamMap.get('exitOnComplete') === 'true';

    const queued = this.route.snapshot.queryParamMap.get('queued');
    if (queued) {
      this.queued = queued.split(',');
    }

    this.route.params.subscribe((params) => {
      const roomId = params.id;

      if (!this.authService.activeUser && !this.route.snapshot.queryParamMap.get('code')) {
        const inputs = {};
        const outputs = {
          completeHandler: (data) => {
            this.displayName = data.displayName || $localize`Guest`;
            this.loadRoom(roomId);
          },
        };
        this.modalService.init(ModalLiveRoomUserInfoComponent, inputs, outputs, null, null, true);
      } else {
        const code = this.route.snapshot.queryParamMap.get('code');
        const email = this.route.snapshot.queryParamMap.get('email');
        this.loadRoom(roomId, false, code, email);
      }
    });

    setTimeout(() => {
      this.store.dispatch(hide());
    }, 0);
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.store.dispatch(show());
    }, 0);
  }

  formatDate(date) {
    const formattedDate = format(parseISO(date), 'do MMM yyyy');
    const formattedTime = format(parseISO(date), 'h:mma').toLowerCase();
    return `${formattedTime} on ${formattedDate}`;
  }

  loadRoom(roomId: string, retry = false, code: string = null, email: string = null) {
    this.roomError = null;
    this.roomVisible = false;
    this.reloadingRoom = !!this.roomId;

    forkJoin({
      room: this.getRoom(roomId, code, email),
      waitForRoomToClose: this.reloadingRoom && !retry ? this.roomClosedSubscription$.pipe(take(1)) : of(true),
    }).subscribe(
      ({ room }) => {
        this.room = room;
        this.roomId = room.id;
        this.pin = room.pin;
        this.secret = room.secret;
        this.token = room.token;

        if (this.room.privacy === 2) {
          this.actions.push('roomLink');
        }

        if (this.room.categoryid === 4) {
          this.actions = this.actions.filter((action) => action !== 'addUser');
        } else {
        }

        this.roomVisible = true;

        if (room.statusid === 0) {
          setTimeout(() => {
            const code = this.route.snapshot.queryParamMap.get('code');
            const email = this.route.snapshot.queryParamMap.get('email');
            this.loadRoom(this.roomId, true, code, email);
          }, 10000);
        }
      },
      (error) => {
        this.roomError = $localize`Access Denied`;
      },
    );
  }

  startCall(newCall: string): Observable<LiveVideoRoom> {
    const date = new Date();

    let uid = this.authService.activeUser?.uid;
    if (!uid && this.isPublicCall) {
      uid = 'u0dXXXrMnVS0yyy4kLAqqZzZK6H2';
    }

    this.liveVideoService.observeOutgoingCall(newCall, uid).subscribe((call) => {
      const now = new Date();
      const elapsed = now.getTime() - date.getTime();

      if (elapsed < 30000) {
        this.activeOutgoingCall = call;
        return;
      }

      if (call === null && this.activeOutgoingCall && !this.queued?.length && this.exitOnComplete) {
        this.exit();
        return;
      }

      if (call === null && this.activeOutgoingCall && this.queued.length) {
        this.call = this.queued.shift();

        this.liveVideoService
          .addUserToRoom(this.call, this.roomId)
          .pipe(switchMap(() => this.startCall(this.call)))
          .subscribe();
      }

      this.activeOutgoingCall = call;
    });

    return this.liveVideoService.call(newCall, this.roomId, this.isPublicCall, this.displayName);
  }

  getRoom(roomId: string, code: string = null, email: string = null): Observable<LiveVideoRoom> {
    return this.liveVideoService.getRoom(roomId, code, email);
  }

  onJanusConnectionSuccess(): void {
    // if (!this.authService.activeUser) {
    //   this.reloadingRoom = false;
    //   return;
    // }

    this.liveVideoService
      .connect(this.roomId, this.isPublicCall)
      .pipe(
        switchMap((room) => {
          if (!this.call) {
            return of(room);
          }

          // Remove call query param
          this.router.navigate(['.'], {
            relativeTo: this.route,
            replaceUrl: true,
          });

          return this.startCall(this.call);
        }),
      )
      .subscribe((_) => {
        this.reloadingRoom = false;
      });
  }

  exit() {
    if (this.exiting) {
      return;
    }

    const cancelCall$ = this.call
      ? this.liveVideoService.cancelCall(this.call, this.roomId, this.isPublicCall).pipe(tap((_) => (this.call = null)))
      : of(null);

    this.roomClosedSubscription$.next(true);

    if (this.reloadingRoom) {
      cancelCall$.subscribe();
      return;
    }

    this.exiting = true;

    cancelCall$
      .pipe(
        catchError((_) => of(true)),
        switchMap((_) => this.liveVideoService.disconnect(this.roomId, this.isPublicCall)),
        catchError((_) => of(true)),
      )
      .subscribe((_) => this.close());
  }

  close() {
    if (this.returnUrl === 'closeTab') {
      window.history.go(-1);
      return;
    }

    this.router.navigate([this.returnUrl]);
  }

  addPeople() {
    const id = this.authService.activeUser.uid;
    const activeUser = { id };

    const inputs = {
      blacklist: [activeUser],
      actionTitle: $localize`:@@call:Call`,
      modalTitle: $localize`:@@inviteUser:Invite User`,
    };
    const outputs = {
      actionCallback: (user) => {
        this.makeCall(user);
      },
    };
    this.modalService.init(ModalUserSearchComponent, inputs, outputs);
  }

  makeCall(user) {
    this.liveVideoService
      .addUserToRoom(user.id, this.roomId)
      .pipe(
        switchMap((_) => {
          return this.liveVideoService.call(user.id, this.roomId, this.isPublicCall, this.displayName);
        }),
      )
      .subscribe((_) => {
        this.toastr.info($localize`Calling ${user.name}...`);
      });
  }

  viewRoomLink() {
    const inputs = {
      title: $localize`:@@roomLink:Room Link`,
      url: this.getRoomUrl(),
    };
    this.modalService.init(ModalUrlComponent, inputs);
  }

  getRoomUrl() {
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;

    return `${baseUrl}/live/${this.roomId}`;
  }
}
