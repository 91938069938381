import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, BusinessService } from '@app/core/services';

import { configUrl } from '@env/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-verify',
  template: '',
})
export class VerifiedComponent {
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private businessService: BusinessService,
  ) {
    const subdomain = this.route.snapshot.queryParamMap.get('subdomain');
    if (subdomain) {
      this.businessService.navigateToCompany({ subdomain }, '/verified');
      return;
    }

    if (this.authService.activeUser) {
      this.authService.activeUser.reload().then(() => {
        this.toastr.success($localize`Email verified`);
        this.router.navigate(['/']);
      });
    } else {
      this.toastr.success($localize`Email verified. Please sign in.`);
      this.router.navigate(['/sign-in']);
    }
  }
}
