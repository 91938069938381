import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { QueryService, ClipService, UiModalService, AuthService, UserService } from '@app/core/services';

import { ModalUserListComponent } from '../modal/modal-user-list/modal-user-list.component';
import { Clip } from '@app/shared/model';
import { ModalConfirmComponent } from '../modal/modal-confirm/modal-confirm.component';
import { ModalPostEditComponent } from '../modal/modal-post-edit/modal-post-edit.component';

@Component({
  selector: 'app-manage-clip',
  templateUrl: './manage-clip.component.html',
  styleUrls: ['./manage-clip.component.scss'],
})
export class ManageClipComponent {
  @Input() clip: Clip;
  @Output() clipChange = new EventEmitter<Clip>();

  get userHasEditRights(): boolean {
    return (
      this.authService.activeUser?.uid === this.clip?.authorid ||
      !!this.authService.userInfo?.isadmin ||
      this.authService.isCompanyAdmin
    );
  }

  constructor(
    private qs: QueryService,
    private clipService: ClipService,
    private toastr: ToastrService,
    private router: Router,
    private modal: UiModalService,
    public authService: AuthService,
    private userService: UserService,
    private modalService: UiModalService,
  ) {}

  editClipAction() {
    const inputs = {
      clip: this.clip,
    };
    const outputs = {
      completeHandler: (clip: Clip) => {
        this.clip = clip;
        this.clipChange.emit(clip);
      },
    };
    this.modalService.init(ModalPostEditComponent, inputs, outputs);
  }

  reportClipAction() {
    const inputs = {
      title: $localize`Report Post`,
      message: $localize`Are you sure you want to report post ${this.clip.title}`,
    };
    const outputs = {
      completeHandler: (yes: boolean) => {
        if (yes) {
          this.reportClip();
        }
      },
    };
    this.modalService.init(ModalConfirmComponent, inputs, outputs);
  }

  deleteClipAction() {
    const inputs = {
      title: $localize`Delete Post`,
      message: $localize`Are you sure you want to delete post ${this.clip.title}`,
    };
    const outputs = {
      completeHandler: (yes: boolean) => {
        if (yes) {
          this.deleteClip();
        }
      },
    };
    this.modalService.init(ModalConfirmComponent, inputs, outputs);
  }

  playedByAction() {
    this.qs.getClipPlays(this.clip).subscribe((users) => {
      this.playedBy(users);
    });
  }

  private playedBy(users: any[]) {
    const inputs = {
      actionTitle: $localize`:@@profile:Profile`,
      modalTitle: $localize`Played By`,
      users: users.map((playedUser) => ({
        id: playedUser.userid,
        name: playedUser.username,
        imageid: playedUser.imageid,
      })),
    };
    const outputs = {
      actionCallback: (user) => {
        this.router.navigate([`/combined/user/${user.id}`]);
      },
    };
    this.modal.init(ModalUserListComponent, inputs, outputs);
  }

  private reportClip() {
    const body = {
      id: this.clip.id,
    };

    this.clipService.reportClip(body).subscribe(() => {
      this.toastr.info($localize`Clip reported`);
    });
  }

  private deleteClip() {
    const body = {
      id: this.clip.id,
    };

    this.clipService.deleteClip(body).subscribe(() => {
      this.clip = null;
      this.clipChange.emit(null);
      this.toastr.info($localize`Clip deleted`);
    });
  }
}
