<div class="ui-modal-header">
  <h2>{{ title }}</h2>
  <div class="ui-modal-close" (click)="closeAction()">
    <mdi name="Close"></mdi>
  </div>
</div>

<div class="ui-modal-body">
  <div class="mb-3">
    <label for="url">{{ label }}</label>
    <div class="input-group">
      <input type="text" class="form-control" id="url" name="url" [(ngModel)]="url" required readonly #urlElement />
      <button class="btn btn-dark" type="button" (click)="copyAction(urlElement)">Copy</button>
    </div>
  </div>
</div>

<div class="ui-modal-footer"></div>
