import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { PollsService } from '../services';

@Injectable()
export class PollsListResolver implements Resolve<any> {
  constructor(private polls: PollsService) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): Observable<any> {
    return this.getData();
  }

  getData(): Observable<any> {
    return this.polls.getPolls();
  }
}
