import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { configUrl } from '@env/environment';

@Injectable()
export class GroupMembersResolver implements Resolve<any> {
  constructor(private http: HttpClient) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): Observable<any> {
    const id = route.paramMap.get('id');
    return this.getData(id);
  }

  getData(id: string): Observable<any> {
    const url = configUrl.groupMembers + id;
    return this.http.get(url).pipe(catchError((err) => of(null)));
  }
}
