import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';

import { configUrl } from '@env/environment';
import { switchMap } from 'rxjs/operators';
import { AwsService } from './aws.service';

@Injectable()
export class MeetingsService {
  constructor(
    private http: HttpClient,
    private aws: AwsService,
  ) {}

  public postMeeting(name, startdate, enddate, privacy: number): Observable<any> {
    const url = `${configUrl.meeting}`;
    const body = { name, startdate, enddate, privacy };
    return this.http.post<any>(url, body);
  }

  public updateMeeting(roomId, name, startdate, enddate, privacy: number): Observable<any> {
    const url = `${configUrl.meeting}/${roomId}`;
    const body = { name, startdate, enddate, privacy };
    return this.http.put<any>(url, body);
  }

  public updateMeetingMembers(roomId, members): Observable<any> {
    const url = `${configUrl.meeting}/${roomId}/members`;
    return this.http.put<any>(url, members);
  }

  public deleteMeeting(meeting) {
    const url = `${configUrl.meeting}/${meeting.id}`;
    return this.http.delete<any>(url);
  }

  public deleteTable(meeting) {
    const url = `${configUrl.livevideoRoom}${meeting.id}`;
    return this.http.delete<any>(url);
  }

  public postMeetingImage(file): Observable<any> {
    return this.http.get<any>(configUrl.uploadRoomImage).pipe(
      switchMap(({ filename, uploadurl }) =>
        forkJoin({
          filename: of(filename),
          image: this.aws.putImage(file, uploadurl, filename),
        }),
      ),
    );
  }
}
