<ul class="nav nav-tabs" *ngIf="sponsors?.length || days">
  <li class="nav-item">
    <a class="nav-link active" (click)="scrollTo('about')" i18n>Description</a>
  </li>
  <li class="nav-item" *ngIf="sponsors?.length">
    <a class="nav-link" (click)="scrollTo('sp')" i18n="@@sponsors">Sponsors</a>
  </li>
  <li class="nav-item" *ngIf="days">
    <a class="nav-link" (click)="scrollTo('schedule')" i18n="@@schedule">Schedule</a>
  </li>
</ul>

<h3 id="about" *ngIf="sponsors?.length || days" i18n>Description</h3>
<div
  *ngIf="
    !(
      authService.companySettings.videoclip ||
      authService.companySettings.audioclip ||
      authService.companySettings.description2
    )
  "
  class="reception-content"
  i18n
>
  No description
</div>
<div
  *ngIf="
    authService.companySettings.videoclip ||
    authService.companySettings.audioclip ||
    authService.companySettings.description2
  "
  class="reception-content shadow-box"
>
  <div *ngIf="authService.companySettings.videoclip" class="about-media" style="max-width: 400px; display: block">
    <app-responsive-video [videoUrl]="getVideoPath()" [preload]="true"></app-responsive-video>
  </div>
  <div class="about-media" *ngIf="authService.companySettings.audioclip">
    <app-play-audio-button
      [clip]="{ audio: authService.companySettings.audioclip }"
      [audioServer]="configUrl.bucketCompanyAudio"
    ></app-play-audio-button>
    <div class="audio-title">
      <div i18n>Welcome Message</div>
    </div>
  </div>
  <p innerHTML="{{ authService.companySettings.description2 | replaceLineBreaks }}"></p>
</div>

<div id="sp" *ngIf="sponsors?.length && typeOptions">
  <h3 i18n="@@sponsors">Sponsors</h3>
  <div class="reception-content">
    <div>
      <div *ngFor="let level of typeOptions; let i = index">
        <div
          *ngIf="(sponsors | filter: filterByType : level.id).length"
          class="sp shadow-box"
          [ngClass]="{ 'sp-1': i === 0, 'sp-2': i === 1, 'sp-3': i >= 2 }"
        >
          <div class="day-header">
            <div class="day-header-title">
              <div>{{ level.name }}</div>
              <div *ngIf="type === 1 && (sponsors | filter: filterByType : type).length" i18n>Gold Sponsors</div>
              <div *ngIf="type === 2 && (sponsors | filter: filterByType : type).length" i18n>Silver Sponsors</div>
              <div *ngIf="type === 3 && (sponsors | filter: filterByType : type).length" i18n>Bronze Sponsors</div>
            </div>
          </div>

          <div class="sp-grid">
            <div *ngFor="let sponsor of sponsors | filter: filterByType : level.id" class="sp">
              <div class="sp-image">
                <div class="sp-image-inner" [class.no-logo]="!sponsor.logo">
                  <img *ngIf="sponsor.logo" class="sp-logo" [src]="configUrl.bucketSponsor + sponsor.logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="schedule" *ngIf="days">
  <h3 i18n="@@schedule">Schedule</h3>
  <div class="reception-content">
    <div>
      <div *ngFor="let day of days" class="shadow-box">
        <div class="day-header">
          <div class="day-header-title">{{ day.title }}</div>
          <div class="day-header-date">{{ day.eventdate | date: 'd MMM y' : 'UTC' }}</div>
        </div>

        <div class="day-sessions">
          <div *ngIf="!sessions[day.id]?.length" i18n>No sessions</div>
          <table *ngIf="sessions[day.id]?.length">
            <tbody>
              <tr *ngFor="let session of sessions[day.id]" class="day-session">
                <td class="pinch" style="text-transform: lowercase">
                  {{ session.startdate | date: 'h:mmaa' }} - {{ session.enddate | date: 'h:mmaa' }}
                </td>
                <td>
                  <a href="javascript:void(0)" (click)="navigate(session)">{{ session.title }}</a>
                </td>
                <td class="pinch session-location">
                  <span *ngIf="session.type == 1" i18n>Live Stage {{ session.stageid }}</span>
                  <span *ngIf="session.type == 2" i18n="@@meeting">Meeting</span>
                  <span *ngIf="session.type == 3" i18n="@@networking">Networking</span>
                  <span *ngIf="session.type == 4" i18n="@@expoHall">Expo Hall</span>
                </td>
                <td class="pinch">
                  <div class="dropdown" ngbDropdown placement="bottom-right">
                    <mdi
                      name="EventAdd"
                      class="no-caret"
                      ngbDropdownToggle
                      aria-haspopup="true"
                      aria-expanded="false"
                    ></mdi>
                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                      <a ngbDropdownItem class="dropdown-item" [href]="eventUrl(session, 'ical')">iCal</a>
                      <a ngbDropdownItem class="dropdown-item" [href]="eventUrl(session, 'google')" target="_blank"
                        >Google</a
                      >
                      <a ngbDropdownItem class="dropdown-item" [href]="eventUrl(session, 'outlook')" target="_blank"
                        >Outlook</a
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
