import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataFilter',
})
export class DataFilterPipe implements PipeTransform {
  transform(array: any[], query: string): any {
    if (query) {
      query.toLocaleLowerCase();

      // admin users
      if (typeof array[0].username !== 'undefined') {
        return array.filter((row) => row.username.indexOf(query) > -1);
      } else if (typeof array[0].name === 'undefined') {
        return array.filter(
          (row) =>
            row.title.indexOf(query) > -1 ||
            (row.cliptext && row.cliptext.indexOf(query) > -1) ||
            row.authornamelowercase.indexOf(query) > -1 ||
            row.authorname.indexOf(query) > -1,
        );
      }
    }
    return array;
  }
}
