import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { LiveVideoRoom, LiveVideoService } from '../services';

@Injectable()
export class RoomGuardService implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router,
    private liveVideoService: LiveVideoService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.getRoom(
      route.paramMap.get('id'),
      route.queryParamMap.get('code'),
      route.queryParamMap.get('email'),
    ).pipe(
      catchError((error) => {
        this.router.navigate(['/sign-in']);
        throw error;
      }),
      switchMap((room) => {
        return this.authService.loggedIn$.pipe(
          map((loggedIn) => {
            if (!loggedIn && room.privacy !== 2 && !route.queryParamMap.get('code')) {
              this.router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } });
              return false;
            }

            return true;
          }),
        );
      }),
    );
  }

  getRoom(roomId: string, code: string = null, email: string = null): Observable<LiveVideoRoom> {
    return this.liveVideoService.getRoom(roomId, code, email);
  }
}
