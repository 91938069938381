import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { configUrl } from '@env/environment';

@Injectable()
export class AdminReportedClipsService {
  constructor(private http: HttpClient) {}

  getReportedClips(): Observable<any> {
    return this.http.get(configUrl.adminReportedClips);
  }

  getReportedClipsByCompanyAdmin(): Observable<any> {
    return this.http.get(configUrl.adminReportedClips);
  }

  deleteFromReportedClips(clipId: string): Observable<any> {
    return this.http.delete(`${configUrl.adminDeleteFromReportedClips}${clipId}`);
  }
}
