import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';

import { UserService, UiModalService, ClipService, AuthService, LiveVideoService } from '@app/core/services';
import { config, configUrl } from '@env/environment';
import { PostCreatePopupComponent } from '@app/shared/post/post-create-popup/post-create-popup.component';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';
import { Store } from '@ngrx/store';
import { hide } from '@app/nav.actions';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss'],
})
export class SalesComponent implements OnInit {
  nav$: Observable<any>;

  private seedId: string;
  public clip: any;

  replies: any[] = null;
  repliesLoaded = false;

  config = config;
  configUrl = configUrl;

  presenceSubscription: Subscription;

  presence: any;

  ready = false;
  embed: boolean;

  title: boolean;
  content: boolean;
  profile: boolean;
  call: boolean;
  link: boolean;
  booking: boolean;

  constructor(
    private us: UserService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private modalService: UiModalService,
    private clipService: ClipService,
    public authService: AuthService,
    private afDatabase: AngularFireDatabase,
    private liveVideoService: LiveVideoService,
    private router: Router,
    private serializer: UrlSerializer,
    private store: Store<any>,
  ) {
    this.nav$ = store.select('nav');

    combineLatest([this.route.params, this.route.queryParams]).subscribe(([params, queryParams]) => {
      this.ready = true;
      this.seedId = params.id;

      this.embed = queryParams.embed === 'true';
      this.title = queryParams.title !== 'false';
      this.content = queryParams.content !== 'false';
      this.profile = queryParams.profile !== 'false';
      this.link = queryParams.link !== 'false';
      this.call = queryParams.call !== 'false';
      this.booking = queryParams.booking !== 'false';

      this.loadClip();
      this.loadReplies();
    });

    this.clipService.newClip$.subscribe((clip) => {
      if (clip.parentid === this.seedId) {
        this.loadReplies();
      }
    });
  }

  ngOnInit() {
    this.us.getBusinessStyles();
    setTimeout(() => {
      this.store.dispatch(hide());
    }, 0);
  }

  observeAuthorPresence() {
    this.presenceSubscription?.unsubscribe();
    const subdomain = this.authService.getSubdomain() || '_global';

    this.presenceSubscription = this.afDatabase
      .object<any>(`presence/${subdomain}/${this.clip.authorid}`)
      .valueChanges()
      .subscribe((presence) => {
        (presence.presence = presence.lastSeen
          ? this.timeAgo(new Date(presence.lastSeen))
          : presence.status === 'online'),
          (this.presence = presence);
      });
  }

  loadClip() {
    const url = `${configUrl.getClipByIdUrl}${this.seedId}`;

    this.http.get(url).subscribe((clip) => {
      this.clip = clip;
      this.observeAuthorPresence();
    });
  }

  loadReplies() {
    const url = `${configUrl.getClipChildrenByIdUrl}${this.seedId}?cliptype=All`;
    this.http.get(url).subscribe((response: any[]) => {
      this.replies = response.reverse();
      this.repliesLoaded = true;
    });
  }

  reply() {
    const inputs = {
      heading: $localize`Comment`,
      parentId: this.clip.id,
      title: '',
      inModal: true,
      tabs: ['Text', 'Video', 'Audio'],
    };

    this.modalService.init(PostCreatePopupComponent, inputs);
  }

  makeBooking() {
    const userId = this.clip.authorid;

    if (this.embed) {
      const tree = this.router.createUrlTree(['']);
      const url = configUrl?.host + '/combined/bookings/' + userId;
      this.openOutsideIframe(url);
    } else {
      this.router.navigate([`/combined/bookings/${userId}`]);
    }
  }

  makeCall() {
    const userId = this.clip.authorid;

    if (this.authService.activeUser) {
      this.liveVideoService
        .createRoom()
        .pipe(
          switchMap((room) => {
            return this.liveVideoService.addUserToRoom(userId, room.id).pipe(map((_) => room));
          }),
        )
        .subscribe((room) => {
          this.navigateToRoom(room.id, userId);
        });
    } else {
      this.liveVideoService
        .createPublicRoom()
        .pipe(
          switchMap((room) => {
            return this.liveVideoService.addUserToPublicRoom(userId, room.id).pipe(map((_) => room));
          }),
        )
        .subscribe((room) => {
          this.navigateToRoom(room.id, userId);
        });
    }
  }

  private navigateToRoom(roomId: string, call?: string) {
    const queryParams = { call, returnUrl: this.router.url, public: !this.authService.activeUser };

    if (this.embed) {
      queryParams.returnUrl = 'closeTab';
      const extras = { queryParams };
      const tree = this.router.createUrlTree([''], extras);
      const url = configUrl?.host + '/live/' + roomId + this.serializer.serialize(tree).substr(1);
      this.openOutsideIframe(url);
    } else {
      this.router.navigate([`/live/${roomId}`], { queryParams });
    }
  }

  private timeAgo(date: Date): string | boolean {
    const now = new Date();

    const m = differenceInMinutes(now, date);
    const h = differenceInHours(now, date);
    const d = differenceInDays(now, date);

    if (m === 0) {
      return '1m';
    }
    if (m < 60) {
      return `${m}m`;
    }
    if (h < 24) {
      return `${h}h`;
    }
    if (d > 99) {
      return false;
    }
    return `${d}d`;
  }

  openInNewTab(href: string) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: href,
    }).click();
  }

  openOutsideIframe(href: string) {
    Object.assign(document.createElement('a'), {
      target: '_top',
      rel: 'noopener noreferrer',
      href: href,
    }).click();
  }
}
