<ul class="share-social">
  <li><a *ngIf="embedOption" (click)="openEmbedModal()" class="share-embed" i18n>Embed</a></li>
  <li><a *ngIf="salesUrl" (click)="openSalesModal()" class="share-sales" i18n>Sales</a></li>
  <li>
    <a (click)="openShareWindow(SocialPlatform.Facebook)" class="share-social-facebook" i18n>Share to Facebook</a>
  </li>
  <li><a (click)="openShareWindow(SocialPlatform.Twitter)" class="share-social-twitter" i18n>Share to Twitter</a></li>
  <li>
    <a (click)="openShareWindow(SocialPlatform.WhatsApp)" class="share-social-whatsapp" i18n>Share to WhatsApp</a>
  </li>
  <li>
    <a (click)="openShareWindow(SocialPlatform.LinkedIn)" class="share-social-linkedin" i18n>Share to LinkedIn</a>
  </li>
</ul>
