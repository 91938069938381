import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of } from 'rxjs';

import { AuthService } from './auth.service';
import { configUrl, config } from '@env/environment';
import { Feature } from '@app/shared/model';
import { AwsService } from './aws.service';
import { switchMap, tap } from 'rxjs/operators';

@Injectable()
export class BusinessService {
  responseInvitation = false;
  showCompanyInfo = true;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private aws: AwsService,
  ) {}

  postAudio(file): Observable<any> {
    return this.httpClient.get<any>(configUrl.companySettingAudioUploadUrl).pipe(
      switchMap(({ filename, uploadurl }) =>
        forkJoin({
          filename: of(filename),
          audio: this.aws.putAudio(file, uploadurl, filename),
        }),
      ),
    );
  }

  postVideo(file): Observable<any> {
    return this.httpClient.get<any>(configUrl.companySettingVideoUploadUrl).pipe(
      switchMap(({ filename, uploadurl }) =>
        forkJoin({
          filename: of(filename),
          audio: this.aws.putVideo(file, uploadurl, filename),
        }),
      ),
    );
  }

  postSponsorImage(file): Observable<any> {
    return this.httpClient.get<any>(configUrl.companySponsorImageUploadUrl).pipe(
      switchMap(({ filename, uploadurl }) =>
        forkJoin({
          filename: of(filename),
          image: this.aws.putImage(file, uploadurl, filename),
        }),
      ),
    );
  }

  getCompaniesMembers(offset?: number, limit?: number, emails: boolean = false): Observable<any[]> {
    if (!this.authService.currentBusinessId) {
      return of([]);
    }

    const url = `${configUrl.companyMembers}/${this.authService.currentBusinessId}`;
    const options = {};

    if (offset != null && limit != null) {
      options['params'] = { offset, limit, emails };
    }

    return this.httpClient.get<any[]>(url, options);
  }

  hasFeature(feature: Feature): boolean {
    const companyFeatures = this.authService.companySettings?.features
      ? JSON.parse(this.authService.companySettings.features)
      : {};

    if (companyFeatures[feature] !== undefined) {
      return companyFeatures[feature];
    }

    return config.features.includes(feature);
  }

  getPendingEmailInvitations(): Observable<any[]> {
    const url = `${configUrl.companyUserInviteEmailPending}/${this.authService.currentBusinessId}`;
    return this.httpClient.get<any[]>(url);
  }

  companyUserAdd(member: any): Observable<any> {
    const body = {
      userid: member.userid,
      statusid: parseInt(member.statusid, 10),
      typeid: parseInt(member.typeid, 10),
    };

    return this.httpClient.post(configUrl.companyUser, body);
  }

  companyUserUpdate(member: any, callback: any = () => {}): any {
    const body = {
      companyid: member.companyid,
      userid: member.userid,
      statusid: parseInt(member.statusid, 10),
      typeid: parseInt(member.typeid, 10),
      updatedat: new Date(),
    };

    this.httpClient.put(configUrl.companyUser, body).subscribe(
      (response) => {
        this.toastr.success($localize`User ${member.username} updated`);
        callback(null, response);
      },
      (error) => {
        this.toastr.error($localize`Unable to update user ${member.username}`);
        callback(error, null);
      },
    );
  }

  updateCompanyInvitation(company: any, accept: boolean = true): Observable<any> {
    const statusId = accept ? 2 : 3;

    const body = {
      companyid: company.companyid || company.id,
      userid: this.authService.activeUser.uid,
      statusid: statusId,
      updatedat: new Date(),
    };

    return this.httpClient.put(configUrl.companyUserInvite, body);
  }

  updateCompanyUserInvite(member: any): Observable<any> {
    const body = member.email
      ? {
          companyid: member.companyid,
          email: member.email,
        }
      : {
          companyid: member.companyid,
          userid: member.id,
          statusid: parseInt(member.statusid, 10),
          typeid: parseInt(member.typeid, 10) || 3,
          updatedat: new Date(),
        };

    return this.httpClient.put(configUrl.companyUserInvite, body);
  }

  companyUserInvite(member: any, callback: any = () => {}): any {
    const body = {
      companyid: this.authService.currentBusinessId,
      userid: member.id,
      statusid: 2,
    };

    this.httpClient.post(configUrl.companyUserInvite, body).subscribe(
      (response) => {
        this.toastr.success($localize`Invitation sent to ${member.username}`);
        callback();
      },
      (error) => {
        this.toastr.error($localize`Unable to send invitation to ${member.username}`);
      },
    );
  }

  companyUserInviteEmail(email: string, callback: any = () => {}): any {
    const body = {
      companyid: this.authService.currentBusinessId,
      email,
    };

    this.httpClient.post(configUrl.companyUserInviteEmail, body).subscribe(
      (response) => {
        this.toastr.success($localize`Invitation sent to ${email}`);
        callback(null, response);
      },
      (error) => {
        this.toastr.error($localize`Unable to send invitation to ${email}`);
        callback(error, null);
      },
    );
  }

  navigateToCompany(company: any = null, path: string = '/') {
    if (configUrl.isLocalDomain) {
      if (company?.subdomain) {
        localStorage.setItem('subdomain', company?.subdomain);
      } else {
        localStorage.removeItem('subdomain');
      }
      this.router.navigate([path]).then((_) => window.location.reload());
    } else {
      let subdomainPart = company ? `${company.subdomain}.` : '';
      if (!company && config.globalSubdomain) {
        subdomainPart = `${config.globalSubdomain}.`;
      }
      window.location.href = `${configUrl.urlProtocol}//${subdomainPart}${configUrl.domain}${path}`;
    }
  }
}
