<form #inviteForm="ngForm" (submit)="inviteAction(inviteForm)">
  <div class="ui-modal-header">
    <h2 i18n>Invite To {{ inviteTo }}</h2>
    <div class="ui-modal-close" (click)="dismissAction()">
      <mdi name="Close"></mdi>
    </div>
  </div>

  <div class="ui-modal-body">
    <div class="mb-3">
      <input
        type="email"
        class="form-control"
        id="email"
        name="email"
        i18n-placeholder
        placeholder="Email"
        [(ngModel)]="model.email"
        required
        email
        #email="ngModel"
      />
      <div *ngIf="email.touched && email.errors?.required" class="text-danger" i18n>Email is required.</div>
      <div *ngIf="email.touched && email.errors?.email" class="text-danger" i18n>Email must be valid.</div>
    </div>
    <div class="mb-3">
      <input
        type="name"
        class="form-control"
        id="fullname"
        name="fullname"
        i18n-placeholder
        placeholder="Full Name (optional)"
        [(ngModel)]="model.fullname"
      />
    </div>
  </div>

  <div class="ui-modal-footer">
    <button class="btn btn-md btn-light" (click)="dismissAction()" i18n="@@cancel">Cancel</button>
    <button type="submit" class="btn btn-md btn-primary" [class.ui-modal-saving]="saving" i18n>Send Invitation</button>
  </div>
</form>
