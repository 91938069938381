<div class="auth">
  <img class="sign-up-logo" [src]="logo" height="50" />

  <div *ngIf="invite" class="alert alert-info" i18n>
    You have been invited to {{ invite.companyname }}. Create an account to join or sign in if you already have an
    eventanywhere account.
  </div>

  <div *ngIf="inviteError" class="alert alert-danger">
    {{ inviteError.error?.message || error }}
  </div>

  <app-loading *ngIf="(authService.loggedIn$ | async) && !authService.userInfo"></app-loading>

  <div class="signed-in" *ngIf="(authService.loggedIn$ | async) && authService.userInfo">
    <div class="form-submit">
      <a class="btn btn-primary sign-up-btn" (click)="continueAsLoggedInUser()" i18n
        >Continue as {{ authService.userInfo?.username }}</a
      >
    </div>

    <div class="extra-login dropdown">
      <span i18n>or</span>
      <span> </span>
      <a class="social-link" (click)="logout()" i18n>use a different account</a>
    </div>
  </div>

  <div class="signed-out" *ngIf="authService.loggedOut$ | async">
    <!-- <h2 class="mb-3 subtext">{{lang('Auth_Description')}}</h2> -->
    <p class="powered-by mb-4">
      Powered by <a [href]="config.websiteUrl" target="_blank">{{ config.title }}</a>
    </p>

    <h2 *ngIf="tab === 0">Sign In</h2>
    <h2 *ngIf="tab === 1">Create Account</h2>

    <!-- <div class="sign-up-tabs">
      <div class="sign-up-tabs-tab" [class.sign-up-tabs-tab-active]="tab === 0">
        <a (click)="changeTab(0)" i18n="@@signIn">Sign In</a>
      </div>
      <div class="sign-up-tabs-tab" [class.sign-up-tabs-tab-active]="tab === 1">
        <a (click)="changeTab(1)" i18n="@@createAccount">Create Account</a>
      </div>
    </div> -->

    <div class="signed-out-sign-in" *ngIf="tab === 0">
      <form class="sign-up-form" (submit)="login()">
        <div class="mb-3">
          <input
            type="email"
            [email]="true"
            class="form-control"
            id="email"
            name="email"
            i18n-placeholder
            placeholder="Email"
            [(ngModel)]="email"
            required
            autocomplete="username"
          />
        </div>
        <div class="mb-3">
          <input
            minlength="6"
            type="password"
            class="form-control"
            id="password"
            name="password"
            i18n-placeholder
            placeholder="Password"
            [(ngModel)]="password"
            required
            autocomplete="current-password"
          />

          <div class="mt-3">
            <div>
              Don't have an account?
              <a *ngIf="tab === 0" class="reset-password" (click)="changeTab(1)" i18n>Create Account</a>
            </div>
            <div><a class="reset-password" [routerLink]="['/reset-password']" i18n>Forgot password?</a></div>
          </div>
        </div>
        <div class="form-submit">
          <button type="submit" class="btn btn-primary sign-up-btn" [class.disabled]="loading" i18n="@@signIn">
            Sign In
          </button>
          <a
            *ngIf="config.loginAsGuest && (authService.companySettings?.isPublic || !authService.currentBusinessId)"
            class="btn btn-outline-primary sign-up-btn"
            (click)="loginAsGuest()"
            i18n
            >Continue as Guest</a
          >
        </div>
        <div *ngIf="config.socialLogin?.length" class="extra-login dropdown" ngbDropdown placement="bottom-right">
          <span i18n>or sign in with your</span>&nbsp;<a
            ngbDropdownToggle
            class="social-link dropdown-toggle"
            aria-haspopup="true"
            aria-expanded="false"
            i18n
            >Social Account</a
          >
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
            <a
              *ngIf="config.socialLogin.includes(SocialLogin.Google)"
              ngbDropdownItem
              class="dropdown-item"
              (click)="loginSocial('google')"
              i18n
              >Sign in with Google</a
            >
            <a
              *ngIf="config.socialLogin.includes(SocialLogin.Facebook)"
              ngbDropdownItem
              class="dropdown-item"
              (click)="loginSocial('facebook')"
              i18n
              >Sign in with Facebook</a
            >
          </div>
        </div>
        <div *ngIf="!isChrome()" class="browser-support" i18n>
          We recommend using the chrome browser on a desktop/laptop
        </div>
      </form>
    </div>

    <div class="signed-out-sign-up" *ngIf="tab === 1">
      <div class="sign-up-form" *ngIf="!canSignUp">
        <div class="alert alert-danger" i18n>
          Self registration is disabled for this {{ lang('community') }}. Please request an invite from the
          administrator.
        </div>
        <div style="margin-bottom: 8px">
          Aleady a member? <a *ngIf="tab === 1" class="reset-password" (click)="changeTab(0)" i18n>Sign In</a>
        </div>
      </div>

      <form class="sign-up-form" *ngIf="canSignUp" (submit)="signUp(name, email, password)">
        <div class="mb-3">
          <input
            minlength="3"
            type="text"
            class="form-control"
            id="name"
            name="name"
            i18n-placeholder
            placeholder="Your Name"
            [(ngModel)]="name"
            required
            autocomplete="name"
          />
        </div>

        <div class="mb-3">
          <input
            type="email"
            [email]="true"
            class="form-control"
            id="email"
            name="email"
            i18n-placeholder
            placeholder="Email"
            [(ngModel)]="email"
            required
            autocomplete="username"
          />
        </div>
        <div class="mb-3">
          <input
            minlength="6"
            type="password"
            class="form-control"
            id="password"
            name="password"
            i18n-placeholder
            placeholder="Password"
            [(ngModel)]="password"
            required
            autocomplete="new-password"
          />
        </div>
        <div class="mb-3">
          <div style="margin-bottom: 8px">
            Aleady a member? <a *ngIf="tab === 1" class="reset-password" (click)="changeTab(0)" i18n>Sign In</a>
          </div>
          <div class="form-check mb-3">
            <input
              type="checkbox"
              class="form-check-input"
              id="terms"
              [(ngModel)]="terms"
              [ngModelOptions]="{ standalone: true }"
            />
            <label class="orm-check-label terms" for="terms" i18n
              >I agree to the <a [href]="configUrl.terms" target="_blank">Terms of Service</a> and
              <a [href]="configUrl.policy" target="_blank">Privacy Policy</a></label
            >
          </div>
        </div>

        <div class="form-submit">
          <button type="submit" class="btn btn-primary sign-up-btn" [class.disabled]="loading" i18n>
            Create&nbsp;Account
          </button>
        </div>
        <div *ngIf="!isChrome()" class="browser-support" i18n>
          We recommend using the chrome browser on a desktop/laptop
        </div>
      </form>
    </div>
  </div>
</div>

<div class="auth-about" *ngIf="authService.getSubdomain()">
  <h2 i18n>Welcome</h2>
  <app-company-info *ngIf="authService.companySettings.companyid"></app-company-info>
</div>
