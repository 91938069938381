import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';

import { JanActionComponent } from './jan-action/jan-action.component';
import { JanParticipantsComponent } from './jan-participants/jan-participants.component';
import { JanRoomComponent } from './jan-room/jan-room.component';
import { LiveRoomComponent } from './live-room/live-room.component';
import { RouletteComponent } from './roulette/roulette.component';
import { LiveStageComponent } from './live-stage/live-stage.component';
import { JanChatComponent } from './jan-chat/jan-chat.component';
import { ModalLiveRoomUserInfoComponent } from './modal-live-room-user-info/modal-live-room-user-info.component';
import { GreenroomComponent } from './greenroom/greenroom.component';
import { JanPollsComponent } from './jan-polls/jan-polls.component';
import { ModalPollsListComponent } from './modal-polls-list/modal-polls-list.component';

@NgModule({
  declarations: [
    LiveRoomComponent,
    RouletteComponent,
    JanRoomComponent,
    JanParticipantsComponent,
    JanActionComponent,
    LiveStageComponent,
    GreenroomComponent,
    JanChatComponent,
    JanPollsComponent,
    ModalLiveRoomUserInfoComponent,
    ModalPollsListComponent,
  ],
  imports: [SharedModule],
})
export class LiveVideoModule {}
