<div class="heading">
  <h2 i18n="@@polls">Polls</h2>
  <div class="close-action" (click)="closePanel.emit()">
    <mdi name="Close" size="20"></mdi>
  </div>
  <div class="heading-actions" *ngIf="showAddPoll">
    <a (click)="addPoll.emit()" class="btn btn-outline-primary btn-sm" i18n="@@addPoll">Add Poll</a>
  </div>
</div>

<div class="polls">
  <div *ngIf="polls.length === 0" class="no-polls" i18n>There are no polls on this stage.</div>
  <div class="poll" *ngFor="let poll of polls">
    <app-poll [poll]="poll" [allowRemovePoll]="showAddPoll" (removePoll)="remove($event)"></app-poll>
  </div>
</div>
