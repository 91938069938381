import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-refresh',
  template: '',
})
export class RefreshComponent implements OnInit {
  constructor(private location: Location) {}

  ngOnInit() {
    this.location.back();
  }
}
