import { Component, OnInit, ViewChild, ElementRef, Input, Output, NgZone, OnDestroy } from '@angular/core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { AuthService, UiModalService } from '@app/core/services';
import { configUrl } from '@env/environment';
import { Recipient, RecipientType } from '@app/shared/model';

@Component({
  selector: 'app-modal-user-search',
  templateUrl: './modal-user-search.component.html',
})
export class ModalUserSearchComponent implements OnInit, OnDestroy {
  @ViewChild('suggestionsList') suggestionsList: ElementRef;

  @Input() searchAll = false;
  @Input() closeOnSelection = false;
  @Input() actionTitle = $localize`Action`;
  @Input() modalTitle = $localize`:@@searchUsers:Search Users`;
  @Input() blacklist: [any];
  @Output() actionCallback: (user: any, modal?: ModalUserSearchComponent) => void;

  actioned = [];
  searchQuery: string;
  keyUp = new Subject<string>();
  suggestions: Recipient[] = [];
  limit = '?limit=200&offset=0';

  listTopShadow = false;
  listBottomShadow = false;

  configUrl = configUrl;

  scrollDispatcherSubscription: Subscription;

  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
    private modal: UiModalService,
    private scrollDispatcher: ScrollDispatcher,
    private zone: NgZone,
    private authService: AuthService,
  ) {
    this.scrollDispatcherSubscription = this.scrollDispatcher.scrolled().subscribe((x) => {
      this.updateListShadows();
    });
  }

  ngOnInit() {
    this.keyUp.pipe(debounceTime(500)).subscribe((value) => {
      this.search(value);
    });
  }

  ngOnDestroy() {
    this.scrollDispatcherSubscription?.unsubscribe();
  }

  isBlacklisted(recipient: Recipient): boolean {
    const isInBlacklist = this.blacklist.find((user) => user.id === recipient.id) !== undefined;
    const isActioned = this.actioned.find((user) => user.id === recipient.id) !== undefined;

    return isInBlacklist || isActioned;
  }

  updateListShadows() {
    const element = this.suggestionsList?.nativeElement;

    const listTopShadow = this.suggestionsList && element.scrollTop > 0;
    const listBottomShadow = this.suggestionsList && element.scrollTop < element.scrollHeight - element.clientHeight;

    if (this.listTopShadow !== listTopShadow || this.listBottomShadow !== listBottomShadow) {
      this.zone.run((_) => {
        this.listTopShadow = listTopShadow;
        this.listBottomShadow = listBottomShadow;
      });
    }
  }

  dismiss() {
    this.modal.destroy();
  }

  onChange() {
    this.keyUp.next(this.searchQuery);
  }

  performAction(user: any) {
    this.actioned.push(user);
    this.actionCallback(user, this);
    if (this.closeOnSelection) {
      this.dismiss();
    }
  }

  search(query) {
    const find = '/';
    const re = new RegExp(find, 'g');
    const cleanSearchQuery = query.replace(re, '%2F');

    if (query.length >= 2) {
      const url = `${configUrl.searchUser}${cleanSearchQuery}${this.limit}&type=User`;

      let request = this.http.get<any[]>(url);

      if (this.searchAll) {
        const httpClient = new HttpClient(this.httpBackend);

        const headers = new HttpHeaders({ Authorization: `Bearer ${this.authService.token}` });
        const options = { headers };

        request = httpClient.get<any[]>(url, options);
      }

      request.subscribe((response) => {
        const users = response;

        this.suggestions = users.map((user) => {
          const recipient: Recipient = {
            id: user.id,
            name: user.username,
            type: RecipientType.User,
            imageid: user.userimageid,
            userrole: 2,
          };

          return recipient;
        });

        this.updateListShadows();
      });
    } else {
      this.suggestions = [];
      this.updateListShadows();
    }
  }
}
