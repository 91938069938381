import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Action, Breadcrumb } from '@app/shared/model';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  @Input() breadcrumbs: Breadcrumb[];
  @Input() actions: Action[];
  @Input() primaryAction: Action;
  @Input() disabledAction = false;
  @Output() handlePrimaryAction = new EventEmitter<Action>();
  @Output() actionHandler = new EventEmitter<Action>();

  clickPrimaryAction(action: Action) {
    this.handlePrimaryAction.emit(action);
  }

  onAction(action: Action) {
    if (action) {
      this.actionHandler.emit(action);
    }
  }
}
