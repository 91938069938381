<div class="recordvideo" [class.recordvideo-compact]="appearance === 'compact'">
  <div
    [hidden]="isRecording || videoUrlSaved || loading"
    (click)="startRecording()"
    #placeholder
    class="recordvideo-placeholder"
  >
    <div class="recordvideo-placeholder-btn"></div>
  </div>
  <div *ngIf="!isRecording && !videoUrlSaved && loading" class="recordvideo-loading" i18n>Get ready...</div>

  <div [hidden]="isRecording || !videoUrlSaved" class="record-video-container">
    <video #savedVideo controls></video>
  </div>

  <div [hidden]="!isRecording" class="record-video-container">
    <video #hardwareVideo></video>
    <div class="record-video-stop-recording" (click)="stopRecording()">&#10003; <span i18n>Done</span></div>
    <div class="record-video-record-icon"></div>
    <div class="record-video-progress">
      <div
        class="record-video-progress-bar"
        [style.animation]="'progress ' + this.timeLimit / 1000 + 's linear forwards'"
      ></div>
    </div>
  </div>

  <div [hidden]="!thumbnail || isRecording" class="record-video-thumbnails">
    <div class="record-video-thumbnail">
      <canvas id="canvas" #canvas></canvas>
    </div>
    <div class="record-video-thumbnail-action-container">
      <a (click)="updateThumbail()" class="btn btn-outline-primary" i18n>Update Thumbnail</a>
    </div>
  </div>
</div>
