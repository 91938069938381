<div class="sign-up">
  <div class="form-container">
    <div class="inside-form-container">
      <img class="sign-up-logo" [src]="logo" height="50" />

      <div class="sign-up-tabs sign-up-tabs-only">
        <div class="sign-up-tabs-tab">
          <a (click)="$event.preventDefault()" i18n>Forgotten Password</a>
        </div>
      </div>

      <form class="sign-up-form">
        <div class="mb-3">
          <input
            type="email"
            [email]="true"
            class="form-control"
            id="email"
            name="email"
            placeholder="Email"
            i18n-placeholder
            [(ngModel)]="email"
            required
          />
        </div>

        <div class="form-submit">
          <a class="btn btn-primary sign-up-btn" (click)="resetPassword()" i18n>Reset Password</a>
          <a class="btn btn-outline-primary sign-up-btn" (click)="cancel()" i18n="@@cancel">Cancel</a>
        </div>
      </form>
    </div>

    <div class="terms-policy" i18n>
      <a href="{{ configUrl?.terms }}" target="_blank">Terms of Use</a> and
      <a href="{{ configUrl?.policy }}" target="_blank">Privacy Policy</a>
    </div>
  </div>
</div>
