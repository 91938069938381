import { Component, Input, Output } from '@angular/core';

import { UiModalService } from '@app/core/services';
import { Time } from '@app/shared/timepicker/timepicker.component';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  templateUrl: './modal-datetimerange.component.html',
})
export class ModalDateTimeRangeComponent {
  @Input() title = 'Date Time Range';
  @Output() completeHandler: (data: any) => void;

  @Input() fromTime: Time = { hour: 0, minute: 0 };
  @Input() toTime: Time = { hour: 0, minute: 0 };

  @Input() fromDate: NgbDateStruct;
  @Input() toDate: any;

  constructor(
    private modalService: UiModalService,
    private toastr: ToastrService,
  ) {}

  applyAction() {
    if (!this.fromDate) {
      this.toastr.error($localize`Please choose a From Date`);
      return;
    }

    if (!this.toDate) {
      this.toastr.error($localize`Please choose a To Date`);
      return;
    }

    const data = {
      from: {
        ...this.fromDate,
        ...this.fromTime,
        date: new Date(
          this.fromDate.year,
          this.fromDate.month - 1,
          this.fromDate.day,
          this.fromTime.hour,
          this.fromTime.minute,
        ),
      },
      to: {
        ...this.toDate,
        ...this.toTime,
        date: new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day, this.toTime.hour, this.toTime.minute),
      },
    };

    if (data.to.date < data.from.date) {
      this.toastr.error($localize`End date must come after start date`);
      return;
    }

    this.completeHandler?.(data);
    this.modalService.destroy();
  }

  cancelAction() {
    this.modalService.destroy();
  }
}
