<div
  class="user-navigation dropdown userlogged"
  ngbDropdown
  [placement]="dropUp ? 'top-left' : 'bottom-right'"
  *ngIf="authService.activeUser && authService.activeUser?.uid"
>
  <div
    class="dropdown-toggle pointer"
    aria-haspopup="true"
    aria-expanded="true"
    ngbDropdownToggle
    [ngClass]="{ 'logged-in white-bg': authService.activeUser }"
  >
    <div class="me-container">
      <div class="profile-image">
        <mdi *ngIf="!userInfo?.imageid" name="User"></mdi>
        <img *ngIf="userInfo?.imageid" (error)="updateUrl($event)" [src]="imageSrc" />
      </div>
    </div>
  </div>
  <ul ngbDropdownMenu aria-labelledby="loginuser">
    <li class="dropdown-item-user">
      <div class="user-info">
        <app-user-image
          *ngIf="userInfo"
          [userId]="authService.activeUser.uid"
          [imageId]="userInfo?.imageid"
          [classType]="'nav-photo big'"
        ></app-user-image>
        <span class="dropdown-item-user-text">{{ userInfo?.username }}</span>
      </div>
    </li>
    <a
      ngbDropdownItem
      class="dropdown-item"
      *ngIf="userInfo?.isadmin"
      [routerLink]="['/combined/admin']"
      (click)="navService.hideMenu()"
      i18n
      >Admin Panel</a
    >
    <li role="separator" class="dropdown-divider"></li>

    <a
      ngbDropdownItem
      class="dropdown-item"
      *ngIf="authService.selectedCompany?.id && authService.isCompanyAdmin"
      (click)="navService.hideMenu()"
      [routerLink]="['/combined/business/' + authService.selectedCompany?.id]"
      >{{ lang('Community_Admin') }}</a
    >

    <li
      *ngIf="authService.selectedCompany?.id && authService.isCompanyAdmin"
      role="separator"
      class="dropdown-divider"
    ></li>

    <a ngbDropdownItem class="dropdown-item" (click)="about()" (click)="navService.hideMenu()" i18n
      >About {{ config.title }}</a
    >
    <a ngbDropdownItem class="dropdown-item" (click)="changeLanguage()" (click)="navService.hideMenu()" i18n
      >Change Language...</a
    >

    <a
      ngbDropdownItem
      class="dropdown-item"
      *ngIf="authService.activeUser"
      (click)="navService.hideMenu()"
      [routerLink]="['/combined/user/' + authService.activeUser?.uid]"
      i18n="@@profile"
      >Profile</a
    >
    <a ngbDropdownItem class="dropdown-item" (click)="authService.signOut()" (click)="navService.hideMenu()" i18n
      >Logout</a
    >
  </ul>
</div>
