<div class="companies" ngbDropdown #myDrop="ngbDropdown">
  <div
    class="dropdown-toggle pointer"
    ngbDropdownToggle
    aria-haspopup="true"
    aria-expanded="true"
    [ngClass]="{ 'logged-in white-bg': authService.activeUser }"
  >
    <div style="width: 194px">
      <a class="logo" [class.logo-clickable]="companySelector">
        <img [src]="logo" />
      </a>
    </div>

    <div class="toggle-container" [class.toggle-container-open]="myDrop.isOpen()" *ngIf="companySelector">
      <mdi name="ChevronDown" size="16"></mdi>
    </div>
  </div>
  <div class="dropdown-menu" aria-labelledby="companies" ngbDropdownMenu *ngIf="companySelector">
    <ul>
      <li class="dropdown-menu-heading">
        <span>{{ lang('My_Communities') }}</span>
        <div class="add-company-action" (click)="addCompany()" *ngIf="authService.activeUser">
          <mdi name="Plus" size="22"></mdi>
        </div>
      </li>
      <li [ngClass]="{ active: !authService.selectedCompany }" ngbDropdownItem>
        <div class="company-image" (click)="toggleLocation(null)" [ngClass]="{ active: !authService.selectedCompany }">
          <p class="company">{{ config.title }}</p>
        </div>
      </li>
      <li
        *ngFor="let company of authService.companies | filter: filterByAccepted"
        [ngClass]="{ active: checkIfCompanyActiveBoolean(company) }"
        ngbDropdownItem
      >
        <div class="company-image" (click)="toggleLocation(company)">
          <p class="company">{{ company.name }}</p>
        </div>
      </li>
    </ul>
  </div>
</div>
