import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { configUrl } from '@env/environment';
import { AnalyticsWordsCategory } from '@app/shared/model';

@Injectable()
export class AnalyticsService {
  api = configUrl.URL;

  constructor(private http: HttpClient) {}

  getVideoUserData(id: string, offset = 0, limit = 10, startdate?: Date, enddate?: Date): Observable<any> {
    const params: any = {
      offset: offset.toString(),
      limit: limit.toString(),
    };
    if (startdate && enddate) {
      params.startdate = startdate.toISOString();
      params.enddate = enddate.toISOString();
    }
    return this.http.get(`${this.api}analytics/video/attendees/user/${id}`, { params });
  }

  getVideoRoomData(id: string, offset = 0, limit = 10, startdate?: Date, enddate?: Date): Observable<any> {
    const params: any = {
      offset: offset.toString(),
      limit: limit.toString(),
    };
    if (startdate && enddate) {
      params.startdate = startdate.toISOString();
      params.enddate = enddate.toISOString();
    }
    return this.http.get(`${this.api}analytics/video/attendees/room/${id}`, { params });
  }

  getVideoMeetingsData(): Observable<any> {
    return this.http.get(`${this.api}analytics/video/attendees/category/1`);
  }

  getVideoNetworkingData(): Observable<any> {
    return this.http.get(`${this.api}analytics/video/attendees/category/2`);
  }

  getVideoStagesData(): Observable<any> {
    return this.http.get(`${this.api}analytics/video/attendees/category/3`);
  }

  getVideoExpoHallData(): Observable<any> {
    return this.http.get(`${this.api}analytics/video/attendees/category/4`);
  }

  getClipsData(startdate: string, enddate: string): Observable<any> {
    const params = { startdate, enddate };
    return this.http.get(configUrl.analyticsClips, { params });
  }

  getPlaysData(from: string, to: string, clipid: any): Observable<any> {
    const startDate = encodeURIComponent(from);
    const endDate = encodeURIComponent(to);

    if (clipid) {
      const url = `${configUrl.analyticsClipsPlays}?clipid=${clipid.id}&startdate=${startDate}&enddate=${endDate}`;
      return this.http.get(url);
    }

    return this.http.get(`${configUrl.analyticsClipsPlays}?startdate=${startDate}&enddate=${endDate}`);
  }

  getWordsData(
    from: string,
    to: string,
    type: AnalyticsWordsCategory = AnalyticsWordsCategory.My,
    id: string = null,
  ): Observable<any> {
    const startDate = encodeURIComponent(from);
    const endDate = encodeURIComponent(to);

    let url;
    if (type === AnalyticsWordsCategory.My) {
      url = `${configUrl.analyticsWords}?startdate=${startDate}&enddate=${endDate}`;
    }
    if (type === AnalyticsWordsCategory.Group) {
      url = `${configUrl.analyticsWords}?startdate=${startDate}&enddate=${endDate}&type=Group&groupid=${id}`;
    }
    if (type === AnalyticsWordsCategory.Chat) {
      url = `${configUrl.analyticsWords}?startdate=${startDate}&enddate=${endDate}&type=Chat&chatid=${id}`;
    }

    return this.http.get(url);
  }
}
