<div class="layout-middle-col live-stage-middle-col">
  <div class="stage-container">
    <div class="stage-head">
      <div class="stage-head-heading">
        <ul class="nav nav-pills">
          <li class="nav-item" *ngFor="let s of [].constructor(authService.companySettings?.stages); let i = index">
            <a class="nav-link" [routerLink]="['/combined/stage', i + 1]"><span i18n>Stage </span>{{ i + 1 }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" [routerLink]="['/combined/greenroom']" i18n="@@greenRoom">Green Room</a>
          </li>
        </ul>
      </div>
      <div class="stage-head-actions"></div>
    </div>

    <div class="stage">
      <app-jan-room
        *ngIf="roomVisible"
        [url]="janusServerUrl"
        [iceServers]="iceServers"
        [displayName]="displayName"
        [userId]="userId"
        [pin]="stage.pin"
        [secret]="stage.secret"
        [token]="stage.token"
        [room]="stage.id"
        [actions]="['audioMute', 'videoMute', 'shareScreen', 'participants', 'chat', 'sharePost']"
        emptyMessage="The green room is currently empty"
        (connected)="connected()"
        (leaveRoom)="leftRoom()"
      ></app-jan-room>
    </div>

    <div class="video-container" *ngIf="!authService.canPresentOnLiveStage">
      <div class="video-message" i18n>You do not have permission to join the green room</div>
    </div>
  </div>
</div>
