<div class="heading">
  <h2 i18n="@@chat">Chat</h2>
  <div class="close-action" (click)="closePanel.emit()">
    <mdi name="Close" size="20"></mdi>
  </div>
</div>
<div class="messages" #messages>
  <ul>
    <li
      *ngFor="let chat of chats | filter: filterChats : canModerate : authService.activeUser?.uid"
      [class.pending]="chat.status === 'pending'"
      [class.rejected]="chat.status === 'rejected'"
    >
      <div class="display-name">{{ chat.displayName }}</div>
      <div class="message" [innerHtml]="chat.message | linkify | trustHtml"></div>
      <div class="actions" *ngIf="canModerate && allowChatModeration">
        <div class="action" (click)="approveChat(chat)" *ngIf="chat.status === 'rejected'">
          <mdi name="Undo" size="16"></mdi>
        </div>
        <div class="action" (click)="approveChat(chat)" *ngIf="chat.status === 'pending'">
          <mdi name="Yes" size="16"></mdi>
        </div>
        <div class="action" (click)="rejectChat(chat)" *ngIf="chat.status !== 'rejected'">
          <mdi name="Block" size="16"></mdi>
        </div>
      </div>
    </li>
  </ul>
</div>
<div class="footer">
  <form (ngSubmit)="addChat()" novalidate>
    <div>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="message"
        name="message"
        placeholder="Send a message"
        i18n-placeholder
        aria-label="Message"
        aria-describedby="button-addon2"
        autocomplete="off"
      />
    </div>
  </form>
</div>
