import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { forkJoin, from, fromEvent, Observable, of, Subscription } from 'rxjs';
import { catchError, map, switchMap, take, tap, toArray } from 'rxjs/operators';
import { JanPublisher, JanSession } from '@app/shared/model';
import { AuthService, JanService, LoggerService, PollsService, UiModalService } from '@app/core/services';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';

const ResizeObserver = (window as any).ResizeObserver || Polyfill;

import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

import { v1 as uuid } from 'uuid';

import { ModalPostListComponent } from '@app/shared/modal/modal-post-list/modal-post-list.component';
import { HttpClient } from '@angular/common/http';
import { configUrl } from '@env/environment';
import { ModalConfirmComponent } from '@app/shared/modal/modal-confirm/modal-confirm.component';
import { ModalPollsListComponent } from '../modal-polls-list/modal-polls-list.component';
import { RecordingService } from '@app/core/services/recording.service';
import { ActivatedRoute } from '@angular/router';

enum RecordingStatus {
  creating = 'creating',
  starting = 'starting',
  recording = 'recording',
  stopped = 'stopped',
  uploading = 'uploading',
  uploaded = 'uploaded',
  terminated = 'terminated',
}

@Component({
  selector: 'app-jan-room',
  templateUrl: './jan-room.component.html',
  styleUrls: ['./jan-room.component.scss'],
})
export class JanRoomComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() url: string | undefined;
  @Input() iceServers: any[] | undefined;
  @Input() room: string | null = null;
  @Input() token: string | null = null;
  @Input() pin: string | null = null;
  @Input() secret: string | null = null;
  @Input() displayName = 'Me';
  @Input() userId: string | null = null;
  @Input() publish = true;
  @Input() showToolbar = true;
  @Input() actions: string[] = [];
  @Input() showAllAttendees = false;
  @Input() showChat = false;
  @Input() showPolls = false;
  @Input() allowChatModeration = false;
  @Input() canModerate: boolean = false;
  @Input() emptyMessage: string = null;

  @Output() connected = new EventEmitter<null>();
  @Output() closeRoom = new EventEmitter<null>();
  @Output() leaveRoom = new EventEmitter<null>();
  @Output() addUser = new EventEmitter<null>();
  @Output() roomLink = new EventEmitter<null>();

  @ViewChild('roomVideos') roomVideos: ElementRef<HTMLDivElement> | undefined;
  @ViewChild('cameraVideo') cameraVideo: ElementRef<HTMLDivElement> | undefined;

  maxVisibleVideos = 9;
  newMessagesCount = 0;

  audioMuted = false;
  videoMuted = false;

  audioPaused = false;

  shutdown = false;
  sidePanel: string | null = null;

  audio: { id: number; audio: HTMLAudioElement }[] = [];

  cameraPublisherId: number | undefined;
  screenPublisherId: number | undefined;
  videoPublisherId: number | undefined;

  publishers: JanPublisher[] = [];
  attendees: JanPublisher[] = [];

  chats: any[] = [];
  polls: any[] = [];

  recordingStatusSubscription: Subscription | null = null;
  recordingStatus: RecordingStatus | null = null;
  recordingKey: string | undefined;

  isRecordingSubscription: Subscription | null = null;

  get recordIcon(): string {
    switch (this.recordingStatus) {
      case RecordingStatus.creating:
      case RecordingStatus.starting:
      case RecordingStatus.uploading:
      case RecordingStatus.uploaded:
      case RecordingStatus.stopped:
        return 'Loading';
      case null:
        return 'RecordRoom';
      case RecordingStatus.recording:
        return 'Stop';
      default:
        return '';
    }
  }

  isRecording = false;
  recordingInstance: any | null = null;

  get audioMutedText() {
    return this.audioMuted ? $localize`Unmute` : $localize`Mute`;
  }

  get videoMutedText() {
    return this.videoMuted ? $localize`Start Video` : $localize`Stop Video`;
  }

  get viewers(): JanPublisher[] {
    const publisherInList = (publisher: JanPublisher): boolean => {
      return !!this.publishers.filter((p) => p.id === publisher.id).length;
    };

    return this.attendees.filter(
      (attendee) =>
        attendee.id !== this.cameraPublisherId &&
        attendee.id !== this.screenPublisherId &&
        !publisherInList(attendee) &&
        attendee.display !== 'ROOM-WATCHER',
    );
  }

  publisherSubscription: Subscription | undefined;
  attendeesSubscription: Subscription | undefined;

  // @ts-ignore
  resizeObserver: ResizeObserver | undefined;

  public cameraStream: MediaStream | undefined;
  public shareStream: MediaStream | undefined;
  public videoStream: MediaStream | undefined;

  videoPlayer: HTMLVideoElement | undefined;

  private session: JanSession;

  syncwords = false;

  constructor(
    private janService: JanService,
    private app: ApplicationRef,
    private toastr: ToastrService,
    private afDatabase: AngularFireDatabase,
    private modal: UiModalService,
    private http: HttpClient,
    private logger: LoggerService,
    private pollsService: PollsService,
    private recordingService: RecordingService,
    public authService: AuthService,
    private route: ActivatedRoute,
  ) {
    this.syncwords = !!this.route.snapshot.queryParams['syncwords'];
  }

  ngOnInit(): void {
    this.isRecordingSubscription = this.afDatabase
      .object(`/recorders/${this.room}`)
      .valueChanges()
      .subscribe((recorders) => {
        console.log(recorders);

        if (!recorders) {
          this.isRecording = false;
          this.recordingInstance = null;
          this.recordingStatus = null;
          return;
        }

        this.isRecording = Object.values(recorders)?.[0]?.status === 'recording';
        console.log(Object.values(recorders)?.[0]?.status, this.isRecording);
        this.recordingInstance = recorders ? Object.values(recorders)?.[0] : null;
        this.recordingStatus = this.recordingInstance?.status ?? null;
      });

    if (!this.url) {
      throw Error('No URL');
    }

    if (!this.iceServers) {
      throw Error('No Ice Servers');
    }

    this.updateRoomVideosClass();
    this.observeChat();
    this.loadPolls();

    forkJoin({
      stream: this.publish ? this.startMediaStream() : of(undefined),
      session: this.janService.connect(this.url, this.iceServers, this.token),
    }).subscribe(
      ({ stream, session }) => {
        if (!this.room) {
          throw Error('No Room');
        }

        if (stream === null) {
          return;
        }

        if (this.shutdown) {
          stream?.getTracks().forEach((track) => track.stop());
          return;
        }

        this.session = session;
        this.cameraStream = stream;
        this.updateRoomVideosClass();

        this.connected.emit();

        this.publisherSubscription = this.janService.publishers$.subscribe((publishers) =>
          this.observePublishers(session, publishers),
        );
        this.attendeesSubscription = this.janService.attendees$.subscribe((attendees) =>
          this.observeAttendees(attendees),
        );

        const userId = this.userId ? this.userId : uuid();
        const userType = this.userId ? '2' : '1';
        let pubId = `${userId}:1:${userType}:${this.randomPart()}`;

        if (stream) {
          this.janService
            .publishCameraStream(stream, this.token, pubId, this.room, this.displayName, this.pin)
            .subscribe(
              (response) => {
                response.peerConnection.onconnectionstatechange = (event) => {
                  if (response.peerConnection.connectionState === 'failed') {
                    const errorMessage = $localize`WebRTC connection failed. Could not publish your feed.`;
                    this.toastr.error(errorMessage, null, { onActivateTick: true });
                  }
                };

                this.cameraPublisherId = response.publisherId;
                this.updateRoomVideosClass();
              },
              (err) => {
                if (err.code === 'JanusRoomFull') {
                  this.toastr.error($localize`Sorry. This room is full.`);
                  if (this.actions.includes('leave')) {
                    this.leaveRoomAction();
                  } else {
                    this.shutdownRoom();
                  }
                } else {
                  this.toastr.error(err.message);
                }
              },
            );
        }

        pubId = `${userId}:1:${userType}:${this.randomPart()}`;

        this.janService.join(session, true, pubId, this.room, this.displayName, null, this.pin, this.token).subscribe();
      },
      (error) => {
        this.toastr.error($localize`There was an problem connecting to the video room`);
        this.logger.error(error);
      },
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const publishChange = changes.publish;
    if (publishChange && !publishChange.firstChange && publishChange.currentValue && !publishChange.previousValue) {
      if (this.session) {
        this.joinRoom();
      } else {
        this.toastr.error($localize`Cannot join room because there was a problem connecting to the video room`);
      }
    }

    const showChat = changes.showChat;
    if (showChat && !showChat.firstChange && showChat.currentValue !== showChat.previousValue) {
      this.toggleSide('chat');
    }

    const showPolls = changes.showPolls;
    if (showPolls && !showPolls.firstChange && showPolls.currentValue !== showPolls.previousValue) {
      this.toggleSide('polls');
    }
  }

  ngAfterViewInit(): void {
    this.observeSize();
  }

  ngOnDestroy(): void {
    this.recordingStatusSubscription?.unsubscribe();
    this.isRecordingSubscription?.unsubscribe();
    if (!this.shutdown) {
      this.shutdownRoom();
    }
  }

  record() {
    if (this.recordingStatus === null) {
      // Start recording

      this.recordingService.startRecording(this.room).subscribe();
      this.recordingStatus = RecordingStatus.creating;
      return;
    }

    if (this.recordingStatus === RecordingStatus.recording) {
      // Stop recording

      this.stopRecording();
      return;
    }
  }

  stopRecording() {
    if (!this.recordingInstance) {
      console.log('Recording instance not found');
      return;
    }

    this.recordingStatus = RecordingStatus.stopped;
    const instanceId = this.recordingInstance.id;
    this.recordingService.updateStatus(instanceId, 'stopped').subscribe();
  }

  // deleteRecording() {
  //   if (!this.recordingInstance) {
  //     console.log('Recording instance not found');
  //     return;
  //   }

  //   this.recordingStatus = RecordingStatus.stopped;
  //   const instanceId = this.recordingInstance.id;
  //   this.recordingService.deleteRecording(instanceId).subscribe();
  // }

  loadPolls() {
    this.pollsService
      .getRoomPolls(this.room)
      .pipe(
        switchMap((polls) => {
          if (!polls.length) {
            return of([]);
          }
          return forkJoin(
            polls.map((poll) =>
              this.pollsService.getPollOptions(poll.id).pipe(
                map((options) => {
                  return {
                    ...poll,
                    options,
                  };
                }),
              ),
            ),
          );
        }),
      )
      .subscribe((polls) => {
        this.polls = polls;
      });
  }

  joinRoom(): void {
    if (!this.session) {
      throw new Error($localize`Session not found`);
    }

    this.startMediaStream().subscribe((stream) => {
      if (!stream) {
        return;
      }
      this.cameraStream = stream;
      this.app.tick();
      this.updateRoomVideosClass();

      const userId = this.userId ? this.userId : uuid();
      const userType = this.userId ? '2' : '1';
      const pubId = `${userId}:1:${userType}:${this.randomPart()}`;

      this.janService
        .publishCameraStream(this.cameraStream, this.token, pubId, this.room, this.displayName, this.pin)
        .subscribe(
          (response) => {
            response.peerConnection.onconnectionstatechange = (event) => {
              if (response.peerConnection.connectionState === 'failed') {
                const errorMessage = $localize`WebRTC connection failed. Could not publish your feed.`;
                this.toastr.error(errorMessage, null, { onActivateTick: true });
              }
            };

            this.cameraPublisherId = response.publisherId;
            this.updateRoomVideosClass();
          },
          (err) => {
            if (err.code === 'JanusRoomFull') {
              this.toastr.error($localize`Sorry. This room is full.`);
              if (this.actions.includes('leave')) {
                this.leaveRoomAction();
              } else {
                this.shutdownRoom();
              }
            } else {
              this.toastr.error(err.message);
            }
          },
        );
    });
  }

  leaveRoomAction(): void {
    this.minimise();

    this.cameraStream?.getTracks().forEach((track) => track.stop());
    this.cameraStream = undefined;

    this.cameraPublisherId = undefined;

    this.janService.unpublishCamera(this.token);

    this.app.tick();

    this.updateRoomVideosClass();

    this.leaveRoom.emit();
  }

  shutdownRoom(): void {
    this.publisherSubscription?.unsubscribe();

    this.cameraStream?.getTracks().forEach((track) => track.stop());
    this.cameraStream = undefined;

    this.janService.disconnect(this.token);

    this.resizeObserver?.disconnect();
    this.resizeObserver = undefined;

    this.shutdown = true;

    this.closeRoom.emit();
    this.closeRoom.complete();
  }

  observeSize(): void {
    if (!this.roomVideos) {
      return;
    }
    const roomVideosContainer = this.roomVideos.nativeElement;
    // @ts-ignore
    this.resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        // your code
        const entry = entries[0];
        const width = entry.target.offsetWidth;
        const height = entry.target.offsetHeight;
        roomVideosContainer.setAttribute('style', `--videoroom-width: ${width}px; --videoroom-height: ${height}px;`);
        roomVideosContainer.classList.remove('videoroom-3-4', 'videoroom-1-2', 'videoroom-3-8', 'videoroom-1-4');
        if (height / width > 0.75) {
          roomVideosContainer.classList.add('videoroom-3-4');
        }
        if (height / width > 0.5) {
          roomVideosContainer.classList.add('videoroom-1-2');
        }
        if (height / width > 0.375) {
          roomVideosContainer.classList.add('videoroom-3-8');
        }
        if (height / width > 0.25) {
          roomVideosContainer.classList.add('videoroom-1-4');
        }
      });
    });

    this.resizeObserver.observe(roomVideosContainer);
  }

  toggleSide(panel: string): void {
    if (panel === 'chat') {
      this.newMessagesCount = 0;
    }
    this.sidePanel = this.sidePanel === panel ? null : panel;
  }

  closeSide(): void {
    this.sidePanel = null;
  }

  observeAttendees(attendees: JanPublisher[]): void {
    this.attendees = attendees;
  }

  observePublishers(session: JanSession, publishers: JanPublisher[]): void {
    const videoIds = this.publishers.map((p) => p.id);
    const newPublishers = publishers.filter((publisher) => !videoIds.includes(publisher.id));
    const oldPublishers = videoIds.filter((videoId) => !publishers.map((p) => p.id).includes(videoId));

    newPublishers.forEach((publisher) => {
      if (this.room) {
        this.publishers.push(publisher);

        this.janService
          .subscribeToStream(session, publisher, this.room, this.token, this.pin)
          .pipe(
            switchMap((pc) => {
              if (!pc) {
                return of(null);
              }
              pc.onconnectionstatechange = (event) => {
                if (pc.connectionState === 'failed') {
                  const errorMessage = $localize`WebRTC connection failed. Could not subscribe to ${publisher.display} feed.`;
                  this.toastr.error(errorMessage, null, { onActivateTick: true });
                }
              };

              let numberStreams = 0;
              if (publisher.video_codec) {
                numberStreams++;
              }
              if (publisher.audio_codec) {
                numberStreams++;
              }

              return fromEvent<RTCTrackEvent>(pc, 'track').pipe(take(numberStreams), toArray());
            }),
          )
          .subscribe((tracks) => {
            if (!this.roomVideos) {
              return;
            }
            if (tracks === null) {
              return;
            }

            publisher.tracks = tracks;

            const numberVideos = Array.from(this.roomVideos.nativeElement.childNodes).filter(
              (n) => n.nodeType === Node.ELEMENT_NODE,
            ).length;
            const spaceForNewVideo = numberVideos < this.maxVisibleVideos;

            // Add video
            let newVideoContainer: HTMLElement | undefined;

            if (spaceForNewVideo) {
              newVideoContainer = this.createVideo(publisher);
              this.roomVideos.nativeElement.appendChild(newVideoContainer);
              this.updateRoomVideosClass();
            }

            tracks.forEach((event: RTCTrackEvent) => {
              if (event.track.kind === 'audio') {
                const audio = new Audio();
                audio.autoplay = true;
                audio.srcObject = event.streams[0];

                audio.onloadedmetadata = () => {
                  if (audio.paused) {
                    this.audioPaused = true;
                  }
                };

                this.audio.push({
                  id: publisher.id,
                  audio,
                });
              }
              if (event.track.kind === 'video' && newVideoContainer) {
                const video = newVideoContainer.querySelector('video');
                if (video) {
                  video.autoplay = true;
                  video.muted = true;
                  video.playsInline = true;
                  video.srcObject = event.streams[0];
                }
              }
            });
          });
      }
    });

    oldPublishers.forEach((publisherId) => {
      this.publishers = this.publishers.filter((publisher) => publisher.id !== publisherId);

      // Unsubscribe from publisher

      const oldVideo = document.getElementById(`video-${publisherId}`);

      if (oldVideo?.classList.contains('full-screen')) {
        this.minimise();
      }

      oldVideo?.remove();

      this.fill();

      this.updateRoomVideosClass();
    });
  }

  private fill() {
    const roomVideos = this.roomVideos.nativeElement;

    const numberVideos = Array.from(roomVideos.childNodes).filter((n) => n.nodeType === Node.ELEMENT_NODE).length;
    const spaceForNewVideo = numberVideos < this.maxVisibleVideos;

    if (!spaceForNewVideo) {
      return;
    }

    const hiddenPubishers = this.publishers.filter((publisher) => {
      return document.getElementById(`video-${publisher.id}`) === null && publisher.tracks;
    });

    if (hiddenPubishers.length === 0) {
      return;
    }

    const publisher = hiddenPubishers[0];

    const newVideoContainer = this.createVideo(publisher);
    this.roomVideos.nativeElement.appendChild(newVideoContainer);
    this.updateRoomVideosClass();

    publisher.tracks.forEach((event: RTCTrackEvent) => {
      if (event.track.kind === 'video' && newVideoContainer) {
        const newVideo = newVideoContainer.querySelector('video');
        if (newVideo) {
          newVideo.srcObject = event.streams[0];
        }
      }
    });

    this.fill();
  }

  public startAudio() {
    this.audio.forEach((audio) => {
      audio.audio.play();
    });
    this.audioPaused = false;
  }

  private createShareScreenVideo() {
    const newVideoContainer = document.createElement('div');
    newVideoContainer.classList.add('video-container');
    newVideoContainer.id = `video-sharescreen`;

    const newVideo = document.createElement('video');
    newVideo.autoplay = true;

    const displayName = document.createElement('div');
    displayName.classList.add('display-name');
    displayName.innerText = 'My Screen';

    const maximiseButton = document.createElement('div');
    maximiseButton.classList.add('maximise');
    maximiseButton.onclick = (event) => {
      this.maximise(newVideoContainer.id);
    };

    const minimiseButton = document.createElement('div');
    minimiseButton.classList.add('minimise');
    minimiseButton.onclick = (event) => {
      this.minimise();
    };

    newVideoContainer.appendChild(newVideo);
    newVideoContainer.appendChild(displayName);
    newVideoContainer.appendChild(maximiseButton);
    newVideoContainer.appendChild(minimiseButton);

    return newVideoContainer;
  }

  private createVideoVideo() {
    const newVideoContainer = document.createElement('div');
    newVideoContainer.classList.add('video-container');
    newVideoContainer.id = `video-video`;

    const newVideo = document.createElement('video');
    newVideo.controls = true;

    const displayName = document.createElement('div');
    displayName.classList.add('display-name');
    displayName.innerText = 'My Video';

    const maximiseButton = document.createElement('div');
    maximiseButton.classList.add('maximise');
    maximiseButton.onclick = (event) => {
      this.maximise(newVideoContainer.id);
    };

    const minimiseButton = document.createElement('div');
    minimiseButton.classList.add('minimise');
    minimiseButton.onclick = (event) => {
      this.minimise();
    };

    newVideoContainer.appendChild(newVideo);
    newVideoContainer.appendChild(displayName);
    newVideoContainer.appendChild(maximiseButton);
    newVideoContainer.appendChild(minimiseButton);

    return newVideoContainer;
  }

  private createVideo(publisher: JanPublisher): HTMLDivElement {
    const newVideoContainer = document.createElement('div');
    newVideoContainer.classList.add('video-container');
    newVideoContainer.id = `video-${publisher.id}`;

    const newVideo = document.createElement('video');
    newVideo.autoplay = true;

    const displayName = document.createElement('div');
    displayName.classList.add('display-name');
    displayName.innerText = this.stripMeta(publisher.display || 'Anonymous');

    const maximiseButton = document.createElement('div');
    maximiseButton.classList.add('maximise');
    maximiseButton.onclick = (event) => {
      this.maximise(newVideoContainer.id);
    };

    const minimiseButton = document.createElement('div');
    minimiseButton.classList.add('minimise');
    minimiseButton.onclick = (event) => {
      this.minimise();
    };

    newVideoContainer.appendChild(newVideo);
    newVideoContainer.appendChild(displayName);
    newVideoContainer.appendChild(maximiseButton);
    newVideoContainer.appendChild(minimiseButton);

    return newVideoContainer;
  }

  private updateRoomVideosClass(): void {
    const n = this.roomVideos?.nativeElement.querySelectorAll('.video-container').length;
    if (this.roomVideos) {
      this.roomVideos.nativeElement.classList.remove(
        'room-videos-0',
        'room-videos-1',
        'room-videos-2',
        'room-videos-3',
        'room-videos-4',
        'room-videos-5',
        'room-videos-6',
        'room-videos-7',
        'room-videos-8',
        'room-videos-9',
      );
      this.roomVideos.nativeElement.classList.add(`room-videos-${n || 0}`);
    }
  }

  private startMediaStream(): Observable<MediaStream | null> {
    const constraints = { audio: true, video: true };
    const fallbackConstraints = { audio: true, video: false };

    return from(navigator.mediaDevices.getUserMedia(constraints)).pipe(
      catchError(() => {
        return navigator.mediaDevices.getUserMedia(fallbackConstraints);
      }),
      catchError((error) => {
        this.toastr.error($localize`Could not publish your webcam or microphone feed. ${error.message}`);
        return of(null);
      }),
    );
  }

  public shareScreen() {
    if (this.shareStream) {
      this.stopShareScreen();
      return;
    }

    const constraints = { audio: false, video: true };

    // Get stream
    (navigator.mediaDevices as any)
      .getDisplayMedia(constraints)
      .then((stream) => {
        this.shareStream = stream;
        this.shareStream.getVideoTracks()[0].onended = () => {
          this.stopShareScreen();
        };

        this.showShareScreen(stream);
        this.publishSharedScreen(stream);
      })
      .catch((error) => {
        if (error.name !== 'NotAllowedError') {
          console.error(error);
        }
        this.toastr.error(error.message);
      });
  }

  public presentVideo() {
    if (this.videoStream) {
      this.stopPublishingVideo();
      return;
    }

    const inputs = {
      title: $localize`Choose Video Post`,
      message: $localize`Hardware acceleration needs to be turned off to be able to present video posts <div style="margin-top: 8px;padding:53.59% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/542564635?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="New Recording - 4/28/2021, 10:13:45 AM"></iframe></div>`,
      yesButtonText: $localize`I have hardware acceleration turned off`,
      noButtonText: $localize`:@@cancel:Cancel`,
    };
    const outputs = {
      completeHandler: (yes: boolean) => {
        if (yes) {
          this.selectVideo();
        }
      },
    };
    this.modal.init(ModalConfirmComponent, inputs, outputs);
  }

  private selectVideo() {
    const timelineUrl = configUrl.getTimeline;
    const params = {
      cliptype: 'Video',
      offset: '0',
      limit: '100',
    };

    const url = configUrl.clipMy;

    forkJoin({
      timeline: this.http.get<any[]>(timelineUrl, { params }),
      myVideos: this.http.get<any[]>(url).pipe(map((posts) => posts.filter((post) => post.videoid))),
    }).subscribe(({ timeline, myVideos }) => {
      const inputs = {
        actionTitle: $localize`Present`,
        modalTitle: $localize`Choose Video Post`,
        timeline,
        myVideos,
      };
      const outputs = {
        actionCallback: (post) => {
          const src = `/video/${post.videoid}`;
          // const src = `/assets/sample.mp4`;
          this.showVideo(src, (stream) => {
            this.videoStream = stream;
            this.publishVideo(stream);
          });
        },
      };
      this.modal.init(ModalPostListComponent, inputs, outputs);
    });
  }

  private publishVideo(stream: MediaStream) {
    const userId = this.userId ? this.userId : uuid();
    const userType = this.userId ? '2' : '1';
    const pubId = `${userId}:3:${userType}:${this.randomPart()}`;

    this.janService.publishVideoStream(stream, this.token, pubId, this.room, this.displayName, this.pin).subscribe(
      (response) => {
        this.videoPublisherId = response.publisherId;
        this.toastr.success($localize`You are sharing your video`);
        this.updateRoomVideosClass();
      },
      (err) => {
        this.toastr.error($localize`Sorry. You are at the maximum number of screens.`);
        this.stopPublishingVideo();
      },
    );
  }

  private stopPublishingVideo() {
    if (!this.videoStream) {
      return;
    }

    this.videoStream?.getTracks().forEach((track) => track.stop());
    this.videoStream = undefined;

    const oldVideo = document.getElementById('video-video');
    if (oldVideo?.classList.contains('full-screen')) {
      this.minimise();
    }
    oldVideo?.remove();
    this.fill();
    this.updateRoomVideosClass();

    this.janService.unpublishVideo(this.token);

    this.toastr.success($localize`You have stopped sharing your post`);

    this.app.tick();
  }

  private publishSharedScreen(stream: MediaStream) {
    const userId = this.userId ? this.userId : uuid();
    const userType = this.userId ? '2' : '1';
    const pubId = `${userId}:2:${userType}:${this.randomPart()}`;

    this.janService.publishScreenStream(stream, this.token, pubId, this.room, this.displayName, this.pin).subscribe(
      (response) => {
        this.screenPublisherId = response.publisherId;
        this.toastr.success($localize`You are sharing your screen`);
        this.updateRoomVideosClass();
      },
      (err) => {
        this.toastr.error($localize`Sorry. You are at the maximum number of screens.`);
        this.stopShareScreen();
      },
    );
  }

  private stopShareScreen() {
    if (!this.shareStream) {
      return;
    }

    this.shareStream?.getTracks().forEach((track) => track.stop());
    this.shareStream = undefined;

    const oldVideo = document.getElementById('video-sharescreen');
    if (oldVideo?.classList.contains('full-screen')) {
      this.minimise();
    }
    oldVideo?.remove();
    this.fill();
    this.updateRoomVideosClass();

    this.janService.unpublishScreen(this.token);

    this.toastr.success($localize`You have stopped sharing your screen`);

    this.app.tick();
  }

  public toggleAudioMute(): void {
    if (!this.cameraStream) {
      return;
    }

    this.muteAudio(!this.audioMuted);
    this.audioMuted = !this.audioMuted;
  }

  public toggleVideoMute(): void {
    if (!this.cameraStream) {
      return;
    }

    this.muteVideo(!this.videoMuted);
    this.videoMuted = !this.videoMuted;
  }

  private muteAudio(mute: boolean = true) {
    if (!this.cameraStream) {
      return;
    }

    // this.janService.sendCamera({ request: 'configure', audio: !mute }, this.token);
    this.cameraStream.getAudioTracks().forEach((track) => (track.enabled = !mute));
  }

  private muteVideo(mute: boolean = true) {
    if (!this.cameraStream) {
      return;
    }

    // this.janService.sendCamera({ request: 'configure', video: !mute }, this.token);
    this.cameraStream.getVideoTracks().forEach((track) => (track.enabled = !mute));
  }

  private showShareScreen(stream) {
    if (!this.roomVideos) {
      return;
    }

    const roomVideos = this.roomVideos.nativeElement;
    const numberVideos = Array.from(roomVideos.childNodes).filter((n) => n.nodeType === Node.ELEMENT_NODE).length;
    const spaceForNewVideo = numberVideos < this.maxVisibleVideos;

    this.minimise();

    if (!spaceForNewVideo) {
      if (!roomVideos.lastElementChild) {
        throw new Error('Element not found');
      }
      roomVideos.removeChild(roomVideos.lastElementChild);
    }

    const newVideoContainer = this.createShareScreenVideo();
    if (this.cameraVideo) {
      this.insertAfter(newVideoContainer, this.cameraVideo.nativeElement);
    } else {
      roomVideos.insertBefore(newVideoContainer, roomVideos.firstElementChild);
    }
    this.updateRoomVideosClass();

    const newVideo = newVideoContainer.querySelector('video');
    if (newVideo) {
      newVideo.srcObject = stream;
    }
  }

  private showVideo(src: string, videoLoaded: (stream: MediaStream) => void) {
    if (!this.roomVideos) {
      return;
    }

    const roomVideos = this.roomVideos.nativeElement;
    const numberVideos = Array.from(roomVideos.childNodes).filter((n) => n.nodeType === Node.ELEMENT_NODE).length;
    const spaceForNewVideo = numberVideos < this.maxVisibleVideos;

    this.minimise();

    if (!spaceForNewVideo) {
      if (!roomVideos.lastElementChild) {
        throw new Error('Element not found');
      }
      roomVideos.removeChild(roomVideos.lastElementChild);
    }

    const newVideoContainer = this.createVideoVideo();
    if (this.cameraVideo) {
      this.insertAfter(newVideoContainer, this.cameraVideo.nativeElement);
    } else {
      roomVideos.insertBefore(newVideoContainer, roomVideos.firstElementChild);
    }
    this.updateRoomVideosClass();

    const newVideo = newVideoContainer.querySelector('video');
    if (newVideo) {
      newVideo.onloadeddata = (event) => {
        const stream = (newVideo as any).captureStream
          ? (newVideo as any).captureStream()
          : (newVideo as any).mozCaptureStream();
        videoLoaded?.(stream);
      };
      newVideo.src = src;
    }
  }

  public showUser(user: JanPublisher): void {
    if (!this.roomVideos) {
      return;
    }

    this.minimise();

    const roomVideos = this.roomVideos.nativeElement;
    const numberVideos = Array.from(roomVideos.childNodes).filter((n) => n.nodeType === Node.ELEMENT_NODE).length;
    const spaceForNewVideo = numberVideos < this.maxVisibleVideos;

    const video = document.getElementById(`video-${user.id}`);

    if (video) {
      if (this.cameraVideo) {
        this.insertAfter(video, this.cameraVideo.nativeElement);
      } else {
        roomVideos.insertBefore(video, roomVideos.firstElementChild);
      }
    } else {
      if (!spaceForNewVideo) {
        if (!roomVideos.lastElementChild) {
          throw new Error('Element not found');
        }
        roomVideos.removeChild(roomVideos.lastElementChild);
      }

      const newVideoContainer = this.createVideo(user);
      if (this.cameraVideo) {
        this.insertAfter(newVideoContainer, this.cameraVideo.nativeElement);
      } else {
        roomVideos.insertBefore(newVideoContainer, roomVideos.firstElementChild);
      }
      this.updateRoomVideosClass();

      user.tracks.forEach((event: RTCTrackEvent) => {
        if (event.track.kind === 'video' && newVideoContainer) {
          const newVideo = newVideoContainer.querySelector('video');
          if (newVideo) {
            newVideo.srcObject = event.streams[0];
          }
        }
      });
    }
  }

  private insertAfter(newNode: HTMLElement, referenceNode: HTMLElement): void {
    referenceNode.parentNode?.insertBefore(newNode, referenceNode.nextSibling);
  }

  public maximise(videoId: string) {
    this.roomVideos.nativeElement.classList.add('full-screen-mode');
    const videoContainers = document.querySelectorAll('.video-container');

    for (let i = 0; i < videoContainers.length; ++i) {
      videoContainers[i].classList.remove('full-screen');

      if (videoContainers[i].id === videoId) {
        videoContainers[i].classList.add('full-screen');
      }
    }
  }

  public minimise() {
    this.roomVideos.nativeElement.classList.remove('full-screen-mode');
    const videoContainers = document.querySelectorAll('.video-container');

    for (let i = 0; i < videoContainers.length; ++i) {
      videoContainers[i].classList.remove('full-screen');
    }
  }

  private observeChat() {
    this.afDatabase
      .list<any>(`/videochat/${this.room}`)
      .snapshotChanges()
      .pipe(
        map((chats) => {
          return chats.map((item) => ({
            ...item.payload.val(),
            id: item.payload.key,
          }));
        }),
      )
      .subscribe((chats) => (this.chats = chats));
    this.afDatabase
      .list<any>(`/videochat/${this.room}`)
      .stateChanges(['child_added'])
      .subscribe(() => {
        if (this.sidePanel !== 'chat') {
          this.newMessagesCount++;
        }
      });
  }

  public addChat(message: string) {
    const key = this.afDatabase.createPushId();
    this.afDatabase.object(`/videochat/${this.room}/${key}`).set({
      created: firebase.database.ServerValue.TIMESTAMP,
      displayName: this.displayName,
      userId: this.userId,
      status: this.allowChatModeration ? 'pending' : 'approved',
      message,
    });
  }

  public approveChat(chat: any) {
    this.moderateChat(chat, 'approved');
  }

  public rejectChat(chat: any) {
    this.moderateChat(chat, 'rejected');
  }

  private moderateChat(chat: any, status: string) {
    const key = chat.id;
    this.afDatabase.object(`/videochat/${this.room}/${key}`).update({ status });
  }

  public async addPoll() {
    const polls = await this.pollsService.getPolls().toPromise();

    const inputs = {
      actionTitle: $localize`Choose`,
      modalTitle: $localize`:@@addPoll:Add Poll`,
      polls,
    };
    const outputs = {
      actionCallback: (poll) => {
        this.pollsService.addPollToRoom(poll.id, this.room).subscribe(() => {
          this.toastr.success($localize`Poll added to room`);
          this.loadPolls();
        });
      },
    };
    this.modal.init(ModalPollsListComponent, inputs, outputs);
  }

  async removePoll(poll) {
    const confirmed = await this.confirmRemove();
    if (!confirmed) {
      return;
    }

    this.pollsService.removePollFromRoom(poll.id, this.room).subscribe(() => {
      this.toastr.success($localize`Poll removed from room`);
      this.loadPolls();
    });
  }

  private stripMeta(value: string): string {
    return value.replace(/^\[.*\]/, '');
  }

  private randomPart() {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 10; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  confirmRemove(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const inputs = {
        title: $localize`Remove Poll`,
        message: $localize`Are you sure you want to remove this poll from this stage?`,
        yesButtonText: $localize`Yes`,
        noButtonText: $localize`No`,
      };
      const outputs = {
        completeHandler: (yes: boolean) => {
          resolve(yes);
        },
      };
      this.modal.init(ModalConfirmComponent, inputs, outputs);
    });
  }
}
