import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { configUrl } from '@env/environment';

@Injectable()
export class GreenroomResolver implements Resolve<any> {
  constructor(private http: HttpClient) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): Observable<any> {
    return this.getData('10');
  }

  getData(id: string): Observable<any> {
    const url = `${configUrl.livevideoStage}${id}`;
    return this.http.get(url);
  }
}
