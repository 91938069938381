import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

declare var videojs: any;

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class VideoComponent implements OnInit {
  @ViewChild('target', { static: true }) target: ElementRef<HTMLVideoElement>;
  @Input() options: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    sources: {
      src: string;
      type: string;
    }[];
  };
  @Output() firstPlayed = new EventEmitter<boolean>();
  played = false;
  player: any;

  constructor() {}

  ngOnInit() {
    videojs.addLanguage('en', {
      'The media could not be loaded, either because the server or network failed or because the format is not supported.':
        'Converting video...',
    });
    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {});
    this.target.nativeElement.onplay = () => {
      if (!this.played) {
        this.firstPlayed.emit(true);
        this.played = true;
      }
    };
  }

  onError(event: Event) {
    const video: HTMLVideoElement = this.target.nativeElement;
    const error = video.error;

    if (error.code !== MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
      return;
    }

    setTimeout(() => this.reloadVideo(), 5000);
  }

  reloadVideo() {
    const video: HTMLVideoElement = this.target.nativeElement;
    video.load();
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
