import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { UserService, AuthService } from '@app/core/services';
import { configUrl } from '@env/environment';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { hide, show } from '@app/nav.actions';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss'],
})
export class RestorePasswordComponent implements OnInit, OnDestroy {
  nav$: Observable<any>;

  configUrl = configUrl;
  email: any = '';
  subdomain: string;

  logo = 'assets/images/logo.png';

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router,
    private store: Store<any>,
  ) {
    this.nav$ = store.select('nav');

    this.authService.user$.subscribe((auth) => {
      const logo = auth.companySettings.logo;
      this.logo = logo ? configUrl.bucketURL + 'logo/' + logo : 'assets/images/logo.png';
    });
  }

  ngOnInit() {
    this.subdomain = this.authService.getSubdomain();
    setTimeout(() => {
      this.store.dispatch(hide());
    }, 0);
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.store.dispatch(show());
    }, 0);
  }

  resetPassword() {
    this.userService.resetPassword(this.email, (error) => {
      if (!error) {
        this.toastr.info(
          $localize`Please visit your email inbox and follow instructions`,
          $localize`Reset password email sent`,
        );
        this.router.navigate(['/sign-in']);
      } else {
        this.toastr.error($localize`There was a problem reseting your password`);
      }
    });
  }

  cancel() {
    this.router.navigate(['/sign-in']);
  }
}
