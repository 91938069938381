<div class="layout-middle-col live-stage-middle-col">
  <div class="stage-container">
    <div class="stage-head">
      <div class="stage-head-heading">
        <ul class="nav nav-pills">
          <li class="nav-item" *ngFor="let s of [].constructor(authService.companySettings?.stages); let i = index">
            <a class="nav-link" [class.active]="i + 1 == stageNumber" [routerLink]="['/combined/stage', i + 1]"
              ><span i18n>Stage </span>{{ i + 1 }}</a
            >
          </li>
          <li class="nav-item" *ngIf="authService.companySettings?.greenroom">
            <a class="nav-link" [routerLink]="['/combined/greenroom']" i1="@@greenRoom">Green Room</a>
          </li>
        </ul>
      </div>
      <div class="stage-head-actions">
        <span class="watchers" *ngIf="viewersCount"> <mdi name="Viewers"></mdi>&nbsp;{{ viewersCount }} </span>
        <a
          *ngIf="primaryAction"
          class="btn btn-outline-light"
          [class.disabled]="!canJoin || !isConnected"
          (click)="join()"
          >{{ primaryAction.name }}</a
        >
        <a
          *ngIf="!authService.canPresentOnLiveStage && this.polls?.length"
          class="btn btn-outline-light"
          (click)="togglePolls()"
          style="margin-left: 5px"
          i18n="@@polls"
          >Polls</a
        >
        <a
          *ngIf="!authService.canPresentOnLiveStage && !authService.companySettings.disablestagechat"
          class="btn btn-outline-light"
          (click)="toggleChat()"
          style="margin-left: 5px"
          i18n="@@chat"
          >Chat</a
        >
      </div>
    </div>

    <div class="stage">
      <app-jan-room
        *ngIf="roomVisible"
        [url]="janusServerUrl"
        [iceServers]="iceServers"
        [displayName]="displayName"
        [userId]="userId"
        [pin]="stage.pin"
        [secret]="stage.secret"
        [token]="stage.token"
        [room]="stage.id"
        [publish]="roomJoined"
        [showToolbar]="roomJoined || authService.canPresentOnLiveStage"
        [showAllAttendees]="true"
        [canModerate]="authService.canPresentOnLiveStage"
        [allowChatModeration]="true"
        [actions]="actions"
        [showChat]="chatVisible"
        [showPolls]="pollsVisible"
        emptyMessage="This stage is currently empty"
        (connected)="connected()"
        (leaveRoom)="leftRoom()"
      ></app-jan-room>
    </div>
  </div>
</div>
