<div *ngIf="ready" class="sales-container" [class.embedded]="embed">
  <div class="header">
    <div style="max-width: 280px">
      <app-company-selector [companySelector]="false"></app-company-selector>
    </div>
    <div style="padding-top: 5px">
      <app-app-bar-user *ngIf="authService.activeUser?.uid" [dropUp]="false"></app-app-bar-user>
      <!-- <a
        *ngIf="!authService.activeUser?.uid"
        class="btn btn-md btn-primary d-none d-sm-inline-block"
        style="margin-top: 5px"
        [href]="config.websiteUrl"
        target="_blank"
        i18n
        >Get {{ config.title }} Free</a
      >
      <a
        *ngIf="!authService.activeUser?.uid"
        class="btn btn-md btn-primary d-inline-block d-sm-none"
        style="margin-top: 5px"
        [href]="config.websiteUrl"
        target="_blank"
        i18n
        >Get&nbsp;Free</a
      > -->
    </div>
  </div>

  <div class="feed" *ngIf="clip">
    <div *ngIf="title" class="sales-title">
      <h1>{{ clip.title }}</h1>
    </div>

    <div *ngIf="content" class="sales-content">
      <div *ngIf="clip.cliptype === 'Audio'">
        <app-play-audio-button [clip]="clip"></app-play-audio-button>
      </div>

      <div *ngIf="clip.cliptype === 'Video'">
        <app-responsive-video
          [videoUrl]="configUrl.awsUrl + clip.videoid"
          [thumbnailUrl]="configUrl.thumbnailFS + clip.thumbnail"
        ></app-responsive-video>
      </div>
    </div>

    <div *ngIf="clip.link && link" class="sales-link">
      <a [href]="clip.link" target="_blank">{{ clip.link }}</a>
    </div>

    <div *ngIf="profile" class="sales-profile">
      <div class="tile-profile-image">
        <app-user-image [userId]="clip.authorid" [imageId]="clip.imageid"></app-user-image>
        <div *ngIf="presence?.presence === true" class="presence"></div>
        <div *ngIf="presence?.presence && presence?.presence !== true" class="presence presence-text">
          {{ presence.presence }}
        </div>
      </div>
      <div>{{ clip.authorname }}</div>
    </div>

    <div *ngIf="call || booking" class="buttons">
      <!-- <button (click)="reply()" class="btn btn-lg btn-outline-primary">Comment</button> -->
      <button *ngIf="booking" (click)="makeBooking()" class="btn btn-lg btn-primary" i18n>Book</button>
      <button *ngIf="call && presence?.status === 'online'" (click)="makeCall()" class="btn btn-lg btn-primary" i18n>
        Video Call
      </button>
      <button
        *ngIf="call && presence?.status !== 'online'"
        (click)="makeCall()"
        class="btn btn-lg btn-secondary"
        disabled
        i18n
      >
        Video Call
      </button>
    </div>
    <p *ngIf="call && presence?.status !== 'online'" class="offline" i18n>
      {{ this.clip.authorname }} is currently unavailable
    </p>
  </div>
</div>
