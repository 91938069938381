<div class="tile">
  <div class="tile-profile-image" *ngIf="userId">
    <app-user-image [userId]="userId" [imageId]="imageId"></app-user-image>
  </div>
  <div class="tile-icon" *ngIf="icon">
    <mdi [name]="icon" size="56"></mdi>
  </div>
  <div class="tile-body">
    <div class="tile-title">{{ maintitle }}</div>
    <div class="tile-subtitle">{{ subtitle }}</div>
  </div>
  <div class="tile-actions" *ngIf="actions.length">
    <a
      *ngFor="let action of actions"
      class="btn"
      [class.btn-primary]="action.primaryAction"
      [class.btn-outline-primary]="!action.primaryAction"
      (click)="actionClick($event, action)"
    >
      {{ action.name }}
    </a>
  </div>
</div>
