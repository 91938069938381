import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@app/core/services';
import { JanPublisher } from '@app/shared/model';

@Component({
  selector: 'app-jan-participants',
  templateUrl: './jan-participants.component.html',
  styleUrls: ['./jan-participants.component.scss'],
})
export class JanParticipantsComponent implements OnInit {
  @Input() displayName: string | undefined;
  @Input() participants: JanPublisher[] | undefined;
  @Input() attendees: JanPublisher[] | undefined;
  @Input() showAddUser: boolean | undefined;
  @Input() isPublishing = false;

  @Output() showUser = new EventEmitter<JanPublisher>();
  @Output() closePanel = new EventEmitter<null>();
  @Output() addUser = new EventEmitter<null>();

  constructor(public authService: AuthService) {}

  ngOnInit(): void {}

  showParticipant(participant: JanPublisher): void {
    if (!participant.tracks) {
      return;
    }
    this.showUser.emit(participant);
  }
}
