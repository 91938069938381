<div class="poll">
  <div class="title-container">
    <div class="title">{{ poll.title }}</div>
    <a *ngIf="allowRemovePoll" (click)="remove()" href="javascript:void(0)" i18n="@@remove">Remove</a>
  </div>

  <div *ngIf="!voted" class="options">
    <div class="option" *ngFor="let option of poll.options" (click)="vote(option)">{{ option.title }}</div>
  </div>

  <div *ngIf="voted" class="results">
    <div
      class="option"
      [class.active]="option.id === voted"
      *ngFor="let option of poll.options"
      style="cursor: default"
    >
      <div class="option-bar" [style.width.%]="getPercentage(option.id, false)"></div>
      <div class="option-title">
        <div class="option-title-title">{{ option.title }}</div>
        <div class="option-title-result">{{ getPercentage(option.id) }}%</div>
      </div>
    </div>
  </div>

  <div class="total" i18n>{{ getResultTotal() }} votes</div>
</div>
