import { Component, Output } from '@angular/core';

import { UiModalService } from '@app/core/services';
import { configUrl } from '@env/environment';

@Component({
  templateUrl: './modal-live-room-user-info.component.html',
  styleUrls: ['./modal-live-room-user-info.component.scss'],
})
export class ModalLiveRoomUserInfoComponent {
  @Output() completeHandler: (any) => void;

  configUrl = configUrl;

  displayName: string;

  constructor(private modal: UiModalService) {}

  submitAction() {
    if (!this.displayName) {
      return;
    }

    this.modal.destroy(true);

    this.completeHandler?.({
      displayName: this.displayName,
    });
  }
}
