import { Component, Input, Output, OnInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

import { CategoryService, ClipService, QueryService, UiModalService } from '@app/core/services';
import { Clip } from '@app/shared/model';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { configUrl } from '@env/environment';

import * as CryptoJS from 'crypto-js';

class ImageSnippet {
  constructor(
    public src: string,
    public file: File,
    public imageCheckSum: string,
  ) {}
}

@Component({
  templateUrl: './modal-post-edit.component.html',
  styleUrls: ['./modal-post-edit.component.scss'],
})
export class ModalPostEditComponent implements OnInit {
  saving = false;
  categoriesLoaded = false;
  categories: any[] = null;

  thumbnail: ImageSnippet;
  originalUrl: any;

  @Input() clip: Clip;
  @Output() completeHandler: (clip: Clip) => void;

  public editForm: UntypedFormGroup;

  constructor(
    private modalService: UiModalService,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private clipService: ClipService,
    private categoryService: CategoryService,
    private cd: ChangeDetectorRef,
    private qs: QueryService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    if (this.clip.image) {
      this.qs.getAudioImage(this.clip.image, (getAudioImageObserv) => {
        getAudioImageObserv.subscribe((response) => {
          if (response) {
            const data = new Uint8Array(response);
            const blob = new Blob([data], { type: 'image/png' });
            const blobURL = URL.createObjectURL(blob);
            this.originalUrl = this.sanitizer.bypassSecurityTrustUrl(blobURL);
          }
        });
      });
      this.originalUrl = `${configUrl.audioImageFS}${this.clip.image}`;
    }

    this.editForm = this.createFormGroup();
    this.loadCategories();
  }

  createFormGroup() {
    return this.formBuilder.group({
      title: [this.clip.title, [Validators.required]],
      category: [this.clip.categoryid],
      link: [this.clip.link, [Validators.pattern(/^(ftp|http|https):\/\/[^ "]+$/)]],
    });
  }

  public submitForm(form: NgForm) {
    if (form.invalid) {
      this.toastr.error($localize`There was a problem`);
      return;
    }

    this.updateClip();
  }

  private updateClip() {
    this.saving = true;
    const body: any = {
      id: this.clip.id,
      title: this.editForm.get('title').value,
      categoryid: this.editForm.get('category').value,
      link: this.editForm.get('link').value || '',
    };

    if (this.thumbnail) {
      body.imageUrlArray = [
        {
          imagechecksum: this.thumbnail.imageCheckSum,
          file: this.thumbnail.file,
          name: 'attachment.jpeg',
        },
      ];
    } else if (!this.originalUrl && this.clip.image) {
      body.deleteimage = 'true';
    }

    this.clipService.updateClip(body).subscribe(
      (clip: Clip) => {
        this.toastr.success($localize`Post successfully updated`);
        this.completeHandler(clip);
        this.modalService.destroy();
      },
      (error) => {
        this.toastr.error($localize`Updating post failed`);
        this.modalService.destroy();
      },
    );
  }

  closeAction() {
    this.modalService.destroy();
  }

  private loadCategories() {
    this.categoryService
      .getCategory()
      .pipe(map((categories) => this.categoryService.selectList(categories)))
      .subscribe((categories) => {
        this.categoriesLoaded = true;
        this.categories = categories;
      });
  }

  processFile(podcastThumbnailInput: any) {
    const file: File = podcastThumbnailInput.files[0];
    const reader = new FileReader();
    const image = document.createElement('img');

    reader.addEventListener('load', (event: any) => {
      image.onload = (e) => {
        const reader2 = new FileReader();

        reader2.onloadend = () => {
          const wordArray = CryptoJS.lib.WordArray.create(reader2.result);
          const imageCheckSum = CryptoJS.MD5(wordArray).toString();

          this.thumbnail = new ImageSnippet(event.target.result, file, imageCheckSum);
          this.cd.detectChanges();
        };

        reader2.readAsArrayBuffer(file);
      };
      image.src = event.target.result;
    });

    reader.readAsDataURL(file);
  }

  removeOriginalImage() {
    this.originalUrl = null;
    this.cd.detectChanges();
  }

  removeThumbnail() {
    this.thumbnail = null;
    this.cd.detectChanges();
  }
}
