import { Component, Input, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

import { UiModalService } from '@app/core/services';

@Component({
  templateUrl: './modal-url.component.html',
})
export class ModalUrlComponent {
  @Input() url: string;
  @Input() title: string;
  @Input() label = $localize`URL`;

  constructor(
    private modalService: UiModalService,
    private toastr: ToastrService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  closeAction() {
    this.modalService.destroy();
  }

  copyAction(urlElement) {
    this.copyToClipboard(urlElement);
  }

  copyToClipboard(element) {
    element.select();
    this.document.execCommand('copy');
    this.toastr.info($localize`${this.label} copied to clipboard`);
  }
}
