import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { configUrl } from '@env/environment';
import { map, share } from 'rxjs/operators';

@Injectable()
export class ChatService {
  configUrl: any = configUrl;

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router,
  ) {}

  getMyChats(userId: string, params?: { [param: string]: string }, fullResponse: boolean = false): Observable<any> {
    const options: any = { params };
    const mapBody = (chats) =>
      chats.map((chat) => {
        if (chat.userid1 === userId) {
          chat.username = chat.username2;
          chat.details = {
            id: chat.userid2,
            username: chat.username2,
            userimageid: chat.userimageid2,
          };
        } else {
          chat.username = chat.username1;
          chat.details = {
            id: chat.userid1,
            username: chat.username1,
            userimageid: chat.userimageid1,
          };
        }

        return chat;
      });

    if (fullResponse) {
      options.observe = 'response';
      options.responseType = 'json';

      return this.http.get(this.configUrl.chatMy, options).pipe(
        map((response: any) => {
          response.body = mapBody(response.body);
          return response;
        }),
      );
    }

    return this.http.get<any[]>(this.configUrl.chatMy, options).pipe(map((chats) => mapBody(chats)));
  }

  newRoom(userId1: string, userId2: string): Observable<any> {
    const formData = {
      userid1: userId1,
      userid2: userId2,
    };

    return this.http.post(this.configUrl.chatUrl, formData);
  }

  navigateToChat(userId1: string, userId2: string, username: string): Observable<any> {
    const newRoom$ = this.newRoom(userId1, userId2).pipe(share());

    newRoom$.subscribe(
      (response) => {
        this.goToRoom(response, username);
      },
      (_) => {
        this.toastr.error($localize`Unable to chat person`);
      },
    );

    return newRoom$;
  }

  private goToRoom(response: any, username: string) {
    this.router.navigate(['/combined/chat', response.id], { queryParams: { openChat: false } });
  }
}
