<form #inviteForm="ngForm" autocomplete="off">
  <div class="ui-modal-header">
    <h2>{{ modalTitle }}</h2>
    <div class="ui-modal-close" (click)="dismiss()">
      <mdi name="Close"></mdi>
    </div>
  </div>

  <div class="ui-modal-body">
    <div
      class="members-list posts-list"
      [ngClass]="{ 'scroll-at-top': listTopShadow, 'scroll-at-bottom': listBottomShadow }"
    >
      <ul cdkScrollable #usersList>
        <li *ngFor="let language of languages | keyvalue" style="padding: 0 70px 0 0">
          <div class="members-list-name" style="line-height: 36px">
            {{ language.value }}
          </div>
          <a (click)="performAction(language.key)" class="action">{{ actionTitle }}</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="ui-modal-footer"></div>
</form>
