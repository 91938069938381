import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { configUrl } from '@env/environment';

@Injectable()
export class SignupBusinessService {
  constructor(private http: HttpClient) {}

  checkCompanyUrl(subdomain: string): Observable<any> {
    const body = { subdomain };
    return this.http.post(configUrl.companySubdomainUrl, body);
  }

  postCompanyUrl(name: string, subdomain: string): Observable<any> {
    const body = { name, subdomain };
    return this.http.post(configUrl.companyUrl, body);
  }

  putCompany(id: string, name: string): Observable<any> {
    const body = { id, name };
    return this.http.put(configUrl.companyUrl, body);
  }
}
