import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';

import { configUrl } from '@env/environment';

interface UploadUrl {
  filename: string;
  uploadurl: string;
}

@Injectable({
  providedIn: 'root',
})
export class AwsService {
  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
  ) {}

  getVideoUrl(): Observable<UploadUrl> {
    return this.http.get<UploadUrl>(configUrl.videoUploadUrl);
  }

  putAudio(audio: Blob, uploadUrl: string, filename: string): Observable<any> {
    // Use different http client so http intercepters are bypassed
    const http = new HttpClient(this.httpBackend);
    const mimeType = 'audio/mpeg';

    return http.put(uploadUrl, audio, {
      // reportProgress: true,
      // observe: 'events',
      headers: {
        'Content-Type': mimeType,
        'x-amz-acl': 'public-read',
      },
      params: {
        clientFilename: filename,
        mimeType,
      },
    });
  }

  putVideo(
    video: Blob,
    uploadUrl: string,
    filename: string,
    mimeType = 'video/webm',
    reportProgress = false,
  ): Observable<any> {
    // Use different http client so http intercepters are bypassed
    const http = new HttpClient(this.httpBackend);

    const data: any = {
      headers: {
        'Content-Type': mimeType,
        'x-amz-acl': 'public-read',
      },
      params: {
        clientFilename: filename,
        mimeType,
      },
    };

    if (reportProgress) {
      data.reportProgress = true;
      data.observe = 'events';
    }

    return http.put(uploadUrl, video, data);
  }

  putImage(image: Blob, uploadUrl: string, filename: string): Observable<any> {
    // Use different http client so http intercepters are bypassed
    const http = new HttpClient(this.httpBackend);
    const mimeType = 'image/png';

    return http.put(uploadUrl, image, {
      // reportProgress: true,
      // observe: 'events',
      headers: {
        'Content-Type': mimeType,
        'x-amz-acl': 'public-read',
      },
      params: {
        clientFilename: filename,
        mimeType,
      },
    });
  }
}
