<div class="ui-modal-header">
  <h2>{{ title }}</h2>
  <div class="ui-modal-close" (click)="cancelAction()">
    <mdi name="Close"></mdi>
  </div>
</div>

<div class="ui-modal-body">
  <div class="mb-3">
    <label i18n>From</label>
    <div class="row">
      <div class="col">
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="fromDate"
            [(ngModel)]="fromDate"
            ngbDatepicker
            #from="ngbDatepicker"
            container="body"
          />
          <button class="btn btn-outline-secondary calendar" (click)="from.toggle()" type="button">
            <mdi name="Calendar" size="20"></mdi>
          </button>
        </div>
      </div>
      <div class="col-4">
        <app-timepicker [(ngModel)]="fromTime"></app-timepicker>
      </div>
    </div>
  </div>
  <div class="mb-3">
    <label i18n>To</label>
    <div class="row">
      <div class="col">
        <div class="input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="toDate"
            [(ngModel)]="toDate"
            ngbDatepicker
            #to="ngbDatepicker"
            container="body"
          />
          <button class="btn btn-outline-secondary calendar" (click)="to.toggle()" type="button">
            <mdi name="Calendar" size="20"></mdi>
          </button>
        </div>
      </div>
      <div class="col-4">
        <app-timepicker [(ngModel)]="toTime"></app-timepicker>
      </div>
    </div>
  </div>
</div>

<div class="ui-modal-footer">
  <button class="btn btn-md btn-light" (click)="cancelAction()" i18n="@@cancel">Cancel</button>
  <button class="btn btn-md btn-primary" (click)="applyAction()" i18n>Apply</button>
</div>
