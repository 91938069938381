import { createReducer, on } from '@ngrx/store';
import { show, hide } from './nav.actions';

export const initialState = {
  visible: true,
};

const _navReducer = createReducer(
  initialState,
  on(show, (state) => ({
    ...state,
    visible: true,
  })),
  on(hide, (state) => ({
    ...state,
    visible: false,
  })),
);

export function navReducer(state, action) {
  return _navReducer(state, action);
}
