import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from '@app/core/services';

@Component({
  selector: 'app-jan-chat',
  templateUrl: './jan-chat.component.html',
  styleUrls: ['./jan-chat.component.scss'],
})
export class JanChatComponent implements OnInit {
  @Input() chats: any;
  @Input() canModerate: boolean = false;
  @Input() allowChatModeration = false;
  @Output() closePanel = new EventEmitter<null>();
  @Output() addChatMessage = new EventEmitter<string>();
  @Output() approveChatMessage = new EventEmitter<string>();
  @Output() rejectChatMessage = new EventEmitter<string>();

  @ViewChild('messages') messages: ElementRef;

  message: string;

  constructor(public authService: AuthService) {}

  ngOnInit(): void {}

  addChat() {
    if (!this.message) {
      return;
    }

    this.messages.nativeElement.scrollTo(0, 0);
    this.addChatMessage.emit(this.message);
    this.message = undefined;
  }

  approveChat(chat: any) {
    this.approveChatMessage.emit(chat);
  }

  rejectChat(chat: any) {
    this.rejectChatMessage.emit(chat);
  }

  filterChats(chat, canModerate, userId) {
    return canModerate ? true : chat.status === 'approved' || !chat.status || chat.userId === userId;
  }
}
