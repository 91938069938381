import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import { configUrl } from '@env/environment';

@Component({
  selector: 'app-user-image',
  templateUrl: './user-image.component.html',
  styleUrls: ['./user-image.component.scss'],
})
export class UserImageComponent implements OnInit {
  @Input() userId: string;
  @Input() imageId: string;
  @Input() userName: string;
  @Input() classType: string;
  @Input() type: any;

  configUrl: any = configUrl;
  imageSrc: any = '/assets/images/profile.png';
  imageSrcOnError: any = '/assets/images/profile.png';
  imageSub: any;

  constructor(
    private afDatabase: AngularFireDatabase,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    if (this.imageId) {
      this.imageSrc = this.configUrl.bucketImage + this.userId + '/' + this.imageId;
    } else {
      const url = `${configUrl.getUserUrl}${this.userId}`;
      this.http.get<any>(url).subscribe((user) => {
        this.imageSrc = this.configUrl.bucketImage + this.userId + '/' + user.imageid;
      });
    }
  }

  ngDestroy() {
    if (this.imageSub) {
      this.imageSub.unsubscribe();
    }
  }

  updateUrl(event: any) {
    this.imageSrc = this.imageSrcOnError;
  }
}
