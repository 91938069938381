<div class="clip" *ngIf="clip">
  <div class="item message" [ngClass]="extraClass">
    <div class="box">
      <div class="box-padding first">
        <p class="date">
          {{ qs.formatDateInbox(clip?.createdat) }} &bull;
          <a href="javascript:void(0)" [ngbPopover]="authorPopover" i18n-popoverTitle popoverTitle="Author">{{
            clip?.authorname
          }}</a>
        </p>

        <div class="clip-options" *ngIf="authService.activeUser !== null">
          <div class="manage-clip">
            <app-manage-clip [(clip)]="clip"></app-manage-clip>
          </div>
        </div>

        <div class="clip-play-title-container clip-play-title-container-{{ clip.cliptype | lowercase }}">
          <div class="clip-data-title-media" *ngIf="clip.cliptype === 'Audio'">
            <app-play-audio-button [clip]="clip"></app-play-audio-button>
          </div>

          <div class="clip-data-title-media" *ngIf="clip.cliptype === 'Video'">
            <app-responsive-video
              [videoUrl]="configUrl.awsUrl + clip.videoid"
              [thumbnailUrl]="configUrl.thumbnailFS + clip.thumbnail"
              [clipid]="clip.id"
            ></app-responsive-video>
          </div>

          <div class="clip-data-title">
            <ng-template #authorPopover>
              <div class="popover-content">
                <app-user-image
                  [userId]="clip.authorid"
                  [imageId]="clip.authorimageid"
                  classType="nav-photo"
                ></app-user-image
                >&nbsp;{{ clip?.authorname }}
              </div>
              <div class="popover-actions">
                <a
                  [class.disabled]="!canInteract"
                  [routerLink]="['/combined/user/' + clip?.authorid]"
                  class="btn btn-sm btn-primary"
                  ><mdi name="User" size="20"></mdi>&nbsp;<span i18n="@@profile">Profile</span></a
                >&nbsp;
                <a
                  [class.disabled]="!canInteract"
                  (click)="navigateToChat(authService.activeUser.uid, clip?.authorid, clip?.authorname)"
                  class="btn btn-sm btn-primary"
                  ><mdi name="Message" size="20"></mdi>&nbsp;<span i18n="@@message">Message</span></a
                >
              </div>
            </ng-template>

            <p class="post-title" [routerLink]="['/combined/clip/' + clip.id]">
              <mdi name="Private" size="20" *ngIf="clip?.privacy === 'Private'"></mdi>{{ clip?.title }}
            </p>

            <div class="author-and-plays">
              <div class="author-link">
                <div class="clip-link" *ngIf="clip?.link">
                  <a class="theme-color-font" [href]="clip?.link" target="_blank">{{ clip?.link }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="clip-image">
        <app-post-image *ngIf="clip?.image" [audioImage]="clip?.image"></app-post-image>
      </div>

      <div class="replies">
        <p *ngIf="clip.cliptype !== 'Text' && clip.played === 1" class="replies-plays" i18n>{{ clip.played }} Play</p>
        <p *ngIf="clip.cliptype !== 'Text' && clip.played > 1" class="replies-plays" i18n>{{ clip.played }} Plays</p>
        <p *ngIf="clip?.numreplies == 1" class="replies-replies" [routerLink]="['/combined/clip/' + clip?.id]">
          <span class="replies-replies-link" i18n>{{ clip?.numreplies }} Reply</span>
        </p>
        <p *ngIf="clip?.numreplies > 1" class="replies-replies" [routerLink]="['/combined/clip/' + clip?.id]">
          <span class="replies-replies-link" i18n>{{ clip?.numreplies }} Replies</span>
        </p>
      </div>

      <hr />

      <div class="box-padding last">
        <div class="aside">
          <div class="left">
            <button class="like" (click)="likeSeed()">
              <span>
                <mdi *ngIf="clip?.likedat" name="Like"></mdi>
                <mdi *ngIf="!clip?.likedat" name="LikeOutline"></mdi>
              </span>
              {{ clip?.likes }}
            </button>

            <button
              *ngIf="!clip?.groupid && !clip?.chatid"
              class="share"
              [ngbPopover]="sharePopover"
              i18n-popoverTitle
              popoverTitle="Share"
            >
              <mdi name="Share"></mdi>
            </button>

            <ng-template #sharePopover>
              <div *ngIf="clip.privacy !== 'Public'">
                <p i18n>You cannot share a private post!</p>
                <div>
                  <button class="btn btn-sm btn-primary share-btn" (click)="setToPublic($event, clip)" i18n>
                    Set to Public</button
                  >&nbsp;
                  <button class="btn btn-sm btn-light share-btn" i18n="@@cancel">Cancel</button>
                </div>
              </div>
              <app-share-social
                *ngIf="clip.privacy === 'Public'"
                [url]="configUrl?.host + '/combined/clip/' + clip?.id"
                [salesUrl]="configUrl?.host + '/share/' + clip?.id"
                [title]="clip?.title"
                [twitterCardUrl]="configUrl?.host + '/twittercard/' + clip?.id"
                [embedOption]="true"
              >
              </app-share-social>
            </ng-template>
          </div>
          <div class="right">
            <button class="replay" *ngIf="!clip?.parentid && !noReply && !clip?.chatid" (click)="showReply(clip)">
              <span class="icon-reply">
                <mdi name="Reply"></mdi>
              </span>
              <span i18n="@@reply">Reply</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
