import { Injectable } from '@angular/core';

@Injectable()
export class GlobalvariablesService {
  navVisible = true;
  businessStyles: any = '';
  userSettings: any = null;
  userCounters: any = {
    chats: [],
    groups: [],
  };
  invites: any = {
    groups: [],
  };
}
