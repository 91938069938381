import { Component, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

import { UiModalService } from '@app/core/services';
import { Router, UrlSerializer } from '@angular/router';

@Component({
  templateUrl: './modal-embed.component.html',
})
export class ModalEmbedComponent {
  label = $localize`URL`;
  @Input() baseUrl: string;
  @Input() embed = true;

  title = true;
  content = true;
  link = true;
  profile = true;
  call = true;
  booking = true;

  get url(): string {
    const extras = {
      queryParams: {
        embed: this.embed,
        title: String(this.title),
        content: String(this.content),
        profile: String(this.profile),
        link: String(this.link),
        call: String(this.call),
        booking: String(this.booking),
      },
    };

    const tree = this.router.createUrlTree([''], extras);
    const url = this.baseUrl + this.serializer.serialize(tree).substr(1);

    return this.embed ? `<iframe width="500" height="500" src="${url}" frameborder="0"></iframe>` : url;
  }

  constructor(
    private modalService: UiModalService,
    private toastr: ToastrService,
    private router: Router,
    private serializer: UrlSerializer,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  closeAction() {
    this.modalService.destroy();
  }

  copyAction(urlElement) {
    this.copyToClipboard(urlElement);
  }

  copyToClipboard(element) {
    element.select();
    this.document.execCommand('copy');
    this.toastr.info($localize`${this.label} copied to clipboard`);
  }
}
