import {
  Component,
  Input,
  Output,
  OnChanges,
  OnInit,
  SimpleChanges,
  EventEmitter,
  ApplicationRef,
} from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AuthService, PollsService } from '@app/core/services';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss'],
})
export class PollComponent implements OnInit, OnChanges {
  @Input() poll: any;
  @Input() allowRemovePoll = false;

  @Output() removePoll = new EventEmitter<any>();

  resultsSubscription: Subscription;
  results: any[];

  voted: string | null = null;

  constructor(
    private afDatabase: AngularFireDatabase,
    private authService: AuthService,
    private pollsService: PollsService,
    private toastr: ToastrService,
    private app: ApplicationRef,
  ) {}

  ngOnInit() {
    this.observeResults();
    this.voted = localStorage.getItem(`poll-${this.poll.id}-${this.optionsHash(this.poll)}`);
  }

  ngOnChanges(changes: SimpleChanges) {}

  vote(option) {
    this.pollsService.vote(this.poll.id, option.id).subscribe(() => {
      this.toastr.success($localize`Voted`);
      this.voted = option.id;
      localStorage.setItem(`poll-${this.poll.id}-${this.optionsHash(this.poll)}`, option.id);
    });
  }

  remove() {
    this.removePoll?.emit(this.poll);
  }

  observeResults() {
    const id = this.poll.id;
    const companyId = this.authService.currentBusinessId;

    this.resultsSubscription?.unsubscribe();
    this.resultsSubscription = this.afDatabase
      .list<any>(`company/${companyId}/polls/${id}`)
      .valueChanges()
      .subscribe((results) => {
        this.results = results;
      });
  }

  getPercentage(optionId: string, rounded = true): string {
    const total = this.getResultTotal();
    if (total === 0) {
      return '';
    }

    const proportion = this.getResult(optionId) / total;

    return rounded ? Math.round(proportion * 100).toString() : (proportion * 100).toString();
  }

  getResult(optionId: string) {
    if (!this.results) {
      return 0;
    }

    return this.results.filter((option) => option.polloptionid === optionId)[0]?.num ?? 0;
  }

  getResultTotal(): number {
    if (!this.results) {
      return 0;
    }

    return this.results.reduce((prev, current) => {
      return prev + current.num;
    }, 0);
  }

  optionsHash(poll) {
    if (!poll.options) {
      return 'nohash';
    }
    const ids = poll.options?.reduce((prev, curr) => `${prev.id}${curr.id}`, '');
    return CryptoJS.MD5(ids).toString();
  }
}
