import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { parseISO } from 'date-fns';

import { configUrl } from '@env/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class MeetingListResolver implements Resolve<any> {
  constructor(private http: HttpClient) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): Observable<any> {
    return this.getData();
  }

  getData(): Observable<any> {
    const publicUrl = configUrl.meetingPublic;
    return forkJoin({
      publicMeetings: this.http.get<any[]>(publicUrl),
    }).pipe(map(({ publicMeetings }) => [...publicMeetings].sort(this.compare)));
  }

  private compare(a, b) {
    const aTime = parseISO(a.startdate).getTime();
    const bTime = parseISO(b.startdate).getTime();
    if (aTime > bTime) {
      return 1;
    }
    if (aTime < bTime) {
      return -1;
    }
    return 0;
  }
}
