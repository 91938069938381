import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import {
  QueryService,
  ClipService,
  AdminReportedClipsService,
  UiModalService,
  AuthService,
  UserService,
  LangService,
} from '@app/core/services';

import { ModalConfirmComponent } from '@app/shared/modal/modal-confirm/modal-confirm.component';
import { Breadcrumb, Clip, CompanyMemberType } from '@app/shared/model';
import { config } from '@env/environment';

enum Scope {
  Admin = 'admin',
  Company = 'company',
}

@Component({
  selector: 'app-reported-clips',
  templateUrl: './reported-clips.component.html',
  styleUrls: ['./reported-clips.component.scss'],
})
export class ReportedClipsComponent implements OnInit {
  Scope = Scope;

  reportedClips: any;
  clip: Clip;
  scope: Scope;
  filterQuery = '';

  public breadcrumbs: Breadcrumb[];

  get canViewPage(): boolean {
    if (this.scope === Scope.Company) {
      return this.authService.selectedCompany && this.authService.isCompanyAdmin;
    }

    return !!this.authService.userInfo?.isadmin;
  }

  constructor(
    public qs: QueryService,
    public authService: AuthService,
    private userService: UserService,
    private adminRC: AdminReportedClipsService,
    private clipService: ClipService,
    private toastr: ToastrService,
    private modalService: UiModalService,
    private activatedRoute: ActivatedRoute,
    private langService: LangService,
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.scope = data.scope;

      this.breadcrumbs = [
        ...(this.scope === Scope.Admin ? [{ name: 'Admin', link: ['/combined/admin'] }] : []),
        ...(this.scope === Scope.Company
          ? [
              {
                name: this.langService.lang('Community_Admin'),
                link: [`/combined/business/${authService.currentBusinessId}`],
              },
            ]
          : []),
        { name: 'Reported Clips' },
      ];
    });
  }

  ngOnInit() {
    this.loadReportedClips();
  }

  public filterByTitle(clip: Clip, queryString: string): boolean {
    if (!queryString.length) {
      return true;
    }

    return queryString.length > 2 && clip.title.toLowerCase().includes(queryString.toLowerCase());
  }

  public modalActionDelete(clip: Clip) {
    const inputs = {
      title: $localize`:@@deleteClip:Delete Clip`,
      message: $localize`Do you want to delete ${clip.title} from clips?`,
      yesButtonText: $localize`:@@delete:Delete`,
      noButtonText: $localize`:@@cancel:Cancel`,
    };
    const outputs = {
      completeHandler: (yes: boolean) => {
        if (yes) {
          this.deleteClip(clip);
        }
      },
    };
    this.modalService.init(ModalConfirmComponent, inputs, outputs);
  }

  public modalActionRemoveFromReported(clip: Clip) {
    const inputs = {
      title: $localize`Undo Report Clip`,
      message: $localize`Do you want to remove "${clip.title}" from reported clips?`,
      yesButtonText: $localize`:@@Remove:Remove`,
      noButtonText: $localize`:@@cancel:Cancel`,
    };
    const outputs = {
      completeHandler: (yes: boolean) => {
        if (yes) {
          this.deleteClipFromReported(clip);
        }
      },
    };
    this.modalService.init(ModalConfirmComponent, inputs, outputs);
  }

  private loadReportedClips() {
    switch (this.scope) {
      case Scope.Admin:
        this.adminRC.getReportedClips().subscribe((data) => (this.reportedClips = data));
        break;
      case Scope.Company:
        this.adminRC.getReportedClipsByCompanyAdmin().subscribe((data) => (this.reportedClips = data));
        break;
    }
  }

  private deleteClip(clip: Clip) {
    const body = {
      id: clip.id,
    };
    this.clipService.deleteClip(body).subscribe(
      (_) => {
        this.adminRC.deleteFromReportedClips(clip.id).subscribe(
          (_) => {
            this.toastr.success($localize`Clip deleted`);
            this.loadReportedClips();
          },
          (_) => {
            this.toastr.error($localize`Unable to delete clip from reported clips`);
          },
        );
      },
      (_) => {
        this.toastr.error($localize`Unable to delete clip`);
      },
    );
  }

  private deleteClipFromReported(clip: Clip) {
    const deleteFromReportedSub = this.adminRC.deleteFromReportedClips(clip.id);
    deleteFromReportedSub.subscribe(
      (_) => {
        this.toastr.success($localize`Successfully removed from reported clips`);
        this.loadReportedClips();
      },
      (_) => {
        this.toastr.error($localize`Unable to delete clip from reported clips`);
      },
    );
  }
}
