import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ViewChild,
} from '@angular/core';
import {
  AuthService,
  BusinessService,
  CategoryService,
  ChatService,
  ClipService,
  GroupService,
  UiModalService,
} from '@app/core/services';
import { ToastrService } from 'ngx-toastr';

import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { PostRecordAudioComponent } from '../post-record-audio/post-record-audio.component';
import { PostRecordVideoComponent } from '../post-record-video/post-record-video.component';
import { ModalInputComponent } from '@app/shared/modal/modal-input/modal-input.component';
import { Clip, Feature } from '@app/shared/model';

import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';

enum ClipType {
  Text = 'Text',
  Audio = 'Audio',
  Video = 'Video',
}

class ImageSnippet {
  constructor(
    public src: string,
    public file: File,
    public imageCheckSum: string,
  ) {}
}

@Component({
  selector: 'app-post-create-form',
  templateUrl: './post-create-form.component.html',
  styleUrls: ['./post-create-form.component.scss'],
})
export class PostCreateFormComponent implements OnInit, OnDestroy, OnChanges {
  Feature = Feature;

  public postForm: UntypedFormGroup;

  public ClipType = ClipType;

  public categories = [];
  public groups = [];
  public chats = [];

  categoriesLoaded = false;

  thumbnail: ImageSnippet;
  originalUrl: string;

  audioUrl;
  videoUrl;
  videoThumbnailUrl;

  initialFormValues;

  get titlePlaceholderText(): string {
    if (this.appearance !== 'compact') {
      return $localize`:title:Title`;
    }

    if (this.clipType === 'Video') {
      return $localize`Video Title`;
    }

    if (this.clipType === 'Audio') {
      return $localize`Audio Title`;
    }

    return $localize`Post Text`;
  }

  @ViewChild(PostRecordAudioComponent) recordAudioComponent: PostRecordAudioComponent;
  @ViewChild(PostRecordVideoComponent) recordVideoComponent: PostRecordVideoComponent;
  @ViewChild('ngForm') ngForm: NgForm;

  @Input() public clipType = ClipType.Text;
  @Output() public cancelAction = new EventEmitter();
  @Output() public uploadComplete = new EventEmitter();

  @Input() public chat: any = null;
  @Input() public group: any = null;
  @Input() public title = '';
  @Input() public parentId: string = null;
  @Input() public categoryId: number = null;
  @Input() public target: string = null;
  @Input() public userId: string = null;
  @Input() public appearance: string = null;
  @Input() public where: string = null;
  @Input() public cancelButtonText = $localize`:@@cancel:Cancel`;

  constructor(
    private categoryService: CategoryService,
    private groupService: GroupService,
    private chatService: ChatService,
    private authService: AuthService,
    private clipService: ClipService,
    private toastr: ToastrService,
    private cd: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private modalService: UiModalService,
    private business: BusinessService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.postForm = this.createFormGroup();
    this.initialFormValues = this.postForm.value;
    this.loadGroups();
    this.loadChats();
    this.loadCategories();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.reset();
    this.postForm?.patchValue({
      clipType: this.clipType,
      chat: this.chat,
      group: this.group,
    });
  }

  ngOnDestroy() {}

  hasFeature(feature: Feature) {
    return this.business.hasFeature(feature);
  }

  addVideo(videoUrl) {
    this.videoUrl = videoUrl;
    this.postForm?.patchValue({ videoUrl });
    this.postForm?.markAsDirty();
  }

  addVideoThumbnail(videoThumbnailUrl) {
    this.videoThumbnailUrl = videoThumbnailUrl;
  }

  addAudio(audioUrl) {
    this.audioUrl = audioUrl;
    this.postForm?.patchValue({ audioUrl });
    this.postForm?.markAsDirty();
  }

  transcription(phrase) {
    this.postForm.patchValue({
      cliptext: `${this.postForm.get('cliptext').value} ${phrase}`,
    });
  }

  compactReveal() {
    return this.appearance !== 'compact' || this.postForm.dirty;
  }

  createFormGroup() {
    let where = this.where;

    if (this.chat) {
      where = 'chat';
    }

    if (this.group) {
      where = 'group';
    }

    return this.formBuilder.group(
      {
        target: [this.target],
        title: [this.title, [Validators.required]],
        category: [this.categoryId],
        parentId: [this.parentId],
        where: [where],
        group: [this.group],
        chat: [this.chat],
        link: ['', [Validators.pattern(/^(ftp|http|https):\/\/[^ "]+$/)]],
        cliptext: [''],
        audioUrl: [null],
        videoUrl: [null],
        clipType: [this.clipType],
      },
      {
        validators: [this.validateGroup, this.validateChat, this.validateAudio, this.validateVideo, this.validateWhere],
      },
    );
  }

  cancel() {
    this.reset();
    this.cancelAction.emit();
  }

  private validateGroup(form: UntypedFormGroup): { [key: string]: any } | null {
    const valid = !!form.get('group').value || form.get('where').value !== 'group';
    return valid ? null : { invalidGroup: { valid: false, value: form.get('group').value } };
  }

  private validateChat(form: UntypedFormGroup): { [key: string]: any } | null {
    const valid = !!form.get('chat').value || form.get('where').value !== 'chat';
    return valid ? null : { invalidChat: { valid: false, value: form.get('chat').value } };
  }

  private validateAudio(form: UntypedFormGroup): { [key: string]: any } | null {
    const valid = !!form.get('audioUrl').value || form.get('clipType').value !== ClipType.Audio;
    return valid ? null : { invalidAudio: { valid: false, value: form.get('audioUrl').value } };
  }

  private validateVideo(form: UntypedFormGroup): { [key: string]: any } | null {
    const valid = !!form.get('videoUrl').value || form.get('clipType').value !== ClipType.Video;
    return valid ? null : { invalidVideo: { valid: false, value: form.get('videoUrl').value } };
  }

  private validateWhere(form: UntypedFormGroup): { [key: string]: any } | null {
    const valid =
      !!form.get('where').value ||
      !!form.get('parentId').value ||
      form.get('target').value === 'AudioProfile' ||
      form.get('target').value === 'UserTestimonial';
    return valid ? null : { invalidWhere: { valid: false, value: form.get('where').value } };
  }

  addLink() {
    const inputs = {
      title: this.postForm.get('link').value ? $localize`Edit Web Link` : $localize`Add Web Link`,
      label: $localize`Web Link`,
      text: this.postForm.get('link').value,
      validators: [Validators.pattern(/^(ftp|http|https):\/\/[^ "]+$/)],
    };

    const outputs = {
      completeHandler: (url: string) => {
        this.postForm.patchValue({
          link: url,
        });
      },
    };

    this.modalService.init(ModalInputComponent, inputs, outputs);
  }

  removeLink() {
    this.postForm.patchValue({
      link: '',
    });
  }

  public submitForm(form: NgForm) {
    if (form.invalid) {
      this.toastr.error($localize`There was a problem`);
      return;
    }

    this.postClip();
  }

  public compareId(a: any, b: any): boolean {
    return a && b ? a.id === b.id : a === b;
  }

  public reset() {
    this.thumbnail = null;
    this.originalUrl = null;
    this.audioUrl = null;
    this.videoUrl = null;
    this.videoThumbnailUrl = null;

    this.ngForm?.resetForm(this.initialFormValues);
    this.postForm?.patchValue({
      clipType: this.clipType,
    });
    this.postForm?.markAsPristine();
    this.postForm?.markAsUntouched();

    this.recordAudioComponent?.reset();
    this.recordVideoComponent?.reset();
  }

  public changeWhere() {
    this.postForm?.get('group').reset();
    this.postForm?.get('chat').reset();
    this.postForm?.get('category').reset();
  }

  private postClip() {
    const clip: Clip = {
      title: this.postForm.get('title').value,
      cliptype: this.postForm.get('clipType').value,
      parentid: this.postForm.get('parentId').value,
      categoryid: this.postForm.get('category').value,
      privacy: this.postForm.get('where').value === 'myvideos' ? 'Private' : 'Public',
      link: this.postForm.get('link').value !== '' ? this.postForm.get('link').value : null,
      groupid: this.postForm.get('where').value === 'group' ? this.postForm.get('group').value.id : null,
      chatid: this.postForm.get('where').value === 'chat' ? this.postForm.get('chat').value.id : null,
      userid: this.userId,
      cliptext: this.postForm.get('cliptext').value,
    };

    let image: any = null;
    if (this.thumbnail) {
      image = {
        imagechecksum: this.thumbnail.imageCheckSum,
        file: this.thumbnail.file,
      };
    }

    let file: any = null;
    let thumbnail: any = null;

    if (this.clipType === ClipType.Audio) {
      file = this.audioUrl;
    }
    if (this.clipType === ClipType.Video) {
      file = this.videoUrl;
      thumbnail = this.videoThumbnailUrl;

      this.clipService.post(clip, this.target, image, file, thumbnail).subscribe();

      this.cancel();
      return;
    }

    this.clipService.post(clip, this.target, image, file, thumbnail).subscribe(
      (response) => {
        this.toastr.success($localize`Success`);
        this.uploadComplete.emit();
        this.cancel();
      },
      (error) => {
        this.toastr.error($localize`Error`);
      },
    );
  }

  private loadGroups() {
    this.groupService
      .getMyGroups({
        limit: '100',
        memberstatusid: '2',
      })
      .pipe(
        map((groups) =>
          groups.map((group) => ({
            id: group.id,
            name: group.name,
          })),
        ),
      )
      .subscribe((groups) => (this.groups = groups));
  }

  private loadChats() {
    this.chatService
      .getMyChats(this.authService.activeUser.uid, {
        limit: '100',
      })
      .pipe(
        map((chats) =>
          chats.map((chat) => ({
            id: chat.id,
            username: chat.username,
          })),
        ),
      )
      .subscribe((chats) => (this.chats = chats));
  }

  private loadCategories() {
    this.categoryService
      .getCategory()
      .pipe(map((categories) => this.categoryService.selectList(categories)))
      .subscribe((categories) => {
        this.categoriesLoaded = true;
        this.categories = categories;
      });
  }

  processFile(podcastThumbnailInput: any) {
    const file: File = podcastThumbnailInput.files[0];
    const reader = new FileReader();
    const image = document.createElement('img');

    reader.addEventListener('load', (event: any) => {
      image.onload = (e) => {
        const reader2 = new FileReader();

        reader2.onloadend = () => {
          const wordArray = CryptoJS.lib.WordArray.create(reader2.result);
          const imageCheckSum = CryptoJS.MD5(wordArray).toString();

          this.thumbnail = new ImageSnippet(event.target.result, file, imageCheckSum);
          this.cd.detectChanges();
        };

        reader2.readAsArrayBuffer(file);
      };
      image.src = event.target.result;
    });

    reader.readAsDataURL(file);
  }

  removeThumbnail() {
    this.thumbnail = null;
    this.cd.detectChanges();
  }
}
