import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { configUrl } from '@env/environment';

@Injectable()
export class CategoryService {
  constructor(private httpClient: HttpClient) {}

  getCategory(): Observable<any[]> {
    return this.httpClient.get<any[]>(configUrl.getCategory);
  }

  getCategoryTree(): Observable<any[]> {
    return this.httpClient.get<any[]>(configUrl.getCategoryTree);
  }

  postCategory(body: any): Observable<any> {
    return this.httpClient.post(configUrl.addCategory, body);
  }

  updateCategory(body: any): Observable<any> {
    return this.httpClient.put(configUrl.addCategory, body);
  }

  removeCategory(body: any): Observable<any> {
    return this.httpClient.request<any>('delete', configUrl.categoryDelete, { body });
  }

  selectList(categories: any[]) {
    return categories
      .map((category) => ({
        ...category,
        fullName: this.categoryNames(category, categories).join(' → '),
      }))
      .sort((a, b) => {
        if (a.fullName < b.fullName) {
          return -1;
        }
        if (a.fullName > b.fullName) {
          return 1;
        }
        return 0;
      });
  }

  private categoryNames(category: any, categories: any[], nameStack: string[] = []): string[] {
    const names = [category.name, ...nameStack];

    if (!category.parentid) {
      return names;
    }

    const parent = categories.filter((item) => item.id === category.parentid)[0];

    if (parent) {
      return this.categoryNames(parent, categories, names);
    }

    return ['...', ...names];
  }
}
