import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { parseISO } from 'date-fns';

import { configUrl } from '@env/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ScheduleDayListResolver implements Resolve<any> {
  constructor(private http: HttpClient) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): Observable<any> {
    return this.getData();
  }

  getData(): Observable<any> {
    const url = `${configUrl.eventDay}`;
    return this.http.get(url);
  }
}
