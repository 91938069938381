import { NgModule, ErrorHandler, Type, Inject, LOCALE_ID } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ActivationStart, Router, RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireAuth, AngularFireAuthModule, LANGUAGE_CODE } from '@angular/fire/compat/auth';
import { AngularFireDatabase, AngularFireDatabaseModule, ChildEvent } from '@angular/fire/compat/database';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { CookieService } from 'ngx-cookie-service';

import { ToastrModule, ToastrService } from 'ngx-toastr';

import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { AuthModule } from '@app/auth/auth.module';
import { NavModule } from '@app/nav/nav.module';
import { TwitterCardModule } from '@app/twitter-card/twitter-card.module';
import { SalesModule } from '@app/sales/sales.module';
import { LiveVideoModule } from '@app/live-video/live-video.module';

import { AppComponent } from './app.component';
import { RefreshComponent } from '@app/core/refresh/refresh.component';
import { NavComponent } from '@app/nav/nav/nav.component';
import { LiveRoomComponent } from './live-video/live-room/live-room.component';

import { firebaseConfig, environment, config, configUrl } from '@env/environment';

import 'zone.js';
import 'zone.js/dist/webapis-rtc-peer-connection';
import 'zone.js/dist/zone-patch-user-media';
import adapter from 'webrtc-adapter';

import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

import * as Sentry from '@sentry/angular';

import * as LogRocket from 'logrocket';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { EmailVerifiedGuardService } from './core/guards/email-verified-guard.service';
import { RouletteComponent } from './live-video/roulette/roulette.component';
import { LiveStageComponent } from './live-video/live-stage/live-stage.component';
import { GreenroomResolver, StageResolver } from './core/resolvers';
import { RoomGuardService } from './core/guards/room-guard.service';
import { Feature } from './shared/model';

import { NotFoundComponent } from './core/not-found/not-found.component';
import { AuthService } from './core/services';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GreenroomComponent } from './live-video/greenroom/greenroom.component';
import { EmbedContactModule } from './embed-contact/embed-contact.module';
import { StoreModule } from '@ngrx/store';
import { navReducer } from './nav.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxHotjarModule } from 'ngx-hotjar';

if (environment.production) {
  Sentry.init({
    dsn: config.sentryDSN,
    release: config.prefix + '@' + environment.version,
    beforeSend(event) {
      const msg = event.exception?.values?.[0]?.value || '';

      if (/^There is no user record corresponding to this identifier/.test(msg)) {
        return null;
      }
      if (/^The password is invalid or the user does not have a password/.test(msg)) {
        return null;
      }
      if (/^The email address is badly formatted/.test(msg)) {
        return null;
      }
      if (/^The email address is already in use by another account/.test(msg)) {
        return null;
      }
      if (/^Access to this account has been temporarily disabled/.test(msg)) {
        return null;
      }
      if (/^Password should be at least 6 characters/.test(msg)) {
        return null;
      }
      if (/^The popup has been closed by the user before finalizing the operation/.test(msg)) {
        return null;
      }
      if (/^This operation has been cancelled due to another conflicting popup being opened/.test(msg)) {
        return null;
      }

      if (/This browser doesn't support the API's required to use the firebase SDK/.test(msg)) {
        return null;
      }
      if (/The project's quota for this operation has been exceeded/.test(msg)) {
        return null;
      }

      if (/Loading chunk [\d]+ failed/.test(msg)) {
        window.location.reload();
        return null;
      }

      return event;
    },
  });
}

if (configUrl.logRocketAppId) {
  LogRocket.init(configUrl.logRocketAppId);

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}

const base = document.querySelector('base').attributes.getNamedItem('href').nodeValue;
const code = base.match(/[a-z]+/)?.[0];

const languageCode = code || 'en';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'combined',
    pathMatch: 'full',
  },
  {
    path: 'refresh',
    component: RefreshComponent,
  },
  {
    path: 'live/:id',
    component: LiveRoomComponent,
    canActivate: [RoomGuardService, EmailVerifiedGuardService],
  },
  {
    path: 'combined',
    component: NavComponent,
    canActivate: [EmailVerifiedGuardService],
    children: [
      {
        path: '',
        redirectTo: config.features.includes(Feature.Reception) ? 'reception' : 'timeline',
        pathMatch: 'full',
      },
      {
        path: 'timeline',
        loadChildren: () => import('./timeline/timeline.module').then((m) => m.TimelineModule),
      },
      {
        path: 'my-videos',
        loadChildren: () => import('./my-videos/my-videos.module').then((m) => m.MyVideosModule),
      },
      {
        path: 'inbox',
        loadChildren: () => import('./inbox/inbox.module').then((m) => m.InboxModule),
      },
      {
        path: 'group',
        loadChildren: () => import('./group/group.module').then((m) => m.GroupModule),
      },
      {
        path: 'chat',
        loadChildren: () => import('./chat/chat.module').then((m) => m.ChatModule),
      },
      {
        path: 'bookings',
        loadChildren: () => import('./appointments/appointments.module').then((m) => m.AppointmentsModule),
      },
      {
        path: 'clip/:id',
        loadChildren: () => import('./branch/branch.module').then((m) => m.BranchModule),
      },
      {
        path: 'meetings',
        loadChildren: () => import('./meetings/meetings.module').then((m) => m.MeetingsModule),
      },
      {
        path: 'stage',
        redirectTo: 'stage/1',
        pathMatch: 'full',
      },
      {
        path: 'stage/:id',
        component: LiveStageComponent,
        resolve: {
          stage: StageResolver,
        },
        canActivate: [AuthGuardService, EmailVerifiedGuardService],
      },
      {
        path: 'greenroom',
        component: GreenroomComponent,
        resolve: {
          stage: GreenroomResolver,
        },
        canActivate: [AuthGuardService, EmailVerifiedGuardService],
      },
      {
        path: 'roulette',
        component: RouletteComponent,
        canActivate: [AuthGuardService, EmailVerifiedGuardService],
        data: { page: 'roulette' },
      },
      {
        path: 'video-upload',
        loadChildren: () => import('./video-upload/video-upload.module').then((m) => m.VideoUploadModule),
      },
      {
        path: 'business/:companyid',
        loadChildren: () => import('./company/company.module').then((m) => m.CompanyModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'network',
        loadChildren: () => import('./network/network.module').then((m) => m.NetworkModule),
      },
      {
        path: 'analytics',
        loadChildren: () => import('./analytics/analytics.module').then((m) => m.AnalyticsModule),
      },
      {
        path: 'notifications',
        loadChildren: () => import('./notifications/notifications.module').then((m) => m.NotificationsModule),
      },
      {
        path: 'search',
        loadChildren: () => import('./search/search.module').then((m) => m.SearchModule),
      },
      {
        path: 'reception',
        loadChildren: () => import('./reception/reception.module').then((m) => m.ReceptionModule),
      },
      {
        path: 'expo',
        loadChildren: () => import('./expo/expo.module').then((m) => m.ExpoModule),
      },
      {
        path: 'user/:userid',
        loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
      },
    ],
  },
  {
    path: ':id',
    redirectTo: 'combined/clip/:id',
    pathMatch: 'full',
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(firebaseConfig.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { enableTracing: false, relativeLinkResolution: 'legacy' }),
    CoreModule,
    SharedModule,
    AuthModule,
    NavModule,
    TwitterCardModule,
    SalesModule,
    EmbedContactModule,
    LiveVideoModule,
    ToastrModule.forRoot({
      autoDismiss: true,
      positionClass: 'toast-top-center',
      closeButton: true,
    }),
    StoreModule.forRoot(
      {
        nav: navReducer,
      },
      {},
    ),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    NgxHotjarModule.forRoot(configUrl['hotjarSiteId'] || ''),
  ],
  providers: [
    AngularFireAuth,
    AngularFireDatabase,
    CookieService,
    Title,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: 'googleTagManagerId',
      useValue: configUrl.gtmId || 'INVALID_GTM',
    },
    {
      provide: LANGUAGE_CODE,
      useValue: languageCode,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  pageSubject = new BehaviorSubject<string | null>(null);
  page$ = this.pageSubject.asObservable();

  alertsSubscription: Subscription;

  constructor(
    private router: Router,
    private afDatabase: AngularFireDatabase,
    private authService: AuthService,
    private toastr: ToastrService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {
        this.pageSubject.next(event.snapshot.data.page || 'unknown');
      }
    });

    const subdomain = this.authService.getSubdomain() || '_global';

    this.afDatabase.database.ref('.info/connected').on('value', (snapshot) => {
      if (snapshot.val() === false) {
        return;
      }

      let previousUid: string | null = null;

      this.alertsSubscription?.unsubscribe();
      this.alertsSubscription = this.afDatabase
        .list<any>(`/alerts/${subdomain}`)
        .stateChanges(['child_added'])
        .pipe(filter((action) => !action.payload.val().expired))
        .subscribe((action) => {
          this.toastr.info(action.payload.val().message, null, {
            timeOut: 30000,
          });
        });

      this.authService.user$.subscribe((auth) => {
        const uid = auth.auth?.uid || null;
        if (uid) {
          const ref = this.afDatabase.database.ref(`presence/${subdomain}/${uid}`);
          if (uid !== previousUid) {
            ref
              .onDisconnect()
              .update({
                status: 'offline',
                lastSeen: firebase.database.ServerValue.TIMESTAMP,
                page: null,
              })
              .then(() => {
                ref.set({
                  uid,
                  status: 'online',
                  lastSeen: null,
                  page: this.pageSubject.getValue(),
                });
              });
            previousUid = uid;
          }
        } else if (previousUid) {
          const ref = this.afDatabase.database.ref(`presence/${subdomain}/${previousUid}`);
          ref.update({
            status: 'offline',
            lastSeen: firebase.database.ServerValue.TIMESTAMP,
            page: null,
          });
          previousUid = null;
        }
      });

      this.page$.subscribe((page) => {
        if (page && previousUid) {
          const ref = this.afDatabase.database.ref(`presence/${subdomain}/${previousUid}`).update({
            previousUid,
            status: 'online',
            lastSeen: null,
            page,
          });
        }
      });
    });
  }
}
