export const endpoints = (URL: string, audioURL: string) => {
  return {
    css: `${URL}company/css`,
    getCss: `${URL}css/`,
    logo: `${URL}company/logo`,
    logoDelete: `${URL}company/logo/delete`,

    // User
    user: `${URL}user`,
    user2: `${URL}user2`,
    usersession: `${URL}usersession`,
    getUserUrl: `${URL}user/`,
    searchUrl: `${URL}search/web/`,
    searchUrlES: `${URL}search/es/`,
    searchUser: `${URL}search/`,
    userSettings: `${URL}setting/user`,
    userSettingsDefaultcompany: `${URL}setting/user/defaultcompany`,
    userImage: `${URL}image`,
    userPasswordReset: `${URL}user/passwordreset`,

    // Follower
    follower: `${URL}follower/`,
    followingUnfollow: `${URL}following/unfollow/`,
    followingByIdUrl: `${URL}following/`,
    followingStatus: `${URL}followingstatus/`,
    followerByIdUrl: `${URL}follower/`,
    followerDefault: `${URL}follower/default`,
    followerDefaultBackward: `${URL}follower/default/backward`,

    // Group
    groupMy: `${URL}group/my`,
    groupUserInvite: `${URL}group/user/invite`,
    group: `${URL}group`,
    groupDelete: `${URL}group/delete`,
    groupMembers: `${URL}group/members/`,
    groupUser: `${URL}group/user`,
    groupUserInviteEmail: `${URL}group/user/invite/email`,
    groupUserInviteEmailPending: `${URL}group/user/invite/email/pending`,

    // Chat
    chatMy: `${URL}chat/my`,
    chatUrl: `${URL}chat/`,

    // Inbox
    inboxMy: `${URL}inbox`,

    // Invites
    ivitesMy: `${URL}invites/my`,
    multipleInvites: `${URL}invites/user`,

    // Clip
    clipUrl: `${URL}clip`,
    clipUpdate: `${URL}clip/update`,
    clipPlays: `${URL}clip/plays/`,
    clipReply: `${URL}clip/reply`,
    clipDelete: `${URL}clip/delete`,
    clipReport: `${URL}clip/report`,
    clipMarkAsPlayed: `${URL}clip/markasplayed`,
    getClipByIdUrl: `${URL}clip/`,
    getClipByCategory: `${URL}clip/category/`,
    getClipChildrenByIdUrl: `${URL}clip/parent/`,
    getChatClipById: `${URL}clip/chat/`,
    getGroupClipById: `${URL}clip/group/`,
    clipLike: `${URL}clip/like`,
    clipByUser: `${URL}clip/user`,
    clipByUserId: `${URL}clip/user/`,
    clipMy: `${URL}clip/my`,

    // Video
    videoUrl: `${URL}video`,
    videoUploadUrl: `${URL}video/uploadurl`,

    // Video
    timeline: `${URL}timeline`,
    timelinePublic: `${URL}timeline/public`,

    // Analytics
    analyticsClips: `${URL}analytics/clips`,
    analyticsWords: `${URL}analytics/words`,
    analyticsAdminClips: `${URL}analytics/admin/clips`,
    analyticsClipsPlays: `${URL}analytics/clips/plays`,

    // Admin
    adminUserBlock: `${URL}admin/user/block`,
    adminReportedClips: `${URL}admin/reportedclips`,
    adminDeleteFromReportedClips: `${URL}admin/reportedclips/delete/`,

    adminCompanyList: `${URL}admin/company/list`,
    adminCompany: `${URL}admin/company`,

    // Company
    companyUrl: `${URL}company`,
    companySubdomainUrl: `${URL}company/checksubdomain`,
    companyMy: `${URL}company/my`,
    companyMembers: `${URL}company/members`,
    companyUser: `${URL}company/user`,
    companyUserInvite: `${URL}company/user/invite`,
    companyUserInviteEmail: `${URL}company/user/invite/email`,
    companyUserInviteEmailPending: `${URL}company/user/invite/email/pending`,
    companyUserJoin: `${URL}company/user/join`,
    companySettingAudioUploadUrl: `${URL}setting/company/uploadurl?mimetype=audio/mpeg`,
    companySettingVideoUploadUrl: `${URL}setting/company/uploadurl?mimetype=video/webm`,
    companySponsorImageUploadUrl: `${URL}sponsor/uploadurl?mimetype=image/png`,

    companyInvite: `${URL}company/invite`,
    companyInviteEmail: `${URL}company/invite/email`,
    companyInviteUser: `${URL}company/invite/user`,

    // Category
    addCategory: `${URL}category`,
    getCategory: `${URL}category`,
    getCategoryTree: `${URL}category/tree`,
    categoryDelete: `${URL}category/delete`,
    getByCategoryId: `${URL}clip/category/`,

    // Timeline
    getTimeline: `${URL}timeline`,

    // Setting
    setting: `${URL}setting`,
    companySetting: `${URL}setting/company`,

    // Notifications
    notifications: `${URL}notifications/`,
    notificationsRead: `${URL}notification/read/`,

    // Live Video
    livevideoRoom: `${URL}livevideo/room/`,
    livevideoPublicRoom: `${URL}livevideo/room/public`,
    meeting: `${URL}livevideo/room/schedule`,
    meetingUpcoming: `${URL}livevideo/room/schedule/upcoming`,
    meetingPublic: `${URL}livevideo/room/public/upcoming`,
    livevideoStage: `${URL}livevideo/stage/`,
    expo: `${URL}livevideo/room/expo`,
    uploadRoomImage: `${URL}livevideo/room/uploadimageurl`,

    // Text
    textUrl: `${URL}text`,
    textReply: `${URL}text/reply`,

    // Sponsor
    sponsor: `${URL}sponsor/`,

    // Sponsor Levels
    sponsorLevels: `${URL}sponsortype`,

    // Schedule
    eventDay: `${URL}eventday/`,
    eventSession: `${URL}eventsession/`,

    // Recording
    recorder: `${URL}recorder`,
    recorderSchedule: `${URL}recorderschedule`,
  };
};
