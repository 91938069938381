import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { configUrl } from '@env/environment';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services';

@Injectable()
export class UserResolve implements Resolve<any> {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): Observable<any> {
    const id = route.paramMap.get('id');
    return this.getData(id);
  }

  getData(id: string): Observable<any> {
    return this.authService.activeUser
      ? this.http.get(`${configUrl.user}/${id}`)
      : this.http.get(`${configUrl.user}/${id}/public`);
  }
}
