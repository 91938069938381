export enum JanErrorCode {
  JANUS_OK = 0,
  JANUS_ERROR_UNAUTHORIZED = 403,
  JANUS_ERROR_UNAUTHORIZED_PLUGIN = 405,
  JANUS_ERROR_UNKNOWN = 490,
  JANUS_ERROR_TRANSPORT_SPECIFIC = 450,
  JANUS_ERROR_MISSING_REQUEST = 452,
  JANUS_ERROR_UNKNOWN_REQUEST = 453,
  JANUS_ERROR_INVALID_JSON = 454,
  JANUS_ERROR_INVALID_JSON_OBJECT = 455,
  JANUS_ERROR_MISSING_MANDATORY_ELEMENT = 456,
  JANUS_ERROR_INVALID_REQUEST_PATH = 457,
  JANUS_ERROR_SESSION_NOT_FOUND = 458,
  JANUS_ERROR_HANDLE_NOT_FOUND = 459,
  JANUS_ERROR_PLUGIN_NOT_FOUND = 460,
  JANUS_ERROR_PLUGIN_ATTACH = 461,
  JANUS_ERROR_PLUGIN_MESSAGE = 462,
  JANUS_ERROR_PLUGIN_DETACH = 463,
  JANUS_ERROR_JSEP_UNKNOWN_TYPE = 464,
  JANUS_ERROR_JSEP_INVALID_SDP = 465,
  JANUS_ERROR_TRICKE_INVALID_STREAM = 466,
  JANUS_ERROR_INVALID_ELEMENT_TYPE = 467,
  JANUS_ERROR_SESSION_CONFLICT = 468,
  JANUS_ERROR_UNEXPECTED_ANSWER = 469,
  JANUS_ERROR_TOKEN_NOT_FOUND = 470,
  JANUS_ERROR_WEBRTC_STATE = 471,
  JANUS_ERROR_NOT_ACCEPTING_SESSIONS = 472,
}

export enum JanEvent {
  Create = 'create',
  Message = 'message',
  Attach = 'attach',
  Event = 'event',
  Destroy = 'destroy',
  Leave = 'leave',
  KeepAlive = 'keepalive',
}

export enum JanPlugin {
  Videoroom = 'janus.plugin.videoroom',
}

export type JanMessage = {
  janus: JanEvent;
  transaction: string;
  token?: string;
  error?: JanError;
  data?: any;
  plugin?: string;
  plugindata?: any;
  jsep?: any;
  sender?: number;
  session_id?: number;
};

export type JanError = {
  code: JanErrorCode;
  reason: string;
};

export type JanSessionId = number;
export type JanPluginSessionId = number;

export type JanPublisher = {
  id: number;
  display?: string;
  audio_codec?: string;
  video_codec?: string;
  talking?: boolean;
  tracks?: any;
};

export interface JanSession {
  sessionId: number;
  pluginSessionId: number;
  publisherId?: number;
}
