import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-records',
  templateUrl: './no-records.component.html',
  styleUrls: ['./no-records.component.scss'],
})
export class NoRecordsComponent {
  @Input() maintitle = $localize`No Items`;
  @Input() message = $localize`You have no items yet!`;
}
