<div class="ui-modal-header">
  <h2 *ngIf="embed" i18n>Embed Post</h2>
  <h2 *ngIf="!embed" i18n>Public Link</h2>
  <div class="ui-modal-close" (click)="closeAction()">
    <mdi name="Close"></mdi>
  </div>
</div>

<div class="ui-modal-body">
  <div class="mb-3">
    <label *ngIf="embed" for="url" i18n>Embed Code</label>
    <label *ngIf="!embed" for="url" i18n>URL</label>
    <div class="input-group">
      <input type="text" class="form-control" id="url" name="url" [ngModel]="url" required readonly #urlElement />
      <button class="btn btn-dark" type="button" (click)="copyAction(urlElement)" i18n>Copy</button>
    </div>
  </div>

  <h4 i18n>Options</h4>

  <div class="mb-3">
    <div class="form-check">
      <input
        type="checkbox"
        class="form-check-input"
        id="sales-title"
        [(ngModel)]="title"
        [ngModelOptions]="{ standalone: true }"
      />
      <label class="form-check-label" for="sales-title" i18n="@@title">Title</label>
    </div>

    <div class="form-check">
      <input
        type="checkbox"
        class="form-check-input"
        id="sales-content"
        [(ngModel)]="content"
        [ngModelOptions]="{ standalone: true }"
      />
      <label class="form-check-label" for="sales-content" i18n>Video</label>
    </div>

    <div class="form-check">
      <input
        type="checkbox"
        class="form-check-input"
        id="sales-link"
        [(ngModel)]="link"
        [ngModelOptions]="{ standalone: true }"
      />
      <label class="form-check-label" for="sales-link" i18n>Link</label>
    </div>

    <div class="form-check">
      <input
        type="checkbox"
        class="form-check-input"
        id="sales-profile"
        [(ngModel)]="profile"
        [ngModelOptions]="{ standalone: true }"
      />
      <label class="form-check-label" for="sales-profile" i18n>Author Information</label>
    </div>

    <div class="form-check">
      <input
        type="checkbox"
        class="form-check-input"
        id="sales-call"
        [(ngModel)]="call"
        [ngModelOptions]="{ standalone: true }"
      />
      <label class="form-check-label" for="sales-call" i18n>Video Call Action</label>
    </div>

    <div class="form-check">
      <input
        type="checkbox"
        class="form-check-input"
        id="booking"
        [(ngModel)]="booking"
        [ngModelOptions]="{ standalone: true }"
      />
      <label class="form-check-label" for="booking" i18n>Book Action</label>
    </div>
  </div>
</div>

<div class="ui-modal-footer"></div>
