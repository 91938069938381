export enum Feature {
  Podcasts = 'Podcasts',
  Playlists = 'Playlists',
  LiveVideo = 'Meetings and Video Calls',
  GoogleDriveIntegration = 'GoogleDriveIntegration',
  SlackIntegration = 'SlackIntegration',
  AudioProfile = 'AudioProfile',
  SeedPresentationMode = 'SeedPresentationMode',
  Multimodal = 'Multimodal',
  Notifications = 'Notifications',
  Sentiments = 'Sentiments',
  Roulette = 'Networking',
  Analytics = 'Analytics',
  Inbox = 'Inbox',
  PublicSites = 'PublicSites',
  LiveStage = 'Live Stage',
  People = 'People',
  Groups = 'Groups',
  Chat = 'Chat',
  InviteContact = 'InviteContact',
  Invites = 'Invites',
  PublicTimeline = 'Public Timeline',
  Reception = 'Reception',
  ExpoHall = 'Expo Hall',
  HostAFreeEventButton = 'HostAFreeEventButton',
  MyVideos = 'My Videos (Admins and Presenters only)',
  Appointments = 'Bookings',
}

export enum RecipientType {
  Chat,
  Group,
  Public,
  User,
  AudioProfile,
  Testimonial,
  Playlist,
}

export interface Recipient {
  id?: string;
  categoryid?: any;
  name?: string;
  type: RecipientType;
  userId?: string;
  imageid?: string;
  sortorder?: number;
  userrole?: any;
}

export interface Appointment {
  id?: string;
  title: string;
  details?: string;
  manyAttendees: boolean;
  start: string;
  end: string;
  color?: string;
  classNames?: string[];
}

export interface Action {
  id: string;
  name: string;
  icon: string;
}

export interface Breadcrumb {
  name: string;
  link?: any[];
}

export interface User {
  id?: string;
  name?: string;
  imageid?: string;
}

export enum SocialLogin {
  Google = 'google',
  Facebook = 'facebook',
}

export enum AnalyticsWordsCategory {
  My = 'my',
  Group = 'group',
  Chat = 'chat',
}

export enum CompanyMemberStatus {
  Pending = 1,
  Accepted,
  Rejected,
  Deleted,
}

export enum FollowerStatus {
  Pending = 1,
  Accepted,
  Rejected,
  Unfollowed,
}

export enum CompanyMemberType {
  Regular = 'regular',
  Admin = 'admin',
  Owner = 'owner',
  Presenter = 'presenter',
}

export interface Clip {
  id?: string;
  authorid?: string;
  parentid?: string;
  groupid?: string;
  chatid?: string;
  audio?: string;
  title: string;
  companyid?: string;
  cliptext?: string;
  privacy: string;
  favourite?: number;
  likes?: number;
  played?: number;
  sentiment?: string;
  videochrome?: string;
  createdat?: Date;
  updatedat?: Date;
  link?: string;
  image?: string;
  thumbnail?: string;
  videoid?: string;
  authorname?: string;
  authorimageid?: string;
  categoryid?: string;
  categoryname?: string;
  googledocid?: string;
  googledoclink?: string;
  googledocmime?: string;
  googledocname?: string;
  cliptype: string;
  sort?: number;
  playedat?: Date;
  userid?: string;
  notimeline?: number;
  recordingid?: string;
}

export interface TileAction {
  id: string;
  name: string;
  icon: string;
  inMenu: boolean;
}

export enum DateTimeOption {
  AllTime,
  Week,
  Month,
  Custom,
  Session,
}
