<form #inviteForm="ngForm" autocomplete="off">
  <div class="ui-modal-header">
    <h2>{{ modalTitle }}</h2>
    <div class="ui-modal-close" (click)="dismiss()">
      <mdi name="Close"></mdi>
    </div>
  </div>

  <div class="ui-modal-body">
    <div class="mb-3">
      <input
        type="text"
        class="form-control"
        id="search-query"
        name="search-query"
        [(ngModel)]="searchQuery"
        required
        i18n-placeholder="@@searchUsers"
        placeholder="Search Users"
        (ngModelChange)="onChange()"
      />
    </div>
    <div class="suggestions-list" [ngClass]="{ 'scroll-at-top': listTopShadow, 'scroll-at-bottom': listBottomShadow }">
      <ul cdkScrollable #suggestionsList *ngIf="suggestions.length">
        <li *ngFor="let user of suggestions; let i = index">
          <img
            [src]="user.imageid ? configUrl.bucketImage + user.id + '/' + user.imageid : ''"
            class="suggestions-list-profile-image"
            onError="this.src='/assets/images/profile.png';"
          />
          {{ user.name }}

          <a *ngIf="!isBlacklisted(user)" (click)="performAction(user)" class="action">{{ actionTitle }}</a>
          <span *ngIf="isBlacklisted(user)" class="disabled-action">{{ actionTitle }}</span>
        </li>
      </ul>
      <div *ngIf="!suggestions.length" class="suggestions-list-no-results" i18n>No results</div>
    </div>
  </div>

  <div class="ui-modal-footer"></div>
</form>
