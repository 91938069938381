<div class="sign-up">
  <div class="form-container">
    <div class="inside-form-container">
      <img class="sign-up-logo" [src]="logo" height="50" />

      <div class="sign-up-tabs sign-up-tabs-only">
        <div class="sign-up-tabs-tab">
          <a (click)="$event.preventDefault()" i18n>Please verify your email address</a>
        </div>
      </div>

      <div class="sign-up-form">
        <div class="alert alert-info">
          <span i18n
            >Great! You are almost there. Before you can sign in you'll need to verify your email address.</span
          >
          <span *ngIf="email" i18n
            >We've sent a verification email to <b>{{ email }}</b></span
          >
          <span *ngIf="!email" i18n>We've sent a verification email to your email address</span>
        </div>
        <a class="auth-link" (click)="resendVerificationEmail()" i18n>Resend verification email</a>
        <a class="auth-link" (click)="signOut()" i18n>Sign In with a different account</a>
      </div>
    </div>
    <div class="terms-policy" i18n>
      <a href="{{ configUrl?.terms }}" target="_blank">Terms of Use</a> and
      <a href="{{ configUrl?.policy }}" target="_blank">Privacy Policy</a>
    </div>
  </div>
</div>
