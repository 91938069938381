import { ChangeDetectionStrategy, Component, Input, Output, Sanitizer } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { UiModalService } from '@app/core/services';

@Component({
  templateUrl: './modal-confirm.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmComponent {
  @Output() completeHandler: (yes: boolean) => void;
  @Input() title = $localize`Confirm`;
  @Input() message = $localize`Are you sure?`;
  @Input() yesButtonText = $localize`Yes`;
  @Input() noButtonText = $localize`No`;

  constructor(
    private modalService: UiModalService,
    private sanitizer: DomSanitizer,
  ) {}

  sanitizedMessage() {
    return this.sanitizer.bypassSecurityTrustHtml(this.message);
  }

  noAction() {
    this.completeHandler(false);
    this.modalService.destroy();
  }

  yesAction() {
    this.completeHandler(true);
    this.modalService.destroy();
  }

  dismissAction() {
    this.modalService.destroy();
  }
}
