<div class="ui-modal-header">
  <h2 i18n>Create Group</h2>
  <div class="ui-modal-close" (click)="dismissAction()">
    <mdi name="Close"></mdi>
  </div>
</div>

<div class="ui-modal-body">
  <div class="mb-3">
    <label for="playlist-name" i18n>Group Name</label>
    <input type="text" class="form-control" id="playlist-name" name="playlist-name" [(ngModel)]="name" required />
  </div>
</div>

<div class="ui-modal-footer">
  <button class="btn btn-md btn-light" (click)="dismissAction()" i18n="@@cancel">Cancel</button>
  <button class="btn btn-md btn-primary" [ngClass]="{ 'ui-modal-saving': saving }" (click)="createAction()" i18n>
    Create
  </button>
</div>
