import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from '@app/core/services';

@Component({
  selector: 'app-jan-polls',
  templateUrl: './jan-polls.component.html',
  styleUrls: ['./jan-polls.component.scss'],
})
export class JanPollsComponent implements OnInit {
  @Input() polls;
  @Input() showAddPoll = false;

  @Output() closePanel = new EventEmitter<null>();
  @Output() addPoll = new EventEmitter<null>();
  @Output() removePoll = new EventEmitter<any>();

  @ViewChild('messages') messages: ElementRef;

  message: string;

  constructor(public authService: AuthService) {}

  ngOnInit(): void {}

  remove(poll) {
    this.removePoll?.emit(poll);
  }
}
