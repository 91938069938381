import { Feature, SocialLogin } from '../app/shared/model';
import * as psl from 'psl';

export const config = {
  features: [
    Feature.LiveVideo,
    Feature.Multimodal,
    Feature.Notifications,
    Feature.Inbox,
    Feature.PublicSites,
    Feature.Groups,
    Feature.Chat,
    Feature.InviteContact,
    Feature.Invites,
    Feature.PublicTimeline,
  ],
  homeFeature: Feature.PublicTimeline,
  title: 'Watch and Learn',
  enquiryEmail: 'info@webanywhere.co.uk',
  websiteUrl: 'https://watchandlearn.io/',
  prefix: 'wal',
  defaultAudioLimit: 60000,
  globalSubdomain: null,
  navInlineChatsGroups: true,
  sentryDSN: 'https://91ea31b0cff9400d861f9f4a5bd416a7@o23717.ingest.sentry.io/5619326',
  socialLogin: [SocialLogin.Facebook, SocialLogin.Google],
  loginAsGuest: true,
};

export const domain =
  window.location.hostname === 'localhost' || window.location.hostname.match(/^192\.168/)
    ? window.location.hostname
    : psl.parse(window.location.host).domain;

export const isLocalDomain = window.location.hostname === 'localhost' || window.location.hostname.match(/^192\.168/);

export const urlProtocol = location.protocol;
