import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { switchMap } from 'rxjs/operators';

import { ChatService, AuthService, QueryService, BusinessService, GroupService, LangService } from '@app/core/services';

import { configUrl } from '@env/environment';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { hide, show } from '@app/nav.actions';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
})
export class InviteComponent implements OnInit, OnDestroy {
  nav$: Observable<any>;

  queryParams: object;

  name = '';
  email = '';
  password = '';

  configUrl: any = configUrl;
  subdomain: string;

  logo = 'assets/images/logo.png';

  lang = (id: string) => this.langService.lang(id);

  constructor(
    public route: ActivatedRoute,
    public chatService: ChatService,
    public authService: AuthService,
    public qs: QueryService,
    public business: BusinessService,
    public groupManager: GroupService,
    private toastr: ToastrService,
    private router: Router,
    private store: Store<any>,
    private langService: LangService,
  ) {
    this.nav$ = store.select('nav');

    const queryParams = this.route.snapshot.queryParams;

    if (this.isBase64QueryParams(queryParams)) {
      const base64QueryParams = Object.keys(queryParams)[0];

      const rawQueryParam = atob(base64QueryParams);
      const urlParams = new URLSearchParams(rawQueryParam);
      this.queryParams = this.paramsToObject(urlParams);
    } else {
      this.queryParams = queryParams;
    }

    this.email = this.queryParams['email']
      ? this.queryParams['email']
          .trim()
          .replace(/^'?|'?$/g, '')
          .replace(' ', '+')
      : '';

    this.authService.user$.subscribe((auth) => {
      const logo = auth.companySettings.logo;
      this.logo = logo ? configUrl.bucketURL + 'logo/' + logo : 'assets/images/logo.png';
    });
  }

  paramsToObject(entries) {
    const result = {};
    for (const entry of entries) {
      const [key, value] = entry;
      result[key] = value.replace(/^'?|'?$/g, '');
      if (value === 'NULL') {
        result[key] = null;
      }
    }

    return result;
  }

  isBase64QueryParams(queryParams) {
    const queryKeys = Object.keys(queryParams);
    const queryValues = Object.values(queryParams);

    return queryKeys.length === 1 && queryValues[0] === '';
  }

  get isGroupInvite(): boolean {
    return !!this.queryParams['groupid'];
  }

  get isCompanyInvite(): boolean {
    return this.queryParams['companyid'] && !this.queryParams['groupid'];
  }

  get isPlatformInvite(): boolean {
    return !this.queryParams['companyid'] && !this.queryParams['groupid'] && this.queryParams['invitedby'];
  }

  signUp(name, email, password) {
    this.authService
      .signup(name, email, password)
      .pipe(
        switchMap((auth) => {
          // Platform invite
          if (this.isPlatformInvite && this.queryParams['invitedby']) {
            return this.chatService.newRoom(auth['id'], this.queryParams['invitedby']);
          }

          // Company invite
          if (this.isCompanyInvite && this.queryParams['invitedby']) {
            const member = {
              companyid: this.queryParams['companyid'],
              email: this.email,
            };
            return this.business.updateCompanyUserInvite(member);
          }

          // Group invite
          if (this.isGroupInvite && this.queryParams['invitedby']) {
            return this.groupManager.updateGroupUserInviteByEmail(this.email, this.queryParams['groupid']);
          }

          return auth;
        }),
        switchMap((_) => this.authService.loggedIn$),
      )
      .subscribe(
        (_) => this.router.navigate(['/']).then((_) => window.location.reload()),
        (error) => this.toastr.error(error.message),
      );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.store.dispatch(hide());
    }, 0);
    this.subdomain = this.authService.getSubdomain();
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.store.dispatch(show());
    }, 0);
  }
}
