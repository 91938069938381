<div class="layout-container" [ngClass]="{ 'show-menu': menuVisible$ | async }">
  <div class="main-navigation-blackout" (click)="hideMenu()"></div>
  <div class="layout-left-col" id="main-navigation" [class.compact]="compact" #mainNavigation>
    <div class="main-navigation-close">
      <a (click)="hideMenu()"><mdi name="Close"></mdi></a>
    </div>
    <div class="company-selector">
      <app-company-selector></app-company-selector>
    </div>
    <div id="main-navigation-inner">
      <ul>
        <li routerLinkActive="main-navigation-active theme-color-nav-accent" *ngIf="hasFeature(Feature.Reception)">
          <a (click)="hideMenu()" [routerLink]="['/combined/reception']">
            <mdi name="Home" class="list-icon"></mdi>
            <span *ngIf="authService.getSubdomain()" class="main-navigation-item-title" i18n="@@reception"
              >Reception</span
            >
            <span *ngIf="!authService.getSubdomain()" class="main-navigation-item-title" i18n>My Events</span>
          </a>
        </li>
        <li routerLinkActive="main-navigation-active theme-color-nav-accent" *ngIf="hasFeature(Feature.PublicTimeline)">
          <a (click)="hideMenu()" [routerLink]="['/combined/timeline']">
            <mdi name="Timeline" class="list-icon"></mdi>
            <span class="main-navigation-item-title" i18n>Public Timeline</span>
          </a>
        </li>
        <li
          routerLinkActive="main-navigation-active theme-color-nav-accent"
          *ngIf="
            authService.activeUser &&
            authService.selectedCompany &&
            authService.canUploadPrivateVideos &&
            hasFeature(Feature.MyVideos)
          "
        >
          <a (click)="hideMenu()" [routerLink]="['/combined/my-videos']">
            <mdi name="MyVideos" class="list-icon"></mdi>
            <span class="main-navigation-item-title" i18n>My Videos</span>
          </a>
        </li>
        <li
          routerLinkActive="main-navigation-active theme-color-nav-accent"
          *ngIf="authService.activeUser && hasFeature(Feature.Inbox)"
        >
          <a (click)="hideMenu()" [routerLink]="['/combined/inbox']">
            <mdi name="Inbox" class="list-icon"></mdi>
            <span class="main-navigation-item-title" i18n>Inbox</span>
            <span *ngIf="gv.userCounters?.sum" class="main-navigation-badge">{{ gv.userCounters?.sum }}</span>
          </a>
        </li>
        <li
          routerLinkActive="main-navigation-active theme-color-nav-accent"
          *ngIf="authService.activeUser && authService.selectedCompany && hasFeature(Feature.ExpoHall)"
        >
          <a (click)="hideMenu()" [routerLink]="['/combined/expo']">
            <mdi name="Expo" class="list-icon"></mdi>
            <span class="main-navigation-item-title" i18n="@@expoHall">Expo Hall</span>
          </a>
        </li>
        <li
          routerLinkActive="main-navigation-active theme-color-nav-accent"
          *ngIf="authService.activeUser && authService.selectedCompany && hasFeature(Feature.LiveVideo)"
        >
          <a (click)="hideMenu()" [routerLink]="['/combined/meetings']">
            <mdi name="Calendar" class="list-icon"></mdi>
            <span class="main-navigation-item-title" i18n="@@meetings">Meetings</span>
          </a>
        </li>
        <li
          routerLinkActive="main-navigation-active theme-color-nav-accent"
          *ngIf="authService.activeUser && authService.selectedCompany && hasFeature(Feature.Appointments)"
        >
          <a (click)="hideMenu()" [routerLink]="['/combined/bookings']">
            <mdi name="Appointments" class="list-icon"></mdi>
            <span class="main-navigation-item-title" i18n="@@bookings">Bookings</span>
          </a>
        </li>
        <li
          routerLinkActive="main-navigation-active theme-color-nav-accent"
          *ngIf="hasFeature(Feature.LiveStage) && authService.selectedCompany"
        >
          <a (click)="hideMenu()" [routerLink]="['/combined/stage']">
            <mdi name="Stage" class="list-icon"></mdi>
            <span class="main-navigation-item-title" i18n="@@liveStage">Live Stage</span>
          </a>
        </li>
        <li
          routerLinkActive="main-navigation-active theme-color-nav-accent"
          *ngIf="authService.activeUser && authService.selectedCompany && hasFeature(Feature.Roulette)"
        >
          <a (click)="hideMenu()" [routerLink]="['/combined/roulette']">
            <mdi name="Network" class="list-icon"></mdi>
            <span class="main-navigation-item-title" i18n="@@networking">Networking</span>
          </a>
        </li>
        <li
          routerLinkActive="main-navigation-active theme-color-nav-accent"
          *ngIf="authService.activeUser && authService.selectedCompany && hasFeature(Feature.People)"
        >
          <a (click)="hideMenu()" [routerLink]="['/combined/network/users']">
            <mdi name="People" class="list-icon"></mdi>
            <span class="main-navigation-item-title" i18n>People</span>
          </a>
        </li>
        <li
          *ngIf="
            hasFeature(Feature.InviteContact) && authService.activeUser && authService.companySettings?.isselfregister
          "
        >
          <a (click)="inviteContact()" style="cursor: pointer">
            <mdi name="Invite" class="list-icon"></mdi>
            <span class="main-navigation-item-title" i18n>Invite Contact</span>
          </a>
        </li>
      </ul>

      <div
        *ngIf="config.navInlineChatsGroups && (hasFeature(Feature.Chat) || hasFeature(Feature.Groups))"
        style="margin-bottom: 20px"
      >
        <h2 *ngIf="authService.activeUser && hasFeature(Feature.Chat)">
          <span i18n="@@chat">Chat</span>
          <a (click)="openAddChat()" class="main-navigation-heading-action"><mdi name="PlusCircle" size="22"></mdi></a>
        </h2>
        <ul *ngIf="authService.activeUser && chats && hasFeature(Feature.Chat)">
          <li
            routerLinkActive="main-navigation-active theme-color-nav-accent"
            *ngFor="let chat of chats; let i = index"
            [ngClass]="{ 'new-message': checkIfCountersIsSet('chat', chat) }"
          >
            <a (click)="hideMenu()" [routerLink]="['/combined/chat', chat.id]">
              <mdi name="Chat" class="list-icon"></mdi>
              <span class="main-navigation-item-title">{{ chat.username }}</span>
              <span *ngIf="gv.userCounters?.chats && gv.userCounters?.chats[chat.id]" class="main-navigation-badge">
                {{ gv.userCounters?.chats[chat.id] }}</span
              >
              <a
                *ngIf="hasFeature(Feature.LiveVideo)"
                class="livevideo-btn"
                (click)="makeCall(getUserChatId(chat), $event)"
              >
                <mdi name="Video"></mdi>
              </a>
            </a>
          </li>
        </ul>
        <ul *ngIf="authService.activeUser && hasFeature(Feature.Chat)">
          <li class="combined-show-more" (click)="hideMenu()" [routerLink]="['/combined/chat']">
            <a i18n>Show All</a>
          </li>
        </ul>

        <h2 *ngIf="authService.activeUser && hasFeature(Feature.Groups)">
          <span i18n="@@groups">Groups</span>
          <a (click)="openAddGroup()" class="main-navigation-heading-action"><mdi name="PlusCircle" size="22"></mdi></a>
        </h2>
        <ul *ngIf="authService.activeUser && groups && hasFeature(Feature.Groups)">
          <li routerLinkActive="main-navigation-active theme-color-nav-accent" *ngFor="let group of groups">
            <a (click)="hideMenu()" [routerLink]="['/combined/group', group.id]">
              <mdi name="Group" class="list-icon"></mdi>
              <span class="main-navigation-item-title">{{ group.name }}</span>
              <span
                *ngIf="gv.userCounters?.groups && gv.userCounters?.groups[group.id]"
                class="main-navigation-badge"
                >{{ gv.userCounters?.groups[group.id] }}</span
              >

              <div class="new-group">
                <span *ngIf="group.statusid === 1"> &#x25cf; </span>
              </div>
            </a>
          </li>
        </ul>
        <ul *ngIf="authService.activeUser && hasFeature(Feature.Groups)">
          <li class="combined-show-more" (click)="hideMenu()" [routerLink]="['/combined/group']">
            <a i18n>Show All</a>
          </li>
        </ul>
      </div>

      <ul *ngIf="!config.navInlineChatsGroups">
        <li
          routerLinkActive="main-navigation-active theme-color-nav-accent"
          *ngIf="authService.activeUser && hasFeature(Feature.Chat) && authService.selectedCompany"
        >
          <a (click)="hideMenu()" [routerLink]="['/combined/chat']">
            <mdi name="Chat" class="list-icon"></mdi>
            <span class="main-navigation-item-title" i18n="@@chats">Chats</span>
            <span *ngIf="gv.userCounters?.chatsCount" class="main-navigation-badge">{{
              gv.userCounters?.chatsCount
            }}</span>
          </a>
        </li>
        <li
          routerLinkActive="main-navigation-active theme-color-nav-accent"
          *ngIf="authService.activeUser && hasFeature(Feature.Groups) && authService.selectedCompany"
        >
          <a (click)="hideMenu()" [routerLink]="['/combined/group']">
            <mdi name="Group" class="list-icon"></mdi>
            <span class="main-navigation-item-title" i18n="@@groups">Groups</span>
            <span *ngIf="gv.userCounters?.groupsCount" class="main-navigation-badge">{{
              gv.userCounters?.groupsCount
            }}</span>
          </a>
        </li>
      </ul>

      <ul>
        <li
          routerLinkActive="main-navigation-active theme-color-nav-accent"
          *ngIf="
            authService.activeUser &&
            hasFeature(Feature.Invites) &&
            (!authService.currentBusinessId || authService.isCompanyAdmin)
          "
        >
          <a (click)="hideMenu()" [routerLink]="['/combined/network/invites']">
            <mdi name="Message" class="list-icon"></mdi>
            <span class="main-navigation-item-title" i18n>Invites</span>
          </a>
        </li>
      </ul>

      <div *ngIf="hasFeature(Feature.HostAFreeEventButton)" class="actions">
        <a [routerLink]="['/sign-up-create-url']" class="btn btn-outline-primary" target="_blank" i18n
          >Host a free event</a
        >
      </div>
    </div>
    <div *ngIf="authService.activeUser" class="nav-utility">
      <div class="nav-utility-action" (click)="hideMenu()" [routerLink]="['/combined/search']">
        <mdi name="Search"></mdi>
      </div>
      <div class="nav-utility-action" (click)="hideMenu()" [routerLink]="['/combined/notifications']">
        <mdi name="Notifications"></mdi>
        <div *ngIf="notificationsBadgeNumber" class="nav-icon-number">{{ notificationsBadgeNumber }}</div>
      </div>
      <div
        *ngIf="authService.selectedCompany?.id && authService.isCompanyAdmin"
        class="nav-utility-action"
        (click)="hideMenu()"
        [routerLink]="['/combined/business/' + authService.selectedCompany?.id]"
      >
        <mdi name="Settings"></mdi>
      </div>
      <app-app-bar-user style="margin-left: 6px"></app-app-bar-user>
    </div>
    <div *ngIf="!authService.activeUser" class="nav-utility">
      <a [routerLink]="['/sign-in']" class="btn btn-sm btn-outline-primary" (click)="hideMenu()" i18n="@@signIn"
        >Sign In</a
      >
      <a
        *ngIf="authService.companySettings?.isselfregister !== 0"
        [routerLink]="['/sign-up']"
        class="btn btn-sm btn-outline-primary"
        (click)="hideMenu()"
        i18n="@@createAccount"
        >Create Account</a
      >
    </div>
  </div>
  <div class="layout-content-col">
    <div *ngIf="loading" class="loading"></div>
    <router-outlet></router-outlet>
  </div>
</div>
