import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { configUrl } from '@env/environment';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class InviteResolver implements Resolve<any> {
  constructor(private http: HttpClient) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.queryParamMap.get('invite');
    if (!id) {
      return of(null);
    }
    return this.getData(id);
  }

  getData(id: string): Observable<any> {
    const url = `${configUrl.companyInvite}/${id}`;
    return this.http.get(url).pipe(
      catchError((err) => {
        return of(err);
      }),
    );
  }
}
