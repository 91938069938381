import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { AppointmentsService } from '../services';
import { Appointment } from '@app/shared/model';

@Injectable()
export class AppointmentsMyBookedResolver implements Resolve<any> {
  constructor(private appointmentsService: AppointmentsService) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): Observable<any> {
    return this.getData();
  }

  getData(): Observable<Appointment[]> {
    return this.appointmentsService.getMyBooked();
  }
}
