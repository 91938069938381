import { ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService, BusinessService, CalendarService } from '@app/core/services';
import { Feature } from '@app/shared/model';
import { configUrl } from '@env/environment';
import { parseISO } from 'date-fns';
import { forkJoin, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss'],
})
export class CompanyInfoComponent {
  title: string;
  configUrl = configUrl;

  sponsors: any;
  sessions;
  days;

  types = [1, 2, 3];

  typeOptions: any = null;

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    private router: Router,
    private business: BusinessService,
    private viewportScroller: ViewportScroller,
    private calendarService: CalendarService,
    private sanitizer: DomSanitizer,
  ) {
    this.title = this.authService.selectedCompany
      ? this.authService.selectedCompany.name
      : $localize`:@@reception:Reception`;
    this.load();
    this.loadLevels();
  }

  load() {
    this.http.get<any[]>(configUrl.sponsor).subscribe((sponsors) => (this.sponsors = sponsors));

    this.http
      .get<any[]>(configUrl.eventDay)
      .pipe(
        switchMap((days: any[]) => {
          const sessions = days.sort(this.sortDays).reduce((obj, val) => {
            obj[val.id] = this.http
              .get<any[]>(`${configUrl.eventDay}${val.id}/sessions`)
              .pipe(map((sessions) => sessions.sort(this.sortSessions)));
            return obj;
          }, {});
          return forkJoin({
            days: of(days),
            sessions: forkJoin(sessions),
          });
        }),
      )
      .subscribe(({ days, sessions }) => {
        this.sessions = sessions;
        this.days = days;
      });
  }

  loadLevels() {
    this.http.get(configUrl.sponsorLevels).subscribe((levels: any[]) => {
      if (levels.length) {
        this.typeOptions = levels;
      } else {
        this.typeOptions = [
          { id: '1', name: 'Gold' },
          { id: '2', name: 'Silver' },
          { id: '3', name: 'Bronze' },
        ];
      }
    });
  }

  sortSessions(a, b) {
    if (parseISO(a.startdate) > parseISO(b.startdate)) {
      return 1;
    } else if (parseISO(a.startdate) < parseISO(b.startdate)) {
      return -1;
    } else {
      return 0;
    }
  }

  sortDays(a, b) {
    if (parseISO(a.eventdate) > parseISO(b.eventdate)) {
      return 1;
    } else if (parseISO(a.eventdate) < parseISO(b.eventdate)) {
      return -1;
    } else {
      return 0;
    }
  }

  filterByType(obj, query) {
    return obj.type == query;
  }

  navigate(session) {
    this.router.navigate([this.getSessionLink(session)]);
  }

  getSessionLink(session) {
    switch (session.type) {
      case 1:
        return `/combined/stage/${session.stageid}`;
      case 2:
        return `/live/${session.meetingid}`;
      case 3:
        return '/combined/roulette';
      case 4:
        return '/combined/expo';
    }

    return '/';
  }

  scrollTo(elementId: string) {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  eventUrl(session, calendar) {
    let url: string;
    const parsedUrl = new URL(window.location.href);
    const baseUrl = parsedUrl.origin;
    const location = `${baseUrl}${this.getSessionLink(session)}`;
    const title = [
      null,
      $localize`:@@liveStage:Live Stage`,
      $localize`:@@meeting:Meeting`,
      $localize`:@@networking:Networking`,
      $localize`:@@expoHall:Expo Hall`,
    ][session.type];

    const event = {
      name: session.title,
      details: `${title}\n\n${location}`,
      location,
      startsAt: session.startdate,
      endsAt: session.enddate,
    };

    switch (calendar) {
      case 'ical':
        url = this.calendarService.makeICSCalendarUrl(event);
        break;
      case 'google':
        url = this.calendarService.makeGoogleCalendarUrl(event);
        break;
      case 'outlook':
        url = this.calendarService.makeOutlookCalendarUrl(event);
        break;
    }

    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getVideoPath() {
    const filename = this.authService.companySettings.videoclip;
    if (!filename) {
      return null;
    }

    const [name, ext] = filename.split('.');

    switch (ext) {
      case 'webm':
        return `${configUrl.bucketCompanyVideo}${filename}`;
      case 'mp4':
        return `${configUrl.videoUrlAWS}${filename}`;
      default:
        return null;
    }
  }
}
