<form #inviteForm="ngForm" autocomplete="off">
  <div class="ui-modal-header">
    <h2>{{ modalTitle }}</h2>
    <div class="ui-modal-close" (click)="dismiss()">
      <mdi name="Close"></mdi>
    </div>
  </div>

  <div class="ui-modal-body">
    <div class="members-tabs">
      <ul>
        <li [ngClass]="{ active: page === 1 }"><a (click)="changePage(1)" i18n>My Videos</a></li>
        <li [ngClass]="{ active: page === 2 }"><a (click)="changePage(2)" i18n>Public Timeline</a></li>
      </ul>
    </div>
    <div
      *ngIf="page === 2"
      class="members-list posts-list"
      [ngClass]="{ 'scroll-at-top': listTopShadow, 'scroll-at-bottom': listBottomShadow }"
    >
      <ul cdkScrollable #usersList *ngIf="timeline.length">
        <li *ngFor="let post of timeline; let i = index" class="has-subtitle" style="padding: 0 70px 0 0">
          <div class="members-list-name">
            {{ post.title }}
            <div class="members-list-subtitle">
              {{ qs.formatDateInbox(post.createdat) }} &bull; {{ post.authorname }}
            </div>
          </div>
          <a (click)="performAction(post)" class="action">{{ actionTitle }}</a>
        </li>
      </ul>
      <div *ngIf="!timeline.length" class="suggestions-list-no-results" i18n>No Posts</div>
    </div>
    <div
      *ngIf="page === 1"
      class="members-list posts-list"
      [ngClass]="{ 'scroll-at-top': listTopShadow, 'scroll-at-bottom': listBottomShadow }"
    >
      <ul cdkScrollable #usersList *ngIf="myVideos.length">
        <li *ngFor="let post of myVideos; let i = index" class="has-subtitle" style="padding: 0 70px 0 0">
          <div class="members-list-name">
            {{ post.title }}
            <div class="members-list-subtitle">
              {{ qs.formatDateInbox(post.createdat) }} &bull; {{ post.authorname }}
            </div>
          </div>
          <a (click)="performAction(post)" class="action">{{ actionTitle }}</a>
        </li>
      </ul>
      <div *ngIf="!myVideos.length" class="suggestions-list-no-results" i18n>No Posts</div>
    </div>
  </div>

  <div class="ui-modal-footer"></div>
</form>
