import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Router } from '@angular/router';
import { AuthService, BusinessService, LiveVideoService } from '@app/core/services';
import { ToastrService } from 'ngx-toastr';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-roulette',
  templateUrl: './roulette.component.html',
  styleUrls: ['./roulette.component.scss'],
})
export class RouletteComponent implements OnInit {
  users: any[];

  constructor(
    private businessService: BusinessService,
    private toastr: ToastrService,
    private authService: AuthService,
    private liveVideoService: LiveVideoService,
    private router: Router,
    private afDatabase: AngularFireDatabase,
  ) {
    this.loadUsers();
  }

  ngOnInit(): void {}

  loadUsers() {
    const subdomain = this.authService.getSubdomain() || '_global';
    this.afDatabase
      .list<any>(`presence/${subdomain}`)
      .valueChanges()
      .subscribe((users) => {
        this.users = users
          .filter((user) => user.status === 'online' && user.page === 'roulette')
          .filter((user) => user.uid !== this.authService.activeUser?.uid)
          .map((user) => ({ userid: user.uid }));
      });
  }

  callRandomUser() {
    if (!this.users?.length) {
      this.toastr.error($localize`Cannot find any users`);
    }

    const randomUsers = this.chooseRandomUsers();

    const firstUser = randomUsers.shift();
    this.makeCall(firstUser, randomUsers);
  }

  private chooseRandomUsers() {
    let userPool = this.users;
    const randomUsers = [];
    while (userPool.length && randomUsers.length < 3) {
      const chosenUser = this.chooseRandomUser(userPool);
      userPool = userPool.filter((user) => user !== chosenUser);
      randomUsers.push(chosenUser);
    }
    return randomUsers;
  }

  private chooseRandomUser(users: any[]): any {
    return users[Math.floor(Math.random() * users.length)];
  }

  private makeCall(user: any, queuedUsers: any[]) {
    this.liveVideoService
      .createRoom()
      .pipe(
        switchMap((room) => {
          return this.liveVideoService.addUserToRoom(user.userid, room.id).pipe(map((_) => room));
        }),
      )
      .subscribe((room) => {
        const queuedParam = queuedUsers.length ? queuedUsers.map((u) => u.userid).join(',') : null;
        this.navigateToRoom(room.id, user.userid, queuedParam);
      });
  }

  private navigateToRoom(roomId: string, call: string, queuedParam: string | null) {
    const returnUrl = '/combined/roulette';
    const exitOnComplete = true;
    const queryParams: any = { call, returnUrl, exitOnComplete };
    if (queuedParam) {
      queryParams.queued = queuedParam;
    }
    this.router.navigate([`/live/${roomId}`], { queryParams });
  }
}
