<div class="video-header">
  <h2>{{ clip.title }}</h2>
  <h3>{{ clip.authorname }}</h3>
  <div class="ui-modal-close" (click)="dismissAction()">
    <mdi name="Close" size="30"></mdi>
  </div>
</div>

<div class="video-body">
  <app-responsive-video
    [videoUrl]="configUrl.awsUrl + clip.videoid"
    [thumbnailUrl]="configUrl.thumbnailFS + clip.thumbnail"
    [autoplay]="true"
    [clipid]="clip.id"
  ></app-responsive-video>
</div>
