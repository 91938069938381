import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { configUrl } from '@env/environment';
import { forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

export interface CalendarEvent {
  name: string;
  details: string;
  location: string;
  startsAt: string;
  endsAt: string;
  add?: string;
}

type Query = { [key: string]: null | boolean | number | string };

@Injectable()
export class CalendarService {
  api = configUrl.URL;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
  ) {}

  public getMyExternalCalendars() {
    const uid = this.auth.activeUser?.uid;
    if (!uid) return of([]);

    const url = `${this.api}calendar/user/${uid}`;
    return this.http.get<any[]>(url);
  }

  public getMyExternalCalendarEvents() {
    return this.getMyExternalCalendars().pipe(
      switchMap((calendars) => {
        if (calendars.length === 0) return of([]);
        return forkJoin(calendars.map((calendar) => this.http.get<any[]>(`${this.api}calendar/${calendar.id}/json`)));
      }),
    );
  }

  public importCalendar(name, url) {
    const body = { name, url };
    return this.http.post<any[]>(`${this.api}calendar`, body);
  }

  public syncCalendar(calendar) {
    const url = `${this.api}calendar/${calendar.id}/refresh`;
    return this.http.get<any[]>(url);
  }

  public removeCalendar(calendar) {
    const url = `${this.api}calendar/${calendar.id}`;
    return this.http.delete<any[]>(url);
  }

  public makeGoogleCalendarUrl(event: CalendarEvent): string {
    let query: any = {
      action: 'TEMPLATE',
      dates: `${this.makeTime(event.startsAt)}/${this.makeTime(event.endsAt)}`,
      location: event.location,
      text: event.name,
      details: event.details,
    };

    if (event.add) {
      query.add = event.add;
    }

    return this.makeUrl('https://calendar.google.com/calendar/render', query);
  }

  public makeOutlookCalendarUrl(event: CalendarEvent): string {
    const url = this.makeUrl('https://outlook.live.com/owa', {
      rru: 'addevent',
      startdt: new Date(event.startsAt).toISOString(),
      enddt: new Date(event.endsAt).toISOString(),
      subject: event.name,
      location: event.location,
      body: event.details,
      allday: false,
      path: '/calendar/view/Month',
    });

    return url;
  }

  public makeYahooCalendarUrl(event: CalendarEvent): string {
    const url = this.makeUrl('https://calendar.yahoo.com', {
      v: 60,
      view: 'd',
      type: 20,
      title: event.name,
      st: this.makeTime(event.startsAt),
      dur: this.makeDuration(event),
      desc: event.details,
      in_loc: event.location,
    });

    return url;
  }

  public makeICSCalendarUrl(event: CalendarEvent): string {
    const components = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      `URL:${document.URL}`,
      `DTSTART:${this.makeTime(event.startsAt)}`,
      `DTEND:${this.makeTime(event.endsAt)}`,
      `SUMMARY:${event.name}`,
      `DESCRIPTION:${event.details}`,
      `LOCATION:${event.location}`,
      'END:VEVENT',
      'END:VCALENDAR',
    ];

    return encodeURI(`data:text/calendar;charset=utf8,${components.join('\n')}`);
  }

  private makeUrl(base: string, query: Query) {
    return Object.keys(query).reduce((accum, key, index) => {
      const value = query[key];

      if (value !== null) {
        return `${accum}${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(value)}`;
      }
      return accum;
    }, base);
  }

  private makeTime(time: string) {
    return new Date(time).toISOString().replace(/[-:]|\.\d{3}/g, '');
  }

  private makeDuration(event: CalendarEvent) {
    const minutes = Math.floor((+new Date(event.endsAt) - +new Date(event.startsAt)) / 60 / 1000);
    return `${Math.floor(minutes / 60)}:${`0${minutes % 60}`.slice(-2)}`;
  }
}
