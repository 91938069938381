import { Component, Output, EventEmitter } from '@angular/core';
import { AuthService, ClipService, LoggerService } from '@app/core/services';

import { config } from '@env/environment';
import { Subscription } from 'rxjs';

import * as RecordRTC from 'recordrtc';

enum RecordingState {
  NotStarted,
  Recording,
  Finished,
}

@Component({
  selector: 'app-post-record-audio',
  templateUrl: './post-record-audio.component.html',
  styleUrls: ['./post-record-audio.component.scss'],
})
export class PostRecordAudioComponent {
  @Output() fileRecorded = new EventEmitter<any>();
  @Output() transcription = new EventEmitter<string>();

  private transcriptionSubscription: Subscription;

  public RecordingState = RecordingState;
  private config = config;

  private recorder: any;
  private player: any;

  public recordingState = RecordingState.NotStarted;
  public playing = false;
  private timeLimit: number;
  private recordingTimer: any;
  private url: string;

  private mediaStreamSource: any;
  private meter: any;
  private levelsTimeout: any;

  public animation = '';
  public shadow = '';

  constructor(
    private authService: AuthService,
    private clipService: ClipService,
    private logger: LoggerService,
  ) {
    this.timeLimit = this.authService.selectedCompany?.recordingtime
      ? this.authService.selectedCompany?.recordingtime * 1000
      : this.config.defaultAudioLimit;

    this.player = new Audio();

    this.initRecorder();
  }

  initRecorder() {}

  async startRecording() {
    this.recordingState = RecordingState.Recording;
    this.animation = `progress ${this.timeLimit / 1000}s linear forwards`;

    let stream;

    try {
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.recorder = new RecordRTC.RecordRTCPromisesHandler(stream, {
        type: 'audio',
      });
    } catch (err) {
      this.logger.error(err);
    }

    this.recorder.startRecording();

    this.transcriptionSubscription = this.clipService.transcription$.subscribe((transcription) =>
      this.transcription.emit(transcription),
    );
    this.clipService.startRecognizingSpeech();

    const audioCtx = new AudioContext();
    this.mediaStreamSource = audioCtx.createMediaStreamSource(stream);
    this.meter = this.createAudioMeter(audioCtx);
    this.mediaStreamSource.connect(this.meter);

    this.levelsTimeout = setInterval(() => {
      const size = Math.min(this.meter.volume * 500, 50);
      this.shadow = `0 2px 8px rgba(0, 0, 0, 0.3), 0 0 0 ${size}px #f4f4f4`;
    }, 50);

    this.recordingTimer = setTimeout(() => {
      this.stopRecording();
    }, this.timeLimit);
  }

  async stopRecording() {
    this.clipService.stopRecognizingSpeech();

    this.recordingState = RecordingState.Finished;
    this.animation = '';

    clearTimeout(this.levelsTimeout);
    this.levelsTimeout = null;
    this.mediaStreamSource?.disconnect(this.meter);
    this.meter = null;
    this.mediaStreamSource = null;

    await this.recorder.stopRecording();
    const blob = await this.recorder.getBlob();

    this.url = URL.createObjectURL(blob);
    this.player.src = this.url;

    this.fileRecorded.emit(blob);

    clearTimeout(this.recordingTimer);
    this.recordingTimer = null;
  }

  play() {
    this.playing = true;
    this.animation = `progress ${this.player.duration}s linear forwards`;

    this.player.onended = () => {
      this.playing = false;
      this.animation = '';
    };

    this.player.currentTime = 0;
    this.player.volume = 1;

    this.player.play();
  }

  stop() {
    this.playing = false;
    this.animation = '';

    this.player.pause();
  }

  reset() {
    this.transcriptionSubscription?.unsubscribe();
    this.transcriptionSubscription = null;

    if (this.recordingState === RecordingState.Recording) {
      this.stopRecording();
    }
    this.recordingState = RecordingState.NotStarted;
  }

  private createAudioMeter(audioContext) {
    function volumeAudioProcess(event) {
      const buf = event.inputBuffer.getChannelData(0);
      const bufLength = buf.length;
      let sum = 0;
      let x;

      // Do a root-mean-square on the samples: sum up the squares...
      for (let i = 0; i < bufLength; i++) {
        x = buf[i];
        if (Math.abs(x) >= this.clipLevel) {
          this.clipping = true;
          this.lastClip = window.performance.now();
        }
        sum += x * x;
      }

      // ... then take the square root of the sum.
      const rms = Math.sqrt(sum / bufLength);

      // Now smooth this out with the averaging factor applied
      // to the previous sample - take the max here because we
      // want "fast attack, slow release."
      this.volume = Math.max(rms, this.volume * this.averaging);
    }

    const processor = audioContext.createScriptProcessor(512);
    processor.onaudioprocess = volumeAudioProcess;
    processor.clipping = false;
    processor.lastClip = 0;
    processor.volume = 0;
    processor.clipLevel = 0.98;
    processor.averaging = 0.95;
    processor.clipLag = 750;

    // this will have no effect, since we don't copy the input to the output,
    // but works around a current Chrome bug.
    processor.connect(audioContext.destination);

    processor.checkClipping = () => {
      if (!processor.clipping) {
        return false;
      }
      if (processor.lastClip + processor.clipLag < window.performance.now()) {
        processor.clipping = false;
      }
      return processor.clipping;
    };

    processor.shutdown = () => {
      processor.disconnect();
      processor.onaudioprocess = null;
    };

    return processor;
  }
}
