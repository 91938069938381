<div *ngIf="user !== undefined" class="page-wrapper" [ngClass]="{ 'page-wrapper-has-navbar': (nav$ | async)?.visible }">
  <app-app-bar *ngIf="(nav$ | async)?.visible"></app-app-bar>
  <router-outlet></router-outlet>
</div>

<div *ngIf="user === undefined" class="logo-container">
  <img id="logo-loading" src="assets/images/logo.png" />
  <app-loading></app-loading>
</div>

<div class="modals">
  <div id="modal-container" class="ui-modal-hidden"></div>
  <div id="overlay" (click)="removeModal()" class="ui-modal-hidden"></div>
  <div id="popup-container" class="ui-popup-hidden"></div>
</div>
