import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges {
  @Input() columns: any;
  @Input() rows: any;

  @Input() pagination = false;
  @Input() paginationLimit = 10;
  @Input() paginationOffset = 0;

  @Output() requestPage = new EventEmitter<any>();

  freezeUI = false;

  get hasActions(): boolean {
    return this.rows?.filter((row) => row.actions).length ?? false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.rows.firstChange) {
      this.freezeUI = false;
    }
  }

  prev() {
    if (this.freezeUI) {
      return;
    }

    this.freezeUI = true;

    const event = {
      offset: Math.max(this.paginationOffset - this.paginationLimit, 0),
      limit: this.paginationLimit,
    };

    this.requestPage.emit(event);
  }

  next() {
    if (this.freezeUI) {
      return;
    }

    this.freezeUI = true;

    const event = {
      offset: this.paginationOffset + this.paginationLimit,
      limit: this.paginationLimit,
    };

    this.requestPage.emit(event);
  }
}
