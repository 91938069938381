<form [formGroup]="inputForm" #ngForm="ngForm" (ngSubmit)="submitForm(inputForm)" novalidate>
  <div class="ui-modal-header">
    <h2>{{ title }}</h2>
    <div class="ui-modal-close" (click)="closeAction()">
      <mdi name="Close"></mdi>
    </div>
  </div>

  <div class="ui-modal-body">
    <div class="mb-3">
      <label for="text">{{ label }}</label>
      <input
        autocomplete="off"
        type="text"
        [class.is-invalid]="inputForm.get('text').invalid && (inputForm.get('text').dirty || ngForm.submitted)"
        class="form-control"
        id="text"
        name="text"
        formControlName="text"
        i18n-placeholder
        placeholder="Optional link"
      />
      <div class="invalid-feedback">{{ errorMessage }}</div>
    </div>
  </div>

  <div class="ui-modal-footer">
    <button class="btn btn-md btn-light" (click)="closeAction()" i18n="@@cancel">Cancel</button>
    <button type="submit" class="btn btn-primary" i18n>OK</button>
  </div>
</form>
