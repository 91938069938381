import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'janPublisher',
})
export class JanPublisherPipe implements PipeTransform {
  transform(value: string, query: string): string | boolean {
    const [uid, screenType = '0', userType = '0'] = value.split(':');

    switch (query) {
      case 'isAuthenticatedUser':
        return userType === '2';
      case 'isScreen':
        return screenType === '2';
      case 'userId':
      default:
        return uid;
    }
  }
}
