<div class="recordaudio">
  <div class="pie">
    <svg>
      <circle r="30.5" cx="35" cy="35" [style.animation]="animation"></circle>
    </svg>
  </div>
  <div class="recordaudio-btn-wrapper" *ngIf="recordingState === RecordingState.NotStarted">
    <div (click)="startRecording()" class="recordaudio-btn recordaudio-btn-record"></div>
  </div>
  <div class="recordaudio-btn-wrapper" *ngIf="recordingState === RecordingState.Recording">
    <div
      (click)="stopRecording()"
      class="recordaudio-btn recordaudio-btn-record-stop"
      [ngStyle]="{ 'box-shadow': shadow }"
    ></div>
  </div>
  <div class="recordaudio-btn-wrapper" *ngIf="recordingState === RecordingState.Finished && !playing">
    <div (click)="play()" class="recordaudio-btn recordaudio-btn-play"></div>
  </div>
  <div class="recordaudio-btn-wrapper" *ngIf="recordingState === RecordingState.Finished && playing">
    <div (click)="stop()" class="recordaudio-btn recordaudio-btn-play-stop"></div>
  </div>
</div>
