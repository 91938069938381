import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { SharedModule } from '@app/shared/shared.module';

import { AppBarComponent } from './app-bar/app-bar.component';
import { RefreshComponent } from './refresh/refresh.component';
import { LiveVideoIncomingCallToastComponent } from './live-video-incoming-call-toast/live-video-incoming-call-toast.component';
import { UploadingToastComponent } from './uploading-toast/uploading-toast.component';

import { AuthInterceptor } from './auth.interceptor';
import { AuthGuardService } from './guards/auth-guard.service';
import { EmailVerifiedGuardService } from './guards/email-verified-guard.service';
import { RoomGuardService } from './guards/room-guard.service';

import {
  ChatResolver,
  GroupMembersResolver,
  GroupResolver,
  MeetingListResolver,
  MeetingMembersResolver,
  MeetingResolver,
  ExpoResolver,
  UserResolve,
  StageResolver,
  GreenroomResolver,
  PollsListResolver,
  PollResolver,
  PollResultsResolver,
  PollOptionsResolver,
  AppointmentsResolver,
  StageListResolver,
} from './resolvers';

import {
  AdminReportedClipsService,
  AnalyticsService,
  AudioPlayerService,
  AuthService,
  AwsService,
  BusinessService,
  CalendarService,
  CategoryService,
  ChatService,
  ClipService,
  GlobalvariablesService,
  GroupService,
  JanService,
  LiveVideoService,
  LiveVideoCallToastsService,
  LoggerService,
  MeetingsService,
  NavService,
  NotificationsService,
  QueryService,
  SignupBusinessService,
  TimelineService,
  UiModalDomService,
  UiModalService,
  UiPopupService,
  UserService,
  UiPopupDomService,
  PollsService,
  ExportService,
  LangService,
  AppointmentsService,
  RecordingService,
} from './services';
import { ScheduleDayListResolver } from './resolvers/schedule-day-list-resolver.service';
import { InviteResolver } from './resolvers/invite-resolver.service';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthPublicGuardService } from './guards/auth-public-guard.service';
import { AppointmentsMyResolver } from './resolvers/appointments-my-resolver.service';
import { AppointmentsUsersResolver } from './resolvers/appointments-users.resolver.service';
import { AppointmentsMyBookedResolver } from './resolvers/appointments-my-booked.resolver.service';
import { SponsorLevelsResolver } from './resolvers/sponsor-levels-resolver.service';

@NgModule({
  declarations: [
    AppBarComponent,
    RefreshComponent,
    NotFoundComponent,
    LiveVideoIncomingCallToastComponent,
    UploadingToastComponent,
  ],
  providers: [
    ChatResolver,
    GroupMembersResolver,
    GroupResolver,
    MeetingListResolver,
    MeetingMembersResolver,
    MeetingResolver,
    ExpoResolver,
    UserResolve,
    StageResolver,
    GreenroomResolver,
    ScheduleDayListResolver,
    SponsorLevelsResolver,
    InviteResolver,
    PollsListResolver,
    PollResolver,
    PollResultsResolver,
    PollOptionsResolver,
    AdminReportedClipsService,
    AnalyticsService,
    AudioPlayerService,
    AuthService,
    AwsService,
    BusinessService,
    CalendarService,
    CategoryService,
    ChatService,
    ClipService,
    GlobalvariablesService,
    GroupService,
    JanService,
    LangService,
    LiveVideoService,
    LiveVideoCallToastsService,
    LoggerService,
    MeetingsService,
    NavService,
    NotificationsService,
    QueryService,
    SignupBusinessService,
    TimelineService,
    UiModalDomService,
    UiPopupDomService,
    UiModalService,
    UiPopupService,
    UserService,
    AuthGuardService,
    AuthPublicGuardService,
    EmailVerifiedGuardService,
    RoomGuardService,
    RecordingService,
    PollsService,
    ExportService,
    AppointmentsService,
    AppointmentsResolver,
    AppointmentsUsersResolver,
    AppointmentsMyResolver,
    AppointmentsMyBookedResolver,
    StageListResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  imports: [SharedModule],
  exports: [
    AppBarComponent,
    RefreshComponent,
    NotFoundComponent,
    LiveVideoIncomingCallToastComponent,
    UploadingToastComponent,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
    }
  }
}
