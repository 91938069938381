import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { QueryService } from '@app/core/services';

@Component({
  selector: 'app-post-image',
  templateUrl: './post-image.component.html',
  styleUrls: ['./post-image.component.scss'],
})
export class PostImageComponent implements OnInit {
  @Input() fullSize: boolean;
  @Input() audioImage: string;

  imageSrc: any = '/assets/images/spacer.gif';
  fullScreen = false;

  constructor(
    public qs: QueryService,
    public sanitizer: DomSanitizer,
  ) {}

  originalSize() {
    if (this.fullScreen) {
      this.fullScreen = false;
    } else {
      this.fullScreen = true;
    }
  }

  ngOnInit() {
    this.qs.getAudioImage(this.audioImage, (getAudioImageObserv) => {
      getAudioImageObserv.subscribe((response) => {
        if (response) {
          const data = new Uint8Array(response);
          const blob = new Blob([data], { type: 'image/png' });
          const blobURL = URL.createObjectURL(blob);

          this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(blobURL);
        }
      });
    });
  }
}
