<div class="viewer" [style.width]="containerWidth + 'px'">
  <img
    #img
    [src]="imageUrl"
    (load)="onLoad(img)"
    [style.width]="width ? width * zoom + 'px' : 'auto'"
    [style.height]="height ? height * zoom + 'px' : 'auto'"
    [style.top]="y - ((height || 0) * zoom) / 2 + centerY + offsetY + 'px'"
    [style.left]="x - ((width || 0) * zoom) / 2 + centerX + offsetX + 'px'"
    [style.visibility]="width && height ? 'visible' : 'hidden'"
  />
  <div
    class="overlay"
    (mousedown)="onMouseDown($event)"
    (mousemove)="onMouseMove($event)"
    (mouseup)="onMouseUp($event)"
    (mouseout)="onMouseUp($event)"
  >
    <div class="guides" [style.left]="centerX - 0.5 * circle + 'px'"></div>
  </div>
</div>

<div class="zoom" [style.width]="containerWidth + 'px'">
  <input type="range" min="1" max="5" step="0.05" [ngModel]="zoom" (ngModelChange)="onChangeZoom($event)" />
</div>

<div class="actions">
  <a (click)="cancel()" class="btn btn-default" i18n="@@cancel">Cancel</a>&nbsp;
  <a (click)="finish()" class="btn btn-primary" i18n="@@next">Next</a>
</div>
