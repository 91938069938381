import { Action } from '.';

export const Actions: { [key: string]: Action } = {
  editGroup: { id: 'editGroup', name: $localize`Edit Group`, icon: 'Settings' },
  viewMembers: { id: 'viewMembers', name: $localize`View Members`, icon: 'Group' },
  inviteToGroup: { id: 'inviteToGroup', name: $localize`Invite To Group`, icon: 'UserAdd' },
  inviteByEmail: { id: 'inviteByEmail', name: $localize`Invite By Email`, icon: 'Invite' },
  leaveGroup: { id: 'leaveGroup', name: $localize`Leave Group`, icon: 'Bin' },
  deleteGroup: { id: 'deleteGroup', name: $localize`Delete Group`, icon: 'Bin' },
  addGroup: { id: 'addGroup', name: $localize`Add Group`, icon: 'Plus' },
  addChat: { id: 'addChat', name: $localize`New Chat`, icon: 'Plus' },
  uploadVideo: { id: 'uploadVideo', name: $localize`:@@uploadVideo:Upload Video`, icon: 'Plus' },
  addCompany: { id: 'addCompany', name: $localize`Add Community`, icon: 'Plus' },
  addMeeting: { id: 'addMeeting', name: $localize`Add Meeting`, icon: 'Plus' },
  viewProfile: { id: 'viewProfile', name: $localize`View Profile`, icon: 'User' },
  makeCall: { id: 'makeCall', name: $localize`:@@call:Call`, icon: 'Video' },
  editProfile: { id: 'editProfile', name: $localize`:@@editProfile:Edit Profile`, icon: 'Edit' },
  message: { id: 'message', name: $localize`:@@message:Message`, icon: 'Message' },
  join: { id: 'join', name: $localize`Enter Stage`, icon: 'Plus' },
  leave: { id: 'leave', name: $localize`Leaver as Presenter`, icon: 'Plus' },
  addSponsor: { id: 'addSponsor', name: $localize`Add Sponsor`, icon: 'Plus' },
  addSponsorLevel: { id: 'addSponsorLevel', name: $localize`Add Sponsor Level`, icon: 'Plus' },
  addScheduleDay: { id: 'addScheduleDay', name: $localize`Add Day`, icon: 'Plus' },
  addExpoTable: { id: 'addExpoTable', name: $localize`Add Table`, icon: 'Plus' },
  addCommunityAction: { id: 'addCommunityAction', name: $localize`Add`, icon: 'Plus' },
  addPoll: { id: 'addPoll', name: $localize`:@@addPoll:Add Poll`, icon: 'Plus' },
  addRecording: { id: 'addRecording', name: $localize`:@@scheduleRecording:Schedule Recording`, icon: 'Plus' },
  manage: { id: 'manage', name: $localize`Manage`, icon: 'Settings' },
};
