import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[uploading-toast]',
  templateUrl: './uploading-toast.component.html',
  styleUrls: ['./uploading-toast.component.scss'],
})
export class UploadingToastComponent extends Toast {
  progress = 0;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  updateProgress() {
    this.width = this.progress;
  }
}
