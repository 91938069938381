import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SharedModule } from '@app/shared/shared.module';

import { SalesComponent } from './sales/sales.component';

const twitterCardRoutes: Routes = [
  {
    path: 'share/:id',
    component: SalesComponent,
  },
];

@NgModule({
  declarations: [SalesComponent],
  imports: [SharedModule, RouterModule.forChild(twitterCardRoutes)],
  exports: [RouterModule],
})
export class SalesModule {}
