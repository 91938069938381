import { Component, Input, Output, EventEmitter } from '@angular/core';

import { TileAction } from '../../model';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() maintitle: string;
  @Input() subtitle: string;
  @Input() imageId: string;
  @Input() userId: string;
  @Input() icon: string;
  @Input() actions: any[] = [];

  @Output() handleAction = new EventEmitter<TileAction>();

  actionClick(event: MouseEvent, action: TileAction) {
    event.stopImmediatePropagation();
    this.handleAction.emit(action);
  }
}
