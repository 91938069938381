import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';

import { NavComponent } from './nav/nav.component';
import { ModalGroupAddComponent } from './modal-group-add/modal-group-add.component';

@NgModule({
  declarations: [NavComponent, ModalGroupAddComponent],
  imports: [SharedModule],
  exports: [NavComponent],
})
export class NavModule {}
